import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InputDateRange from '../../Components/InputDateRange';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangeBox } from '../../Components/InputDateRange/styles';
import { actions, dispatch as TodosDispatcher } from '../../Reducers/TodosFilter';
import { Block, Column, Row, Vertical } from '../../Components/StyledComponents';
import { VirtualMap } from '../../Components/VirtualList';
import { statusText } from '../../Components/StatusIcon'
import CommerceDashboardEditor from '../CommerceDashboardEditor';
import { useParams } from 'react-router-dom';
import { UserAvatar } from '../../Components/AvatarUserIcon';

const Icon = (Prop) => ({ InputProps: { endAdornment: <InputAdornment position="start"><Prop /></InputAdornment> } })

/**
 * @param {import('../../typeroots').RouterDom} props 
 */
export default function CommerceDashboard(props) {
  const dispatch = useDispatch();
  const { dates, status, collection } = useSelector(state => state.todosFilter);

  const [search, setSearch] = React.useState('');
  const [payment, setPayment] = React.useState('');
  const { edit, uuid } = useParams();


  return (

    <Paper style={{ minHeight: '100vh', padding: 12 }}>

      <Block>
        <Typography>Relatório de Pedidos</Typography>
        <Typography gutterBottom variant="subtitle2">
          Consulte os pedidos feitos em seu restaurante
        </Typography>
      </Block>

      <Grid spacing={2} container alignContent="space-around">
        <Grid item md={4} xs={12}>
          <InputDateRange
            allowSameDateSelection={true}
            startText="De"
            endText="Até"
            value={dates}
            onAccept={e => e && TodosDispatcher.filter(e)}
            onChange={ /**@param {Date[]}*/([begin,finish]) => {
              if(finish) {
                finish.setHours(23,59,59)
              }
              dispatch(actions.setDates([begin,finish]))
            }}
            variant="outlined"
            component={(startProps, endProps) =>
              <DateRangeBox>
                <TextField size="small" {...startProps} {...Icon(DateRangeIcon)} />
                <TextField size="small" {...endProps} {...Icon(DateRangeIcon)} InputProps={{
                  endAdornment: 
                    <InputAdornment>
                      <IconButton size="small" onClick={() => TodosDispatcher.filter(dates)}>
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                }} />
              </DateRangeBox>
            } />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField fullWidth
            variant="outlined"
            value={search}
            onChange={e => setSearch(e.target.value)}
            label="Pedido"
            size="small"
            helperText="Pesquisar"
          />
        </Grid>

        <Grid item md={2} xs={6}>
          <TextField fullWidth variant="outlined" value={status} onChange={e => dispatch(actions.setStatus(e.target.value))} label="Status" select size="small" helperText="Selecionar" >
            <MenuItem value="ALL">Todos</MenuItem>
            {Array.from(new Set(collection.map(e => e.status))).map(state => <MenuItem key={state} value={state}>{statusText(state)}</MenuItem>)}
          </TextField>
        </Grid>

        <Grid item md={2} xs={6}>
          <TextField fullWidth variant="outlined" value={payment} onChange={e => setPayment(e.target.value)} label="Pagamento" select size="small" helperText="Filtrar">
            {Array.from(new Set(collection.map(e => e.description?.rmvParentheses().After('|') ))).map(string => <MenuItem key={string + '-paid-types'} value={string}>{string || 'Tudo'}</MenuItem>)}
          </TextField>
        </Grid>
      </Grid>

      <Grid spacing={2} container style={{ marginTop: 12 }} alignContent="space-around">
        <Grid item xs={12}>
          <VirtualMap
            calcHeight={-170}
            rowHeight={62}
            collection={collection.$filter(search).filter(e => status === "ALL" ? true : e.status === status).filter(e => payment ? e.description?.includes(payment) : e)}
          >
            {x => {
              const user = x.users.find(e=> e.mode === "DELIVER")?.user;

              return (
                <Row dense={true} button divider onClick={() => props.history.push(`/commerce/dashboard/task/${x.uuid}`)}>
                  <Column
                    display="desktop"
                    max='45px'
                    secondary={"#"+x.id}
                    secondaryTypographyProps={{
                      variant: "caption",
                    }}
                  />
                  <Vertical orientation="vertical" />
                  <Column
                    max="250px"
                    primary={x.title}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{style: { fontSize: 11 }}}
                    secondary={x.description?.After('|')}
                  />
                  <Vertical orientation="vertical" />
                  <Column
                    max="65px"
                    align="right"
                    primary={`${x.topics.length} Itens`}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{style: { fontSize: 11 }}}
                    secondary={( x.topics.sum('price') + x.topics.reduce((a, b)=> a + b.toasts.sum('price'), 0 )).monetizeme()}
                  />
                  <Vertical orientation="vertical" />

                  <Column
                    display="desktop"
                    align="right"
                    max="120px"
                    secondary={x.begin.ptBR(true)}
                    secondaryTypographyProps={{style: { fontSize: 11 }}}
                    primary={x.begin.CompareMinutes(x.done).displayAsTime()}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                    }}
                  />
                  <Vertical orientation="vertical" />
                  <Column
                    display="desktop"
                    max="110px"
                    align="center"
                    secondary={x.done.ptBR(true)}
                    secondaryTypographyProps={{style: { fontSize: 11 }}}
                    primary={statusText(x.status)}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                    }}
                  />
                  <Vertical orientation="vertical" />
                  <Column
                    max="70px"
                    align="center"
                    primary={user && <UserAvatar user={user} /> }
                  />
                </Row>
              )
            }}

          </VirtualMap>
        </Grid>
      </Grid>

      <CommerceDashboardEditor edit={edit} uuid={uuid} {...props} />
    </Paper>

  );
}