/*eslint no-extend-native: ["error", { "exceptions": ["Object","ArrayBuffer","Array","String","Number","Function"] }]*/

Number.prototype.Format = function (mask, placeholder = '0') {
    var s = '' + this, r = '';
    while (s.length < mask.match(/#/g).length) s = placeholder + s;

    for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
        r += mask.charAt(im) === '#' ? s.charAt(is++) : mask.charAt(im);
    }
    return r;
}

Number.prototype.monetizeme = function () {
    return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(this);
};

Number.prototype.percentage = function ($max, fix = 0) {
    return ((this / $max) * 100).toFixed(fix)
}

Number.prototype.displayAsTime = function () {
    var hours = (this / 60);
    var rounded_hours = Math.floor(hours);
    return this > 60 ? `${rounded_hours}h` : `${this}m`;
};

//---------------------EXCLUSIVE FOR THIS PROJECT
Object.defineProperty(Number.prototype, 'asPriority', {
    value: function asPriority() {
        // console.log('Number', this.valueOf());
        switch (this.valueOf()) {
            case 1: return 'MEDIUM';
            case 2: return 'HIGH';
            case 3: return 'URGENT';
            default: return 'SLOW';
        }
    }
});

