import { makeStyles, styled } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';

const drawerWidth = 335;

export const BottomBar = styled(AppBar)({
    top: "auto", 
    bottom: 0
})

// or default makeStyles
export default makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        fontSize: 10,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        '& .MuiListItemIcon-root': {
            minWidth: 40
        },
    },
    paper: {
        backgroundColor: theme.palette.secondary,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    navList: {
        height: '100%',
        display: 'grid',
        alignContent: 'center',
        '& .MuiButtonBase-root': {
            height: 56
        }
    }
}));