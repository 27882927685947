import React from 'react';
import { Draggable,Droppable } from 'react-beautiful-dnd';
import { Map } from 'react-lodash';
import { SubHeader } from '../StyledComponents';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    opacity: isDragging ? .5 : 1,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    opacity: isDraggingOver ? .5 : 1,
    width: '100%',
    minHeight: '10vh'
});
/**
 * 
 * @param {{value: String, subheader: String, collection: {uuid: String, & Object}, children: import('react').ReactChildren}} props 
 */
export default function DragDropContainer({children:Children, ...props}) {
    return (

        <Droppable droppableId={props.value}>
            {(provided, snapshot) =>
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {props.subheader &&  <SubHeader>{props.subheader}</SubHeader> }
                    <Map 
                        collection={props.collection} 
                        iteratee={(toast, i) =>
                        <Draggable draggableId={toast.uuid} index={i} divider key={toast.uuid}>
                            {(provided, snapshot) =>
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                     <Children {...toast}/>
                                </div>
                            }
                        </Draggable>
                    } />
                </div>
            }
        </Droppable>

    );
}