import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import ExportMenuItem from "@material-ui/core/MenuItem";
// import useStyles from './styles';

const initialState = {
  mouseX: null,
  mouseY: null,

};

export const ContextDefaultState = {
  current: {
    Open: () => () => true,
    OpenMenu: () => true,
    Close: () => true,
    Get: () => true,
    ItemHas: () => true,
    ItemMatch: (key, value) => true,
    Item: {}
  }
};

export const MenuItem = ({when, ...props}) => {
  return Boolean(when) === true && (
    <ExportMenuItem {...props}>
      {props.children}
    </ExportMenuItem>
  )
};

/**
 * @example
 * 
 * hook
 const MenuRef = useRef({});
 const [menuItem, SetMenuItem] = useState({});
 * 
 <ContextMenu ref={MenuRef}>
    <MenuItem disabled>{menuItem.title}</MenuItem>
    <MenuItem onClick={() => doSOmething() && MenuRef.current.Close()}>Adicionar ao carrinho</MenuItem>
    </ContextMenu>
    * 
    * (InsideList)
    * onContextMenu={(event) => MenuRef.current.Open(event)(SetMenuItem(item))}
    * 
    *
    * 
 */

/**
 * @param {{children: React.ReactNode, keepMounted: Boolean, onClose: Function, MenuProps: import("@material-ui/core").MenuProps }} props
 */
const ContextMenu = React.forwardRef((props, ref) => {
  const [state, setState] = useState(initialState);
  const [item, setItem] = useState({});

  const Open = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  const handleClose = () => {
    setState(initialState);

    if(Boolean(item)) setItem({});
    if(Boolean(props.onClose)) setTimeout(() => props.onClose(), 300);
  };

  //(event) => MenuRef.current.Open(event)(SetMenuItem(snack) )
  ref.current.Open = event => callback => {
    Open(event);

    if (callback) {
      if (typeof callback === 'function') {

        return callback;
      }
      else {

        return setItem(callback)
      }
    }

  };

  //or
  //(event) => MenuRef.current.OpenMenu(event, snack)
  ref.current.OpenMenu = (event, callback) => {
    Open(event);

    if (callback) {
      if (typeof callback === 'function') {

        return callback;
      }
      else {

        return setItem(callback)
      }
    }
  };

  ref.current.Close = () => setTimeout(handleClose, 100);
  ref.current.Item = item;

  ref.current.Get = (prop) => item?.[prop] ? item[prop] : null;

  ref.current.ItemHas = prop => {
    if (item?.[prop])
      return true;

    return false

  };

  ref.current.ItemMatch = (key, value) => {
    return item && item[key] && item[key] === value;
  };

  return (
    <Menu 
      {...props.MenuProps}
      keepMounted={props.keepMounted}
      open={state.mouseY !== null}
      onClose={handleClose}
      onContextMenu={handleClose}
      PaperProps={{
        style: {
          minWidth: 285
        },
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      {props.children}
    </Menu>
  );
})

export const disableContextMenu = { onContextMenu: e => e.preventDefault() };
export default ContextMenu;