import React from 'react';

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

import FilterListIcon from '@material-ui/icons/FilterList'

var timeout;
class TopBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menu: false
        }

        this.setMenu = this.setMenu.bind(this);
    }

    setMenu = (menu) => this.setState({ menu })

    bindChange = (e) => {
        const {value} = e.target;

        if (timeout) clearTimeout(timeout);
        return timeout = setTimeout(() => this.props.setInput(value), 200);
    }

    render() {
        const { classes, ...props } = this.props;

        return (
            <ListItem component="form" onSubmit={props.onSubmit}>
                <ListItemText primary={<>
                    <TextField
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {props.startActions}
                                </InputAdornment>
                            ),
                            endAdornment: Boolean(props.actions) && (
                                <InputAdornment position="end">
                                    {props.actions}
                                </InputAdornment>
                            )
                        }}
                        onChange={this.bindChange}
                        placeholder={props.placeholder}
                        className={classes.input} />
                </>}
                />
                {Boolean(props.menuOptions) && <>
                    <Divider orientation="vertical" flexItem className={classes.divider} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={(e) => this.setMenu(e.currentTarget)}>
                            <FilterListIcon />
                        </IconButton>
                        <Menu anchorEl={this.state.menu} open={Boolean(this.state.menu)} onClose={() => this.setMenu(false)} >
                            {props.menuOptions.map(m =>
                                <MenuItem
                                    key={m.label}
                                    onClick={m.type === 'function' && m.fn}
                                    disabled={m.type === 'label'}
                                >{m.label}
                                </MenuItem>
                            )}
                        </Menu>
                    </ListItemSecondaryAction>
                </>}
            </ListItem>
        )
    }
}

export default withStyles({
    input: {
        width: '100%'
    },
    divider: {
        marginRight: 62,
    }
})(TopBar);