import React from 'react';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { ToolbarTitle, ToolbarIconButton, DrawerSecondary } from '../../Components/StyledComponents';
import CommerceRoutes from './routes';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import Badge from '@material-ui/core/Badge';

import ArrowBack from '@material-ui/icons/ArrowBack';
import RoomIcon from '@material-ui/icons/Room';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import InboxIcon from '@material-ui/icons/Inbox';
import MenuBookIcon from '@material-ui/icons/MenuBook';

// LARGE VIEW BUTTONS
import ArpeggioSound from '../../sounds/arpeggio.mp3';
import Allow from '../../Components/Allow';

const Arpeggio = new Audio(ArpeggioSound);
Arpeggio.loop = true;


const CommerceNewsOption = (props) => {

    React.useEffect(() => {
        if (props.news.length > 0) {
            Arpeggio.play();
        }

        else {

            Arpeggio.pause();
        }

        return () => {

            Arpeggio.pause();
        }

    }, [props])

    if (Boolean(props.news.length < 1)) {
        return null
    }

    return (
        <Badge badgeContent={props.news.length} color="secondary">
            <InboxIcon className={props.news.length > 0 ? "shake" : ""} />
        </Badge>
    )

}


class Commerce extends React.Component {


    getRouteLabel = () => {
        switch (this.props.history.location.pathname) {
            case '/commerce/kitchen': return 'Cozinha';
            case '/commerce/panel': return 'Pedidos';
            case '/commerce/delivery': return 'Entregas';

            default: return 'Comércio';
        }
    }

    render() {
        const { news, history, ...props } = this.props;

        return (
            <>
                <DrawerSecondary variant="permanent" anchor="right">
                    <List>
                        <Allow on="CommercePanel">
                            {Boolean(news.length > 0) &&
                                <ListItem component={NavLink} to="#news" button divider>
                                    <Tooltip placement="left" title="Novo!">
                                        <CommerceNewsOption  {...this.props} />
                                    </Tooltip>
                                </ListItem>
                            }
                        </Allow>

                        {CommerceRoutes.map(route =>
                            <Allow on={route.on} key={'route-' + route.to}>
                                <ListItem component={NavLink} to={route.to} button divider style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div style={{ textAlign: 'center', width: '57px', fontSize: 9 }}>
                                        <route.icon />
                                        <Typography variant="inherit" display="block">{route.label}</Typography>
                                    </div>
                                </ListItem>
                            </Allow>
                        )}

                    </List>
                </DrawerSecondary>

                <Hidden mdUp>
                    <AppBar position="fixed">
                        <Toolbar>
                            <ToolbarIconButton onClick={history.goBack} edge="start" color="inherit">
                                <ArrowBack />
                            </ToolbarIconButton>
                            <ToolbarTitle variant="h6" children={this.getRouteLabel() || 'Painel'} />

                            <Allow on="CommercePanel">
                                <ToolbarIconButton onClick={() => history.push('/commerce/panel')} size="small" color="inherit">
                                    <EqualizerIcon />
                                </ToolbarIconButton>
                            </Allow>
                            <Allow on="CommerceKitchen">
                                <ToolbarIconButton onClick={() => history.push('/commerce/kitchen')} size="small" color="inherit">
                                    <OutdoorGrillIcon />
                                </ToolbarIconButton>
                            </Allow>
                            <Allow on="CommerceDelivery">
                                <ToolbarIconButton onClick={() => history.push('/commerce/delivery')} size="small" color="inherit">
                                    <LocalShippingIcon />
                                </ToolbarIconButton>
                            </Allow>
                            <Allow on="CommerceMotoboy">
                                <ToolbarIconButton onClick={() => history.push('/commerce/deliveryman')} size="small" color="inherit">
                                    <RoomIcon />
                                </ToolbarIconButton>
                            </Allow>
                            <Allow on="CommerceDashboard">
                                <ToolbarIconButton onClick={() => history.push('/commerce/dashboard')} size="small" color="inherit">
                                    <MenuBookIcon />
                                </ToolbarIconButton>
                            </Allow>

                            <Allow on="CommercePanel">
                                <ToolbarIconButton onClick={() => history.push('#news')} size="small" color="inherit">
                                    <CommerceNewsOption  {...this.props} />
                                </ToolbarIconButton>
                            </Allow>
                        </Toolbar>
                    </AppBar>
                </Hidden>
                {props.children}
            </>
        )
    }
}


export default connect(state => ({
    news: state.task.filter(e => e.status === "NEW")
}))(Commerce);
