import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Input, Icon } from './styles';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import ListSubheader from "@material-ui/core/ListSubheader";
import StatusIcon from "../StatusIcon";
import TodoSlider from '../TodoSlider'
import EditIcon from '@material-ui/icons/Edit'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import AlarmIcon from '@material-ui/icons/Alarm'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import TimelineIcon from '@material-ui/icons/Timeline';
import CommentIcon from "@material-ui/icons/Comment";

import { onConfirm } from "../../services/useKeyDowns";


// import InputDateRange from '../ToastUtils/InputDateRange';
// import AlarmAddOutlined from "@material-ui/icons/AlarmAddOutlined";
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
        clearInterval(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
};

/**
 * 
 * @param {{menuItem: (Task|Topic|Toast), onCheck, onSelect, onGallery, onDelete, onChangePrice, onChangePeso, onChangeGroup, onChangeStatus, onChangePriority, onChangeType}} props 
 * 
 */

const TodoContextMenu = React.forwardRef((props, ref) => {

    const handleChangePrice = debounce((value) => {
            const newPesoValue = Number(value);
            if (!isNaN(newPesoValue) && newPesoValue >= 0) {
                return props.onChangePrice(props.menuItem, newPesoValue);
            }
        }, 2000);

    const handleChangeGroup = debounce((value) => {
            return props.onChangeGroup(props.menuItem, value);
        }, 2000);

    const handleChangePeso = debounce((value) => {
            const newPesoValue = Number(value);
            if (!isNaN(newPesoValue) && newPesoValue >= 0) {
                return props.onChangePeso(props.menuItem, newPesoValue);
            }
        }, 2000);

    if (!props.menuItem) return null;

    return (
        <>
            <MenuList>
                <ListSubheader><Typography display="block" variant="caption">{props.menuItem?.title?.substr(0, 40)} ...</Typography></ListSubheader>
                <ListSubheader>Opções </ListSubheader>

                {props.onComments &&
                    <MenuItem onClick={props.onComments}>
                        <Icon><CommentIcon /> </Icon>
                        <Typography variant="body2">
                            Comentários ({props.menuItem?.comments.length||0})
                        </Typography>
                    </MenuItem>
                }

                {props.onCheck &&
                    <MenuItem onClick={props.onCheck}>
                        <Icon><EditIcon /> </Icon>
                        <Typography variant="body2">
                            Selecionar
                        </Typography>
                    </MenuItem>
                }
                {props.onSelect &&
                    <MenuItem onClick={props.onSelect}>
                        <Icon><EditIcon /> </Icon>
                        <Typography variant="body2">
                            Editar
                       </Typography>
                    </MenuItem>
                }

                {props.onDatepicker &&
                    <MenuItem onClick={props.onDatepicker}>
                        <Icon><AlarmIcon /> </Icon>
                        <Typography variant="body2">
                            {props.menuItem.begin.ptBR()} - {props.menuItem.finish.ptBR()}
                        </Typography>
                    </MenuItem>
                }

                {props.onGallery &&
                    <MenuItem onClick={props.onGallery}>
                        <Icon><PhotoLibraryIcon /> </Icon>
                        <Typography variant="body2">
                            Galeria {props.menuItem.files?.length || 0} itens
                        </Typography>
                    </MenuItem>
                }

                {props.onOpenHistory &&
                    <MenuItem onClick={props.onOpenHistory}>
                        <Icon><TimelineIcon /> </Icon>
                        <Typography variant="body2">
                            Histórico de alterações
                        </Typography>
                    </MenuItem>
                }


                {props.onDelete &&
                    <MenuItem onClick={props.onDelete} >
                        <Icon><DeleteSweepIcon /> </Icon>
                        <Typography variant="body2">
                            Excluir
                    </Typography>
                    </MenuItem>
                }
            </MenuList>

            <ListSubheader>Configurar</ListSubheader>
            {props.onChangePeso &&
                <ListItem button divider component="label">
                    <Icon>Peso</Icon>
                    <Input
                        onKeyPress={onConfirm(props.onClose)}
                        type="number"
                        min={0}
                        onChange={e => handleChangePeso(e.target.value)}
                        defaultValue={props.menuItem.peso}
                    />
                </ListItem>
            }

            {props.onChangePrice &&
                <ListItem button divider component="label">
                    <Icon>Custo</Icon>
                    <Input
                        onKeyPress={onConfirm(props.onClose)}
                        type="number"
                        min={0}
                        onChange={e => handleChangePrice(e.target.value)}
                        defaultValue={props.menuItem.price}
                    />
                </ListItem>
            }

            {props.onChangeGroup &&
                <ListItem button divider component="label">
                    <Icon>Grupo</Icon>
                    <Input
                        onKeyPress={onConfirm(props.onClose)}
                        onChange={e => handleChangeGroup(e.target.value)}
                        defaultValue={props.menuItem.group}
                    />
                </ListItem>
            }

            {props.onChangeType &&
                <ListItem button divider component="label">
                    <Typography variant="body2">
                        <Switch checked={props.menuItem.useAsModel} onChange={props.onChangeType} />
                              Use como modelo
                        </Typography>
                </ListItem>
            }
            <List>
                {props.onChangeStatus && <ListSubheader disabled>Status</ListSubheader>}
                {props.onChangeStatus &&
                    <ListItem>
                        <Tooltip title="STOP" disabled={props.menuItem.status === 'STOP'}>
                            <IconButton component="span" onClick={props.onChangeStatus("STOP")}>
                                <StatusIcon status="STOP" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="PLAY" disabled={props.menuItem.status === 'PLAY'}>
                            <IconButton component="span" onClick={props.onChangeStatus("PLAY")} >
                                <StatusIcon status="PLAY" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="DONE" disabled={props.menuItem.status === 'DONE'}>
                            <IconButton component="span" onClick={props.onChangeStatus("DONE")}>
                                <StatusIcon status="DONE" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="CQ" disabled={props.menuItem.status === 'CQ'}>
                            <IconButton component="span" onClick={props.onChangeStatus("CQ")}>
                                <StatusIcon status="CQ" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="DROP" disabled={props.menuItem.status === 'DROP'}>
                            <IconButton component="span" onClick={props.onChangeStatus("DROP")}>
                                <StatusIcon status="DROP" />
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                }

                {props.onChangePriority && <ListSubheader disabled>Prioridade</ListSubheader>}
                {props.onChangePriority &&
                    <ListItem component="label">
                        <TodoSlider value={props.menuItem.priority} onChange={props.onChangePriority} />
                    </ListItem>
                }
            </List>
        </>
    )
})

export default TodoContextMenu