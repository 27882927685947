import React from 'react'

import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'

import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'

import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import FilterListIcon from '@material-ui/icons/FilterList'
import DoneAllIcon from '@material-ui/icons/DoneAll'

import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import socket from '../../services/socket'

import CodenamesReducer from '../../Reducers/CodenamesReducers'

const AcceptRows = [
  Math.round((window.innerHeight / 38)),
  Math.round((window.innerHeight / 36)),
  Math.round((window.innerHeight / 30)),
  Math.round((window.innerHeight / 20)),
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell component="th">
          <DoneAllIcon />
        </TableCell>

        {props.headers.map((headCell) => (
          <TableCell
            component="th"
            align={`left`}
            key={headCell}
            sortDirection={orderBy === headCell ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}>{typeof headCell !== 'number' ? headCell.UcFirst() : headCell}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 65px)',
    padding: theme.spacing(1),
  },
  inputRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(AcceptRows[0]);
  const [list, setList] = React.useState([]);
  // const [props.fileCurrentSelected.filekeys, setHeaders] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (props.fileContentList)
      setList(props.fileContentList);

  }, [props.fileContentList])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleNewHeaders = (e) => {
    let newheaders;

    if (props.fileCurrentSelected.filekeys.rmv(x => e === x))
      newheaders = [...props.fileCurrentSelected.filekeys];

    else
      newheaders = [...props.fileCurrentSelected.filekeys, e];

    socket.emit('comparison:reference:willChangeHeaders', newheaders);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClearSearch = () => {
    document.querySelector('#inputbase').value = '';
    setList(props.fileContentList);

  };
  var timer = null
  const handleSearch = (e) => {
    clearTimeout(timer);
    const { value } = e.target;

    timer = setTimeout(() => { setList(props.fileContentList.$filter(value)); setPage(0) }, 555);
  };

  const isNumeric = (num) => !isNaN(num);

  const isSelected = (name) => props.fileKeyCode === name._id;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.fileContentList.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className={classes.inputRoot}>

        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          id="inputbase"
          className={classes.input}
          placeholder="Search file Api"
          onChange={handleSearch}
          inputProps={{ 'aria-label': 'Search file Api' }}
        />
        <IconButton color="primary" className={classes.iconButton} onClick={handleClearSearch} aria-label="Close">
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton className={classes.iconButton} aria-label="props.fileCurrentSelected.filekeys" onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="simple-menu-for-props.fileCurrentSelected.filekeys-key"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.keys(props.fileContentList.first() || []).map((e, i) => 
            <MenuItem 
              key={i}
              selected={props.fileCurrentSelected && props.fileCurrentSelected.filekeys.indexOf(e) > -1} 
              onClick={() => handleNewHeaders(e)} >
                {e}
            </MenuItem>
          )}

        </Menu>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table
          style={{ width: (props.fileCurrentSelected.filekeys.length + 1) * 200 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.fileContentList.length}
            headers={props.fileCurrentSelected.filekeys || []}
          />
          <TableBody>
            {stableSort(list, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const CountAssociateds = props.CountAssociated(row._id);
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={() => props.fileWillUpdateInternalKeyCode(row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={labelId}
                    selected={CountAssociateds > 0}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      {CountAssociateds}
                    </TableCell>
                    {props.fileCurrentSelected.filekeys.map(e => {
                      const element = row.hasOwnProperty(e) && row[e];

                      return (
                        <TableCell
                          key={labelId + element}
                          style={{ whiteSpace: 'nowrap', maxWidth: isNumeric(element) && 25 }}
                          id={labelId + element}
                          scope="row"
                          padding="none"
                        >
                          {element}
                        </TableCell>
                      )
                    }
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 25 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={AcceptRows}
        component="div"
        count={props.fileContentList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}


const mapStateToProps = state => {
  return {
    ...state.codenames
  }
}

const { fileWillUpdateInternalKeyCode } = new CodenamesReducer();
const mapDispatchToProps = dispatch => {
  return {
    fileWillUpdateInternalKeyCode: payload => {
      dispatch(fileWillUpdateInternalKeyCode(payload))
    }

  }
}



export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);