import React from 'react';
import Iframe from 'react-iframe';
import HideOnScroll from '../../Components/HideOnScroll';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ToolbarTitle, ToolbarIconButton } from '../../Components/StyledComponents'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { host_api } from '../../services/setproxy';
export default class Frame extends React.Component {

    render() {

        return (
            <div style={{ position: 'fixed', height: '100vh', width: '100vw' }}>
                <HideOnScroll>
                    <AppBar position="relative" style={{
                        boxShadow: 'none'
                    }}>
                        <Toolbar>
                            <ToolbarIconButton onClick={() => this.props.history.goBack()} edge="start" color="inherit" aria-label="menu">
                                <ArrowBack />
                            </ToolbarIconButton>
                            <ToolbarTitle variant="h6">
                                Meus Atalhos
                           </ToolbarTitle>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <div style={{height: 'calc(100vh - 64px)'}}>
                    <Iframe 
                        width="100%" 
                        height="100%" 
                        src={`${host_api}/frames/navigate/${this.props.match.params.target}`} 
                        sandbox="allow-forms allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation allow-modals allow-downloads"
                        />
                </div>
            </div>
        )
    }
}
