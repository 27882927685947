import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { useSelector } from 'react-redux';
import { Container } from './styles';
import { ListView, SubHeader } from '../../Components/StyledComponents';

import Iframe from 'react-iframe'
import socket from '../../services/socket';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useCurrentPosition } from 'react-use-geolocation';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

const findMany = (fn) => state => state.task.filter(e => fn.call([], e) && e.users.some(x => x.user.id === state.user.id))
const findOne = (fn) => state => state.task.find(e => fn.call([], e) && e.users.some(x => x.user.id === state.user.id))

export const CommerceDescription = (props) => {
    
    const [Address, Payment] = props?.description.split('|') || [];
    return (
    <React.Fragment>
        <Typography display="block" color="primary">{Address?.rmvParentheses()}</Typography>
        <Typography variant="subtitle2" display="block" color="initial">Pagamento: {Payment?.rmvParentheses()}</Typography>
        <Typography variant="subtitle2" display="block" color="initial">Observações/Troco: {Payment?.getParentheses() || ''}</Typography>
    </React.Fragment>
)}


function CommerceDeliveryman(props) {

    const [position, error] = useCurrentPosition();

    console.log(position);

    const start = useSelector(findMany(e => e.status === 'PLAY'));
    const indeliver = useSelector(findMany(e => e.status === 'DONE'))
    const deliveredcq = useSelector(findMany(e => e.status === 'CQ'))
    // const faction = useSelector(state => state.user.faction);

    const element = useSelector(findOne(e => e.uuid === props.match.params.uuid));

    if (!position && !error) {
        return <p>Obtendo localizaçao...</p>;
    }

    if (error) {
        return <p>{error.message}</p>;
    }

    const isOpen = Boolean(props.match.params.uuid);
    const setClose = () => {
        props.history.replace('/commerce/deliveryman')
    }

    const handleStatusChange = (newstate) => () => {
        socket.emit('order:will:update', { uuid: element.uuid, status: newstate });
        setClose();
    }

    // if(!element) return null;

    const SomeInDeliver = indeliver.some(e => e.uuid === element?.uuid);    
    const [AddressFull, Payment] = element?.description?.split('|') || [];
    const Address = AddressFull?.rmvParentheses();
    // const fullAddress = [faction.address.street,faction.address.neighborhood,faction.address.city,faction.address.number].join('+');

    const fullAddress = [position.coords.latitude, position.coords.longitude].join(',');

    return (
        <Container container spacing={1}>

            <Container item xs={12} md={4}>
                <ListView subheader={<SubHeader>Aguardando aceite<b>{start.length}</b></SubHeader>}>
                    {start.map(task =>
                        <ListItem divider button key={task.uuid} onClick={() => props.history.push(`/commerce/deliveryman/${task.uuid}`)}>
                            <ListItemText primary={`#${task.id} ${task.title.toUpperCase()}`} 
                                secondary={<CommerceDescription description={task.description}  />} />
                        </ListItem>
                    )}
                    <Divider />
                </ListView>            
            </Container>
            <Container item xs={12} md={4}>
                <ListView subheader={<SubHeader>Em Corrida<b>{indeliver.length}</b></SubHeader>}>
                    {indeliver.map(task =>
                        <ListItem divider button key={task.uuid} onClick={() => props.history.push(`/commerce/deliveryman/${task.uuid}`)}>
                            <ListItemText primary={`#${task.id} ${task.title.toUpperCase()}`} 
                                secondary={<CommerceDescription description={task.description}  />} />
                        </ListItem>
                    )}
                    <Divider />
                </ListView>
            </Container>
            <Container item xs={12} md={4}>
                <ListView subheader={<SubHeader>Entregue<b>{deliveredcq.length}</b></SubHeader>}>
                    {deliveredcq.map(task =>
                        <ListItem divider key={task.uuid}>
                            <ListItemText primary={`#${task.id} ${task.title.toUpperCase()}`} 
                                secondary={<CommerceDescription description={task.description}  />} />
                        </ListItem>
                    )}
                </ListView>
            </Container>

            <SwipeableDrawer disableSwipeToOpen anchor="bottom" open={isOpen} onOpen={() => true} onClose={setClose}>
                <DialogTitle disableTypography>
                    {element?.title}. Pagamento: {Payment?.rmvParentheses()}, Observações/Troco: {Payment?.getParentheses() || 'Não Informado'}<br />
                    {Address}
                </DialogTitle>
                <DialogContent style={{ height: '80vh', padding: 0, margin: 0, overflow: 'hidden', display: 'block' }} >
                    {element &&
                        <Iframe
                            url={"https://www.google.com/maps/embed/v1/directions" +
                                "?key=AIzaSyB4FirNd4zTH8PkpKxBTPhDVfYohM--M_o" +
                                "&origin=" + fullAddress + (Boolean(indeliver.length === 0 ) ? '' : "&waypoints=" + indeliver.map(e => e.description.Before('|').rmvParentheses()).join('|')) +
                                "&destination=" + Address}
                            width="100%"
                            height="100%"
                            id="waze-frame"
                            display="initial"
                            position="relative"
                        />
                    }
                </DialogContent>
                <DialogActions>
                    {/* <Button component="a" target="_blank" rel="noreferrer"
                        href={`https://www.google.com/maps?q=${Address}&z=17&hl=pt-BR`}
                        color="primary"
                        variant="contained" >
                        <MapIcon />
                                   Rota
                         </Button> */}
                    <Button color="inherit" onClick={setClose} startIcon={<CloseIcon />}>Fechar</Button>
                    {SomeInDeliver
                        ? <Button color="secondary" variant="contained" onClick={handleStatusChange("CQ")} startIcon={<DoneAllIcon />}> Entregue</Button>
                        : <Button color="secondary" variant="contained" onClick={handleStatusChange("DONE")} startIcon={<DoneIcon />}> Aceitar</Button>
                    }

                </DialogActions>


                {/* <MapContainer
                        center={{ lat: position.coords.latitude, lng: position.coords.longitude }}
                        zoom={13}
                        scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[position.coords.latitude, position.coords.longitude]} />
                        <Marker position={[-23.5228324, -46.7155846]} >
                            <Popup>
                                Local da entrega
                            </Popup>
                        </Marker>
                    </MapContainer> */}
                {/* <ActionsDescription color="inherit" variant="overline">{element?.description} </ActionsDescription> */}
            </SwipeableDrawer>



        </Container>
    );
}

export default CommerceDeliveryman;