import React from 'react'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'

import DeleteIcon from '@material-ui/icons/Delete'
import RoomIcon from '@material-ui/icons/Room'
import AddressForm from '../../Components/AddressForm'
import { dispatch } from '../../Reducers/Clients'


/**@param {import('../../typeroots').ClientWeb} props */
function TabAddress({ tab, ...props }) {


    const handleDelete = (address) => {
        dispatch.Connect({
            uuid: props.client.uuid,
            address: { delete: { uuid: address.uuid } }
        })
    }


    /**@param {{cep: string, city: string, complement: string|null, coords: string, formattedAddress: string, latitude: number, logitude: number, neighborhood: string, number: number, state: string, street: string, title: string}} input */
    const handleCreate = (input) => {
        dispatch.Connect({
            uuid: props.client.uuid, 
            address: {
                create: {
                    title: "Casa",
                    number: String(input.number),
                    isMain: true,
                    formattedAddress: input.formattedAddress,
                    neighborhood: input.neighborhood,
                    city: input.city,
                    complement: input.complement,
                    cep: input.cep,
                    street: input.street,
                    state: input.state,
                    coords: input.coords
                }
            }
        })
    }

    if (!tab) return null

    return (
        <List dense={true} >

            {props.client?.address.map(address =>
                <ListItem disabled={!address.coords} divider key={address.uuid} button onClick={() => Boolean(props.onSelect) && props.onSelect(address)}>
                    <ListItemIcon><RoomIcon /></ListItemIcon>
                    <ListItemText primary={address.title} secondary={`${address.street}, ${address.number} - ${address.neighborhood}/${address.state}`} />
                    <ListItemSecondaryAction>
                        <Tooltip title="Excluir">
                            <IconButton onClick={() => handleDelete(address)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            )}

            <AddressForm onSubmit={handleCreate} />
        </List>
    )
}

export default TabAddress;