import React from 'react'
import socket from './socket'
import PropTypes from "prop-types"

class Socket extends React.Component{
    
    componentDidMount(){
        this.SetEvents();

        if(this.props.onMount){
            Object.keys(this.props.onMount).map( e => socket.emit(e, this.props.onMount[e] ))
        }
    }

    componentWillUnmount(){
        this.RemoveEvents();

        if(this.props.onUnMount){
            Object.keys(this.props.onUnMount).map( e => socket.emit(e, this.props.onUnMount[e] ))
        }

    }

    SetEvents = () => {
        Object.keys(this.props.events).map( e => socket.on(e, this.props.events[e] ))
    }

    RemoveEvents = () => {
        Object.keys(this.props.events).map( e => socket.removeListener(e))
    }

    render(){
        return this.props.children || null;
    }
}

Socket.propTypes = {
    events: PropTypes.object.isRequired,
    onMount: PropTypes.object,
    onUnMount: PropTypes.object
};

const Emit = (e, data) => socket.emit(e, data);

export { Socket, Emit }