import React, { Fragment, useState } from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import InputBase from '@material-ui/core/InputBase'
import InputMask from '../../Components/InputMask';
import PhoneIcon from '@material-ui/icons/Phone'
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { dispatch } from '../../Reducers/Clients';
/**
 * 
 * @param {import('../../typeroots').ClientWeb} props 
 * 
 */

const defaultState = {
    phone: '',
    title: ''
}

function TabContacts({tab, ...props}) {

    const [create, setCreate] = useState(defaultState);
    const handleCreate = (prop) => e => setCreate({ ...create, [prop]: e.target.value });

    const handleDelete = (contact) => {
        dispatch.Connect({
            uuid: props.client.uuid,
            contacts: { delete: { uuid: contact.uuid }}
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch.Connect({
            uuid: props.client.uuid,
            contacts: { create }
        });

        return e.target.reset() && setCreate(defaultState);
    }

    if(!tab) return null

    return (
            <List dense={true} component="form" onSubmit={handleSubmit}>
                {props.client.contacts.map(contact =>
                    <ListItem divider key={contact.uuid}>
                        <ListItemIcon><PhoneIcon /></ListItemIcon>
                        <ListItemText primary={contact.phone} secondary={contact.title} />
                        <ListItemSecondaryAction>
                            <Tooltip title="Excluir">
                                <IconButton onClick={()=>handleDelete(contact)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <ListItem divider>
                    <ListItemIcon><AddIcCallIcon /></ListItemIcon>
                    <ListItemText primary={
                        <Fragment>
                            <InputBase required placeholder="Título" onChange={handleCreate('title')} />
                            <InputMask required
                                onChange={handleCreate('phone')}
                                component={InputBase}
                                mask={create.phone.substring(5, 6) !== '9' ? "(99) 9999-9999" : "(99) 9 9999-9999"}
                                placeholder="Novo telefone"
                            />
                        </Fragment>
                    } />
                    <ListItemSecondaryAction>
                        {/* {Boolean(create.phone.length > 13) && */}
                        <Tooltip title="Salvar">
                            <IconButton type="submit">
                                <BookmarkBorderIcon />
                            </IconButton>
                        </Tooltip>
                        {/* } */}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        )
}

export default TabContacts;