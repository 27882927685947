import socket from '../services/socket';
import update, { extend } from "immutability-helper";
import { upload, get } from '../services/Auth';

extend('$combine', function (data, original) {
    if (Array.isArray(data)) {
        return original.concat(data)
    }
    else
        return original.concat([data])
});

export default class Reducer {
    /**
     * @param {string} type
     * @param {(import('../typeroots').Tasks|import('../typeroots').Topics|import('../typeroots').Toasts)} initialState
     * */
    constructor(type, initialState = []) {
        this.initialState = initialState;
        // this.OnConnect = () => setTimeout(() => socket.emit(this.types.DIDMOUNT), 100);
        this.type = type;



        this.types = {
            SELECT: `${type}:will:select`,
            /**
             * @description merge item object
             */
            WILLUPDATE: `${type}:will:update`,

            WILLCONNECT: `${type}:will:connect`,
            /**
             * @description push one in array
             */
            WILLCREATE: `${type}:will:create`,
            /**
            * @description delete one in array
            */
            WILLDELETE: `${type}:will:delete`,
            /**
             * @description receives an array
             */
            WILLMERGE: `${type}:will:merge`,
            /**
             * @description restore from initialState
             */
            DIDMOUNT: `${type}:did:mount`
        };
    }

    actions = {
        /**@param {import('../typeroots').Task & {selected: boolean}} data*/
        Select: (data) => {
            return { type: this.types.SELECT, data }
        },

        DidMount: (data) => {
            return { type: this.types.DIDMOUNT, data }
        },

        WillCreate: (data) => {
            return { type: this.types.WILLCREATE, data }
        },

        WillUpdate: (data, callback = null) => {
            return { type: this.types.WILLUPDATE, data: data, callback }
        },

        WillConnect: (data) => {
            return { type: this.types.WILLCONNECT, data }
        },

        WillDelete: (data) => {
            return { type: this.types.WILLDELETE, data }
        },

        WillMerge: (array) => {
            return { type: this.types.WILLMERGE, data: array }
        }
    }

    dispatch = {
        /**
         * @typedef {{ input: String, group: ?String, status: ?String }} createDataTask
         * @typedef {{ taskId: Number, taskUuid: String,  input:String , group: ?String, status: ?String }} createDataTopic
         * @typedef {{ taskId: Number, topicId: Number,  topicId: Number, input:String , group: ?String, status: ?String }} createDataToast
         */


        /**@param {createDataTask|createDataTopic|createDataToast} data*/
        willCreate: (data) => {
            return socket.emit(this.types.WILLCREATE, data)
        },
        /**@param {{uuid: String}} data*/
        willDelete: ({ uuid }) => {
            return socket.emit(this.types.WILLDELETE, { uuid })
        },
        /**@param {{ id, uuid, datatoupdate }} data*/
        willUpdate: (data) => {
            return socket.emit(this.types.WILLUPDATE, data)
        },
        willConnect: ({ uuid, users }) => {
            return socket.emit(this.types.WILLCONNECT, { uuid, users })
        },
        /**@param {InputEvent} event *@param {String} uuid *@param {Function} cb*/
        willCreateFile: (event, uuid, cb) => upload(`/gallery/upload/todo/${this.type}/${uuid}`, event.target)
            .then(() => socket.emit(`${this.type}:will:change`, { uuid, files: { select: { name: true, file: true } } }))
            .then(cb),

        /**@param {String} file *@param {String} uuid *@param {Function} cb*/
        willDeleteFile: (file, uuid, cb) => get(`/gallery/delete/${file}`)
            .then(() => socket.emit(`${this.type}:will:change`, { uuid: uuid, files: { select: { name: true, file: true } } }))
            .then(cb),
    }

    listen = (store, mountOnLoad = true) => {
        socket.on(this.types.DIDMOUNT, (data) => store.dispatch(this.actions.DidMount(data)));
        socket.on(this.types.WILLCREATE, (data) => store.dispatch(this.actions.WillCreate(data)));
        socket.on(this.types.WILLDELETE, (data) => store.dispatch(this.actions.WillDelete(data)));
        socket.on(this.types.WILLUPDATE, (data) => store.dispatch(this.actions.WillUpdate(data)));
        socket.on(this.types.WILLCONNECT, (data) => store.dispatch(this.actions.WillConnect(data)));

        socket.on(this.types.WILLMERGE, (data) => store.dispatch(this.actions.WillMerge(data)));

        // if (mountOnLoad) {
            // socket.on('connect', this.OnConnect);
        // }
    }

    /**
     * @param {initialState} state 
     * @param {Array<Object>} action 
     */
    reducer = (state = this.initialState, action) => {
        const FILTER = e => action.data.uuid ? e.uuid === action.data.uuid : e.id === action.data.id;
        let index;

        switch (action.type) {
            case this.types.DIDMOUNT:
                return state = action.data;

            case this.types.WILLMERGE:
                return update(state, {
                    $combine: action.data
                });

            case this.types.WILLCREATE:
                return update(state, {
                    $push: [action.data]
                });


            case this.types.SELECT:
                index = state.findIndex(FILTER);

                return update(state, {
                    [index]: {
                        selected: {
                            $set: action.data.selected
                        }
                    }
                })


            case this.types.WILLUPDATE:
                index = state.findIndex(FILTER);

                try {
                    return update(state, { [index]: { $merge: action.data } })

                } catch (error) {
                    console.error('state', { [index]: { $merge: action.data } });
                    return [...state];
                }

            case this.types.WILLCONNECT:
                return update(state, {
                    [state.findIndex(FILTER)]: { users: { $set: action.data.users } }
                })

            case this.types.WILLDELETE:
                return update(state, {
                    $splice: [[state.findIndex(FILTER), 1]]
                })

            default:
                return [...state];
        }
    }



}