import { styled } from "@material-ui/core/styles";


export const ListIcon = styled('li')({
    width: 58,
    padding: 3,
    display: 'flex',
    placeContent: 'center',
    '& .MuiListItemAvatar-root': {
      minWidth: 'auto',
      margin: '0 auto',
    }
  })
  
