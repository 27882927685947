import LZString from 'lz-string';

const Cache = {
    set: function(key, obj) {
        return localStorage.setItem(key, LZString.compress(JSON.stringify(obj)));
    },
    
    get: function(key) {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(LZString.decompress(data)) : false
    },
}

export default Cache 