import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Map } from 'react-lodash'
import socket from '../../services/socket'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import FastfoodIcon from '@material-ui/icons/Fastfood';
import DoneIcon from '@material-ui/icons/Done';
import TaskReducer from '../../Reducers/TasksReducers'

const { dispatch } = new TaskReducer('topic');
/**
 * 
 * @param {{task: Task, topics: Array<Topic>,toasts: Array<Toast>}} props 
 */
const CardComponent = (props) => {
    const [color, setColor] = React.useState(null);
    const [minutes, setMinutes] = React.useState(props.task.begin.CompareMinutes())

    const HandleUpdateStatus = React.useCallback((topic) => {
        dispatch.willUpdate({ uuid: topic.uuid, status: topic.status !== 'DONE' ? 'DONE' : 'PLAY' })
    }, [])


    React.useEffect(() => {
        let interval;

        if (!interval)
            interval = setInterval(() => setMinutes(props.task.begin.CompareMinutes()), 30000);

        return () => clearInterval(interval);

    }, [props.task]);

    React.useEffect(() => {

        if (minutes > 20) return setColor("rgba(166, 63, 63, .5)");
        if (minutes > 15) return setColor("rgba(166, 147, 63, .5)");
        if (minutes > 10) return setColor("rgba(116, 166, 63, .5)");
        if (minutes > 5) return setColor("rgba(63, 166, 96, .5)");

        return setColor("rgba(63, 137, 166, .5)");


    }, [minutes])


    const handleStatusChange = React.useCallback(() => {
        socket.emit('order:will:update', { uuid: props.task.uuid, status: "PLAY" });

    }, [props.task])

    const Optionals = React.useCallback((callback) => {
        return props.toasts.filter(callback);

    }, [props])

    // const Ingredients = props.toasts.filter(EDITABLE);

    return (
        <Grid item xs={12} md={4}>
            <Card>
                <CardHeader
                    style={{ backgroundColor: color }}
                    title={`${props.task.title.toUpperCase()}`}
                    subheader={`#${props.task.id} - ${props.topics.length} Pedidos`}
                    action={`Há ${minutes}m`}
                />
                <CardContent component={List} style={{ padding: 0 }} subheader={<ListSubheader>Pedido</ListSubheader>}>
                    {props.topics.map(topic =>
                        <ListItem button divider key={topic.uuid} onClick={() => HandleUpdateStatus(topic)}>
                            <List
                                dense
                                disablePadding
                                subheader={<>
                                    <ListItem>
                                        <ListItemIcon>{topic.status === 'DONE' ? <DoneIcon /> : <FastfoodIcon />}</ListItemIcon>
                                        <ListItemText primary={topic.title} secondary={topic.description || "Sem Comentários"} />
                                    </ListItem>
                                </>}>
                                <Collapse in={topic.status !== 'DONE'}>
                                    {Optionals(e => e.topicId === topic.id)
                                        .filter(e => ['DROP','OPTIONAL', "REQUIRED"].indexOf(e.status) !== -1 )
                                        .map(e =>
                                            <ListItem style={{ height: 20 }} key={e.uuid}>
                                                <ListItemIcon></ListItemIcon>
                                                {e.status === 'REQUIRED' && <ListItemText primary={e.title} />}
                                                {e.status === 'OPTIONAL' && <ListItemText primary={`+ ${e.title}`} />}
                                                {e.status === 'DROP' && <ListItemText primaryTypographyProps={{ style: { textDecoration: 'line-through' } }} primary={`- ${e.title}`} />}
                                            </ListItem>
                                        )}
                                </Collapse>
                            </List>
                        </ListItem>
                    )}
                </CardContent>

                <CardActions>
                    <Button fullWidth variant="outlined" style={{ backgroundColor: color }} onClick={handleStatusChange}>Finalizar</Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default function CommerceKitchen() {

    const kitchen = useSelector(state => state.task.filter(e => e.status === "STOP").sort((a, b) => new Date(a.begin) - new Date(b.begin)));
    const topics = useSelector(state => state.topic);
    const toasts = useSelector(state => state.toast);

    return (
        <Grid container spacing={1} justify="center" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Map collection={kitchen}
                iteratee={task =>
                    <CardComponent key={task.id} task={task}
                        topics={topics.filter(e => e.taskId === task.id).sort((a, b) => a.title.localeCompare(b.title))}
                        toasts={toasts.filter(e => e.taskId === task.id).sort((a, b) => a.title.localeCompare(b.title))}
                    />}
            />
        </Grid>
    )
}