import React from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import FastFoodIcon from '@material-ui/icons/Fastfood';
import AlarmIcon from '@material-ui/icons/Alarm';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import { OPTIONAL, ORDERTOTAL } from '../../typeroots';
import { Socket } from '../../services/useSocket'

const margin = { marginRight: 26 };
/**@param {{orders: import('../../typeroots').RestaurantOrder, onClear: function, onRemove: function }} props */
export default function CommerceOrdersList({ orders, ...props }) {

    const maxTime = orders.map(e => e.peso).max();

    return (
        <Socket events={{
            'orders:will:clear': props.onClear
        }}>
            {orders.length === 0 && <ListItem>
                <ListItemText
                    primary="Sua sacola está vazia"
                    secondary="Adicione itens"
                />
            </ListItem>}
            {orders.map((order, i) =>
                <ListItem divider key={`cash-${i}`}>
                    <ListItemIcon>
                        <FastFoodIcon />
                    </ListItemIcon>
                    <ListItemText
                        style={margin}
                        primary={order.title}
                        secondary={[
                            (order.toasts.sum('price', OPTIONAL) + Number(order.price)).monetizeme(),
                            ` (${order.toasts.count(OPTIONAL)} Adicionais)`,
                            "\n", order.description
                        ]}
                    />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => props.onRemove(order)}>
                            <DeleteSweepIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )}

            {Boolean(maxTime) &&
                <ListItem divider>
                    <ListItemIcon><AlarmIcon /></ListItemIcon>
                    <ListItemText
                        style={margin}
                        primary={maxTime ? `${maxTime} - ${maxTime + 10} mins` : null}
                    />
                </ListItem>}

            <ListItem divider>
                <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                <ListItemText
                    style={margin}
                    secondary={`Total de items: ${orders.length}`}
                />
                {orders.reduce(ORDERTOTAL, 0).monetizeme()}
            </ListItem>
        </Socket>
    );
}