import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'

import {Emit} from '../../services/useSocket'

const useStyles = makeStyles((theme) => ({
    input: {
      flex: 1,
    },
    iconButton: {
      padding: 10,
      fontSize: 10
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));
  
export default function FeaturesAndAttributesGlobals(props) {
    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
        Emit(`comparison:${props.event}:willUpsert`, { input: e.target[0].name, value: e.target[0].value });
        e.target.reset();
        props.onChange();
    }

    const list = props.list.filter(e => !props.registered.some(f => f.id === e.id))

    return (<>
        <List dense={true} >
            <ListSubheader component="div">{props.subheader}</ListSubheader>

            {list.map(e => (<>
                <ListItem component="form" key={`globals-${props.event}-${e.label}`} onSubmit={handleSubmit}>
                    <InputBase
                        required
                        fullWidth
                        name={e.label}
                        autoComplete="off"
                        className={classes.input}
                        placeholder={`Cadastrar ${e.label}?`}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton type="submit" aria-label="Save"  className={classes.iconButton} >
                        <SaveIcon />
                    </IconButton>
                </ListItem>
            </>)
            )}
            {list.length === 0 && (
                <ListItem>
                    <ListItemText
                        primary={`${props.subheader} estão todas cadastradas`}
                        secondary="Você pode adicionar manualmente"
                    />
                </ListItem>
            )}
        </List>
        <Divider variant="middle" />
    </>)
}