import React from 'react';
import { Emit, Socket } from '../../services/useSocket';
import Button from '@material-ui/core/Button'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@material-ui/core/Collapse'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import InputMask from '../InputMask'

import AddLocation from '@material-ui/icons/AddLocation'
import Home from '@material-ui/icons/Home'
import Save from '@material-ui/icons/Save'
import Close from '@material-ui/icons/Close'
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MyLocation from '@material-ui/icons/MyLocation';

// import SearchLocation from '../SearchLocation';
const initialState = {
    title: 'Casa',
    street: '',
    neighborhood: '',
    city: 'São Paulo',
    state: 'SP',
    cep: '',
    complement: '',
    number: '',
    coords: null,
    latitude: 0,
    logitude: 0,
    formattedAddress: ''
}

export default function AddressForm(props) {
    const [input, setInput] = React.useState(props.default || initialState);

    const handleChange = (prop, cb) => e => {
        setInput({ ...input, [prop]: e.target.value });
        return cb && cb(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(input);
        return setTimeout(() => setInput(initialState));
    }

    const setCep = ({ cep, ...data }) => {
        setInput({ ...input, ...data, cep: cep.Format("#####-###") });

        const nextinput = document.getElementById('house-complement');
        if (nextinput) nextinput.focus();
    };

    const setGeo = ({ coords }) => {
        setInput({ ...input, coords });
    }

    const handleGeoCep = (e) => {
        if (e.length === 9) {
            setTimeout(function () {
                let number = window.prompt("Número da casa");

                if (number && Number(number))
                    return Emit('get:cep', { cep: e, number: Number(number) })

            }, 500);
        }
    }

    return (
        // <SearchLocation
        //     onCapture={setInput}
        //     data={input}
        //     secondaryAction={<Button onClick={handleSubmit} >Salvar</Button>}
        //     inputProps={{
        //         fullWidth: true,
        //         value: input.formattedAddress,
        //         onChange: handleChange('formattedAddress'),
        //         label: 'Cadastrar um novo endereço',
        //         helperText: 'Digite o cep ou seu endereço completo',
        //         placeholder: 'Ex: 500, rua do bonito, são paulo SP'
        //     }}
        // />
        <form onSubmit={handleSubmit}>
            <Socket events={{
                'set:cep': setCep,
                'set:geo': setGeo
            }} />

            <ListItem button component="label" >
                <ListItemIcon><AddLocation /></ListItemIcon>
                <ListItemText
                    secondary={input.cep ? `${input.city}/${input.state}` : "Cadastrar aqui um novo endereço"}
                    primary={
                        <InputMask required
                            placeholder="Cep"
                            mask="99999-999"
                            type="tel"
                            value={input.cep}
                            onChange={handleChange('cep', handleGeoCep)} />
                    }
                />
                <ListItemSecondaryAction>
                    <Tooltip title="Não sei meu cep">
                        <IconButton onClick={() => window.open("https://buscacepinter.correios.com.br/app/endereco/index.php", "_blank")}>
                            <LiveHelpIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Limpar Endereço">
                        <IconButton onClick={() => setInput(initialState)}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>

            <Collapse in={Boolean(input.cep.length === 9)}>
                <ListItem>
                    <ListItemIcon><Home /></ListItemIcon>
                    <ListItemText
                        secondary={"Número da residência"}
                        primary={
                            <InputBase fullWidth required autoFocus
                                placeholder="Casa"
                                value={input.number}
                                type="number"
                                id="house-number"
                                onChange={e => handleChange('number')({ target: { value: Number(e.target.value) } })}
                            />
                        } />
                    <ListItemSecondaryAction>
                        <Button startIcon={<Save />} disabled={!input.number || !input.coords} type="submit">
                            Salvar
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </Collapse>

            <Collapse in={Boolean(input.cep.length === 9 && input.number)}>
                <ListItem>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <InputBase fullWidth required
                                placeholder="Rua/Logradouro"
                                value={input.street}
                                onChange={e => handleChange('street')({ target: { value: e.target.value.Capitalize() } })} />
                            <InputBase fullWidth required
                                placeholder="Bairro"
                                value={input.neighborhood}
                                onChange={e => handleChange('neighborhood')({ target: { value: e.target.value.Capitalize() } })} />
                            <InputBase fullWidth required
                                placeholder="Título Novo Endereço"
                                value={input.title}
                                onChange={e => handleChange('title')({ target: { value: e.target.value.Capitalize() } })} />
                            <InputBase fullWidth
                                placeholder="Complemento"
                                value={input.complement}
                                id="house-complement"
                                onChange={handleChange('complement')} />
                        </React.Fragment>
                    } />
                </ListItem>
            </Collapse>

            <Collapse in={Boolean(input.cep.length !== 9)}>
        <ListItem divider button onClick={() => Emit('get:reverse')}>
            <ListItemIcon><MyLocation /></ListItemIcon>
            <ListItemText primary="Usar minha localização" />
        </ListItem>
            </Collapse>
        </form>
    );
}