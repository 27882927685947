import { styled, withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import Avatar from '@material-ui/core/Avatar';
import Particles from 'react-particles-js'
// export const 

export const ParticlesView = styled(Particles)({
    width: '50%',
    position: 'absolute',
    height: '100%',
    right: 0
}) 

export const Container = withStyles(() => ({
    root: {
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        // '@media(prefers-color-scheme: light)': {
        //     '& .MuiGrid-item': {
        //         [theme.breakpoints.up('md')]: {
        //             marginLeft: 'auto',
        //             marginRight: '20vh'
        //         }
        //     }
        // }
    },
}))(Grid);

export const AvatarLarge = withStyles((theme) => ({
    root: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: 22
    },
}))(Avatar);


export const Item = withStyles((theme) => ({
    root: {
        overflow: 'hidden',
        position: 'relative'
    },
}))(Grid);


export const Background = withStyles((theme) => ({
    root: {
        height: '100vh',
        position: "absolute",
        width: '50%',
        left: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',

        }
    },
}))(Card);


export const Form = styled('form')({
    paddingLeft: 55,
    paddingRight: 55,
    paddingBottom: 35
});