import React from 'react';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles';
import { get } from '../../services/Auth';
import { Emit } from '../../services/useSocket'
import CloseIcon from '@material-ui/icons/Close'

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { host_gallery } from '../../services/setproxy';

const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginTop: 10
    },
    dialog: {
        height: '100vh',
        '& .MuiDialog-paperScrollPaper': {
            maxHeight: 'inherit'
        }
    },
    dialogArrowLeft: { position: 'absolute', display: 'flex', left: 0 },
    dialogArrowRight: { position: 'absolute', display: 'flex', right: 0},
    gridList: { flexWrap: 'nowrap', transform: 'translateZ(0)'},
    title: {color: theme.palette.primary.light},
    gridImage: { height: '100%'},
    targetImage: { width: 'auto', height: '100%', display: 'flex', margin: '0 auto' },
    titleBar: { background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }
}));

export default function FeaturesAndAttributesDilalog({ Event, Item, OnClose }) {

    const [selected, setSelected] = React.useState(false)
    const classes = useStyles();

    const handleFileRemove = (id, file) => window.confirm(`Exluir permanentemente essa imagem?`)
        && get(`/upload/${Event}/delete/${file}`).then(() => Emit(`comparison:${Event}:willremoveupload`, { id, file }))


    return Boolean(Item) && (
        <Dialog maxWidth={`md`} className={classes.dialog} open={Boolean(Item)} scroll="paper" onClose={OnClose} aria-labelledby={`images-${Event}`} >
            <DialogContent>
                <GridList cols={1} className={classes.gridImage}>

                    <GridListTile style={{ height: '65vh', backgroundColor: '#fafafa' }}>

                        <Button className={classes.dialogArrowLeft} color={"secondary"}>
                            <ArrowLeftIcon />
                        </Button>
                        <Button className={classes.dialogArrowRight} color={"secondary"}>
                            <ArrowRightIcon />
                        </Button>

                        <Button component="a" href={`${host_gallery}${selected || Item.files[0]?.file}`} target="_blank" className={classes.targetImage}>
                            <img
                                src={`${host_gallery}${selected || Item.files[0]?.file}`}
                                alt={selected || Item.files[0]?.file}
                                onerror="this.onerror=null;this.src='/file.png';"
                            />
                        </Button>


                    </GridListTile>
                </GridList>

                <div className={classes.grid}>
                    <GridList cols={3} className={classes.gridList}>
                        {Item.files.map((tile) => (
                            <GridListTile key={tile.file} onClick={() => setSelected(tile.file)}>
                                
                                <img
                                    src={`${host_gallery}${tile.file}`} alt={tile.name}
                                    onerror="this.onerror=null;this.src='/file.png';"
                                />
                                <GridListTileBar title={tile.name} actionIcon={
                                    <IconButton
                                        size="small"
                                        aria-label={`remove ${tile.name}`}
                                        onClick={() => handleFileRemove(Item.id, tile.file)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </DialogContent>
        </Dialog>
    )
}