import React from 'react';
// import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const statusText = function (status) {
    switch (status) {
        case 'NEW': return 'NOVO';
        case 'STOP': return 'PARADO';
        case 'PLAY': return 'ANDANDO';
        case 'DONE': return 'PRONTO';
        case 'DROP': return 'CANCELADO';
        case 'CQ': return 'ENTREGUE';
        case 'CARTE': return 'CARDÁPIO';
        case 'EDITABLE': return 'EDITÁVEL';
        case 'REMOVED': return 'REMOVIDO';
        case 'OPTIONAL': return 'OPCIONAL';
        case 'REQUIRED': return 'OBRIGATÓrio';
        case 'CREATE': return 'CRIADO';
    
        default: return '';
    }
}

export default function StatusIcon({status, ...props}) {


    switch (status) {
        case 'NEW':
            return <MoreVertIcon {...props} />

        case 'DROP':
            return <CancelIcon {...props} />

        case 'PLAY':
            return <PlayCircleOutlineIcon {...props} />

        case 'STOP':
            return <PauseCircleFilledIcon {...props} />

        case 'DONE':
            return <DoneIcon {...props} />

        case 'CQ':
            return <DoneAllIcon {...props} />

        default:
            return null;
    }
}