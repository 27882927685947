import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

/**
 * @typedef {{width: string|number } & import('@material-ui/core').SwipeableDrawerProps } props
 * @extends {React.Component<props>}
 */
export default class Sidenav extends React.Component {
    
    constructor(props) {
        
        super(props);

        this.state = {
            draweranchor: 'right'
        }

        this.resizeScreen = this.resizeScreen.bind(this);
    }


    componentDidMount() {

        window.addEventListener("resize", this.resizeScreen());

    }


    resizeScreen() {
        if (window.innerWidth < 933) {
            this.setState({ draweranchor: 'bottom' });
        } else {
            this.setState({ draweranchor: 'right' });
        }

    }
    render() {

        return <SwipeableDrawer disableSwipeToOpen disableBackdropTransition onOpen={() => true}  anchor={this.state.draweranchor} {...this.props} />
    }
}
