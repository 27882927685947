import React from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton'
import ListSubheader from '@material-ui/core/ListSubheader'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {Socket, Emit} from '../../services/useSocket'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EditIcon from '@material-ui/icons/Edit'
import FeaturesAndAttributesList from './FeaturesAndAttributesList';
import FeaturesAndAttributesGlobals from './FeaturesAndAttributesGlobals';
import Divider from '@material-ui/core/Divider'
export default class AddEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      codename: {
        id: 0,
        label: '',
        caption: '',
        reference: null,
        files: [],
        CodenameUserFeature: [{
          id: 0,
          label: '',
          files: [],
          feature: {
            id: 0,
            label: ''
          }
        }],
        CodenameUserAttribute: [{
          id: 0,
          label: '',
          files: [],
          attribute: {
            id: 0,
            label: ''
          }
        }],
      },
      tab: 0
    }

    this.events = {
      "codenames:will:update": this.setCodename,
      "codenames:will:update:error": () => alert("Falha"),
      "comparison:attribute:will:delete:success": this.attributesWillDelete,
      "comparison:attribute:willUpsert:success": this.attributesWillUpsert,
      "comparison:attribute:will:update:success": this.attributesWillUpdate,
      "comparison:attribute:willreceiveupload:success": this.attributesWillReceiveUpload,
      "comparison:attribute:willremoveupload:success": this.attributesWillRemoveUpload,
      "comparison:feature:will:delete:success": this.featuresWillDelete,
      "comparison:feature:willUpsert:success": this.featuresWillUpsert,
      "comparison:feature:will:update:success": this.featuresWillUpdate,
      "comparison:feature:willreceiveupload:success": this.featuresWillReceiveUpload,
      "comparison:feature:willremoveupload:success": this.featuresWillRemoveUpload,
    }
  }

  setCodename = (codename) => this.setState({ codename });
  setTab = (tab) => this.setState({ tab });


  // handleChange = (e) => this.setCodename({ ...this.state.codename, [e.target.name]: e.target.value })
  handleCodenameUpsert = (e) => {
    e.preventDefault();
    Emit("codenames:willUpsert", { [e.target[0].name]: e.target[0].value, id: this.state.codename.id });
    e.target[0].blur();
  };

  featuresWillDelete = (data) => {
    const { codename } = this.state;
    codename.CodenameUserFeature.rmv(x => x.id === data.id);
    this.setCodename(codename);
  }
  attributesWillDelete = (data) => {
    const { codename } = this.state;
    codename.CodenameUserAttribute.rmv(x => x.id === data.id);
    this.setCodename(codename);
  }

  attributesWillUpsert = (data) => {
    const { codename } = this.state;
    const { Attributes } = this.props;

    if (codename.CodenameUserAttribute.findIndex(i => i.id === data.id) === -1) {
      codename.CodenameUserAttribute.push(data);
      this.setCodename(codename);
    }

    const { attribute } = data;
    if (Attributes.findIndex(i => i.id === attribute.id) === -1) {
      Attributes.push(attribute);
      this.props.setAttributes(Attributes);
    }

  }

  featuresWillUpsert = (data) => {
    const { codename } = this.state;
    const { Features } = this.props;

    if (codename.CodenameUserFeature.findIndex(i => i.id === data.id) === -1) {
      codename.CodenameUserFeature.push(data);
      this.setCodename(codename);
    }


    const { feature } = data;
    if (Features.findIndex(i => i.id === feature.id) === -1) {
      Features.push(feature);
      this.props.setFeatures(Features)
    }

  }

  attributesWillUpdate = (newitem) => {
    this.setState(({ codename }) => codename.CodenameUserAttribute.set(x => x.id === newitem.id, newitem))
  }

  attributesWillReceiveUpload = ({ id, files }) => {
    const { codename } = this.state;
    let i = codename.CodenameUserAttribute.findIndex(e => e.id === id);

    for (let index = 0; index < files.length; index++) {
      codename.CodenameUserAttribute[i].files.push(files[index])
    }

    this.setState({ codename });
  }

  attributesWillRemoveUpload = ({ id, file }) => {
    const { codename } = this.state;

    codename.CodenameUserAttribute.filter(e => e.id === id).first().files.rmv(e => e.file === file)

    this.setState({ codename });
  }

  featuresWillUpdate = (newitem) => {
    this.setState(({ codename }) => codename.CodenameUserFeature.set(x => x.id === newitem.id, newitem))
  }

  featuresWillReceiveUpload = ({ id, files }) => {
    const { codename } = this.state;
    let i = codename.CodenameUserFeature.findIndex(e => e.id === id);

    for (let index = 0; index < files.length; index++) {
      codename.CodenameUserFeature[i].files.push(files[index])
    }

    this.setState({ codename });
  }

  featuresWillRemoveUpload = ({ id, file }) => {
    const { codename } = this.state;
    codename.CodenameUserFeature.filter(e => e.id === id).first().files.rmv(e => e.file === file)
    this.setState({ codename });
  }

  componentDidMount() {
    if (this.props.id) {
      Emit("codenames:get", this.props.id);
    }
  }


  render() {
    return (
      <>
        <Socket events={this.events} />
        <Grid item md={4} xs={12}>
          <Paper spacing={2}>

            <List dense={true} >
              <ListSubheader component="div">Editar identidade</ListSubheader>
              <ListItem component="form" onSubmit={this.handleCodenameUpsert}>
                <TextField fullWidth autoComplete="off" size="small" name="label" required
                  helperText="Editar título do codename"
                  placeholder={this.state.codename.label}
                  InputProps={{
                    endAdornment: (
                      < InputAdornment>
                        <IconButton type="submit" aria-label="Save" size="small" >
                          <EditIcon />
                        </IconButton>
                      </InputAdornment>

                    )
                  }}
                />
              </ListItem>
              <ListItem component="form" onSubmit={this.handleCodenameUpsert}>
                <TextField fullWidth 
                    required 
                    autoComplete="off" 
                    size="small" 
                    name="caption" 
                    helperText="editar descrição do codename"
                    placeholder={this.state.codename.caption}
                    InputProps={{
                      endAdornment: (
                        < InputAdornment>
                          <IconButton type="submit" aria-label="Save" size="small" >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                />
              </ListItem>
            </List>
            <Divider variant="middle"/>
            <FeaturesAndAttributesGlobals
              subheader="Principais Características"
              registered={this.state.codename.CodenameUserFeature.map(e => e.feature) || []}
              list={this.props.Features.filter(e => e.codenameId === null) || []}
              event="feature"
              onChange={() => this.setTab(0)}
            />

            <FeaturesAndAttributesGlobals
              subheader="Principais Atributos"
              registered={this.state.codename.CodenameUserAttribute.map(e => e.attribute) || []}
              list={this.props.Attributes.filter(e => e.codenameId === null) || []}
              event="attribute"
              onChange={() => this.setTab(1)}
            />

          </Paper>
        </Grid>
        <Grid item md={8} xs={12} className="no-overflow">
          <Paper spacing={2} >

            <Tabs value={this.state.tab} onChange={(e, value) => this.setTab(value)} aria-label="codename tab" indicatorColor="primary" textColor="primary" variant="fullWidth">
              <Tab label={`Características (${this.state.codename.CodenameUserFeature.length})`} />
              <Tab label={`Atributos (${this.state.codename.CodenameUserAttribute.length})`} />
            </Tabs>

            {Boolean(this.state.tab === 0) &&
              <FeaturesAndAttributesList
                name={"Características"}
                event="feature"
                list={this.state.codename.CodenameUserFeature || []}
                options={this.props.Features.filter(e => !e.codenameId || e.codenameId === this.props.id)}
              />}

            {Boolean(this.state.tab === 1) &&
              <FeaturesAndAttributesList
                name={"Atributos"}
                event="attribute"
                list={this.state.codename.CodenameUserAttribute || []}
                options={this.props.Attributes.filter(e => !e.codenameId || e.codenameId === this.props.id)}
              />}
          </Paper>
        </Grid>
      </>
    )
  }
}