import React, { Fragment, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { upload } from '../../services/Auth';
import socket from '../../services/socket';
import { ConvertIntoImage } from '../../services/useFunctions';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CameraHandler from './CameraHandler';

function CameraPreview({ user }) {
    const history = useHistory();
    const [dataUri, setDataUri] = useState('');

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const setClose = useCallback(() => {
        setDataUri('');
        history.push("#")

    }, [history])

    const handleTakePhoto = useCallback(() => {
        const FileList = ConvertIntoImage(dataUri, user.uuid);
        upload(`/gallery/upload/image/user/${user.uuid}`, { files: [FileList] })
            .then(() => socket.emit('admin:users:will:change', { uuid: user.uuid }))
            .then( setClose );
            // setClose
    }, [user, dataUri, setClose]);

    return (
        <Dialog fullScreen={isMobile} fullWidth maxWidth={'md'} open={Boolean(window.location.hash === '#camera')} onClose={setClose}>
            <DialogContent dividers>
                <Grid container justify="center">

                    {Boolean(dataUri) &&
                        <img
                            alt="hot-pic"
                            src={dataUri}
                        />
                    }

                    {!Boolean(dataUri) &&
                        <CameraHandler
                            cameraMobile={isMobile}
                            onTakePhoto={setDataUri}
                        />
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                {dataUri &&
                    <Fragment>
                        <Button onClick={() => setDataUri(false)}>Tentar de novo</Button>
                        <Button variant="outlined" onClick={handleTakePhoto}>Salvar</Button>
                    </Fragment>
                }
                <Button onClick={() => setClose(false)}>Sair</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CameraPreview;