import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemIcon, ListItemText, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StyledGroupAvatar, UploadAvatar, UploadButton } from '../../Components/AvatarUserIcon';
import ContextMenu from '../../Components/ContextMenu';
import TaskReducer from '../../Reducers/TasksReducers';
const { dispatch } = new TaskReducer('commerce:carte');

function CommerceCarteDialog(props) {

    const Open = Boolean(props.location.hash);

    const contextMenu = React.useRef({});

    /**@typedef {Task} Carte */
    const Carte = useSelector(state => state.task.find(e => e.uuid === props.location.hash.replace("#", '')));

    const [carte, setCarte] = React.useState({
        id: 0,
        uuid: '',
        title: '',
        description: '',
        finish: new Date()
    });

    React.useEffect(() => {
        if (Carte) {
            const { title, uuid, id, description, finish } = Carte;
            setCarte({ title, uuid, id, description, finish })
        }

    }, [Carte])


    const handleChange = React.useCallback(target => (e) => {
        setCarte({ ...carte, [target]: e.target.value })

    }, [carte]);


    const handleSave = React.useCallback(() => {
            dispatch.willUpdate({...carte, finish: carte.finish.toDate('date') });

    }, [carte])

    const handleCreateUpload = React.useCallback(e => {
        dispatch.willCreateFile(e, Carte.uuid);

    }, [Carte])

    const handleDeleteUpload = React.useCallback(() => {
        dispatch.willDeleteFile(contextMenu.current.Item.file, Carte.uuid, () => contextMenu.current.Close());

    }, [Carte])


    return (
        <Dialog maxWidth={'sm'} fullWidth open={Open} onClose={() => props.history.push("#")}>
            {Carte && <>

                <ContextMenu ref={contextMenu}>
                    <MenuItem onClick={handleDeleteUpload}>Excluir Imagem</MenuItem>
                </ContextMenu>

                <DialogTitle>Editar {Carte.title}</DialogTitle>
                <DialogContent>

                    <ListItem>
                        <TextField fullWidth onChange={handleChange('title')} defaultValue={Carte.title} helperText="Editar Título do Cardápio" />
                    </ListItem>

                    <ListItem>
                        <TextField fullWidth onChange={handleChange('description')} defaultValue={Carte.description} helperText="Descrição do Cardápio" />
                    </ListItem>

                    <ListItem>
                        <TextField type="date" fullWidth 
                            onChange={handleChange('finish')} 
                            value={Carte.finish.toDate('date')} 
                            label="Validade" 
                            helperText={`Data que o cardápio vai sumir ${Carte.finish.toDate('date')}`}/>
                        {/* <InputMask mask="99/99/9999" component={TextField} fullWidth onChange={handleChange('finish')} defaultValue={Carte.finish.ptBR()} helperText="Validade" /> */}
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <UploadButton onFireUpload={handleCreateUpload} />
                        </ListItemIcon>

                        <ListItemText>
                            {Carte.files.length > 0 ?
                                <StyledGroupAvatar children={(Carte.files || []).map(file =>
                                    <UploadAvatar onContextMenu={e => contextMenu.current.OpenMenu(e, file)} file={file.file} key={file.file} title={file.name} />
                                )}
                                />
                                : "Subir Imagens Para O Carrossel"
                            }
                        </ListItemText>

                    </ListItem>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.history.push("#")}>Cancelar</Button>
                    <Button 
                        disabled={Carte.title===carte.title&&Carte.description===carte.description&&Carte.finish.ptBR()===carte.finish}
                        onClick={handleSave}>Salvar</Button>
                </DialogActions>
            </>}
        </Dialog>
    );
}

export default CommerceCarteDialog;