import React from 'react';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GreyButton, SubHeader, DrawerList, RedButton } from '../../Components/StyledComponents';
import Reducer from '../../Reducers/TasksReducers'
import DeliverCardHeader, { DeliverCardContent } from '../../Components/DeliverCardHeader';
import { getDistance } from 'geolib';

import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import MapIcon from '@material-ui/icons/Map'
import MessageIcon from '@material-ui/icons/Message';

const { dispatch } = new Reducer('order');


function CommerceNews() {
    const News = useSelector(state => state.task.filter(e => e.status === 'NEW'));

    const Topics = useSelector(state => state.topic);
    const Toasts = useSelector(state => state.toast);
    const history = useHistory();
    const faction = useSelector(state => state.user.faction);

    const [factionLatitude, factionLogitude] = React.useMemo(() => {
        return (faction?.address?.coords) ? faction.address.coords.split(',') : [];

    }, [faction]);

    const setDistanceBy = React.useCallback((Address) => {

        const [latitude, longitude] = (Address) ? Address.getParentheses().split(',') : [];

        if (factionLatitude && latitude)
            return ((getDistance({ latitude: factionLatitude, longitude: factionLogitude }, { latitude, longitude }) / 1000) * 1.6).toFixed(0);

        else
            return null

    }, [factionLatitude, factionLogitude]);

    if (!News || News.length === 0) {
        return null;
    };

    if (!faction.address)
        return (
            <Drawer anchor="right" open={history.location.hash === '#news'} onClose={() => history.push("#")}>
                <DrawerList>
                    <Button onClick={() => history.push("/my/work")} fullWidth>Cadastre o endereço do restaurante</Button>
                </DrawerList>
            </Drawer>
        )

    const fullAddress = [faction.address.street, faction.address.neighborhood, faction.address.city, faction.address.number].join('+');

    return (
        <Drawer anchor="right" open={history.location.hash === '#news'} onClose={() => history.push("#")}>
            <DrawerList>
                <SubHeader>
                    Novos Pedidos
                </SubHeader>
                {
                    News.map(x => {
                        const [Address] = x.description?.split('|') || [];
                        const distance = setDistanceBy(Address);

                        return (
                            <Card key={x.uuid} variant="outlined" style={{ margin: 12 }}>
                                <DeliverCardHeader {...x} />
                                <DeliverCardContent
                                    topics={Topics.filter(topic => x.id === topic.taskId).map(topic => ({ ...topic, toasts: Toasts.filter(toast => toast.topicId === topic.id) }))}
                                />

                                <CardActions>
                                    <GreyButton component="a" target="_blank" rel="noreferrer"
                                        size="small"
                                        href={Address && `https://www.google.com/maps/dir/?api=1&origin=${fullAddress}&destination=${Address.rmvParentheses()}&travelmode=bicycling`}
                                        color="primary"
                                        variant="contained" startIcon={<MapIcon />}>
                                        {distance}KM
                                    </GreyButton>
                                    <RedButton
                                        size="small"
                                        color="inherit"
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => dispatch.willUpdate({ id: x.id, uuid: x.uuid, status: 'DROP' })}>
                                        Recusar
                                    </RedButton>
                                    <Button
                                        size="small"
                                        startIcon={<DoneIcon />}
                                        variant="contained"
                                        onClick={() => dispatch.willUpdate({ id: x.id, uuid: x.uuid, status: 'STOP' })}
                                        color="secondary">
                                        Aceitar
                                </Button>
                                <IconButton onClick={() => history.push(`/todos/comment/task/${x.uuid}`, { background: history.location }) }>
                                    <MessageIcon />
                                </IconButton>
                                </CardActions>
                            </Card>
                        )
                    }
                    )}
            </DrawerList>
        </Drawer>
    );
}

export default CommerceNews;