import React from 'react';
import PropTypes from 'prop-types';

export default class TabPanel extends React.Component {

  render() {
    const { children, value, index, onChangeIndex, ...other } = this.props;

    return (
             <div
          role="tabpanel"
          hidden={value !== index}
          id={`swipeable-tabpanel-${index}`}
          aria-labelledby={`swipeable-tabpanel-${index}`}
          {...other}
        >
          {Boolean(value === index) && (
            children
          )}
        </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
  value: PropTypes.any,
};
