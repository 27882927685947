import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { withRouter } from 'react-router';
/**
 * @typedef { {drawerProps:import('@material-ui/core').SwipeableDrawerProps} & import('react-router').RouterProps } props
 * @extends {React.Component<props>}
 */
class SwipeableNavigation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false
        }

        this.setOpen = this.setOpen.bind(this);
        this.setClose = this.setClose.bind(this);
    }

    setOpen = () => this.setState({ open: true });
    setClose = () =>this.setState({ open: false });

    close = () => {

        if(this.state.open)
        return this.props.history.goBack();
    }

    componentDidMount(){
        if(!this.props.path) this.setOpen()
    }


    componentDidUpdate() {
        if(this.props.path){

            if (!this.state.open && this.props.location.pathname === this.props.path)
            return this.setOpen();
            
            if (this.state.open && this.props.location.pathname !== this.props.path)
            return this.setClose()

        }
    }

    PushRender = () => {

        if (this.props.path && this.props.location.pathname !== this.props.path)
            this.props.history.push(this.props.path, { background: this.props.location })
    }


    render() {
        const {component: Component, children, drawerProps, ...props} = this.props;
        return (
            <SwipeableDrawer open={this.state.open} onClose={this.close} onOpen={this.PushRender} {...drawerProps} key={'swipeable-drawer-'+this.props.history.key} >
                {Boolean(this.props.component) ? <Component {...props} /> : children}
            </SwipeableDrawer>
        )
    }
}

export default withRouter(SwipeableNavigation)