import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';


export default function Image(props) {
    return (
        <GridList cols={1} rows={1}>
            <GridListTile
                key={props.src}
                cols={1}>
                <img
                    src={props.src}
                    alt={props.title}
                    width={props.width}
                    height={props.height}
                    style={{
                        paddingLeft: '25%',
                        paddingRight: '25%'
                    }} />
                <GridListTileBar
                    title={props.title}
                    subtitle={props.subtitle}
                    actionIcon={props.action} />
            </GridListTile>
        </GridList>
    )
}