import 'react-perfect-scrollbar/dist/css/styles.css';
import { withStyles, styled, withTheme } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

export default function StyledComponents(nodeElement) {
  return styled(withTheme(nodeElement))
};


export const Row = styled(ListItem)({
  cursor: "pointer",
  '&.MEDIUM': {
      backgroundColor: 'rgba(90,200,200, .05)'
  },
  '&.HIGH': {
      backgroundColor: 'rgba(200,200,90, .05)'
  },
  '&.URGENT': {
      backgroundColor: 'rgba(200,90,90, .05)'
  }
})

export const Column = withTheme(
  styled(ListItemText)(
      props => ({
          whiteSpace: 'nowrap',
          userSelect: 'none',
          MsUserSelect: 'none',
          MozUserSelect: 'none',
          KhtmlUserSelect: 'none',
          WebkitUserSelect: 'none',
          WebkitTouchCallout: 'none',
          maxWidth: props.max,
          width: props.width,
          textAlign: props.align,
          overflow: props.overflow || 'auto',
          [props.theme.breakpoints.down('md')]: {
              display: props.display === "desktop" && 'none'
          }
      })
  ))

export const StyledBadge = withStyles(() => ({
  badge: {                    //MuiBadge-badge 
    right: -3,
    top: 6,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);


export const RedButton = withStyles((theme) => ({
  root: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
}))(Button);

export const GreyButton = withStyles((theme) => ({
  root: {
    background: theme.palette.success.main
  },
}))(Button);

export const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})

export const FluidBox = styled(Box)({
  display: "flex",
  overflow: "auto",
  alignItems: "center",
  width: '100%'
})

export const Panel = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: `100vh`,
      marginBottom: -theme.spacing(1)
    }
  },
}))(Paper)

export const InputField = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    '& .MuiDivider-vertical': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '& > .MuiSvgIcon-root': {
      marginRight: theme.spacing(2)
    }

  },
}))(Box)

export const Block = withStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    width: '100%'
  },
}))(Box)


export const ScrollBox = withStyles((theme) => ({
  root: {
    height:"100vh",
    overflow:"auto",
    [theme.breakpoints.down('md')] :{
      height:"calc(100vh - 116px)",
    }
  },
}))(Box)


export const GridOverflowed = styled(withTheme(Grid))(
  props => ({
    [props.theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: props.height || `100vh`,
    },
    [props.theme.breakpoints.down('md')]: {
      width: 'inherit',
      height: 'inherit',
    }
  })
)

export const DrawerList = styled(withTheme(List))(
  props => ({
    backgroundColor: props.theme.palette.background.paper,
    overflowY: 'auto',
    [props.theme.breakpoints.up('md')]: {
      width: props.size ? props.size : 'auto',
      overflowX: 'hidden',
      height: '100vh',
    },
    [props.theme.breakpoints.down('md')]: {
      minWidth: 350,
      width: 'auto',
      height: 'auto',
    }
  })
)

export const SubHeader = styled(ListSubheader)({
  display: 'flex',
  justifyContent: 'space-between'
})


export const BackDrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})
)(Backdrop)

export const ContentMain = withStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 58,
        marginRight: 58,
        height: '100vh',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 58,
        marginBottom: 58
      }
    }
  })
)(Box);

export const Vertical = withStyles(
  (theme) => ({
    root: {
      height: 'auto',
      alignSelf: 'stretch',
      marginRight: theme.spacing(2.5),
      marginLeft: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  })
)(Divider);


/**
 * @param {number} subtract
 * ALL LISTS INSIDE BOX OVERFLOWED AND FIXED HEIGHT
 */
export const ListView = withStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      height: `100vh`,
      overflow: 'auto'
    }
  }
}))(List)

/**
 * @param {number} height -- mobile
 * @param {number} subtract -- desktop
 * ALL LISTS THAT WILL USE AUTOSIZER HAVE INSIDE HERE 
 */
export const ScrollViewArea = styled(withTheme(List))(
  props => ({
    [props.theme.breakpoints.down('md')]: {
      height: props.heightmobile,
    },
    [props.theme.breakpoints.up('md')]: {
      backgroundColor: props.theme.palette.background.paper,
      overflow: "hidden",
      height: `100vh`,
      "& .MuiInputBase-input": {
        fontSize: 12,
      }

    }
  })
);

export const DrawerSecondary = styled(withTheme(Drawer))((props) => ({
  flexShrink: 0,
  fontSize: 10,
  display: 'flex',
  alignContent: 'center',
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 25
  }
})
);

export const ToolbarIconButton = styled(withTheme(IconButton))(props => ({
  marginRight: props.theme.spacing(2),
}))

export const ToolbarTitle = styled(Typography)({
  flexGrow: 1,
})

