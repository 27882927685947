import socket from "../../services/socket"
import Cache from '../Compress'
import update from "immutability-helper";

const initialState = Cache.get('deliver') || {
    restaurants: [],
    restaurant: {}
};

export const DID_MOUNT = 'deliver:did:mount';
export const WILL_SELECT = 'deliver:will:select';
export const WILL_CREATE = 'deliver:will:create';
export const SET_RESTAURANT = 'SET_RESTAURANT';


export const actions = {
    didMount: (payload) => ({ type: DID_MOUNT, data: payload }),
    willSelect: (payload) => ({ type: WILL_SELECT, data: payload }),
    setRestaurant: (payload) => ({ type: SET_RESTAURANT, data: payload }),

}

export const dispatch = {
    /**@param {{uuid: string}} data*/
    Mount: (data) => socket.emit(WILL_SELECT, data),

}

export const listen = (store) => {
    socket.on(DID_MOUNT,   (data) => store.dispatch(actions.didMount(data)))

    setTimeout(() => {
        socket.emit(DID_MOUNT);
    }, 3777)

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case DID_MOUNT:
            return update(state, {
                restaurants: { $set: action.data }
            })

        case SET_RESTAURANT:
            return update(state, {
                restaurant: { $set: action.data }
            })

        default:
            return state;
    }
}

export default reducer;