import React from 'react';
import { Redirect, Route } from 'react-router';
import { isAuthenticated } from '../../services/Auth';
import { Faction } from '../Allow';
import { Socket } from '../../services/useSocket';
import { connect } from 'react-redux'
import PLUCK from '../../sounds/pluck.mp3';
// import JUNTOS from  '../../sounds/juntos.mp3'; 
import POP from '../../sounds/pop.mp3';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BackDrop, ContentMain } from '../StyledComponents';
import Navigation from '../Drawer';
import SnackBar from '../SnackBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

var debounce;
const pluck = new Audio(PLUCK);
const pop = new Audio(POP); // pop is more higher than plucks

class PrivateRoute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            backdrop: false
        };

        this.setBackdrop = this.setBackdrop.bind(this);
    }

    setBackdrop = data => {
        if (data === true) {
            if (this.state.backdrop === false) 
            return this.setState({ backdrop: true })

        }
        else {
            if (debounce) clearTimeout(debounce)
            return debounce = setTimeout(() => this.setState({ backdrop: false }), 777);

        }

    };

    render() {
        const { user, component: Component, allow, path, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => isAuthenticated()
                    ?
                    <Faction on={allow} message="Sem acesso ao módulo">
                        <Socket events={{
                            'backdrop': this.setBackdrop,
                            'sound:pop': () => pop.play(),
                            'sound:pluck': () => pluck.play(),
                            'history:push': (route) => props.history.push(route.path, { background: route.background ? props.history.location : false })
                        }}>
                            <SnackBar {...props}/>
                            <TransitionGroup>
                                <CSSTransition
                                    key={path}
                                    classNames="body"
                                    timeout={155}>
                                    <ContentMain>
                                        {Component}
                                    </ContentMain>
                                </CSSTransition>
                            </TransitionGroup>
                            <Navigation user={user} {...props} />
                            <BackDrop className={this.props.classes.backdrop} open={this.state.backdrop}><CircularProgress /></BackDrop>
                        </Socket>
                    </Faction>
                    : <Redirect to={{ pathname: "/realtime", state: { from: props.location } }} />
                }
            />
        )
    }


};


export default connect(state => ({
    user: state.user
}), null)
    (withStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1000,
            color: '#fff',
        },
    }), { withTheme: true })
        (PrivateRoute));