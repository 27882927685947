import React from 'react';
import { connect } from 'react-redux';
import useStyles from './styles.js';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TabPanel from '../../Components/TabPanel';
import TodosTotalsSquare from '../../Components/TodosTotalsSquare';
import { mapStateToProps, mapDispatchToProps, dispatch as mapDispatch } from './flux.js';
import InnerContextMenu from './InnerContextMenu.js';
import InnerTimelineText from './InnerTimelineText.js';
import InnerOptions from './InnerOptions.js';
/**
 * 
 * @param {object} props
 * @param {import('../../typeroots').Task[]} props.tasks
 * @param {import('../../typeroots').Topic[]} props.topics
 * @param {import('../../typeroots').Toast[]} props.toasts
 * @param {[{group?: string, index: number, tasks: props.tasks}]} props.groups
 * @param {{total: number, done: number, play: number, drop: number, news: number, peso: number, price: number}} props.counters
 * 
 */
function TodosAlerts(props) {
    const theme = useTheme();

    const classes = useStyles();
    const contextMenu = React.useRef({});

    const [contextItem, setContextItem] = React.useState({});
    const [index_group, setIndexGroup] = React.useState(0);

    const topics = React.useCallback((task) => {
        return props.topics.filter(topic => topic.taskId === task.id);

    }, [props.topics])


    const toasts = React.useCallback((topic) => {
        return props.toasts.filter(toast => toast.topicId === topic.id);

    }, [props.toasts])


    const redirectDefault = React.useCallback((type, uuid) => {
        props.history.push(`/todos/comment/${type}/${uuid}`, { background: props.location });

    }, [props.history, props.location])



    const lastLength = React.useMemo(() => {
        return props.groups.length;

    }, [props.groups]);


    return (
        <Grid className={classes.root} container spacing={1}>
            <InnerContextMenu
                ref={contextMenu}
                contextItem={contextItem}
                onClose={() => setContextItem({})}
                {...props}
            />
            <Hidden mdDown>
                <Grid item xs={12} md={3} style={{ height: '100vh', overflow: 'auto' }}>
                    <Paper>
                        <TodosTotalsSquare action={props.setStatus} {...props.counters} />
                    </Paper>
                </Grid>
            </Hidden>
            <Grid item xs={12} md={9} className={classes.GridListRoot}>
                <AppBar position="static" color="primary">
                    <Tabs
                        value={index_group}
                        onChange={(e, value) => setIndexGroup(value)}
                        variant="scrollable"
                        indicatorColor="primary"
                        aria-label="full width tabs groups">
                        {props.groups.map(g =>
                            <Tab label={g.group || 'Tarefas'} value={g.index} key={`variant-tab-group-${g.group}`} />
                        )}
                        {window.innerWidth < 933 &&
                            <Tab label={'Totais'} value={lastLength} key={`variant-tab-group-totais`} />
                        }
                    </Tabs>
                </AppBar>
                <SwipeableViews index={index_group} onChangeIndex={(newValue) => setIndexGroup(newValue)}>
                    {props.groups.map(g =>
                        <TabPanel
                            key={'taskpanel-' + g.group}
                            index={g.index}
                            value={index_group}
                        >
                            <div style={{ paddingTop: 32 }}>
                                <Timeline className={classes.timelineRoot}>
                                    {g.tasks.map((task, aix) =>
                                        <TimelineItem className={classes.timelineItem} key={'timeline-' + task.uuid}>
                                            <TimelineSeparator>
                                                <TimelineDot style={{ backgroundColor: task.status.asStatusColor() }} />
                                                {g.tasks.length > (aix + 1) && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <List>
                                                    <InnerTimelineText {...task}
                                                        click={e => contextMenu.current.Open(e, setContextItem({ ...task, type: 'task' }))}
                                                        defaultClick={() => redirectDefault('task', task.uuid)}
                                                        dispatch={mapDispatch.task.dispatch}
                                                        actions={<InnerOptions {...task} type="task" />}
                                                    />

                                                    {/* <Collapse in={Boolean(!['DONE', "STOP"].includes(task.status))}> */}
                                                    <Timeline className={classes.timelineRoot}>
                                                        {topics(task).map((topic, bix) =>
                                                            <TimelineItem key={'topic-' + topic.uuid} className={classes.timelineItem}>
                                                                <TimelineSeparator>
                                                                    <TimelineDot style={{ backgroundColor: topic.status.asStatusColor() }} />
                                                                    {topics(task).length > (bix + 1) && <TimelineConnector />}
                                                                </TimelineSeparator>
                                                                <TimelineContent>
                                                                    <List>
                                                                        <InnerTimelineText {...topic}
                                                                            click={e => contextMenu.current.Open(e, setContextItem({ ...topic, type: 'topic' }))}
                                                                            defaultClick={() => redirectDefault('topic', topic.uuid)}
                                                                            dispatch={mapDispatch.topic.dispatch}
                                                                            actions={<InnerOptions {...topic} type="topic" />}
                                                                        />

                                                                        {/* <Collapse in={Boolean(!['DONE', "STOP"].includes(topic.status))}> */}
                                                                        <Timeline className={classes.timelineRoot} >
                                                                            {toasts(topic).map((toast, cix) =>
                                                                                <TimelineItem key={"toast-" + toast.uuid} className={classes.timelineItem}>
                                                                                    <TimelineSeparator>
                                                                                        <TimelineDot style={{ backgroundColor: toast.status.asStatusColor() }} />
                                                                                        {toasts(topic).length > (cix + 1) && <TimelineConnector />}
                                                                                    </TimelineSeparator>
                                                                                    <TimelineContent>
                                                                                        <List>
                                                                                            <InnerTimelineText {...toast}
                                                                                                click={e => contextMenu.current.Open(e, setContextItem({ ...toast, type: 'toast' }))}
                                                                                                defaultClick={() => redirectDefault('toast', toast.uuid)}
                                                                                                dispatch={mapDispatch.toast.dispatch}
                                                                                                actions={<InnerOptions {...toast} type="toast" />}
                                                                                            />
                                                                                        </List>
                                                                                    </TimelineContent>
                                                                                </TimelineItem>
                                                                            )}
                                                                        </Timeline>
                                                                        {/* </Collapse> */}
                                                                    </List>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        )}
                                                    </Timeline>
                                                    {/* </Collapse> */}
                                                </List>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}
                                </Timeline>
                            </div>
                        </TabPanel>
                    )}
                    <TabPanel
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={lastLength}
                        value={index_group}
                    >
                        <TodosTotalsSquare action={props.setStatus} {...props.counters} />
                    </TabPanel>
                </SwipeableViews>
            </Grid>


        </Grid>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(TodosAlerts);