import update from "immutability-helper";
import socket from "../../services/socket"
import Cache from '../Compress'

/**@type {TodosFilter} */
const initialState = Cache.get('todosFilter') || {
    sortBy: 'title',
    sort: 'asc',
    status: 'ALL',
    statusMultiple: ["NEW", "PLAY"],
    users: [],
    dates: [new Date(), new Date()],
    collection: []
};

const SET_DATES = 'SET:DATES';
const SET_STATUS = 'SET:STATUS';
const SET_MULTIPLE_STATUS = 'SET:MULTIPLE:STATUS';
const SET_USERS = 'SET:USERS';
const SET_SORT = 'SET_SORT';
const SET_SORTBY = 'SET_SORTBY';

const SET_COLLECTION = 'task:did:mount:filter';
const COLLECTIONWILLUPDATE = 'task:will:update';

export const actions = {

    /**@param {import("../../typeroots").STATUS} payload*/
    setStatus: (payload) => ({ type: SET_STATUS, data: payload }),
    setMultipleStatus: (payload) => ({ type: SET_MULTIPLE_STATUS, data: payload }),
    setUsers: (payload) => ({ type: SET_USERS, data: payload }),
    setDates: (payload) => ({ type: SET_DATES, data: payload }),
    setCollection: (payload) => ({ type: SET_COLLECTION, data: payload }),
    collectionWillUpdate: (payload) => ({ type: COLLECTIONWILLUPDATE, data: payload })

}

export const dispatch = {
    /** 
     * @param {{Date[]}} dates 
     * @return {void}
     */
    filter: ([begin, finish]) => socket.emit(SET_COLLECTION, { begin, finish }),
}

export const listen = (store) => {
    socket.on('task:did:mount:filter', payload => store.dispatch(actions.setCollection(payload)))
}

const TodosFilter = {
    setMultipleStatus: (payload) => ({ type: SET_MULTIPLE_STATUS, data: payload }),
    setStatus: (payload) => ({ type: SET_STATUS, data: payload }),
    setSort: (payload) => ({ type: SET_SORT, data: payload }),
    setSortBy: (payload) => ({ type: SET_SORTBY, data: payload }),
    setUsers: (payload) => ({ type: SET_USERS, data: payload }),

    reducer: (state = initialState, action) => {
        let index;

        switch (action.type) {
            case SET_COLLECTION:
                return update(state, {
                    collection: { $set: action.data }
                })

            case COLLECTIONWILLUPDATE:
                index = state.collection.findIndex(e => e.uuid === action.data.uuid);
                if (index !== -1)
                    return update(state, {
                        collection: { [index]: { $merge: action.data } }
                    })
                else
                    return state;

            case SET_DATES:
                return update(state, {
                    dates: { $set: action.data }
                })

            case SET_STATUS:
                return update(state, {
                    status: { $set: action.data }
                });

            case SET_MULTIPLE_STATUS:
                index = state.statusMultiple.findIndex(e => e === action.data);

                return update(state, {
                    statusMultiple: index !== -1 ? {
                        $splice: [[index, 1]]
                    } : {
                            $push: [action.data]
                        }
                });

            case SET_SORT:
                return update(state, {
                    sort: { $set: action.data }
                });

            case SET_SORTBY:
                return update(state, {
                    sortBy: { $set: action.data }
                });

            case SET_USERS:
                return update(state, {
                    users: { $set: action.data }
                })

            default:
                return state;
        }
    }
}

export default TodosFilter;