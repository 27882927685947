import React from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

/**
 * @typedef {{
 *  task: import('../../../../typeroots').Task
 *  topics: import('../../../../typeroots').Topic[]
 *  toasts: import('../../../../typeroots').Toast[]
 * }} Props --topics/toasts must be filtered by taskId
 */

const/**@type {React.FC<Props>} */Content = ({ topics, toasts }) => {

    const TopicsMemo = React.useMemo(() => {

        return topics.map(topic => ({
            ...topic,
            toasts: toasts.filter(toast => toast.topicId === topic.id)
        }))
    }, [topics, toasts])

    return (
        <List dense disablePadding 
            component={Box} 
            margin={0} 
            maxHeight={450}
            width="100%"
            overflow="auto">
            {TopicsMemo.map(topic =>
                <div key={'card-content-item-' + topic.uuid}>
                    <ListItem divider>
                        <ListItemText
                            primary={<>
                                <Typography variant="caption">{topic.description}</Typography>
                                <Typography >{topic.title} <div style={{float: 'right'}}>{String(topic.price).monetizeme()}</div> </Typography>
                            </>}
                            secondaryTypographyProps={{ variant: 'body2' }}
                            secondary={<>
                                {topic.toasts.map(toast => (
                                    <Typography 
                                        display="block"
                                        variant="caption"
                                        key={'commerce-cards-' + toast.uuid}
                                        style={{ textDecoration: toast.status === "DROP" && 'line-through' }}>
                                        {toast.title}
                                        {toast.status === 'OPTIONAL' && <div style={{float: 'right'}}>+ {String(toast.price).monetizeme() }</div> }
                                    </Typography>
                                ))}
                            </>}
                        />
                    </ListItem>

                    <List dense>
                    </List>

                </div>)}
        </List>
    )
}

export default Content;