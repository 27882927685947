import React from 'react';
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles';
const source = process.env.PUBLIC_URL + '/mask.png' ;

const Background = styled('div')({
    backgroundImage: source,
    width: '100vw',
    height: '100vh'
})

export default function Welcome(){

    return(
        <Background>
            <Typography variant="h5" color="initial">
                Welcome
            </Typography>
        </Background>
    )
}

