import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    grid: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(3),
        }
    },
    container: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            minHeight: '100vh'
        }
    },
    largeAvatar: {
        textAlign: 'center',
        margin:  theme.spacing(2) + 'px auto',
        width: theme.spacing(10),
        height: theme.spacing(10),
        background: theme.palette.secondary.main
    },
}))
