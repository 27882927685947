import React from 'react';
import { dispatch as mapDispatch } from './flux.js';
import ContextMenu, { MenuItem } from '../../Components/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import StatusIcon from '../../Components/StatusIcon/index.js';
import { CommentRounded, Edit, List } from '@material-ui/icons';

const InnerContextMenu = React.forwardRef(({ contextItem, ...props }, ref) => {

    const handleStatus = React.useCallback((status) => {
        mapDispatch[contextItem.type].dispatch.willUpdate({ uuid: contextItem.uuid, status: status });
        ref.current.Close();

    }, [contextItem, ref]);


    const redirectToList = React.useCallback(() => {
        if (contextItem.type === 'task')
            props.history.push(`/todos/task/${contextItem.id}`);

        if (contextItem.type === 'topic')
            props.history.push(`/todos/task/${contextItem.taskId}/${contextItem.id}`);

        ref.current.Close();

    }, [props, ref, contextItem])

    const redirectToComments = React.useCallback(() => {
        props.history.push(`/todos/comment/${contextItem.type}/${contextItem.uuid}`, { background: props.location });
        ref.current.Close();

    }, [contextItem, ref, props.history, props.location])


    const redirectToEdit = React.useCallback(() => {
        props.history.push(`/todos/edit/${contextItem.type}/${contextItem.uuid}`, { background: props.location })
        ref.current.Close();

    }, [props, ref, contextItem]);


    const ContextIsEditable = React.useMemo(() => {
        return (contextItem.modes?.includes('OWNER') || contextItem.modes?.includes('EDITOR'))

    }, [contextItem]);

    const ContextIsOwner = React.useMemo(() => {
        return (contextItem.modes?.includes('OWNER'))

    }, [contextItem]);

    return (
        <ContextMenu ref={ref} onClose={props.onClose}>

            <MenuItem when={ContextIsOwner} disabled>Opções</MenuItem>
            <MenuItem onClick={redirectToComments} when={contextItem}>
            <ListItemIcon>
                    <CommentRounded fontSize="small"/>
                </ListItemIcon>
                <Typography variant="subtitle2">Comentários {contextItem.comments?.length||0}</Typography>
            </MenuItem>
            
            <MenuItem onClick={redirectToList} when={ContextIsOwner && contextItem.type !== 'toast'}>
                <ListItemIcon>
                    <List fontSize="small" />
                </ListItemIcon>
                <Typography variant="subtitle2">Lista</Typography>
            </MenuItem>
            <MenuItem divider onClick={redirectToEdit} when={ContextIsOwner}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="subtitle2">Editar</Typography>
            </MenuItem>

            <MenuItem when={ContextIsEditable} disabled>Status</MenuItem>
            <MenuItem onClick={() => handleStatus("STOP")} when={ContextIsEditable && contextItem.status !== 'STOP'}>
                <ListItemIcon>
                    <StatusIcon fontSize="small" status="STOP" />
                </ListItemIcon>
                <Typography variant="subtitle2">Pausar</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleStatus("PLAY")} when={ContextIsEditable && ['STOP', 'DONE', 'DROP'].includes(contextItem.status)}>
                <ListItemIcon>
                    <StatusIcon fontSize="small" status="PLAY" />
                </ListItemIcon>
                <Typography variant="subtitle2">Refazer</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleStatus("PLAY")} when={ContextIsEditable && ['NEW'].includes(contextItem.status)}>
                <ListItemIcon>
                    <StatusIcon fontSize="small" status="PLAY" />
                </ListItemIcon>
                <Typography variant="subtitle2">Clique para iniciar</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleStatus("DONE")} when={ContextIsEditable && contextItem.status === 'PLAY'}>
                <ListItemIcon>
                    <StatusIcon fontSize="small" status="DONE" />
                </ListItemIcon>
                <Typography variant="subtitle2">Concluir</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleStatus("DROP")} when={ContextIsEditable && contextItem.status === 'PLAY'}>
                <ListItemIcon>
                    <StatusIcon fontSize="small" status="DROP" />
                </ListItemIcon>
                <Typography variant="subtitle2">Cancelar</Typography>
            </MenuItem>
        </ContextMenu>
    )
})

export default InnerContextMenu;