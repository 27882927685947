import React from 'react';
import update from 'immutability-helper';
import { Grid, Button, ListItem, ListItemText, TextField, ListItemIcon, IconButton, ListItemSecondaryAction, Switch, Divider, MenuItem } from '@material-ui/core';
import FastFoodIcon from '@material-ui/icons/Fastfood'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AlarmIcon from '@material-ui/icons/Alarm';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SearchIcon from '@material-ui/icons/Search'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteSweepOutlined from '@material-ui/icons/DeleteSweepOutlined';
import { ListView, InputField, SubHeader } from '../../Components/StyledComponents';
import { useSelector } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DragDropContext } from 'react-beautiful-dnd';
import DragDropContainer from '../../Components/DragDropContainer';
import Task from '../../Reducers/TasksReducers';
import InputDialog from '../../Components/InputDialog';
import { StyledGroupAvatar, UploadAvatar, UploadButton } from '../../Components/AvatarUserIcon';
import ContextMenu, { ContextDefaultState } from '../../Components/ContextMenu';
import { UniqIngredientsMap } from '../CommerceIngredients';


const { dispatch: SnackDispatch } = new Task('commerce:snack');
const { dispatch: ToastDispatch } = new Task('commerce:ingredient');

const filter = createFilterOptions();

const FilterOptions = (options, params) => {

    const filtered = filter(UniqIngredientsMap(options), params);

    if (params.inputValue !== '' && filtered.$filter(params.inputValue).length !== 1) {
        filtered.unshift({
            inputValue: params.inputValue,
            title: `Criar "${params.inputValue}"?`
        })
    }

    return filtered;
}


/**@param {{snack: Topic}} props*/
function CommerceLunchEditor(props) {
    const [inputToast, setInputToast] = React.useState('');

    const contextMenu = React.useRef(ContextDefaultState);

    /**@typedef {Topic} snack*/
    const [snack, setSnack] = React.useState(props.snack);

    const [change, setChange] = React.useState(false);

    /**@typedef {Array<Toast>} toasts*/
    const toasts = useSelector(state => state.toast.filter(e => e.topicId === snack?.id).sort((a, b) => a.status.localeCompare(b.status)));

    /**@typedef {Array<String>} options */
    const options = useSelector(state => state.toast)

    const onAutoComplete = (e, value) => {
        const regExp = /\(([^)]+)\)$/;
        var peso, title;

        if (value) {


            if (value.inputValue) {
                peso = regExp.exec(value.inputValue);
                peso = peso ? peso.first(1).replace(/[\D+]/g, '') : 0;
                title = value.inputValue.replace(/ *\([^)]*\) */g, "");

                ToastDispatch.willCreate({ taskId: snack.taskId, topicId: snack?.id, input: title, peso })

            } else {
                peso = regExp.exec(value.title);
                peso = peso ? peso.first(1).replace(/[\D+]/g, '') : 0;
                title = value.title.replace(/ *\([^)]*\) */g, "");

                ToastDispatch.willCreate({ taskId: snack.taskId, topicId: snack?.id, input: title, peso })
            }

            console.log({ peso, title })

            setInputToast('');
        }
    }

    const handleSave = React.useCallback(() => {
        const { title, description, price, group, peso, useAsModel } = snack;
        SnackDispatch.willUpdate({ uuid: snack.uuid, title, description, price, group, peso, useAsModel })

    }, [snack])

    const handleSnack = React.useCallback(data => {
        setSnack(update(snack, { $merge: data }));

    }, [snack])

    const handleDragToastEnd = ({ draggableId, source, destination }) => {
        if (source && destination && source.droppableId !== destination.droppableId) {
            ToastDispatch.willUpdate({ uuid: draggableId, status: destination.droppableId });
        }
    }

    const handleEditableToastPrice = React.useCallback((item) => data => {
        ToastDispatch.willUpdate({ uuid: item.uuid, ...data });

    }, [])

    const handleDeleteToast = React.useCallback((item) => () => {
        ToastDispatch.willDelete({ uuid: item.uuid });

    }, [])

    const handleCreateUpload = React.useCallback((event) => {
        SnackDispatch.willCreateFile(event, props.snack.uuid, () => event = null);

    }, [props.snack])

    const handleDeleteUpload = React.useCallback(() => {
        SnackDispatch.willDeleteFile(contextMenu.current.Item.file, props.snack.uuid, () => contextMenu.current.Close());

    }, [props.snack])


    React.useEffect(() => {
        if (props.snack)
            setSnack(props.snack);

    }, [props])

    if (!props.snack) return null;

    const ToastTextHandler = (props) => (
        <>
            <ListItemText primary={`${props.item.title} (${props.item.peso}g)`} secondary={props.secondary} />
            <ListItemSecondaryAction>

                {props.money &&
                    <IconButton onClick={() => setChange({ model: { price: String }, callback: handleEditableToastPrice(props.item), header: `Preço de ${props.item.title}`, value: props.item.price })}>
                        <AttachMoneyIcon />
                    </IconButton>
                }

                {props.edit &&
                    <IconButton onClick={() => setChange({ model: { peso: Number || 0 }, callback: handleEditableToastPrice(props.item), header: 'Editar Peso', value: props.item.peso })}>
                        <EditOutlinedIcon />
                    </IconButton>
                }

                {props.delete &&
                    <IconButton size={'small'} onClick={handleDeleteToast(props.item)}>
                        <DeleteSweepOutlined />
                    </IconButton>
                }

            </ListItemSecondaryAction>
        </>
    )

    return (
        <>
            <InputDialog {...change} onClose={() => setChange(false)} />

            <ContextMenu ref={contextMenu} keepMounted={false}>
                <MenuItem onClick={handleDeleteUpload}>Excluir arquivo</MenuItem>
            </ContextMenu>

            <Grid item xs={12} md={4}>
                <ListView dense={true} subheader={
                    <SubHeader>
                        Editar Detalhes
                        <div>
                            <Button variant="contained" color="secondary" disabled={
                                snack.title === props.snack.title &&
                                snack.description === props.snack.description &&
                                snack.price === props.snack.price &&
                                snack.group === props.snack.group &&
                                snack.useAsModel === props.snack.useAsModel &&
                                snack.peso === props.snack.peso
                            } fullWidth onClick={handleSave}>Salvar</Button>
                        </div>
                    </SubHeader>
                }>

                    <ListItem button
                        onClick={() => setChange({ model: { title: String }, callback: handleSnack, header: 'Editar Título', value: snack.title })}>
                        <ListItemIcon><FastFoodIcon /></ListItemIcon>
                        <ListItemText primary={snack.title} secondary="Título" />
                    </ListItem>

                    <ListItem button
                        onClick={() => setChange({ model: { description: String }, callback: handleSnack, header: 'Editar Descriçao', value: snack.description, multiline: true })}>
                        <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
                        <ListItemText primary={snack.description} secondary="Descrição Comercial" />
                    </ListItem>

                    <ListItem button
                        onClick={() => setChange({ model: { price: String }, callback: handleSnack, header: 'Editar Preço', value: snack.price })}>
                        <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                        <ListItemText primary={snack.price?.monetizeme()} secondary="Preço" />
                    </ListItem>

                    <ListItem button
                        onClick={() => setChange({ model: { group: String }, callback: handleSnack, header: 'Editar Grupo', value: snack.group })}>
                        <ListItemIcon><GroupWorkIcon /></ListItemIcon>
                        <ListItemText primary={snack.group} secondary="Grupo" />
                    </ListItem>

                    <ListItem button
                        onClick={() => setChange({ model: { peso: Number || 0 }, callback: handleSnack, header: 'Editar Tempo', value: snack.peso })}>
                        <ListItemIcon><AlarmIcon /></ListItemIcon>
                        <ListItemText primary={snack.peso} secondary="Tempo De Confecção" />
                    </ListItem>

                    <ListItem button component="label">
                        <ListItemIcon><ReplyAllIcon /></ListItemIcon>
                        <ListItemText primary={snack.useAsModel ? "Ativo" : "Desativado"} secondary="Ativar/Desativar Lanche" />
                        <Switch checked={snack.useAsModel} onChange={() => handleSnack({ useAsModel: !snack.useAsModel })} />
                    </ListItem>

                    <ListItem button component="label">
                        <ListItemIcon>
                            <UploadButton size="small" onFireUpload={handleCreateUpload} />
                        </ListItemIcon>
                        <ListItemText
                            primary={snack.files.length > 0 && <StyledGroupAvatar children={
                                snack.files.map(file =>
                                    <UploadAvatar onContextMenu={e => contextMenu.current.OpenMenu(e, file)} file={file.file} key={file.file} title={file.name} />
                                )} />}
                            secondary="Imagens Do Produto"
                        />
                    </ListItem>
                </ListView>
                <Divider orientation="vertical" flexItem />
            </Grid>

            <Grid item xs={12} md={4}>
                <ListView dense={true} subheader={<SubHeader>Ingredientes</SubHeader>}>
                    <ListItem>
                        <InputField>
                            <SearchIcon />
                            <Autocomplete
                                style={{ minWidth: 260 }}
                                inputValue={inputToast}
                                options={options}
                                filterOptions={FilterOptions}
                                getOptionLabel={option => option.title}
                                onChange={onAutoComplete}
                                renderInput={params => <TextField {...params} onChange={e => setInputToast(e.target.value)} placeholder="Pesquisa/Adicionar Ingrediente" />}
                            />
                        </InputField>
                    </ListItem>

                    <DragDropContext onDragEnd={handleDragToastEnd}>
                        <DragDropContainer value="NEW" subheader="Novo" collection={toasts.filter(e => e.status === 'NEW')}>
                            {/**@param {Toast} item */ item =>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <DragIndicatorIcon />
                                    </ListItemIcon>
                                    <ToastTextHandler item={item} edit={true} delete={true} />
                                </ListItem>
                            }
                        </DragDropContainer>

                        <DragDropContainer value="EDITABLE" subheader="Removíveis" collection={toasts.filter(e => e.status === 'EDITABLE')}>
                            {/**@param {Toast} item */ item =>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <DragIndicatorIcon />
                                    </ListItemIcon>
                                    <ToastTextHandler item={item} edit={true} />
                                </ListItem>
                            }
                        </DragDropContainer>

                        <DragDropContainer value="OPTIONAL" subheader="Opcionais" collection={toasts.filter(e => e.status === 'OPTIONAL')}>
                            {/**@param {Toast} item */ item =>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <DragIndicatorIcon />
                                    </ListItemIcon>
                                    <ToastTextHandler item={item} secondary={(item.price || 0).monetizeme()} money={true} />
                                </ListItem>
                            }
                        </DragDropContainer>

                        <DragDropContainer value="REQUIRED" subheader="Obrigatórios" collection={toasts.filter(e => e.status === 'REQUIRED')}>
                            {/**@param {Toast} item */ item =>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <DragIndicatorIcon />
                                    </ListItemIcon>
                                    <ToastTextHandler item={item} edit={true} />
                                </ListItem>
                            }
                        </DragDropContainer>


                        <DragDropContainer value="REMOVED" subheader="Fora do Estoque" collection={toasts.filter(e => e.status === 'REMOVED')}>
                            {/**@param {Toast} item */ item =>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <DragIndicatorIcon />
                                    </ListItemIcon>
                                    <ToastTextHandler item={item} />
                                </ListItem>
                            }
                        </DragDropContainer>
                    </DragDropContext>
                </ListView>
            </Grid>
        </>
    );
}

export default CommerceLunchEditor;