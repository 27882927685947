import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import { FlexBox, Container, GridItem, FlexCardActions } from './styles';
import socket from '../../services/socket'
import AvatarAddIcon from '../../Components/AvatarAddIcon'
import IconButton from '@material-ui/core/IconButton'
import {UserAvatar} from '../../Components/AvatarUserIcon'

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

const findMany = (fn) => state => state.task.filter(e => fn.call([], e)).sort((a, b) => new Date(a.begin) - new Date(b.begin))

/**
 * 
 * @param {{task: import('../../typeroots').Task}} props
 */
const CardComponent = ({ task, handleUser, faction, ...props }) => {

    const deliveryman = task.users?.find(e => e.mode === 'DELIVER');

    const handleRemoveUser = useCallback( () => {
        if(deliveryman && window.confirm("Remover este entregador?"))
        socket.emit('order:will:connect', { uuid: task.uuid, users: { disconnect: { id: deliveryman.user.id } } });

    }, [deliveryman, task])

    return (
        <GridItem item xs={12} md={6}>
            <Card>
                <CardContent>
                    <Typography gutterBottom display="block" variant="caption">#{task.id} {task.title.toUpperCase()}</Typography>
                    <Typography gutterBottom display="block" variant="caption" >{task.description} </Typography>
                </CardContent>
                <FlexCardActions>
                    <Avatar src={"https://heaconsultoria.com.br/static/upload/"+task.files.first().image+"_thumb"} alt={task.title}/>
                    {Boolean(task.status === "PLAY") && 
                    <FlexBox>
                        <UserAvatar variant="filled" user={deliveryman?.user} onClick={handleRemoveUser} />
                        <AvatarAddIcon includeself={true} onChange={handleUser(task)} options={props.availableUsers} current={task.users} modalHeaderComponent="Selecione o entregador">
                            <IconButton disabled={Boolean(deliveryman)} >
                                {deliveryman ? <PersonAddDisabledIcon /> : <PersonAddIcon /> }
                            </IconButton>
                        </AvatarAddIcon>
                    </FlexBox>
                    }
                </FlexCardActions>
            </Card>
        </GridItem>
    )
}

export default function CommerceDelivery() {
    const kitchen = useSelector(findMany(e => e.status === "STOP"));
    const awaiting = useSelector(findMany(e => e.status === "PLAY"));

    const availableUsers = useSelector(state => state.adminUsers);

    const handleUser = (task) => (data) => {
        socket.emit('order:will:connect', { uuid: task.uuid, users: data, mode: 'DELIVER', unique: true });
    }

    return (
        <Container container spacing={2}>

            <Grid item xs={12} md={6}>
                <Typography variant="h6">Em Preparo</Typography>
                <FlexBox>
                    {kitchen.map(task => <CardComponent key={task.uuid} task={task} />)}
                </FlexBox>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="h6">Pronto</Typography>
                <FlexBox>
                    {awaiting.map(task => <CardComponent key={task.uuid} task={task} availableUsers={availableUsers} handleUser={handleUser} />)}
                </FlexBox>
            </Grid>


        </Container >
    )
}
