import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid'

import { logout } from '../../services/Auth';

export default function SignOut(){

    useEffect( () => {

        logout( () => {
            window.location.reload();
        })
    })


    return(
        <Grid container style={{height:'100%'}} placeContent="center" justify="center">
            Deslogando ...
        </Grid>
    )
}