// import HomeIcon from '@material-ui/icons/Home';
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

import BuildIcon from '@material-ui/icons/Build';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

// import MuseumIcon from '@material-ui/icons/Museum';
// import MuseumOutlinedIcon from '@material-ui/icons/MuseumOutlined';

import StoreIcon from '@material-ui/icons/Store';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';

const routes = [
    {
        path: '/comparison/selector',
        label: 'Gestor de veículos',
        allow: 'isComparative',
        caption: false,
        active: BuildIcon,
        default: BuildOutlinedIcon
    },
    {
        path: '/todos/task',
        label: 'Gestor de projetos',
        caption: "Acompanhamento de atividades",
        allow: "isManagement",
        active: DashboardIcon,
        default: DashboardOutlinedIcon
    },
    {
        path: '/commerce',
        label: 'Gestor de comércio',
        caption: "Acompanhamento de pedidos",
        allow: 'isRestaurant',
        active: StoreIcon,
        default: StoreOutlinedIcon
    }
]

export default routes;