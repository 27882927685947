import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Allow from '../Allow'
import { NavLink, useLocation } from 'react-router-dom'
import { DrawerSecondary } from '../StyledComponents';

const router = [
    {
        path: '/comparison/codenames',
        label: 'Editar codenomes',
        caption: false,
        allow: 'ComparisonCreate', 
        active: NoteAddIcon,
        default: NoteAddOutlinedIcon
    },
    {
        path: '/comparison/user',
        label: 'Comparativos',
        allow: 'ComparisonNavigate', 
        caption: false,
        active: FindInPageIcon,
        default: FindInPageOutlinedIcon
    },
]



export default function SubNavigation(){
    const location = useLocation();

    return (
        <DrawerSecondary anchor="right" variant="permanent">
            <List>
                {router.map(route =>
                    <Allow on={route.allow}>
                        <Tooltip placement="left" key={route.label} title={route.label}>
                            <ListItem button component={NavLink} to={route.path}>

                                    {location.pathname === route.path
                                        ? <route.active />
                                        : <route.default />
                                    }

                            </ListItem>
                        </Tooltip>
                    </Allow>
                )}
            </List>
        </DrawerSecondary>
    )
}