import React from 'react';
import Sidenav from '../../Components/Sidenav';
import { DrawerList, SubHeader, Vertical } from '../../Components/StyledComponents';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'; 

import { useSelector } from 'react-redux';

import TaskReducer from '../../Reducers/TasksReducers';
import Input from '../../Components/Input';

const { dispatch } = new TaskReducer('commerce:carte');

/**@param {{edit: ('task'|'topic'|'toast'), uuid: string } & import('react-router-dom').RouteChildrenProps} props*/
function CommerceDashboardEditor(props, ref) {

    const task = useSelector(state => state.todosFilter.collection.find(e => e.uuid === props.uuid));

    const handleUpdate = React.useCallback((objectWillUpdate) => {
        dispatch.willUpdate({ id: task.id, uuid: task.uuid, ...objectWillUpdate });

    }, [task]);

    const [open, setOpen] = React.useState(false);
    const setClose = () => {

        setOpen(false);
        setTimeout(() => props.history.push('/commerce/dashboard'), 555);
    }

    React.useEffect(() => {

        if (Boolean(props.edit && props.uuid))
            setOpen(true);

        return () => {
            setOpen(false);
        }

    }, [props])

    return (
        <Sidenav ref={ref} open={open} onClose={setClose}>
            <DrawerList size={'650px'} dense>

                <SubHeader>Consulta e edição de informações <Button onClick={() => { dispatch.willDelete({uuid: task.uuid}); props.history.goBack() } } variant="text" color="alert">Excluir</Button></SubHeader>
                {task && <>
                    <ListItem divider component="label">
                        <Input fullWidth
                            margin="normal"
                            helperText="Modificar Título"
                            label="Título"
                            value={task.title}
                            onSubmit={input => handleUpdate({ title: input })}
                        />
                    </ListItem>

                    <ListItem divider component="label">
                        <Input fullWidth
                            margin="normal"
                            helperText="Modificar Descrição (Para Pedidos Endereço)"
                            label="Descrição"
                            value={task.description?.Before('|')}
                            onSubmit={input => handleUpdate({ description: input + '|' + (task.description?.After('|') || '') })}
                        />
                    </ListItem>

                    <ListItem divider component="label">
                        <Input
                            fullWidth
                            margin="normal"
                            helperText="Modificar Observações (Para Pedidos Forma De Pagamento)"
                            label="Observações"
                            value={task.description?.After('|')}
                            onSubmit={input => handleUpdate({ description: (task.description?.Before('|') || '') + '|' + input })}
                        />
                    </ListItem>

                    <ListItem divider component="label" style={{ justifyContent: 'space-between' }}>
                        <Input
                            readOnly
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            helperText="Data e Hora"
                            label="Data Inicial"
                            value={task.begin.toDate('datetime-local')}
                            onSubmit={input => handleUpdate({ begin: input.toDate() })}
                        />

                        <Input
                            readOnly
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            helperText="Data e Hora"
                            label="Data Conclusao"
                            value={task.done.toDate('datetime-local')}
                            onSubmit={input => handleUpdate({ done: input.toDate() })}
                        />

                        <Input
                            type="date"
                            readOnly
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            helperText="Prazo expira em"
                            label="Exclusão"
                            value={task.finish.toDate('date')}
                            onSubmit={input => handleUpdate({ finish: input.toDate('date') })}
                        />
                    </ListItem>

                    <SubHeader>Lista de itens pedidos</SubHeader>

                    {task.topics?.length > 0 && task.topics.map(topic =>
                        <ListItem divider key={topic.uuid}>
                            <ListItemText
                                style={{ width: 400 }}
                                primary={topic.title}
                                secondary={topic.toasts.map(toast => ['OPTIONAL', 'DROP'].indexOf(toast.status) !== -1 &&
                                    <Typography
                                        style={{ textDecoration: toast.status === 'DROP' && 'line-through' }}
                                        key={toast.uuid}
                                        display="block"
                                        variant="caption">
                                        {toast.title} {toast.status === 'OPTIONAL' && `+ ${(toast.price || 0).monetizeme()}`}
                                    </Typography>
                                )}
                            />
                            <Vertical orientation="vertical" />
                            <ListItemText
                                style={{textAlign: 'right'}}
                                primary={topic.price?.monetizeme()}
                                secondary={'+ ' + topic.toasts.sum('price', toast => toast.status === 'OPTIONAL').monetizeme() }
                            />
                        </ListItem>
                    )}
                </>}

            </DrawerList>
        </Sidenav>
    );
}

export default React.forwardRef(CommerceDashboardEditor);