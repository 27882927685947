import { createBrowserHistory } from 'history';

export default createBrowserHistory();

export const backSlash = () => {
    const history = createBrowserHistory();
    return history.location.pathname.split('/').slice(0, -1).join('/');
} 

export const savePath = (path) => {
    localStorage.setItem('pathname', JSON.stringify(path));
  }