import React from 'react';
import { withRouter } from 'react-router';

import { Switch, Route, Redirect } from "react-router-dom";

import {
    ComparisonEditor,
    ComparisonIndex,
    ComparisonFileList
} from '..'


import PrivateRoute from '../PrivateRoute';
import DateRangePicker from '../DateRangePicker'
import SwipeableNavigation from '../SwipeableNavigation';

import Main from '../../Pages/Main'
import Todos from '../../Pages/Todos'
import TodosTask from '../../Pages/TodosTask'
import TodosTopic from '../../Pages/TodosTopic'
import TodosToast from '../../Pages/TodosToast'
import TodosEditDrawer from '../../Pages/TodosEditDrawer'
import SettingsDrawer from '../../Pages/SettingsDrawer'
import GalleryDrawer from '../../Pages/GalleryDrawer'


import SignIn from '../../Pages/SignIn'
import SignOut from '../../Pages/SignOut'


import Commerce from '../../Pages/Commerce'
import CommerceOptions from '../../Pages/Commerce/Options'
import CommerceKitchen from '../../Pages/CommerceKitchen'
import CommercePanel from '../../Pages/Commerce/Panel'
import CommerceDelivery from '../../Pages/CommerceDelivery'
import CommerceDeliveryman from '../../Pages/CommerceDeliveryman'
import MyAccount from '../../Pages/MyAccount'
import CommerceCashItems from '../../Pages/CommerceCashItems'
import CommerceCashOrders from '../../Pages/CommerceCashOrders'
import CommerceNews from '../../Pages/CommerceNews'
import CommerceClient from '../../Pages/CommerceClient'
import CommerceClientEdit from '../../Pages/CommerceClientEdit'
import CommerceDashboard from '../../Pages/CommerceDashboard'
import CommerceCarte from '../../Pages/CommerceCarte'
import CommerceIngredients from '../../Pages/CommerceIngredients'
import MyWork from '../../Pages/MyWork';
import TodosAlerts from '../../Pages/TodosAlerts';
import Frame from '../../Pages/Frame';
import ShortcutsDrawer from '../../Pages/ShortcutsDrawer';
import TodosCommentDrawer from '../../Pages/TodosCommentDrawer';
import TodosFiles from '../../Pages/TodosFiles';

import MyTeams from '../../Pages/MyTeams';
import MyTeamsCurrent from '../../Pages/MyTeamsCurrent';

import Allow from '../../Components/Allow';

class RenderPages extends React.Component {

    render() {

        const { location, history } = this.props;
        const background = location.state && location.state.background;
        return (
            <React.Fragment>
                <Switch location={background || location}>

                    <PrivateRoute
                        allow={true}
                        path="/my"
                        component={
                            <React.Fragment>
                                <Route exact path="/my/account" component={MyAccount} />
                                <Route exact path="/my/work" component={MyWork} />
                                <Allow on="AdminUsers">
                                    <Route exact path="/my/teams/:uuid?" component={(routerprops) =>
                                        <MyTeams {...routerprops}>
                                            <MyTeamsCurrent {...routerprops} />
                                        </MyTeams>
                                    } />
                                </Allow>
                            </React.Fragment>
                        } />

                    <PrivateRoute
                        allow={'isComparator'}
                        path="/comparison"
                        component={
                            <React.Fragment>
                                <PrivateRoute exact allow="ComparisonNavigate" path="/comparison/user" component={<ComparisonIndex />} />
                                <PrivateRoute exact allow="ComparisonNavigate" path="/comparison/selector" component={<ComparisonFileList />} />
                                <PrivateRoute exact allow="ComparisonCreate" path="/comparison/codenames/:id?" component={<ComparisonEditor />} />
                            </React.Fragment>
                        } />

                    <PrivateRoute
                        allow={'isRestaurant'}
                        path="/commerce"
                        component={
                            <Commerce history={history} location={location}>
                                <Route exact path="/commerce" component={CommerceOptions} />
                                <Route path="/commerce/carte/:carte?/:uuid([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})?" component={CommerceCarte} />
                                <Route path="/commerce/dashboard/:edit?/:uuid([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})?" component={CommerceDashboard} />
                                <Route exact path="/commerce/cash/orders" component={CommerceCashOrders} />
                                <Route path="/commerce/cash/:uuid([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})?" component={CommerceCashItems} />
                                <Route path="/commerce/kitchen" component={CommerceKitchen} />
                                <Route path="/commerce/panel" component={CommercePanel} />
                                <Route path="/commerce/delivery" component={CommerceDelivery} />
                                <Route path="/commerce/deliveryman/:uuid([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})?" component={CommerceDeliveryman} />
                                <Route path="/commerce/client/:uuid" component={CommerceClientEdit} />
                                <CommerceNews />
                                <CommerceClient />
                                <CommerceIngredients location={location} history={history} />
                            </Commerce>
                        } />

                    <PrivateRoute
                        allow="isManagement"
                        path="/todos"
                        component={
                            <Todos history={history} location={location}>
                                <Route exact path="/todos/alerts" component={TodosAlerts} />
                                <Route exact path="/todos/task" component={TodosTask} />
                                <Route path="/todos/task/:taskId/" component={TodosTopic} />
                                <Route path="/todos/task/:taskId/:topicId" component={TodosToast} />
                            </Todos>
                        } />

                    <Route path="/deliver" component={() => {
                        window.location.href = 'https://mydeliver.vercel.app';
                        return null;
                    }} />

                    <PrivateRoute exact allow={true} path="/" component={<Main />} />
                    <PrivateRoute exact allow={true} path="/signout" component={<SignOut />} />

                    <Route path="/frame/:target" component={Frame} />
                    <Route path="/realtime" component={SignIn} />
                    <Route path="/continue" render={() => <Redirect to={JSON.parse(localStorage.getItem('pathname')) || "/"} />} />
                    <Route path="*" render={() => <Redirect to="/realtime" />} />
                </Switch >

                <Route path="/todos/files" component={TodosFiles} />
                <Route path="/todos/edit/:mode/:uuid" component={TodosEditDrawer} />
                <Route path="/todos/gallery/:mode/:uuid" component={GalleryDrawer} />
                <Route path="/todos/comment/:mode/:uuid" component={TodosCommentDrawer} />


                <SwipeableNavigation path="/settings" component={SettingsDrawer} />
                <SwipeableNavigation path="/shortcuts" component={ShortcutsDrawer} drawerProps={{ disableSwipeToOpen: true }} />

                <DateRangePicker location={location} history={history} />
            </React.Fragment >
        )
    }
}

export default withRouter(RenderPages);