import React from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { DrawerList } from '../../Components/StyledComponents'
import { connect } from 'react-redux'

import { host_gallery } from '../../services/setproxy';
import InputFile from '../../Components/InputFile';
import { dispatch } from '../../Reducers/User';

import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const inputDefault = {
    title: '',
    description: '',
    src: 'https://',
    image: false
}

const regex = new RegExp("^(http[s]?://)");

/**
 * @typedef {import('react-router').RouterProps & {links: import('../../typeroots').UserLink[]}} props
 * @extends {React.PureComponent<props>}
 */
class SettingsDrawer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            input: inputDefault
        }
    }

    HandleLink = (linkdata) => {
        let newWindow;

        if (!regex.test(linkdata.src)) return alert(`o link ${linkdata.src} é inválido! exclua por favor.`);

        if (linkdata.isIframe) {
            return this.props.history.push(`/frame/${linkdata.uuid}`);
            // newWindow = window.open(`http://localhost:3001/frame/${linkdata.uuid}`, null,`width=${window.outerWidth},height=${window.outerHeight},top=0,left=0,right=0,scrollbars=no,status=0,statusbar=0,searchbar=0,postwindow,noopener,noreferrer,` );

        } else {
            newWindow = window.open(
                linkdata.src, 
                "Projav", 
                "width="+window.outerWidth+",height="+window.outerHeight+",toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes")
            
        }

        if (newWindow) newWindow.opener = null;

    }

    /**@param {Event} e */
    HandleLinkInput = (e) => {
        const { target: { value } } = e;
        if (regex.test(value)) {
            this.setState({ input: { ...this.state.input, src: value } })

        } else {
            this.setState({ input: { ...this.state.input, src: 'https://' + value.replace(regex, '') } })

        }
    }

    HandleDelete = (link) => {
        if (window.confirm("Deseja excluir permanentemente este link?"))
            dispatch.removeLink(link);
    }

    /**@param {Event} e */
    HandleSubmit = (e) => {
        e.preventDefault();

        dispatch.createLink(this.state.input);
        this.setState({ input: inputDefault, collapse: false });

    }

    render() {

        return (
            <DrawerList size={400}>

                <ListItem button divider key={'photoshopea'} onClick={() => this.HandleLink({uuid: 'photoshopea', src:"https://projav.herokuapp.com/frames/navigate/photoshopea"})}>
                    <ListItemAvatar>
                        <Avatar alt={"Photoshopea"} src={'/photoshopea_thumb.png'} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Photoshopea"}
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondary={"Editor de imagens"}
                    />
                </ListItem>

                {this.props.links.map(link =>
                    <ListItem button divider key={link.image} onClick={() => this.HandleLink(link)}>
                        <ListItemAvatar>
                            <Avatar alt={link.title} src={host_gallery + link.image + '_thumb'} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={link.title}
                            primaryTypographyProps={{ variant: 'body2' }}
                            secondary={link.description}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.HandleDelete(link.image)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <ListItem button divider onClick={() => this.setState({ collapse: !this.state.collapse })}>
                    <ListItemAvatar>
                        <Avatar>
                            <AddIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Adicionar atalho"
                        primaryTypographyProps={{ variant: 'body2' }}
                    />
                </ListItem>
                <Collapse in={this.state.collapse === true}>
                    <form onSubmit={this.HandleSubmit}>
                        <ListItem divider>
                            <ListItemText primary={
                                <TextField required fullWidth
                                    label="Título"
                                    onChange={e => this.setState({ input: { ...this.state.input, title: e.target.value } })}
                                    value={this.state.input.title}
                                    helperText="Inserir identificador do atalho"
                                />
                            } />
                        </ListItem>

                        <ListItem divider>
                            <ListItemText primary={
                                <TextField required fullWidth
                                    label="Link"
                                    onChange={this.HandleLinkInput}
                                    value={this.state.input.src}
                                    helperText="Inserir link"
                                />
                            } />
                        </ListItem>

                        <ListItem divider>
                            <ListItemText primary={
                                <TextField fullWidth
                                    label="Descrição"
                                    onChange={e => this.setState({ input: { ...this.state.input, description: e.target.value } })}
                                    value={this.state.input.description}
                                    helperText="Inserir comentários do atalho"
                                />
                            } />
                        </ListItem>
                        <ListItem divider button>
                            <InputFile
                                accept="image/*"
                                component={ListItemText}
                                primary="Imagem"
                                secondary="clique para inserir imagem"
                                onChange={e => this.setState({ input: { ...this.state.input, image: e } })}
                                preview={this.state.input.image}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary={
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!Boolean(this.state.input.image && this.state.input.title)}
                                    type="submit">
                                    Salvar
                                </Button>}
                            />
                        </ListItem>
                    </form>
                </Collapse>
            </DrawerList>
        )
    }
};


export default connect(state => ({
    links: state.user?.links || []

}), null)(SettingsDrawer)