import { styled } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid'
import CardActions from "@material-ui/core/CardActions";

export const FlexBox = styled('div')({
    flexFlow: 'wrap', 
    display: 'flex',
    alignItems: 'center'

})

export const Container = styled(Grid)({
    height: '100%', 
    overflow: 'auto'
})

export const GridItem = styled(Grid)({
    padding: 4
})

export const FlexCardActions = styled(CardActions)({
    justifyContent: 'space-between'
})