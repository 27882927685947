import React from 'react';

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LinearProgressWithLabel from '../ToastUtils/LinearProgress';
import StatusIcon from '../StatusIcon';
export default class TodosTotalsSquare extends React.Component {
    render() {

        return (
            <List dense>
                {/* <ListItem dense disableGutters divider> */}
                    <ListItem divider>
                        <ListItemText
                            primary={`Conclusão ${(this.props.done + this.props.drop)}/${this.props.total} tarefas`}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <LinearProgressWithLabel
                                    color="secondary"
                                    variant="determinate"
                                    value={Number((this.props.done + this.props.drop).percentage(this.props.total))}
                                />}
                        />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('NEW')} onClick={() => this.props.action('NEW')}>
                        <ListItemIcon><StatusIcon status="NEW" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.news}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`News`} />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('PLAY')} onClick={() => this.props.action('PLAY')}>
                        <ListItemIcon><StatusIcon status="PLAY" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.play}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`Plays`} />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('STOP')} onClick={() => this.props.action('STOP')}>
                        <ListItemIcon><StatusIcon status="STOP" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.stop}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`Pausado`} />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('DONE')} onClick={() => this.props.action('DONE')}>
                        <ListItemIcon><StatusIcon status="DONE" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.done}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`Oks`} />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('CQ')} onClick={() => this.props.action('CQ')}>
                        <ListItemIcon><StatusIcon status="CQ" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.cqs}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`Cqs`} />
                    </ListItem>

                    <ListItem button divider selected={this.props.status.includes('DROP')} onClick={() => this.props.action('DROP')}>
                        <ListItemIcon><StatusIcon status="DROP" /></ListItemIcon>
                        <ListItemText
                            secondaryTypographyProps={{ variant: 'subtitle1' }} secondary={this.props.drop}
                            primaryTypographyProps={{ variant: 'caption' }} primary={`Cancelado`} />
                    </ListItem>

                {/* </ListItem> */}

                <ListItem divider>
                    <ListItemText primary={`Peso: ${this.props.peso}`} />
                    {Boolean(this.props.price) &&
                        <ListItem divider>
                            <ListItemText primary={`Valor: ${this.props.price.monetizeme()}`} />
                        </ListItem>
                    }
                </ListItem>

            </List>
        )
    }
}