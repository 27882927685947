import React from 'react';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

const marginTop5 = { marginTop: '-5px' };

const Component = ({ value, onSubmit, startAdornment, InputProps, disableUnderline, shrink, ...props }) => {

    /**@typedef {string} current */
    const [current, setCurrent] = React.useState('');
    
    /**@typedef {string} input */
    const [input, setInput] = React.useState('');

    React.useEffect(() => {

        if (value && value !== current) {
            setCurrent(value);
        }
        
        setInput(value);
    }, [value, current])

    /**@param {import('react').FormEvent & { target: Array<InputEvent>}} e */
    const handleSubmit = React.useCallback(e => {
        e.preventDefault();
        onSubmit(input);
        setCurrent(input);
        e.target[0].blur();

    }, [input, onSubmit]);


    const RenderInputProps = {
        ...InputProps,
        startAdornment: startAdornment &&
            <InputAdornment position="start" style={marginTop5}>
                {startAdornment}
            </InputAdornment>,
        endAdornment:
            <InputAdornment style={marginTop5}>
                {Boolean(input) && input !== current && <>
                    <IconButton size="small" type="submit" aria-label={`save ${props.label}}`}>
                        <SaveOutlinedIcon color="primary" />
                    </IconButton>
                    <IconButton size="small" onClick={() => setInput('')} aria-label={`clear ${props.label}}`}>
                        <CloseOutlinedIcon color="primary" />
                    </IconButton>
                </>}
            </InputAdornment>
    };
    if (Boolean(disableUnderline)) RenderInputProps.disableUnderline = true;

    const RenderInputLabelProps = {};
    if (Boolean(shrink)) RenderInputLabelProps.shrink = true;

    return (
        <TextField
            color={value === current ? 'primary' : 'secondary'}
            component="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            value={input}
            onChange={e => setInput(e.target.value)}
            InputLabelProps={RenderInputLabelProps}
            InputProps={RenderInputProps}
            {...props}
        />
    )
}


/**@type {function(import('@material-ui/core').TextFieldProps) : JSX.Element}*/
export default function Input(props) {

    const [state, setState] = React.useState();

    React.useEffect(() => {
        let checkvalue = props.value;
        if (/date/.test(props.type)) {

            if (typeof checkvalue === "string") {
                if (checkvalue)
                    checkvalue = new Date(checkvalue);
                else
                    checkvalue = new Date();

                checkvalue.setHours(checkvalue.getHours() - 3);
            }
            checkvalue = checkvalue.toDate(props.type);
        }

        // console.log({checkvalue});
        setState({ value: checkvalue })
    }, [props])


    return <Component {...state} {...props} />;
}