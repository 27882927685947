import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    content: {
        minHeight: 520,
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            overflow: 'hidden'
        },
        [theme.breakpoints.down('md')]: {
            padding: 0,
            minHeight: '90vh',
        }
    },
    gridContent: {
        '& .MuiList-padding': {
            padding: 0,
        },
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            '& .MuiList-padding': {
                paddingLeft: 12,
                paddingRight: 12,
            }
        }
    }
}))