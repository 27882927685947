import { makeStyles } from "@material-ui/core/styles";



export default makeStyles((theme) => ({
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        bottom: theme.spacing(10),
        right: theme.spacing(2),
      }
    },
    speedName: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(15),
      zIndex: theme.zIndex.drawer,
      [theme.breakpoints.down('md')]: {
        bottom: theme.spacing(11),
        right: theme.spacing(7),
      }
    }
  }));
  