import React, { Fragment, useEffect, useRef } from 'react';
import { Camera } from 'react-cam';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import CameraIcon from '@material-ui/icons/Camera';
export default function CameraHandler(props) {

    const squareheight = props.cameraMobile ? window.innerHeight - 300 : window.innerHeight - 200;
    const cameraRef = useRef();

    useEffect( () => {
        console.log({cameraRef});
        
        return () => {
 
        }
    }, [cameraRef])

    return (
        <Fragment>
            <Camera
                front={Boolean(props.cameraMobile)}
                width={squareheight}
                height={squareheight}
                capture={props.onTakePhoto}
                screenshotFormat="image/jpeg"
                ref={cameraRef}
            />
            <Box position="absolute" width="95%" display="flex" bottom="10vh" justifyContent="center">
                <IconButton onClick={img => cameraRef.current.capture(img)}>
                    <CameraIcon style={{fontSize:55}} />
                </IconButton>
            </Box>
        </Fragment>
    )
};


        // <Camera
        //     isFullscreen={false}
        //     ref={props.cameraRef}
        //     imageCompression={0.67}
        //     imageType={IMAGE_TYPES.JPG}
        //     idealFacingMode={FACING_MODES.USER}
        //     onTakePhoto={props.onTakePhoto}
        // />
