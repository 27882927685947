import { combineReducers, createStore } from 'redux'
import TaskReducer from './TasksReducers'
import CodenamesReducer from './CodenamesReducers'
import * as user from './User'
import RestaurantOrders from './RestaurantOrders'
import Cache from './Compress';
import TodosFilter, { listen as FiltersEvents } from './TodosFilter'
import Clients, { listen as ClientsEvents } from './Clients'
import Ingredients, { listen as IngredientsEvents } from './Ingredients'
import Deliver, { listen as DeliverEvents } from './Deliver';

import { getToken, logout } from '../services/Auth'
import { isLocalhost } from '../services/setproxy'
import Timeline from './Timeline'

const token = getToken();

const tasks = new TaskReducer('task', Cache.get('task') || []);
const topics = new TaskReducer('topic', Cache.get('topic') || []);
const toasts = new TaskReducer('toast', Cache.get('toast') || []);

const adminRoles = new TaskReducer('admin:roles');
const adminUsers = new TaskReducer('admin:users');
const adminRules = new TaskReducer('admin:rules');

const codenames = new CodenamesReducer();

const reducers = combineReducers({
    clients: Clients,
    ingredients: Ingredients,
    deliver: Deliver,
    orders: RestaurantOrders,
    todosFilter: TodosFilter.reducer,
    user: user.reducer,
    task: tasks.reducer,
    topic: topics.reducer,
    toast: toasts.reducer,
    codenames: codenames.reducer,
    timeline: Timeline.reducer,

    adminUsers: token && adminUsers.reducer,
    adminRoles: token && adminRoles.reducer,
    adminRules: token && adminRules.reducer
})


const useStore = createStore(reducers);

let debounce;
useStore.subscribe(() => {
    const states = useStore.getState();
    const token = getToken();
    
    if (debounce) clearTimeout(debounce);
    
    debounce = setTimeout(() => {
        
            // if(!token || !states.user.token){
            //     return logout(window.location.reload);
            // }
        
            if (token && states.user.token.substring(0, 230) !== token.substring(0, 230)) {
                console.error('[catch - token is not the same token]');
                return logout(() => window.location.reload() );
            }

        if(isLocalhost)
        console.log('updated state', states);

            Cache.set('user', states.user)
            Cache.set('deliver', states.deliver)
            Cache.set('ingredients', states.ingredients)
            Cache.set('todosFilter', states.todosFilter)
            Cache.set('task', states.task)
            Cache.set('topic', states.topic)
            Cache.set('toast', states.toast)
            Cache.set('orders', states.orders)
            Cache.set('clients', states.clients)
            Cache.set('timeline', states.timeline)

    }, 3000);


});



ClientsEvents(useStore);
DeliverEvents(useStore);

Timeline.CreateEventListener(useStore);
tasks.listen(useStore);
topics.listen(useStore);
toasts.listen(useStore);

if (token) {
    FiltersEvents(useStore);
    IngredientsEvents(useStore);
    user.listen(useStore);

    codenames.listen(useStore);
    adminUsers.listen(useStore);
    adminRoles.listen(useStore);
    adminRules.listen(useStore);
}

export default useStore;