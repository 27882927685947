import React from 'react';

/**
 * 
 * @param {boolean} initialValue 
 * @param {boolean} listener 
 * @param {function} callback 
 * @param {number} timeout 
 * 
 * @returns { Array<boolean, function> }
 */
export default function useOpen(initialValue, listener, callback, timeout = 255) {
  const [open, setOpen] = React.useState(initialValue);

  const setClose = () => {
    if(open){
      setOpen(false);
      setTimeout(callback, timeout);
    }
  }

  React.useEffect(() => {

    if (Boolean(listener)) {
      setOpen(true);
    }

    return () => {
      setOpen(false);
    }

  }, [listener, callback, timeout]);

  return [open, setClose];
}