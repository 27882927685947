import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from './flux';
import { makeStyles } from '@material-ui/core/styles';

import TreeView from '@material-ui/lab/TreeView';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import StyledTreeItem from '../../Components/StyledTreeItem';

import SwipeableNavigation from '../../Components/SwipeableNavigation';
import { DrawerList } from '../../Components/StyledComponents';
import { host_gallery } from '../../services/setproxy';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 78px)',
        marginTop: 58,
        overflow: 'auto',
        flexGrow: 1,
        padding: theme.spacing(2),

    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
/**
 * 
 * @param {import('react-redux').DefaultRootState & import('react-router').RouterProps} props 
 * @returns 
 */

function TodosFiles(props) {
    const classes = useStyles();

    const Topics = React.useCallback((fn) => {
        return props.topic.filter(fn)

    }, [props.topic])

    const Toasts = React.useCallback((fn) => {
        return props.toast.filter(fn)

    }, [props.toast])

    const download = React.useCallback((data) => {
        fetch(host_gallery + data.file).then(function (t) {
            return t.blob().then((b) => {

                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", data.name);
                a.click();

                document.body.removeChild(a);
                a = null;
            });
        });
    }, [])

    return (
        <SwipeableNavigation drawerProps={{ anchor: 'right', disableSwipeToOpen: true }}>
            <DrawerList size={500}>
                <AppBar position="absolute" color="secondary">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} onClick={props.history.goBack} color="inherit" aria-label="menu">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Arquivos
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TreeView
                    aria-label="files"
                    className={classes.root}
                    defaultExpanded={['1']}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                >
                    {props.task.map(task => {
                        const topics = Topics(e => e.taskId === task.id);
                        const toasts = Toasts(e => e.taskId === task.id);

                        /**@type {number} */
                        const topicsFiles = task.files.length + topics.map(e => e.files).filter(e => e.length > 0).length + (toasts.map(e => e.files)?.filter(e => e.length > 0).length || 0);

                        return (
                            //task list
                            Boolean(topicsFiles > 0) &&
                            <StyledTreeItem key={task.uuid} nodeId={task.uuid} labelText={task.title} labelInfo={`${topicsFiles} arquivos`} color="#1a73e8" bgColor="#e8f0fe">
                                {topics.map(topic => {

                                    const toasts = Toasts(e => e.topicId === topic.id);

                                    /**@type {number} */
                                    const toastsFiles = toasts.map(e => e.files).filter(e => e.length > 0).length + topic.files.length;

                                    return (Boolean(toastsFiles > 0) &&
                                        <StyledTreeItem key={topic.uuid} nodeId={topic.uuid} labelText={topic.title} labelInfo={`${toastsFiles} arquivos`} color="#1a73e8" bgColor="#e8f0fe">

                                            {toasts.map(toast =>
                                                toast.files.map(file =>
                                                    <StyledTreeItem
                                                        key={file.file}
                                                        nodeId={file.file}
                                                        onClick={() => download(file)}
                                                        labelText={file.name}
                                                        labelInfo="baixar"
                                                        labelIcon={FileCopyIcon} />
                                                )
                                            )}

                                            {topic.files.map(file =>
                                                <StyledTreeItem
                                                    key={file.file}
                                                    nodeId={file.file}
                                                    onClick={() => download(file)}
                                                    labelText={file.name}
                                                    labelInfo="baixar"
                                                    labelIcon={FileCopyIcon} />
                                            )}

                                        </StyledTreeItem>
                                    )
                                })}

                                {task.files.map(file =>
                                    <StyledTreeItem
                                        key={file.file}
                                        nodeId={file.file}
                                        onClick={() => download(file)}
                                        labelText={file.name}
                                        labelInfo="baixar"
                                        labelIcon={FileCopyIcon} />
                                )}

                            </StyledTreeItem>
                        )
                    }
                    )}
                </TreeView>
            </DrawerList>
        </SwipeableNavigation>
    );
}

export default connect(mapStateToProps, null)(TodosFiles);