import update from "immutability-helper";

/*eslint no-extend-native: ["error", { "exceptions": ["Object","ArrayBuffer","Array","String","Number","Function"] }]*/
Object.defineProperty(Array.prototype, 'update', {
    value: function (object) {
        return update(this, object);
    }
});


Array.prototype.add = function (data, fn = null) {
    if (fn)
        this.search(e => fn.call([], e)).push(data)
    else
        this.push(data);
};

Object.defineProperty(Array.prototype, 'add', {
    enumerable: false,
    value: function (data, fn = null) {
        if (fn)
            this.search(e => fn.call([], e)).push(data)
        else
            this.push(data);
    }
});

Object.defineProperty(Array.prototype, 'has', {
    value: function has(value, key = null) {
        return key ? this.map(e => e[key]).indexOf(value) !== -1 : this.indexOf(value) !== -1

    }
});


Object.defineProperty(Array.prototype, 'rmv', {
    enumerable: false,
    value: function (fn) {
        let index = this.findIndex(e => fn.call([], e));

        if (window.location.hostname === "localhost") console.log('spliced index', index, typeof index);

        return (index !== -1) ? this.splice(index, 1) : false;
    }
});

Object.defineProperty(Array.prototype, 'set', {
    enumerable: false,
    value: function (fn, params) {
        let sucessfully = false;
        // if (window.location.hostname === "localhost") console.log('set index', fn, params);

        this.map(function (e) {
            if (fn.call([], e)) {
                sucessfully = true;
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        e[key] = params[key];
                    }
                }
            }
            return e;
        })

        return sucessfully;
    }
});

Object.defineProperty(Array.prototype, 'first', {
    enumerable: false,
    value: function ($key = 0) {
        return ($key in this) ? this[$key] : false;
    }
});

Object.defineProperty(Array.prototype, 'search', {
    enumerable: false,
    value: function (cb) {
        if (!this || this.length < 1)
            return undefined

        else {
            let result = this.find(e => cb.call([], e));

            return result ? result : undefined;
        }
    }
});


Object.defineProperty(Array.prototype, '$filter', {
    enumerable: false,
    value: function (query) {
        if (!this || !query) return this || [];

        var parts = query && query.trim().normalize("NFD").replace(/[\u00c7-\u00e7|\u0300-\u036f]/g, "").split(/\s+/),
            keys = Object.keys(this.first());

        if (!parts || !parts.length) return this || [];

        return this.filter(function (obj) {
            return parts.every(function (part) {
                return keys.some(function (key) {
                    return String(obj[key]).toLowerCase().indexOf(part.normalize("NFD").replace(/[\u00c7-\u00e7|\u0300-\u036f]/g, "").toLowerCase()) > -1;
                });
            });
        });
    }
});

Object.defineProperty(Array.prototype, 'sum', {
    enumerable: false,
    /**
     * @param {String} key -- will be grouped and sum
     * @param {?Function} fn -- callback for search
     */
    value: function sum(key, fn = null) {
        return (typeof fn !== 'function')
            ? this.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
            : this.reduce((a, b) => (fn.call([], b)) ? a + (parseFloat(b[key]) || 0) : a + 0, 0);
    }
});

Object.defineProperty(Array.prototype, 'count', {
    enumerable: false,
    /**
     * @param {String|Function} $key -- will be grouped and sum
     */
    value: function count($key) {
        return (typeof $key !== 'function')
            ? this.reduce((a, b) => {
                var i = a.findIndex(x => x[$key] === b[$key]);
                return i === -1 ? a.push({ [$key]: b[$key], times: 1 }) : a[i].times++;
            }, [])
            : this.reduce((a, b) => ($key.call([], b)) ? a + 1 : a + 0, 0);
    }
});

Array.prototype.max = function () {
    return this.length > 0 ? Math.max.apply(null, this) : 0;
};

Array.prototype.min = function () {
    return this.length > 0 ? Math.min.apply(null, this) : 0;
};

Object.defineProperty(Array.prototype, 'toPercent', {
    value: function (PesoKeyContainsValue, condiction) {

        const topicosTotal = this.length;
        let calcule_soma = 0;
        let peso_total = 0;

        for (let i = topicosTotal - 1; i >= 0; i--) {
            peso_total += this[i][PesoKeyContainsValue];
        }

        for (let i = topicosTotal - 1; i >= 0; i--) {
            if (condiction.call([], this[i])) {
                calcule_soma += this[i][PesoKeyContainsValue] * 100 / peso_total;
            }
        }
        return Math.round(calcule_soma) || 0;
    }
})

Object.defineProperty(Array.prototype, 'maxDate', {
    value: function (DateKeyWithValue) {
        return Math.max.apply(Math, this.map(function (o) { return new Date(o[DateKeyWithValue]) }))
    }
})

Object.defineProperty(Array.prototype, 'minDate', {
    value: function (DateKeyWithValue) {
        return Math.min.apply(Math, this.map(function (o) { return new Date(o[DateKeyWithValue]) }))

    }
})



Array.prototype.sortBy = function sb(f, sort = 'asc') {
    for (let i = this.length; i;) {
        let o = this[--i];
        this[i] = [].concat(f.call(o, o, i), o);
    }
    this.sort(function (a, b) {
        for (let i = 0, len = a.length; i < len; ++i) {
            if (a[i] !== b[i]) return a[i] > b[i] ? -1 : 1;
        }
        return 0;
    });
    for (let i = this.length; i;) {
        this[--i] = this[i][this[i].length - 1];
    }
    return this;
}



const Model = (array, columns) => {

    let Keys = Object.keys(columns);
    let newObject = {};
    for (const key in array) {
        newObject[Keys[key]] = columns[Keys[key]](array[key]);
    }

    return newObject;
}

Object.defineProperty(ArrayBuffer.prototype, 'Model', {
    writable: true,
    configurable: true,
    value: function (columns) {
        let buf = this;
        let result = [];
        let r = JSON.parse(new Uint8Array(buf).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ''));

        r.map(line => result.push(Model(line, columns)))

        return result;

    }
})

Object.defineProperty(Array.prototype, 'Distinct', {
    /**
     * @param {array} props
     * @param {function} condiction
     * @description distict by first property e filter by condtion
     * @return {bject[]}
     * @example
     * my_object.Distinct(['uuid', 'name', {'payment': 'Dinheiro'}, {'observations': ''}]) -- where uuid ´s the key, rest is silence
     */
    value: function (props, condiction) {
        var unique = [];
        var distinct = [];
        var first_prop_key = props[0];

        for (let i = 0; i < this.length; i++) {

            if ((!condiction || condiction.call([], this[i])) && !unique[this[i][first_prop_key]]) {
                const pushData = {};

                for (let index = 0; index < props.length; index++) {
                    if (typeof props[index] === 'object') {
                        const key = Object.keys(props[index])[0];
                        pushData[key] = props[index][key];
                    }
                    else
                        pushData[props[index]] = this[i][props[index]]
                }

                distinct.push(pushData);

                unique[this[i][first_prop_key]] = 1;
            }
        }

        return distinct.sort((a, b) => a[first_prop_key] && a[first_prop_key].localeCompare(b[first_prop_key]));
    }
})