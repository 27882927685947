import { connect } from 'react-redux';
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader'
import Tooltip from '@material-ui/core/Tooltip'

import { SubHeader } from '../../Components/StyledComponents';
import { host_gallery } from '../../services/setproxy';
import socket from '../../services/socket';
import Input from '../../Components/Input';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ListRolesRules from './ListRolesRules';
import AddUserDialog from './AddUserDialog';

/**@type {import('.').MyTeams}*/
const MyTeams = ({ children, ...props }) => {
    const [render, setRender] = React.useState(false);
    const [grouptoggle, setGrouptoggle] = React.useState(false);
    const [modal, setModal] = React.useState(false);

    const [grouper, setGrouper] = React.useState({});

    React.useEffect(() => {
        if (!render && props.roles.length === 0) {
            socket.emit('admin:faction:data');
            setRender(true);
        };

    }, [render, props]);

    // a group by any user
    const UsersFromGroup = React.useCallback((group) => {
        return props.users.filter(e => e?.roleId === group.id);

    }, [props.users]);

    const handleUserRmv = (user) => {
        if (window.confirm("Deseja realmente excluir o usuário: " + user.name)) {
            socket.emit('admin:users:will:delete', user);

            if (Boolean(props.match?.params?.uuid)) {
                props.history.replace('/my/teams');
            }
        }
    };

    const handleGroupName = (role) => (text) => {
        socket.emit('admin:roles:will:update', { role, data: { name: text } });
    }

    const handleGroupAdd = (text) => {
        socket.emit('admin:roles:will:create', { name: text });
        setGrouptoggle(false);
    }

    const handleGroupRmv = (group) => {
        if (window.confirm("Deseja realmente excluir o grupo: " + group.name)) {
            socket.emit('admin:roles:will:delete', group);
        }
    }

    return !props.users?.length ? 'loading' : (
        <Grid container spacing={1}>
            <AddUserDialog open={modal} onClose={() => setModal(false)} />

            <Grid item xs={12} md={3} component={Paper}>
                <Collapse in={Boolean(grouper?.name)}>
                    <List style={{ overflow: 'auto', height: '100vh' }}>
                        <SubHeader>
                            {grouper.name || 'Grupo'}
                            <div>
                                <IconButton onClick={() => setGrouper({})} size="small">
                                    <Avatar><CloseIcon /></Avatar>
                                </IconButton>
                            </div>
                        </SubHeader>
                        {Boolean(grouper?.name) && <ListRolesRules role={props.roles.find(e => e.id === grouper.id)} />}
                    </List>
                </Collapse>
                <Collapse in={!Boolean(grouper?.name)}>
                    <List style={{ overflow: 'auto', height: '100vh' }}>
                        <SubHeader>
                            Grupos
                            <div>
                                <IconButton onClick={() => setGrouptoggle(!grouptoggle)} size="small">
                                    <Tooltip title="Criar novo grupo">
                                        <Avatar><GroupAddIcon /></Avatar>
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={() => setModal(true)} size="small">
                                    <Tooltip title="Criar novo usuário">
                                        <Avatar><PersonAddIcon /></Avatar>
                                    </Tooltip>
                                </IconButton>

                            </div>
                        </SubHeader>
                        {/* create new group */}
                        <Collapse in={grouptoggle === true}>
                            <ListItem>
                                <Input autoFocus fullWidth InputProps={{ disableUnderline: true }} label="Nome do novo grupo" value="" onSubmit={handleGroupAdd} />
                            </ListItem>
                        </Collapse>
                        {/* no agrouped */}
                        <List>
                            {UsersFromGroup({ id: null }).map(user =>
                                <ListItem button divider key={user.uuid}
                                    onClick={() => props.history.push('/my/teams/' + user.uuid)}
                                    selected={user.uuid === props?.match?.params?.uuid}>
                                    <ListItemIcon>
                                        <Avatar src={host_gallery + user.image + '_thumb'} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={user.name}
                                        secondary={user.login}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton size="small" onClick={() => handleUserRmv(user)}>
                                            <Avatar><DeleteIcon /></Avatar>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                        {props.roles.map((group, index) => {
                            const users = UsersFromGroup(group);

                            return (
                                <List key={'group-user-list-' + index}>
                                    <ListSubheader>
                                        <Input fullWidth placeholder="Editar nome do grupo"
                                            value=""
                                            label={group.name}
                                            InputProps={{ disableUnderline: true }}
                                            onSubmit={handleGroupName(group)} />
                                        <ListItemSecondaryAction>
                                            <IconButton size="small" onClick={() => setGrouper(group)} >
                                                <Avatar><EditIcon /></Avatar>
                                            </IconButton>
                                            {users.length === 0 &&
                                                <IconButton size="small" onClick={() => handleGroupRmv(group)}>
                                                    <Avatar><DeleteIcon /></Avatar>
                                                </IconButton>
                                            }
                                        </ListItemSecondaryAction>
                                    </ListSubheader>
                                    {users.length === 0 ?
                                        // group is ampty, group can be excluded
                                        <ListItem> <ListItemText secondary={"Nenhum usuário neste grupo"} /> </ListItem>
                                        // show users list
                                        : users.map(user =>
                                            <ListItem button divider key={user.uuid}
                                                onClick={() => props.history.push('/my/teams/' + user.uuid)}
                                                selected={user.uuid === props?.match?.params?.uuid}>
                                                <ListItemIcon>
                                                    <Avatar src={host_gallery + user.image + '_thumb'} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={user.name}
                                                    secondary={user.login}
                                                />
                                            </ListItem>
                                        )}
                                </List>
                            )
                        }
                        )}
                    </List>
                </Collapse>
            </Grid>
            <Grid item xs={12} md={9} children={children}>
                <Container>
                    {Boolean(props.match?.params?.uuid) &&
                        <List component={Paper} style={{ overflow: 'auto', height: '100vh', padding: 26 }}>
                            {children}
                        </List>
                    }
                </Container >
            </Grid>
        </Grid >
    );
}

export default connect(state => ({
    users: state.adminUsers,
    roles: state.adminRoles

}), null)(MyTeams);