import React from 'react'
import { Link } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'

import { install } from '../../serviceWorker'
import { DrawerList } from '../../Components/StyledComponents'
import { connect } from 'react-redux'
import { UserAvatar } from '../../Components/AvatarUserIcon'
import { IsAdmin } from '../../Components/Allow'

import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import GetAppOutlined from '@material-ui/icons/GetAppOutlined'
import ExitToApp from '@material-ui/icons/ExitToApp'
import ArrowBack from '@material-ui/icons/ArrowBack'
import SubscriberButton from '../../services/usePushNotification'

/**
 * @typedef { import('react-router').RouterProps & { user: import('../../typeroots').User } } props
 * @extends {React.Component<props>}
 */

class SettingsDrawer extends React.PureComponent {

    render() {
        const { user } = this.props;
        const isUser = Boolean(user?.faction);

        return (
            <DrawerList size={400}>

                {isUser &&
                    <ListItem divider>
                        <ListItemAvatar>
                            <UserAvatar user={user} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={user.name}
                            primaryTypographyProps={{
                                variant: 'body2'
                            }}
                            secondary={user.login}
                        />
                    </ListItem>
                }


                <ListItem button divider onClick={this.props.history.goBack}>
                    <ListItemAvatar>
                        <Avatar>
                            <ArrowBack />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Voltar"
                        primaryTypographyProps={{ variant: 'body2' }}
                    />
                </ListItem>

                {isUser && <>
                    <ListItem component={Link} to="/my/account" button divider>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountCircleIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Minha Conta"
                            primaryTypographyProps={{ variant: 'body2' }}
                        />
                    </ListItem>
                    <IsAdmin>
                        <ListItem component={Link} to="/my/work" button divider>
                            <ListItemAvatar>
                                <Avatar>
                                    <HomeWorkIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Minha Empresa"
                                primaryTypographyProps={{ variant: 'body2' }}
                            />
                        </ListItem>
                    </IsAdmin>
                    
                    <SubscriberButton />

                    <ListItem button divider onClick={() => this.props.history.push('/signout')}>
                        <ListItemAvatar>
                            <Avatar>
                                <ExitToApp />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Sign Out"
                            primaryTypographyProps={{ variant: 'body2' }}
                        />
                    </ListItem>
                </>}

                {
                    !isUser &&
                    <ListItem component={Link} to="/deliver" button divider>
                        <ListItemAvatar>
                            <Avatar>
                                <ShoppingCartIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Deliver"
                            primaryTypographyProps={{ variant: 'body2' }}
                        />
                    </ListItem>

                }




                <ListItem button divider onClick={install}>
                    <ListItemAvatar>
                        <Avatar>
                            <GetAppOutlined />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Instalar aplicativo"
                        primaryTypographyProps={{ variant: 'body2' }}
                    />
                </ListItem>

            </DrawerList >
        )
    }
};


export default connect(state => ({
    user: state.user?.faction ? state.user : state.clients.find(e => e.selected),

}), null)
    (SettingsDrawer)