import React, { Fragment, useRef, useState } from 'react';
import Drawer from '@material-ui/core/Drawer'
import InputBase from '@material-ui/core/InputBase'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@material-ui/core/Collapse'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DrawerList } from '../../Components/StyledComponents';
import WebClientRegister from '../../Components/WebClientRegister';
import { actions } from '../../Reducers/Clients';

import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ContextMenu from '../../Components/ContextMenu';
import { VirtualMap } from '../../Components/VirtualList';

/**
 * 
 * @typedef {import('../../typeroots').ClientWeb} Client
 * 
 */

export const clr = (string) => {
    return string.length > 0 ? string.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : string
}
// const editInitialState = {
//     name: '',
//     mails: [{
//         email: ''
//     }],
//     contacts: [{
//         title: 'Celular',
//         phone: ''
//     }],
//     address: [{
//         title: 'Casa',
//         state: '',
//         city: '',
//         neighborhood: '',
//         number: '',
//         complement: '',
//         cep: '',
//     }]
// }
const editInitialState = {
    name: '',
    contacts: {
        title: 'Celular',
        phone: ''
    }
}

function CommerceNews() {

    const dispatch = useDispatch()
    const [search, setSearch] = useState('');
    // const [editClient, setEditClient] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const history = useHistory();
    const MenuRef = useRef({});
    const [menuItem, SetMenuItem] = useState({});

    const handleEditClient = (client) => {
        history.push(`/commerce/client/${client.uuid}`)
    }

    const handleCreateClient = () => {
        // setEditClient(false);
        setCollapse(true);
    }

    const handleResetWindow = () => {
        setCollapse(false);
        setSearch('');
        history.goBack()
    }

    /** @typedef {Array<Client>} clients */
    const clients = useSelector(state => state.clients.filter(client => {

        const string = search.replace(/[^0-9]+/g, '');
        return string ? client.contacts.some(e => e.phone.replace(/[^0-9]+/g, '').includes(string)) : clr(client.name).includes(clr(search))

    })
    );

    return (
        <Drawer anchor="right" open={history.location.hash === '#clients'} onClose={handleResetWindow}>
            <ContextMenu ref={MenuRef} onClose={() => SetMenuItem({})}>
                <MenuItem disabled={!menuItem.name} onClick={() => {
                    dispatch(actions.SetSelected(menuItem))
                    MenuRef.current.Close();
                    history.goBack();
                }
                }>Selecionar</MenuItem>
                <MenuItem disabled={!menuItem.name} onClick={() => { handleEditClient(menuItem); MenuRef.current.Close() }}>Editar</MenuItem>
                <MenuItem onClick={() => { handleCreateClient(); MenuRef.current.Close() }}>Adicionar Novo Cliente</MenuItem>
            </ContextMenu>
            <DrawerList size={550} >
                <Collapse in={!collapse} disableStrictModeCompat>
                    <div>
                        <ListItem divider onContextMenu={(event) => MenuRef.current.Open(event)()}>
                            <ListItemIcon><SearchIcon /></ListItemIcon>
                            <ListItemText primary={
                                <InputBase placeholder="Pesquisar cliente" value={search} onChange={e => setSearch(e.target.value)} />
                            } />
                        </ListItem>
                        {search.length > 5 && clients.length !== 1 &&
                            <ListItem
                                button
                                divider
                                onClick={handleCreateClient}
                            >
                                <ListItemText primary={`Cadastrar Novo Cliente`} secondary={search} />
                            </ListItem>}

                        <VirtualMap calcHeight={-62} collection={clients} rowHeight={73}>
                            {client => {
                                return (
                                    <ListItem divider button key={client.uuid}
                                        onContextMenu={(event) => MenuRef.current.Open(event)(SetMenuItem(client))}
                                        selected={client.selected}
                                        onClick={() => {
                                            dispatch(actions.SetSelected(client))
                                            history.push('/commerce/cash');
                                        }}
                                    >
                                        <ListItemText primary={client.name} secondary={`Telefone: ${client.contacts?.map(c => c.phone).join(' - ')} `} />
                                        <ListItemSecondaryAction>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => handleEditClient(client)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }}
                        </VirtualMap>
                    </div>
                </Collapse>
                <Collapse in={collapse} disableStrictModeCompat>
                    <Fragment>
                        <ListItem button divider onClick={() => setCollapse(false)}>
                            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
                            <ListItemText primary={`Cadastrando Novo Cliente`} />
                        </ListItem>
                        <ListItem>
                            {collapse && <WebClientRegister state={{ ...editInitialState, name: search }} onSave={() => setCollapse(false)} />}
                        </ListItem>
                    </Fragment>
                </Collapse>
            </DrawerList>
        </Drawer>
    );
}

export default CommerceNews;