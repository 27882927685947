import { styled } from "@material-ui/core/styles";

export const DateRangeBox = styled('div')({
    display: 'flex',
    '& > div:nth-child(1)': {
        marginRight: 8,
        width: '100%'
    },
    '& > div:nth-child(2)': {
        marginLeft: 8,
        width: '100%'
    }
})