import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import blue from '@material-ui/core/colors/blue';


function Theme(props){
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    
    const theme = React.useMemo(
        () =>
          createMuiTheme({
            palette: {
              type: prefersDarkMode ? 'dark' : 'light',
              primary: {
                main: prefersDarkMode ? '#acacac' : '#393939'
              },
              secondary: blue,
            },
          }),
        [prefersDarkMode],
      );
      
      return(
          <ThemeProvider theme={theme}>
              <CssBaseline/>
              {props.children}
          </ThemeProvider>
      );
}


export const ThemeBlue = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  const theme = React.useMemo(
      () =>
        createMuiTheme({
          palette: {
            type: prefersDarkMode ? 'dark' : 'light',
            primary: {
              main: prefersDarkMode ? '#acacac' : '#393939'
            },
            secondary: blue,
          },
        }),
      [prefersDarkMode],
    );
    
    return(
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {props.children}
        </ThemeProvider>
    );
}




export default Theme;