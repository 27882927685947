import React from 'react';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

export default function ImageGrid(props) {
    return (
        <GridList cellHeight={160} cols={3}>
          {props.tiles.map((tile) => (
            <GridListTile key={tile.src} cols={props.cols || 1}>
              <img src={tile.src} alt={tile.title} />
            </GridListTile>
          ))}
        </GridList>
    );
  }