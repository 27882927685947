import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import StatusSelector from '../ToastUtils/StatusSelector';
import AvatarUserIcon, { UploadButton, UploadAvatar, StyledGroupAvatar } from '../AvatarUserIcon';
import InputAdornment from '@material-ui/core/InputAdornment'
import socket from '../../services/socket'
import { DrawerList, SubHeader, FluidBox } from '../StyledComponents';
import TodoSlider from '../TodoSlider';
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close';
import { get, upload } from '../../services/Auth';
import InputDateRange from '../InputDateRange';
import { DateRangeBox } from '../InputDateRange/styles';

const ButtonAdornment = (
    <InputAdornment position="end">
        <Tooltip title="Salvar alteração">
            <IconButton type={"submit"} >
                <SaveIcon />
            </IconButton>
        </Tooltip>
    </InputAdornment>
)

const defaultTask = {
    id: 0,
    title: '',
    description: '',
    begin: null,
    finish: null,
    status: '',
    files: [],
    users: [],
    priority: ''
}


/**@param {{ task: Task|Topic, size: Number, event: ('task'|'topic'|'toast'), usersOptions: Array<User>, onExit: <> , onConnectUser: <> }} props*/
function TodoEdit({ task, size, event, usersOptions, ...props }) {
    const [values, setValues] = React.useState({});
    const [anchorMenuEl, setAnchorMenuEl] = React.useState([null, null]);

    const handleMenuClick = (event, target) => {
        event.preventDefault();
        setAnchorMenuEl([event.currentTarget, target]);
    };

    const handleMenuClose = () => {
        setAnchorMenuEl([null, null]);
    };

    React.useEffect(() => {
        if (task.title)
            setValues(task);
        else
            setValues(defaultTask);

        return () => setValues(defaultTask)

    }, [task]);


    if (!values.title) return null;

    const handleChangePeso = (e) => {
        e.preventDefault();
        const value = Number(values.peso);

        if (value)
            socket.emit(`${event}:will:update`, { id: values.id, uuid: values.uuid, peso: value, cq: value });
    };

    /**
     * 
     * @param {*} e 
     * @param {Priority} value 
     */
    const handleChangePriority = (e, value) => {
        socket.emit(`${event}:will:update`, { id: values.id, uuid: values.uuid, priority: value.asPriority() });
    };

    /**
     *
     *
     * @param {*} target
     * @param {boolean} [saveOnChange=false]
     */
    const handleChange = (target, saveOnChange = false) => (e) => {
        const newValues = {
            ...values,
            [target]: e.target.value
        }

        if (saveOnChange && newValues !== values && e.target.value !== null) {
            socket.emit(`${event}:will:update`, { id: values.id, uuid: values.uuid, [target]: e.target.value });
        }

        setValues(newValues);
    }

    const handleChangeRange = (task) => (values) => {
        const [begin, finish] = values;

        if (begin && finish) {
            socket.emit(`${event}:will:update`, { id: task.id, uuid: task.uuid, begin, finish });
        }
    }

    const handleSaveProps = (target) => (e) => {
        if (e) e.preventDefault();

        socket.emit(`${event}:will:update`, { id: values.id, uuid: values.uuid, [target]: values[target] });
    }

    const handleFireUpload = ({ target }) => {
        upload(`/gallery/upload/todo/${event}/${values.uuid}`, target)
            .then(() => socket.emit(`${event}:will:change`, { uuid: values.uuid, files: { select: { name: true, file: true } } }));
    }

    const handleDeleteUpload = () => {
        get(`/gallery/delete/${anchorMenuEl[1].file}`)
            .then(() => socket.emit(`${event}:will:change`, { uuid: values.uuid, files: { select: { name: true, file: true } } }))
            .then(() => handleMenuClose());
    }


    // const handleUserInTask = task => (data) => {
    //     socket.emit(`${event}:will:connect`, { id: task.id, uuid: task.uuid, users: data });
    // }

    return (
        <DrawerList size={size}>
            <Menu anchorEl={anchorMenuEl[0]} open={Boolean(anchorMenuEl[0])} onClose={handleMenuClose}>
                <MenuItem disabled>{anchorMenuEl[1]?.name}</MenuItem>
                <MenuItem disabled>Download</MenuItem>
                <MenuItem disabled>Galeria</MenuItem>
                <MenuItem onClick={handleDeleteUpload}>Excluir</MenuItem>
            </Menu>
            <SubHeader>
                <div>
                    Editar Detalhes
                </div>
                {props.onExit &&
                    <div>
                        <Tooltip title="Voltar para lista">
                            <IconButton size="small" onClick={props.onExit}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            </SubHeader>

            <ListItem component="form" onSubmit={handleSaveProps('title')}>
                <TextField fullWidth helperText="Título"
                    InputProps={{ endAdornment: (values.title !== task.title) ? ButtonAdornment : null }}
                    value={values.title}
                    onChange={handleChange('title')}
                />
            </ListItem>

            <ListItem divider component="form" onSubmit={handleSaveProps('description')}>
                <TextField fullWidth helperText="Descrição"
                    InputProps={{ endAdornment: (values.description !== task.description) ? ButtonAdornment : null }}
                    value={values.description||''}
                    onChange={handleChange('description')}
                />
            </ListItem>

            {Boolean(values.hasOwnProperty('peso')) &&
                <React.Fragment>
                    <ListItem divider component="form" onSubmit={handleChangePeso}>
                        <TextField helperText="Peso"
                            InputProps={{ endAdornment: (values.peso !== task.peso) ? ButtonAdornment : null }}
                            value={values.peso}
                            onChange={handleChange('peso')}
                        />
                    </ListItem>
                </React.Fragment>
            }

            <ListSubheader> Arquivos {values.files.length}</ListSubheader>
            <ListItem divider>
                <FluidBox>
                    <UploadButton onFireUpload={e => handleFireUpload(e)} />
                    <StyledGroupAvatar>
                        <>
                            {values.files.map(file =>
                                <UploadAvatar key={file.file} onContextMenu={e => handleMenuClick(e, file)} title={file.name} file={file.file} />)
                            }
                        </>
                    </StyledGroupAvatar>
                </FluidBox>
            </ListItem>

            <ListItem divider component="label">
                <InputDateRange
                    startText="De"
                    endText="Até"
                    value={[values.begin, values.finish]}
                    onAccept={handleChangeRange(task)}
                    onChange={() => false}
                    component={(startProps, endProps) => 
                        <DateRangeBox>
                            <TextField {...startProps} size="small" variant="standard" margin="normal" />
                            <TextField {...endProps} size="small" variant="standard" margin="normal" />
                        </DateRangeBox>
                    }
                />
            </ListItem>

            <ListItem button divider>
                <StatusSelector value={values.status} onChange={handleChange('status', true)} />
            </ListItem>

            {
                Boolean(props.onConnectUser) && <>
                    <ListSubheader> Usuários do projeto {values.users.length}</ListSubheader>
                    <ListItem divider>
                        <FluidBox>
                            <AvatarUserIcon
                                onChange={props.onConnectUser(task)}
                                options={usersOptions}
                                users={values.users || []}
                            />
                        </FluidBox>
                    </ListItem>
                </>
            }

            {
                Boolean(values.hasOwnProperty('priority')) && <>
                    <ListSubheader> Nivel Prioridade </ListSubheader>
                    <ListItem divider>
                        <TodoSlider
                            value={values.priority}
                            onChange={handleChangePriority}
                        />
                    </ListItem>
                </>
            }
        </DrawerList >
    )
}

export default TodoEdit;