import socketclient from 'socket.io-client';
import history from './history';
import { getToken, isAuthenticated, logout, setToken } from './Auth';
import { wss } from './setproxy';
import parser from 'socket.io-msgpack-parser'


const options = {
    parser: parser,
    query: { 
        token: getToken() 
    },
};

const socket = socketclient.connect(wss, options)

if((process.env.NODE_ENV === 'development')){
    window.log = (...data) => socket.emit('log', ...data);
}

socket.on('force_reload', () => {
    console.log('socket force: reload')
    window.location.reload();
});

socket.on('connect_failed', () => {
    console.log('socket troubleshoot: connect_failed')

    return logout(() => {
        history.replace('/');
        // window.location.reload();
    });
});

socket.on('connect_error', () => {
    console.log('socket troubleshoot: connect_error')

    return logout(() => {
        history.replace('/');
        // window.location.reload();
    });
});

socket.on('error', () => {
    console.log('socket troubleshoot: error')

    return logout(() => {
        history.replace('/');
        // window.location.reload();
    });
});

socket.on('disconnect', () => {
    if(isAuthenticated()){
         setToken(null);
        //  setTimeout(() => window.location.reload(), 1000);
    }
});

export default socket;