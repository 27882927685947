import React from 'react';
import { Grid, ListItem, ListItemText, Box, Collapse, IconButton, InputBase, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CommerceCarteEditor from '../CommerceCarteEditor';
import CommerceCarteItems from '../CommerceCarteItems';
import { ListView, SubHeader } from '../../Components/StyledComponents';
import { useParams } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import TaskReducer from '../../Reducers/TasksReducers';
import ContextMenu from '../../Components/ContextMenu';
import CommerceCarteDialog from '../CommerceCarteDialog';

const { dispatch: carteDispatch } = new TaskReducer('commerce:carte');

/**
 * 
 * @typedef {import('../../typeroots').Task}  Task
 * @typedef {import('../../typeroots').Topic} Topic
 * @param {*} props 
 */

/**
 * @param {RouterDom} props 
 */
function CommerceCarte(props) {

    const contextMenu = React.useRef({ current: {} })

    const [input, setInput] = React.useState('');

    /**@typedef {String} carte -- Task.uuid */
    /**@typedef {String} uuid  -- Topic.uuid */

    const { carte, uuid } = useParams();

    /**@typedef {Array<Task>} Cartes*/
    const Cartes = useSelector(state => state.task.filter(e => e.status === "CARTE"));

    /**@typedef {Array<Topic>} Snacks*/
    const Snacks = useSelector(state => state.topic);

    const handleCounterSnack = React.useCallback((cb) => {
        return Snacks.count(cb);

    }, [Snacks])

    const handleRedirect = React.useCallback((id) => {
        return (carte === id) ? props.history.replace('/commerce/carte') : props.history.replace('/commerce/carte/' + id);

    }, [props, carte])

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
        carteDispatch.willCreate({ input, status: 'CARTE' });
        setInput('');

    }, [input])

    const handleExclude = React.useCallback((e) => {
        if (window.confirm("Deseja Mesmo Excluir Esse Cardápio Inteiro?")) {

            carteDispatch.willDelete({ uuid: contextMenu.current.Item.uuid });
        }
        contextMenu.current.Close();

    }, [])


    return (
        <Box onContextMenu={e => e.preventDefault()}>

            <CommerceCarteDialog {...props}/>

            <ContextMenu ref={contextMenu}>
                <MenuItem onClick={() => {props.history.push('#' + contextMenu.current.Item?.uuid || ''); contextMenu.current.Close() }}>Editar</MenuItem>
                <MenuItem onClick={handleExclude}>Excluir</MenuItem>
            </ContextMenu>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <ListView dense={true} subheader={<SubHeader>Cardápios</SubHeader>}>
                        <ListItem button divider component="form" onSubmit={handleSubmit}>
                            <ListItemText required component="label" primary={<InputBase fullWidth value={input} placeholder="Adicionar Novo ..." onChange={(e) => setInput(e.target.value.UcFirst())} />} />
                            {input && <IconButton type="submit"><SendIcon /></IconButton>}
                        </ListItem>

                        {Cartes.map(/**@param {Topic} */cart =>
                            <div key={cart.uuid}>
                                <ListItem
                                    button divider
                                    selected={carte === cart.uuid}
                                    onClick={() => handleRedirect(cart.uuid)} onContextMenu={e => contextMenu.current.OpenMenu(e, cart)}>
                                    <ListItemText 
                                        primary={cart.title} 
                                        secondary={`${handleCounterSnack(e => e.taskId === cart.id)} Itens - ${cart.description || 'Sem Comentário'}`} />
                                    <ExpandMoreIcon />
                                </ListItem>
                                <Collapse in={cart.uuid === carte}>
                                    <CommerceCarteItems snacks={Snacks.filter(e => e.taskId === cart.id)} {...props} />
                                </Collapse>
                            </div>
                        )}
                    </ListView>
                </Grid>
                {Boolean(uuid) && <CommerceCarteEditor snack={Snacks.find(e => e.uuid === uuid)} {...props} />}
            </Grid>
        </Box>
    );
}

export default CommerceCarte;