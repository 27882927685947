import React from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '../../Reducers/User';
import { UserAvatar } from '../../Components/AvatarUserIcon';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddressForm from '../../Components/AddressForm';

import Fastfood from '@material-ui/icons/Fastfood';
import Typography from '@material-ui/core/Typography'

// import { VarDump } from '../../Components/Allow';

export function WorkPermissions(object) {
  let string = [];

  if (object.isRestaurant) string.push('Restaurante');
  if (object.isComparative) string.push('Comparativos');
  if (object.isManagement) string.push('Gerenciamento de projetos');

  return string.join(',');

}

function MyWork() {
  const user = useSelector(state => state.user);
  const Permissions = WorkPermissions(user.faction);

  const { address } = user.faction;


  /**@param {{cep: string, city: string, complement: string|null, coords: string, formattedAddress: string, latitude: number, logitude: number, neighborhood: string, number: number, state: string, street: string, title: string}} input */
  const handleCreate = (input) => {

    dispatch.setFactionAddress({
      uuid: user.faction.uuid,
      title: "Casa",
      number: String(input.number),
      isMain: true,
      formattedAddress: input.formattedAddress,
      neighborhood: input.neighborhood,
      city: input.city,
      complement: input.complement,
      cep: input.cep,
      street: input.street,
      state: input.state,
      coords: input.coords
    })
  }

  return (
    <Grid container style={{ height: '100%' }} alignItems="center" justify="center">
      {/* <VarDump {...user.faction} /> */}


      <Grid item xs={12} md={5}>
        <Card>
          <CardHeader
            avatar={<UserAvatar user={user.faction} />}
            title={user.faction.name}
            subheader={
              <React.Fragment>
                <Typography variant="subtitle2" display="block">{Permissions}</Typography>
                {address
                  ? <Typography variant="caption">{address.street}, {address.number} {address.neighborhood} - {address.city}/{address.state} - {address.cep}</Typography>
                  : <Typography variant="caption">Cadastre um endereço</Typography>
                }
              </React.Fragment>
            }
            action={
              <React.Fragment>

              </React.Fragment>
            }

          />
          <CardContent>
            <List>
              <ListItem divider>
                <ListItemIcon><Fastfood /> </ListItemIcon>
                <ListItemText primary={user.faction.branch?.label || "Sem ramo selecionado"} />
              </ListItem>

              <AddressForm default={address} onSubmit={handleCreate} />

            </List>


          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MyWork;