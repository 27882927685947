import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FastfoodIcon from '@material-ui/icons/Fastfood';

export function DeliverCardContent(props) {
    return (
        <CardContent component={List} dense={true}>
            {props.topics.map(topic =>
                    <ListItem divider key={topic.uuid}>
                        <ListItemText
                            primary={topic.title}
                            secondary={`${topic.price?.monetizeme()} + ${topic.toasts?.sum('price', toast => toast.topicId === topic.id && toast.status === "OPTIONAL").monetizeme()} Adicionais`}
                        />
                    </ListItem>
                )}
        </CardContent>

    );
}

function DeliverCardHeader(props) {
    const [Address, Payment] = React.useMemo(() => {
        return (props.description) ? props.description.split('|') : null

    }, [props]);

    return (
        <CardHeader
            avatar={
                <Avatar aria-label={props.title}>
                    <FastfoodIcon />
                </Avatar>
            }
            title={`PEDIDO #${props.id}`}
            subheader={
                <React.Fragment>
                    <Typography variant="caption" display="block">{Address.rmvParentheses()}</Typography>
                    <Typography variant="caption" display="block">Pagamento: {Payment.rmvParentheses()}</Typography>
                    <Typography variant="caption" display="block">Observações: {Payment.getParentheses() || "Nenhuma"}</Typography>
                </React.Fragment>
            }
        />);
}

export default DeliverCardHeader;