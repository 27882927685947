import React from 'react';

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};


//const useSearchStarwarsHero = () => useDebouncedSearch(text => searchStarwarsHeroAsync(text))
// Generic reusable hook
const useDebouncedSearch = (searchFunction) => {

    // Handle the input text state
    const [inputText, setInputText] = React.useState('');
  
    // Debounce the original search async function
    const debouncedSearchFunction = React.useConstant(() =>
      debounce(searchFunction, 300)
    );
  
    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    const searchResults = React.useAsync(
      async () => {
        if (inputText.length === 0) {
          return [];
        } else {
          return debouncedSearchFunction(inputText);
        }
      },
      [debouncedSearchFunction, inputText]
    );
  
    // Return everything needed for the hook consumer
    return {
      inputText,
      setInputText,
      searchResults,
    };
  };


  export default useDebouncedSearch;