const Particles = {
    particles: {
        color: {
            value: "#ccc"
        },
        number: {
            value: 55,
            density: {
                enable: true
            }
        },
        size: {
            value: 3,
            random: true,
            anim: {
                speed: 4,
                size_min: 0.3
            }
        },
        line_linked: {
            enable: true,
            size: 4
        },
        move: {
            speed: 1,
            direction: "top",
            out_mode: "out"
        },
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: "bubble"
            },
            onclick: {
                enable: true,
                mode: "repulse"
            }
        },
        modes: {
            bubble: {
                distance: 100,
                duration: 2,
                size: 0,
                opacity: 0
            },
            repulse: {
                distance: 100,
                duration: 1
            }
        }
    }
}

export default Particles;