import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { ScrollBox, StyledBadge } from '../../Components/StyledComponents';
import AppBar from './AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SpeedDial from '@material-ui/lab/SpeedDial';
import ContextMenu from '../../Components/ContextMenu';
import { addOrder } from '../../Reducers/RestaurantOrders';
import { ACTIVES, CARTE, INGREDIENTS } from '../../typeroots';
import CommerceCarousel from '../CommerceCarousel';
import { debounce } from '../../services/useDebounce';
import CommerceCashItemsGroup from '../../Components/CommerceCashItemsGroup';
import Button from '@material-ui/core/Button'
import CommerceItemDialog from '../CommerceItemDialog';

export default function CommerceCashItems(props) {
  const classes = useStyles();
  const history = useHistory();

  const [menuItem, SetMenuItem] = React.useState({});

  const container = React.useRef({});
  const AppBarRef = React.useRef({});
  const MenuRef = React.useRef({});

  const dispatch = useDispatch();

  const client = useSelector(state => state.clients.find(e => e.selected === true));

  /**@typedef {Array<Task>}*/
  const Cartes = useSelector(state => state.task.filter(CARTE));
  /**@type {Array<Topic>}*/
  const orders = useSelector(state => state.orders.filter(e => e.id));
  /**@type {Array<Toasts>}*/
  const toasts = useSelector(state => state.toast.filter(e => e.topicId === menuItem.id))

  const Groups = useSelector(state => state.topic.Distinct(['group'], ACTIVES).map(e => e.group));

  const sections = Groups.reduce((acc, value) => {
    acc[value] = React.createRef();
    return acc;
  }, {});


  const openCashDialog = (uuid) => {
    return history.push(`/commerce/cash/${uuid}`);
  }

  const openCashOrders = () => {
    return history.push(`/commerce/cash/orders`);
  }

  const handleContainerScroll = debounce(function () {
    var result = 0;
    Groups.forEach((group, i) => {
      if (sections[group].current && (container.current.scrollTop - sections[group].current.offsetTop + 200) > 0) {
        result = i + 1;
      }

      return result;
    });

    AppBarRef.current.setTab(result);
  }, 10);

  return (
    <React.Fragment>

      <ScrollBox ref={container} onScroll={handleContainerScroll}  >
        <AppBar ref={AppBarRef} groups={Groups} container={container} sections={sections} />

        <div style={{ paddingTop: 46, width: '100%' }}>
          <CommerceCarousel height={400} files={Cartes.reduce((a, b) => a.concat(b.files.map(c => ({ file: c.file, name: c.name }))), [])} />
        </div>

        <ContextMenu ref={MenuRef}>
          <MenuItem disabled>{menuItem.title}</MenuItem>
          <MenuItem onClick={() => dispatch(addOrder({ ...menuItem, toasts: toasts.map(INGREDIENTS), description: null })) && MenuRef.current.Close()}>Adicionar ao carrinho</MenuItem>
        </ContextMenu>

        <Button
          color="primary"
          onClick={() => history.push("#clients")}
          variant="contained"
          className={classes.speedName}>
          {client?.name || "Selecione um cliente"}
        </Button>

        <SpeedDial
          open={false}
          ariaLabel="Speed dial shop cart"
          className={classes.speedDial}
          color="primary"
          onClick={openCashOrders}
          hidden={orders.length === 0}
          icon={
            <StyledBadge badgeContent={orders.length} color="error">
              <ShoppingCartIcon />
            </StyledBadge>}
        >
        </SpeedDial>

        <CommerceCashItemsGroup
          onContext={(event, snack) => MenuRef.current.Open(event)(SetMenuItem(snack))}
          onOpen={openCashDialog}
          sections={sections}
        />

      </ScrollBox>
      <CommerceItemDialog {...props} />
    </React.Fragment>
  );
}