import { actions } from '../../Reducers/TodosFilter'

import Reducer from '../../Reducers/TasksReducers'
import TodosMap from '../../Reducers/TodosMap';
const { dispatch: TaskDispatch, actions: TaskActions } = new Reducer('task');

export const dispatch = {
    task: TaskDispatch,
    topic: new Reducer('topic').dispatch,
    toast: new Reducer('toast').dispatch,
}

/**
 * @type {import('react-redux').MapStateToProps<import('react-redux').DefaultRootState>}
 */
export const mapStateToProps = (state) => {
    const Todos = new TodosMap(state);
    return {
        tasks: Todos.tasks,
        topics: Todos.topics,
        toasts: Todos.toasts,
        filters: state.todosFilter
    };
};

export const mapDispatchToProps = (dispatch) => ({
    /**@param {import('../TodosTask/Rows').Task & { selected: boolean }} data*/
    setSelect: (data) => dispatch(TaskActions.Select(data)),
    /**@param {import('../../typeroots').STATUS} status */
    setStatus: (status) => dispatch(actions.setStatus(status))
});