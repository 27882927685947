import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import InputMask from '../InputMask';
import update from 'immutability-helper';
import CloseIcon from '@material-ui/icons/Close'
import { dispatch } from '../../Reducers/Clients';
import { Socket } from '../../services/useSocket'
import { InputFooter, InputRoot } from './styles';
import { SubHeader } from '../StyledComponents';
/**
 * @typedef {import('../../typeroots').ClientWeb} Client
 * @typedef {import('../../typeroots').ClientAddress} Address
 * @typedef {import('../../typeroots').ClientContact} Contact
 * @typedef {import('../../typeroots').ClientMails} Mail
 */

// const focus = (data) => document.querySelector(`#${data}`).focus();
/**
 * 
 * @param {Object} props
 * @param {String} props.defaultName
 * 
 */

const inputProps = (label, helperText, isRequired = true) => ({
    autoComplete: 'off',
    required: isRequired,
    label: label,
    helperText: <Typography variant="caption">{helperText}</Typography>
})


function WebClientRegister({ state, ...props }) {

    /**
     * @typedef {Client} values 
     */


    const [values, setValues] = useState(state);

    const setName = (e) => {
        setValues({
            ...values,
            name: e.target.value
        })
    }

    const [addons, setAddons] = useState({

        contact: {
            title: 'Celular',
            phone: ''
        }
    })
    const handleValues = (name, prop, cb = null) => (e, value = null) => {
        setAddons(update(addons, {
            [name]: {
                [prop]: {
                    $set: value || e.target.value
                }
            }
        }))

        return cb && cb(e.target.value);
    }


    // const handleCep = (e) => {
    //     setAddons(update(addons, {
    //         address: {
    //             cep: {
    //                 $set: e.target.value
    //             }
    //         }
    //     }))

    //     if (e.target.value.match(/[0-9]{5}-[\d]{3}/g)) {
    //         Emit('get:cep', {cep:e.target.value});
    //     }
    // }

    // const setCep = (data) => {
    //     const address = { ...addons.address, ...data };
    //     setAddons({...addons, address});
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch.Create({ ...addons, name: values.name });
    }

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%', flex: 1 }}>
            <Socket events={{
                // 'set:cep': setCep,
                'client:will:create': props.onSave
            }}
            />

            <SubHeader>Dados</SubHeader>
            <InputRoot gutter="">
                <TextField {...inputProps("Nome", `Nome Completo ${values.name.length}/75`)}
                    onChange={setName}
                    value={values.name}
                    InputProps={{ endAdornment: <InputAdornment position="end"><IconButton size="small"><CloseIcon /></IconButton></InputAdornment> }}
                />
            </InputRoot>

            {/* <SubHeader>Endereço e localização</SubHeader>
            <InputRoot>
                <TextField {...inputProps("Título", `Nome de identificação`)} value={addons.address.title} onChange={handleValues('address', 'title')} />
                <InputMask component={TextField} 
                    {...inputProps("Cep", addons.address.state ? `${addons.address.neighborhood} ${addons.address.city}/${addons.address.state}` : `Localizar endereço`)} 
                    mask="99999-999"
                    value={addons.address.cep}
                    onChange={handleCep}
                />
            </InputRoot> */}

            {/* <InputRoot items={2}>
                <TextField {...inputProps("Número", addons.address.street || 'Número')} value={addons.address.number} onChange={e => handleValues('address', 'number')({target: {value: Number(e.target.value)}})} />
                <TextField  {...inputProps("Complemento", `Complemento`, false)} value={addons.address.complement} onChange={handleValues('address', 'complement')} />
            </InputRoot> */}

            <SubHeader>Contato</SubHeader>
            <InputRoot>
                <TextField {...inputProps("Título", `Tipo de Contato`)} onChange={handleValues('contact', 'title')} value={addons.contact.title} />
                <InputMask component={TextField} {...inputProps("Telefone", `Contato com ddd`)}
                    mask={addons.contact.phone.substring(5, 6) !== '9' ? "(99) 9999-9999" : "(99) 9 9999-9999"}
                    value={addons.contact.phone}
                    onChange={handleValues('contact', 'phone')}
                />
            </InputRoot>

            {/* <InputRoot gutter="">
                <TextField {...inputProps("Email", `Email opcional para novidades`, false)} value={addons.mail.email} onChange={handleValues('mail', 'email')} />
            </InputRoot> */}

            <InputFooter><Button type={"submit"}>Cadastrar Cliente</Button></InputFooter>
        </form>
    );
}

export default WebClientRegister;