import React from 'react';

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useStyles from './styles'
import { connect } from 'react-redux';
import TodosFilter from '../../Reducers/TodosFilter'


function TodoFilters(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Tabs value={props.status} onChange={props.setStatus} variant="scrollable" scrollButtons="auto" >
                <Tab
                    value={'ALL'}
                    label={`Tudo ${props.task.length}`}>
                </Tab>
                <Tab
                    value={'NEW'}
                    label={`Novos ${props.task.count(e => e.status === "NEW")}`}>
                </Tab>
                <Tab
                    value={'PLAY'}
                    label={`Produzindo ${props.task.count(e => e.status === "PLAY")}`}>
                </Tab>
                <Tab
                    value={'DONE'}
                    label={`Concluídos ${props.task.count(e => e.status === "DONE")}`}>
                </Tab>
                <Tab
                    value={'CQ'}
                    label={`Revisados ${props.task.count(e => e.status === "CQ")}`}>
                </Tab>
                <Tab
                    value={'DROP'}
                    label={`Cancelados ${props.task.count(e => e.status === "DROP")}`}>
                </Tab>
                <Tab
                    value={'STOP'}
                    label={`Pausados ${props.task.count(e => e.status === "STOP")}`}>
                </Tab>
            </Tabs>
        </Paper>
    )
}



export default connect(
    (state) => ({
        status: state.todosFilter.status
    }),
    (dispatch) => ({
        setStatus: (e, status) => dispatch(TodosFilter.setStatus(status))
    })
)(TodoFilters)