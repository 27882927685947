import React, { Fragment, useState } from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import InputBase from '@material-ui/core/InputBase'
import EmailIcon from '@material-ui/icons/Email'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { dispatch } from '../../Reducers/Clients';
/**
 * 
 * @param {import('../../typeroots').ClientWeb} props 
 * 
 */

const defaultState = {
    email: '',
    title: ''
}

function TabContacts({tab, ...props}) {

    const [create, setCreate] = useState(defaultState);
    const handleCreate = (prop) => e => setCreate({ ...create, [prop]: e.target.value });

    const handleDelete = (mail) => {
        dispatch.Connect({
            uuid: props.client.uuid,
            mails: { delete: { uuid: mail.uuid }}
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch.Connect({
            uuid: props.client.uuid,
            mails: { create }
        });

        return e.target.reset() && setCreate(defaultState);
    }

    if(!tab) return null

    return (
            <List dense={true} component="form" onSubmit={handleSubmit}>
                {props.client.mails.map(mail =>
                    <ListItem divider key={mail.uuid}>
                        <ListItemIcon><EmailIcon /></ListItemIcon>
                        <ListItemText primary={mail.email} secondary={mail.title} />
                        <ListItemSecondaryAction>
                            <Tooltip title="Excluir">
                                <IconButton onClick={()=>handleDelete(mail)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <ListItem divider>
                    <ListItemIcon><EmailIcon /></ListItemIcon>
                    <ListItemText primary={
                        <Fragment>
                            <InputBase required placeholder="Título"    onChange={handleCreate('title')} />
                            <InputBase required placeholder="Add Email" onChange={handleCreate('email')} />
                        </Fragment>
                    } />
                    <ListItemSecondaryAction>
                        <Tooltip title="Salvar">
                            <IconButton type="submit">
                                <BookmarkBorderIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        )
}

export default TabContacts;