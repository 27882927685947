import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';

const AmountSelector = (props) => (
    <div>
        <IconButton disabled={props.number < 2} aria-label="previous" onClick={() => props.onChange(current => current - 1)}>
            <IndeterminateCheckBoxIcon />
        </IconButton>
        <Typography display="inline" variant="h6" style={{ padding: 12 }}>{props.number}</Typography>
        <IconButton aria-label="next" onClick={() => props.onChange(current => current + 1)}>
            <AddBoxIcon />
        </IconButton>
    </div>
)

export default AmountSelector;