import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import socket from '../../services/socket';
import Typography from '@material-ui/core/Typography'

import CloseIcon from '@material-ui/icons/Close';

const initialState = {
    open: false,
    message: '',
    title: null,
    redirect: null,
    background: false,
    delay: 3165
};

export default class SnackBar extends React.Component {

    constructor() {
        super();

        this.state = initialState;
    }

    componentDidMount() {
        socket.on('snackbar', this.Open);
    }

    Redirect = () => {
        if(Boolean(this.state.redirect)){
            let state = {};

            if(Boolean(this.state.background))
            state.background = this.props.location

            this.props.history.push(this.state.redirect, state);
            this.setState(initialState);
        }
    }

    Open = (data) => {
        this.setState({ open: true, ...data });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState(initialState);
    };

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    style={{cursor: Boolean(this.state.redirect) ? 'pointer' : 'inherit'}}
                    open={this.state.open}
                    autoHideDuration={this.state.delay || 3165}
                    onClose={this.handleClose}
                    onClick={this.Redirect}
                    message={<>
                        <Typography display="block" variant="caption">{this.state.title}</Typography>
                        <Typography display="block" variant="subtitle2">{this.state.message}</Typography>
                    </>}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>
        );
    }
}