import React from 'react';
import { List, ListItem, ListItemText, InputBase, IconButton, ListItemIcon, MenuItem, ListItemSecondaryAction } from '@material-ui/core';
import { InputField, SubHeader, Vertical } from '../../Components/StyledComponents';
import { GroupBy } from 'react-lodash';
import { DragDropContext } from 'react-beautiful-dnd';

import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import DragDropContainer from '../../Components/DragDropContainer';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import BlockIcon from '@material-ui/icons/Block'


import TaskReducer from '../../Reducers/TasksReducers';
import ContextMenu from '../../Components/ContextMenu';
const { dispatch } = new TaskReducer('commerce:snack');

let throttle;
function debounce(callback) {

    if (throttle) return clearTimeout(throttle);

    throttle = setTimeout(callback, 2000);
}
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * 
 * @param {import('../../typeroots').RouterDom & {snacks: Array<Topic>}} props 
 */
function CommerceCarteItems(props) {
    const [input, setInput] = React.useState('');

    const contextMenu = React.useRef({ current: {} });

    /**@typedef {String} carte -- Task.uuid */
    /**@typedef {String} uuid  -- Topic.uuid */
    const { carte, uuid } = props.match.params;

    const handleCreate = React.useCallback(() => {
        dispatch.willCreate({ taskUuid: carte, input });

    }, [input, carte])

    const handleDelete = React.useCallback(() => {
        dispatch.willDelete({ uuid: contextMenu.current.Item.uuid });

        contextMenu.current.Close();
    }, [])

    const handleActivate = React.useCallback(() => {
            dispatch.willUpdate({ uuid: contextMenu.current.Item.uuid, useAsModel: !contextMenu.current.Item.useAsModel });

        contextMenu.current.Close();
    }, [])

    const handleDragEnd = React.useCallback(({ draggableId, source, destination }) => {
        if (source && destination && source.droppableId !== destination.droppableId) {
            dispatch.willUpdate({ uuid: draggableId, group: destination.droppableId });
        }

    }, [])

    const snacks = props.snacks.$filter(input);

    const handleInput = (e) => {
        setInput(e.target.value.split(' ').map(capitalize).join(' '));

        debounce(() => contextMenu.current.Open({ preventDefault: e.preventDefault, clientX: 200, clientY: 200 }));

    }

    return (
        <List style={{ marginTop: 12 }} subheader={<>
            <SubHeader>
                <InputField onContextMenu={e => input && contextMenu.current.Open(e)()}>
                    <SearchIcon />
                    <InputBase fullWidth placeholder="Pequisar/Criar Novos Itens" value={input} onChange={handleInput} />
                    <Vertical orientation="vertical" />
                    <IconButton size="small" onClick={() => setInput('')}>
                        <CloseIcon />
                    </IconButton>
                </InputField>
            </SubHeader>
        </>}>

            <ContextMenu ref={contextMenu} MenuProps={{ autoFocus: false }}>
                {input && <MenuItem onClick={handleCreate}>Criar Lanche "{input}"</MenuItem>}
                {contextMenu.current.Item  && <MenuItem onClick={handleActivate}>Ativar/Desativar</MenuItem>}
                {contextMenu.current.Item  && <MenuItem onClick={handleDelete}>Excluir</MenuItem>}
            </ContextMenu>

            {input && snacks.length === 0 && 
            <ListItem button onClick={handleCreate}>
                <ListItemText primary={`Criar "${input}"`} />
            </ListItem>
            }

            <DragDropContext onDragEnd={handleDragEnd}>
                <GroupBy collection={snacks} iteratee={snack => snack.group}>
                    {groups =>
                        Object.keys(groups)
                            .map(group => group !== "Pedidos" &&
                                <List dense={true} key={group}>
                                    <DragDropContainer
                                        value={group}
                                        subheader={<div>{group === 'null' ? "Sem Categoria" : group}  ({groups[group].length})</div>}
                                        collection={groups[group]}>
                                        {snack =>
                                            <ListItem divider button key={snack.uuid}
                                                style={{opacity: !snack.useAsModel && .5}}
                                                onContextMenu={e=>contextMenu.current.OpenMenu(e, snack)}
                                                onClick={() => props.history.push(`/commerce/carte/${carte}/${snack.uuid}`)}
                                                selected={snack.uuid === uuid}
                                            >
                                                <ListItemIcon>
                                                    <DragIndicatorIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={snack.title} secondary={!snack.useAsModel && "Inativo"} />
                                                <ListItemSecondaryAction>
                                                    <IconButton size="small" onClick={e=>contextMenu.current.OpenMenu(e, snack)}>
                                                        {snack.useAsModel ? <MoreVertIcon /> : <BlockIcon /> }
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>}
                                    </DragDropContainer>
                                </List>
                            )}
                </GroupBy>
            </DragDropContext>
        </List>
    );
}

export default CommerceCarteItems;