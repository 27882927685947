import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import socket from '../../../services/socket';
import { useSelector } from 'react-redux';
import { Faction } from '../../../Components/Allow';
import Input from '../../../Components/Input';

export function GetRuleIcon(reference) {
    switch (reference) {
        case 'isRestaurant': return <FastfoodIcon />;
        case 'isManagement': return <SupervisedUserCircleIcon />;
        case 'isComparator': return <SupervisedUserCircleIcon />;
        default: return null;
    }
}

function ListRolesRules({ role }) {
    const rules = useSelector(state => state.adminRules);

    const handleToggle = (data, checked) => {
        socket.emit('admin:roles:will:connect', {
            role,
            rules: checked ? { disconnect: { id: data.id } } : { connect: { id: data.id } }
        });
    };

    const handleEditRole = (role) => data => {
        socket.emit('admin:roles:will:update', { role, data });
    }

    return (
        <>
            <ListSubheader>Editar</ListSubheader>
            <ListItem divider>
                <ListItemText
                    primary={<Input
                        fullWidth
                        value={role.name}

                        onSubmit={input => handleEditRole(role)({ name: input })}
                        helperText='Editar nome do grupo'
                        label="Nome"
                    />
                    }
                />
            </ListItem>

            {rules.filter(e => e.label === "AdminUsers").map(rule => {

                const mapRules = role.rules.map(e => e.label);
                const checked = mapRules.indexOf(rule.label) !== -1;

                return (
                    <Faction on={true} key={'rules' + rule.id} >
                        <ListItem button divider onClick={() => handleToggle(rule, checked)}>
                            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                            <ListItemText id={"switch-list-label-" + rule.label} primary={rule.caption} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={() => handleToggle(rule, checked)}
                                    checked={checked}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label-' + rule.label }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Faction>
                )
            }
            )}

            <ListSubheader>Configurar {role.name}</ListSubheader>
            {rules.sort((a, b) => a.label.localeCompare(b.label)).map(rule => {

                const mapRules = role.rules.map(e => e.label);
                const checked = mapRules.indexOf(rule.label) !== -1;

                return (
                    <Faction on={rule.reference} key={'rules' + rule.id} >
                        <ListItem button divider onClick={() => handleToggle(rule, checked)}>
                            <ListItemIcon>{GetRuleIcon(rule.reference)}</ListItemIcon>
                            <ListItemText id={"switch-list-label-" + rule.label} primary={rule.caption} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={() => handleToggle(rule, checked)}
                                    checked={checked}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label-' + rule.label }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Faction>
                )
            }
            )}
        </>
    );
}

export default ListRolesRules;

