import React from 'react';
import { useSelector } from 'react-redux';

export function isAllowed(stringOn, rulesList) {
    if (!rulesList) return true;

    if (typeof stringOn === 'boolean' && stringOn === true)
        return true;

    else if (typeof stringOn === 'string')
        return rulesList.indexOf(stringOn) > -1;

    else if (stringOn instanceof Array)
        return stringOn.every(N => rulesList.indexOf(N) > -1);
}
/**
 * @function Faction
 * 
 * @param {{on:import('../../typeroots').FactionOns, message: string}} props 
 * @returns {boolean}
 */
export function Faction(props) {
    const user = useSelector(state => state.user);

    if (typeof props.on === 'boolean' && props.on === true)
        return props.children;

    if (user.faction?.[props.on])
        return props.children;

    return props.message || null;
}

/**
 * @function IsAdmin
 * 
 * @param {{on:import('../../typeroots').UserOns, message: string}} props 
 * 
 * @returns {boolean}
 */

export function IsAdmin(props) {
    const [rules, setRules] = React.useState(false);
    const user = useSelector(state => state.user);

    React.useEffect(() => {
        setRules(user?.role.rules.map(e => e.label) || []);

    }, [user])

    if (isAllowed('AdminUsers', rules))
        return props.children;

    return null;

}

/**
 * @function IsAdmin
 * 
 * @param {{on:import('../../typeroots').UserOns, message: string}} props 
 * 
 * @returns {boolean}
 */

export function IsLogged(props) {
    const user = useSelector(state => state.user);

    if (user?.faction)
        return props.children;

    return null;

}

export const VarDump = (props) => (
    <pre style={{ position: 'fixed', margin: 0, padding: 2, right: 0, top: 0, overflow: 'auto', bottom: 0, zIndex: 10000, maxWidth: 350, background: 'rgba(0,0,0, .6)' }}>
        {JSON.stringify(props, null, 2)}
    </pre>
)

/**
 * @function Allow
 * 
 * @param {{on:import('../../typeroots').UserOns, message: string}} props 
 * 
 * @returns {boolean}
 */

export default function Allow(props) {
    const [rules, setRules] = React.useState(false);
    const user = useSelector(state => state.user);

    React.useEffect(() => {
        setRules(user?.role.rules?.map(e => e.label) || []);

    }, [user])


    if (isAllowed(props.on, rules))
        return props.children;

    return props.message || null;

}