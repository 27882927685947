import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core';

const marks = [
  {
    value: 0,
    label: 'Baixo',
  },
  {
    value: 1,
    label: 'Media',
  },
  {
    value: 2,
    label: 'alta',
  },
  {
    value: 3,
    label: 'Urgente',
  },
];

const useStyles = makeStyles({
  slider: {
    width: 200,
    marginLeft: 36,
    marginRight: 36,
    boxSizing: 'border-box'
  }
})

export default function TodoSlider({ value, onChange, ...props }) {
  const [priority, setPriority] = React.useState(0);
  const classes = useStyles();

  React.useEffect(() => {

    if (value)
      setPriority(value.asPriority())

  }, [value])

  return (
    <Slider
      {...props}
      className={classes.slider}
      value={priority}
      onChange={(e, value) => setPriority(value)}
      onChangeCommitted={onChange}
      max={3}
      step={0}
      marks={marks}
    />
  )
}