import React from 'react';
import { FixedSizeList } from 'react-window';
import { AutoSizer, List } from 'react-virtualized';

// const getItemSize = index => rowHeights[index];


export class VirtualMap extends React.Component {
    render() {
        const { children: Children, calcHeight = 0, collection, ...props } = this.props;

        return (
            <div style={{ height: `calc(100vh + ${calcHeight}px)` }}>
                <AutoSizer>
                    {({ width, height }) =>
                        <List {...props}
                            rowCount={collection.length}
                            width={width}
                            height={height}
                            // height={(window.innerHeight + calcHeight)}
                            rowRenderer={({ index, style, key }) =>
                                <div style={style} key={key}>
                                    <Children {...collection[index]} index={index} />
                                </div>
                            }
                        />
                    }
                </AutoSizer >
            </div>
        )
    }
};

const VirtualList = ({ children: Children, ...props }) => (
    <AutoSizer>
        {({ height, width }) =>
            <FixedSizeList
                height={width}
                width={height}
                {...props}
            >
                {({ index, style }) => (
                    <div style={style}>
                        <Children style={style} index={index} />
                    </div>
                )}
            </FixedSizeList >
        }
    </AutoSizer>
);

export default VirtualList;