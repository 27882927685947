import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import GroupAdd from '@material-ui/icons/GroupAdd'
import { connect } from 'react-redux';
import socket from '../../services/socket';

/**
 * @typedef {{event: 'task'|'´topic'|'toast', adminUsers: import('../../typeroots').AdminUser, toasts: import('../../typeroots').Toast[], uuids: string[] } & import('@material-ui/core').IconButtonProps } props
 * @extends {React.Component<props>}
 */

class TodosUserAssociate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            valueOfCurrents: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    /** @param {import('../../typeroots').OwnerModes} mode */
    handleChange = (id, mode) => {
        const ev = this.props.event;

        this.props.toasts.forEach(toast => {

            //rewrite mode
            if (toast.users.find(t => t.mode !== mode && t.user.id === id)) {
                return socket.emit(`${ev}:will:connect`, { uuid: toast.uuid, mode, users: { update: { id: id } } });
            }

            //disconnect
            if (toast.users.find(t => t.mode === mode && t.user.id === id) && this.isChecked(id, mode)) {
                return socket.emit(`${ev}:will:connect`, { uuid: toast.uuid, mode, users: { disconnect: { id: id } } });
            }

            //connect
            if (!toast.users.find(t => t.mode === mode && t.user.id === id)) {
                return socket.emit(`${ev}:will:connect`, { uuid: toast.uuid, mode, users: { connect: { id: id } } });
            }

        });
    };

    /** crazy but a I wanna every from selecteds uuids without duplicated */
    useMemoizedusers() {
        return this.props.toasts.reduce((acc, cur) => {
            cur.users.forEach(e => {
                if (!acc.map(e => e.user.id).includes(e.user.id)
                    && this.props.toasts.every(toast => toast.users.map(users => users.user.id).includes(e.user.id))) {

                    acc.push(e);
                }
            });
            return acc;

        }, []);
    }

    setOpen = () => {
        this.setState({ open: true, valueOfCurrents: [] });
    }

    setClose = () => {
        this.setState({ open: false, valueOfCurrents: [] });
    };

    isChecked = (id, mode) => {
        const valueOfCurrents = this.useMemoizedusers();
        return valueOfCurrents.findIndex(e => e.user.id === id && e.mode === mode) !== -1
    }

    // isActive = (id) => {
    //     return this.state.valueOfCurrents.findIndex(e => e.user.id === id) !== -1
    // }

    render() {

        const {
            adminUsers,
            dispatch,
            event,
            toasts,
            ...props
        } = this.props;

        return (
            <>
                <IconButton onClick={this.setOpen} {...props}>
                    <GroupAdd />
                </IconButton>
                <Dialog
                    id="todos-associate-menu"
                    open={this.state.open}
                    onClose={this.setClose}
                    fullWidth
                    maxWidth="xs"
                    fullScreen={window.innerWidth < 933}
                >
                    <DialogTitle id="confirmation-dialog-title">
                        Usuários do(s) projeto(s) ({toasts.length})
                    </DialogTitle>
                    <DialogContent dividers>
                        <List dense>
                            {(adminUsers).map((user, i) =>
                                Boolean(user.id !== props.user.id) &&
                                <div key={user.login}>
                                    <ListItem>
                                        <ListItemText primary={user.name} secondary={user.login} />
                                    </ListItem>
                                    <ListItem divider={(i + 1 < adminUsers.length)}>

                                        <FormControlLabel
                                            style={{ fontSize: 10, opacity: user.onLine ? 1 : .65 }}
                                            control={<Checkbox checked={this.isChecked(user.id, "OWNER")} />}
                                            onChange={e => this.handleChange(user.id, "OWNER")}
                                            value={user.id}
                                            label={"Proprietário"} />
                                        <FormControlLabel
                                            style={{ fontSize: 10, opacity: user.onLine ? 1 : .65 }}
                                            control={<Checkbox checked={this.isChecked(user.id, "EDITOR")} />}
                                            onChange={e => this.handleChange(user.id, "EDITOR")}
                                            value={user.id}
                                            label={"Executor"} />
                                        <FormControlLabel
                                            style={{ fontSize: 10, opacity: user.onLine ? 1 : .65 }}
                                            control={<Checkbox checked={this.isChecked(user.id, "VIEWER")} />}
                                            onChange={e => this.handleChange(user.id, "VIEWER")}
                                            value={user.id}
                                            label={"Observador"} />

                                    </ListItem>

                                </div>
                            )}

                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.setClose} color="primary">
                            Sair
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const stateToProps = state => ({
    adminUsers: state.adminUsers,
    user: state.user
})

export default connect(stateToProps, null)(TodosUserAssociate)