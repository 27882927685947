import Reducer from '../../Reducers/TasksReducers'
import { actions } from '../../Reducers/TodosFilter'
import TodosMap from '../../Reducers/TodosMap'

export const dispatch = {
    task: new Reducer('task'),
    topic: new Reducer('topic'),
    toast: new Reducer('toast')
}
export const mapDispatchToProps = (dispatcher) => {
    return {
        setStatus: (newStatusFilter) => dispatcher(actions.setMultipleStatus(newStatusFilter))
    }
}

/**@param {DefaultRootState} state*/
export const mapStateToProps = state => {

    const Todos = new TodosMap(state);

    // console.log(Todos);

    return {
        tasks: Todos.tasks.ListByState().sort((e, i) => new Date(e.finish) - new Date(i.finish)),
        topics: Todos.topics.ListByState().sort((e, i) => new Date(e.finish) - new Date(i.finish)),
        toasts: Todos.toasts.ListByState().sort((e, i) => new Date(e.finish) - new Date(i.finish)),
        groups: Todos.tasks.ByGroup(),
        counters:
        {
            status: Todos.filters.statusMultiple,
            total: Todos.Length(),
            cqs:  Todos.Count('CQ'),
            done: Todos.Count('DONE'),
            play: Todos.Count('PLAY'),
            stop: Todos.Count('STOP'),
            drop: Todos.Count('DROP'),
            news: Todos.Count('NEW'),
            peso: Todos.Sum('peso'),
            price: Todos.Sum('price')
        }
    }
}