import React from 'react'
import socket from '../../services/socket'
import useOpen from '../../services/useOpen'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import CloseIcon from '@material-ui/icons/Close'

import TodoContextMenu from "../../Components/TodoContextMenu"
import StatusIcon from '../../Components/StatusIcon'
import AvatarUserIcon from '../../Components/AvatarUserIcon'
import TopBar from '../../Components/ToastUtils/TopBar'
import ContextMenu from '../../Components/Menu'
import { DrawerList, Column, Vertical, FlexBox, SubHeader } from '../../Components/StyledComponents'
import { VirtualMap } from '../../Components/VirtualList'
import InputDialog from '../../Components/InputDialog'
import TodosUserAssociate from '../../Components/TodosUserAssociate'
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from './flux'

const initialAnchor = null;

/**@param {import('react-router').RouteChildrenProps & { tasks: Tasks, topics: Topics, toasts: Toasts }} props*/
function TodosToast(props) {
    const contextMenu = React.useRef({});

    /**@type {[string, function]} */
    const [input, setInput] = React.useState('');

    /**@type {[string[], function]} */
    const [checkbox, setCheckbox] = React.useState([]);

    const [change, setChange] = React.useState(false);

    const HandleCheckbox = React.useCallback((uuid) => checkbox.has(uuid) ? setCheckbox(checkbox.filter(e => e !== uuid)) : setCheckbox([...checkbox, uuid]), [checkbox]);

    /**@type {[{nodes: any element: Toast}, function]} */
    const [element, setElement] = React.useState({ nodes: initialAnchor, element: false });
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    /**@type {{topicId: string}} */
    const { topicId } = props.match.params;

    const handleBack = React.useCallback(() => {
        setElement(false);
        props.history.replace('/todos/task/' + props.match.params.taskId);

    }, [props])

    const [open, close] = useOpen(false, Boolean(topicId), handleBack);

    /**@type {import('../../typeroots').Topic} */
    const topic = React.useMemo(() => props.topics.ToList(e => e.id === Number(topicId)).first(), [props.topics, topicId])

    /**@type {import('../../typeroots').Toasts} */
    const toasts = React.useMemo(() => props.toasts.ToList(e => e.topicId === topic.id), [topic, props.toasts])

    const handleOpenMenu = React.useCallback((event, toast) => {
        if (event) event.preventDefault();

        if (toast.modes.includes("OWNER")) {
            setElement(toast);
            contextMenu.current.Open(event);
        } else {

            props.history.push(`/todos/edit/toast/${toast.uuid}`, { background: props.location });
        }

    }, [props]);

    const handleDeleteToast = () => {
        if (window.confirm('Deseja excluir este sub tópico')) {
            socket.emit("toast:will:delete", element);
        }

        setElement(false);
    }

    const HandleSubmitToast = (e) => {
        e.preventDefault();
        socket.emit("toast:will:create", { input: input, taskId: topic.taskId, topicId: topic.id, finish: topic.finish, begin: topic.begin })
        setInput('');
        e.target.reset();
    }

    const handleStatusChange = (newState) => () => socket.emit('toast:will:update', { uuid: element.uuid, status: newState });
    const handleChangePeso = (elm, value) => socket.emit(`toast:will:update`, { uuid: elm.uuid, peso: value, cq: value });

    const handleChangeMultiple = (prop) => {
        checkbox.map(uuid => socket.emit(`toast:will:update`, { uuid, ...prop }))
    }

    const handleDeleteMany = () => {
        if (window.confirm(`Confirma a exclusão de ${checkbox.length} items?`))
            checkbox.map(uuid => socket.emit(`toast:will:delete`, { uuid }))
    }

    const handleEdit = (toast) => {
        props.history.push(`/todos/edit/toast/${toast.uuid}`, { background: props.location });
    };

    const handleCloneToasts = React.useCallback(({ uuid }) => {
        socket.emit("toast:will:clone", { to: topic, from: uuid })

    }, [topic])

    const openComments = React.useCallback(() => {
        props.history.push(`/todos/comment/toast/${element.uuid}`, { background: props.location });

    }, [element, props.history, props.location]);


    /**@type {{every: string[], some: string[]}}*/
    const useMemoizedChecked = React.useMemo(() => {
        const Maps = toasts.filter(e => e.modes.includes("OWNER")).map(e => e.uuid);
        return {
            every: Maps.every(uuid => checkbox.includes(uuid)),
            some: Maps.some(uuid => checkbox.includes(uuid))
        }

    }, [toasts, checkbox]);


    const handleCheckSome = React.useCallback(() => {
        if (!useMemoizedChecked.every)
            setCheckbox([...toasts.filter(e => e.modes.includes("OWNER")).map(e => e.uuid)]);
        else
            setCheckbox([]);

    }, [toasts, useMemoizedChecked]);

    return (
        <React.Fragment>
            <ContextMenu ref={contextMenu} onClose={() => setElement(false)}>
                <TodoContextMenu
                    onClose={contextMenu.current.Close}
                    menuItem={toasts.find(e => e.id === element.id)}
                    onSelect={() => handleEdit(toasts.find(e => e.id === element.id))}
                    onComments={openComments}
                    onDelete={handleDeleteToast}
                    onChangeStatus={handleStatusChange}
                    onChangePeso={handleChangePeso}
                />
            </ContextMenu>
            <SwipeableDrawer
                disableSwipeToOpen
                anchor={isMobile ? 'bottom' : 'right'}
                open={open}
                onOpen={() => true}
                onClose={close}
            >
                <DrawerList size={850} dense={true}>
                    <SubHeader>
                        <Button onClick={handleBack} startIcon={<CloseIcon />}>
                            {topic?.title}
                        </Button>
                    </SubHeader>
                    <TopBar
                        setInput={setInput}
                        onSubmit={HandleSubmitToast}
                        placeholder={`Pesquisar/Adicionar`}
                        startActions={
                            <Checkbox
                                color="primary"
                                name="checked_control_all"
                                onChange={handleCheckSome}
                                checked={useMemoizedChecked.every}
                                indeterminate={useMemoizedChecked.some && !useMemoizedChecked.every}
                            />

                        }
                        actions={toasts.length > 0 &&
                            <FlexBox>
                                <Vertical orientation="vertical" flexItem />
                                <IconButton
                                    onClick={() => setChange({
                                        model: { uuid: String },
                                        header: `Clonar items de`,
                                        select: 'title',
                                        secondary: 'description',
                                        callback: handleCloneToasts,
                                        values: props.topics.filter(e => e.useAsModel === true)
                                    })}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                                <TodosUserAssociate
                                    disabled={checkbox.length === 0}
                                    event="toast"
                                    toasts={toasts.filter(toast => checkbox.includes(toast.uuid))}
                                />

                                <IconButton disabled={checkbox.length === 0} size="small" onClick={handleDeleteMany}>
                                    <DeleteSweepIcon />
                                </IconButton>

                                <IconButton disabled={checkbox.length === 0} size="small"
                                    onClick={() => setChange({
                                        model: { begin: x => x.toDate('date') },
                                        type: 'date',
                                        callback: handleChangeMultiple,
                                        header: `Datas iniciais de ${checkbox.length} subtópicos`,
                                        value: new Date().toDate('date')
                                    })}>
                                    <AccessTimeIcon />
                                </IconButton>
                                <IconButton disabled={checkbox.length === 0} size="small"
                                    onClick={() => setChange({
                                        model: { finish: x => x.toDate('date') },
                                        type: 'date',
                                        callback: handleChangeMultiple,
                                        header: `Datas finais de ${checkbox.length} subtópicos`,
                                        value: new Date().toDate('date')
                                    })}>
                                    <AccessAlarmIcon />
                                </IconButton>
                            </FlexBox>
                        }
                    />

                    {toasts.length === 0
                        ? <ListItem><Column primary="Lista Vazia" /></ListItem>
                        : <VirtualMap calcHeight={-112} rowHeight={61} collection={toasts}>
                            {({ index, ...toast }) => (
                                <ListItem
                                    button
                                    divider
                                    component={"label"}
                                    key={`dashboard-list-roast-${toasts[index].uuid}`}
                                    onContextMenu={(e) => handleOpenMenu(e, toast)}
                                >
                                    <ListItemIcon>
                                        <Checkbox disableRipple
                                            disabled={!Boolean(toasts[index].modes.includes("OWNER"))}
                                            checked={checkbox.has(toasts[index].uuid)}
                                            onChange={() => HandleCheckbox(toasts[index].uuid)}
                                            color="primary"
                                        />
                                    </ListItemIcon>

                                    <Column onClick={() => handleEdit(toast)} width='25%' style={{ textDecoration: toasts[index].status === "DROP" && 'line-through' }}
                                        primary={toasts[index].title}
                                        secondary={toasts[index].description}
                                    />

                                    {Boolean(toasts[index].group) && <>
                                        <Vertical orientation="vertical" flexItem />
                                        <Column
                                            display="desktop"
                                            max={150}
                                            primary={toasts[index].group}
                                        />
                                    </>}


                                    <Vertical orientation="vertical" flexItem />
                                    <Column
                                        display="desktop"
                                        max={150}
                                        primary={`peso:${toasts[index].peso}`}
                                        secondaryTypographyProps={{ variant: 'caption' }}
                                        secondary={`${toasts[index].begin.ptBR()} - ${toasts[index].finish.ptBR()}`}
                                    />

                                    <Vertical orientation="vertical" flexItem />
                                    <Column max={90} overflow="auto"
                                        primary={
                                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                                <AvatarUserIcon users={toasts[index].users} />
                                            </Box>
                                        }
                                    />

                                    <Vertical orientation="vertical" flexItem />

                                    <ListItemSecondaryAction >
                                        <Tooltip title={`status ${toasts[index].status}`}>
                                            <IconButton onClick={(e) => handleOpenMenu(e, toast)}>
                                                <Badge badgeContent={toasts[index].comments?.length}>
                                                    <StatusIcon status={toasts[index].status} />
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </VirtualMap>
                    }
                </DrawerList>
            </SwipeableDrawer>

            <InputDialog {...change} onClose={() => setChange(false)} />
        </React.Fragment>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(TodosToast)