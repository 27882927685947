import React from 'react'
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { dispatch } from '../../Reducers/Ingredients';
import DialogActions from '@material-ui/core/DialogActions'
import { useSelector } from 'react-redux'

export default dispatch;

export function ingredientType(string) {
    return string === 'QTD' ? 'quantidade' : string === 'G' ? 'g' : 'ml'
}

export function UniqIngredientsList({ icon: Icon, ...props }) {

    return (
        props.list.map(ingredient => {
            const { title, group } = ingredient;

            return (
                <ListItem divider button key={title + group} onClick={e => props.onClick(ingredient)}>
                    <ListItemIcon>
                        <Icon {...ingredient} />
                    </ListItemIcon>
                    <ListItemText secondary={title} />
                </ListItem>
            )
        }
        )
    )
}

export const ingredientTypeByKey = (key) => {
    switch (key) {
        case 'price': return 'text';
        case 'validity': return 'date';
        case 'validityAlert': return 'date';
        default: return 'number';
    }
}

export const ingredientLabelByKey = (key) => {
    switch (key) {
        case 'price': return 'Preço';
        case 'amount': return 'Quantidade';
        case 'amountAlert': return 'Alerta Quantidade';
        case 'validity': return 'Validade';
        case 'validityAlert': return 'Alerta Validade';
        default: return '';
    }
}

export function IncrementFields(props) {
    const [values, setValues] = React.useState({
        price: '',
        amount: 0,
        amountAlert: 0,
        validity: '',
        validityAlert: ''
    });

    const handleValues = (prop) => e => setValues({ ...values, [prop]: e.target.value });


    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch.Increment({ ...values, uuid: props.uuid })

        props.close();
    }

    return (
        <form onSubmit={handleSubmit}>
            {Object.keys(values).map(key =>
                <ListItem key={key}>
                    <ListItemIcon />
                    <TextField
                        required fullWidth
                        value={values[key]}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Digite o valor"
                        type={ingredientTypeByKey(key)}
                        label={ingredientLabelByKey(key)}
                        onChange={handleValues(key)}
                        InputProps={{ disableUnderline: true }}
                    />

                </ListItem>
            )}
            <DialogActions>
                <Button
                    onClick={props.close}
                    size="small" >Fechar
                </Button>
                <Button
                    type="submit"
                    size="small"
                    color="secondary"
                    variant="contained" >Salvar
                </Button>
            </DialogActions>
        </form>
    )
}


export function AddIngredients() {
    const [values, setValues] = React.useState({ title: '', type: 'QTD', item: null });

    /**@type {[{title: string, id: number}]} */
    const topics = useSelector(reducer => {
        const cartes = reducer.task.filter( task => task.status === "CARTE" ).map(task => task.id);
        
            return reducer.topic.filter(topic =>  cartes.indexOf(topic.taskId) !== -1 ).Distinct(['title', 'id'])
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch.Create(values);
        setValues({ title: '', type: 'QTD', item: null });
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                fullWidth
                value={values.title}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    disableUnderline: true
                }}
                placeholder="Digitar nome do grupo de ingredientes"
                label="Adicionar prateleira"
                onChange={e => setValues({ ...values, title: e.target.value })}
            />

            <Collapse in={Boolean(values.title.length > 3)}>
                <TextField
                    fullWidth
                    select
                    InputProps={{
                        disableUnderline: true
                    }}
                    margin="normal"
                    value={values.type}
                    label="Opções de tipo de contagem"
                    onChange={e => setValues({ ...values, type: e.target.value, item: null })}
                >
                    <MenuItem value="G">Gramas</MenuItem>
                    <MenuItem value="ML">Ml</MenuItem>
                    <MenuItem value="QTD">Quantidade</MenuItem>
                </TextField>

                {Boolean(values.type === 'QTD') &&
                    <TextField
                        fullWidth
                        select
                        InputProps={{
                            disableUnderline: true
                        }}
                        margin="normal"
                        value={values.item}
                        label="Qual item será contado"
                        onChange={e => setValues({ ...values, item: e.target.value })}
                    >
                        {topics.map( topic => <MenuItem value={topic.id}>{topic.title}</MenuItem> )}
                        
                    </TextField>
                }


                <DialogActions>
                    <Button
                        onClick={e => setValues({ ...values, title: '' })}
                        size="small" >Fechar
                    </Button>
                    <Button
                        type="submit"
                        size="small"
                        color="secondary"
                        variant="contained" >Salvar
                    </Button>
                </DialogActions>
            </Collapse>
        </form>
    )
}