import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Slide, TextField } from '@material-ui/core';
import { InputField } from '../StyledComponents';

// import { Container } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * 
 * @param {{model: {prop: <callback>}, callback: (), onClose: (), value: String, header: string, multiline:Boolean, select:string}} props 
 * 
 * @example
 *     const [change, setChange] = React.useState(false);
 * 
 *     onClick={() => setChange({ model: { price: String }, callback: handleEditableToastPrice(props.item), header: `Preço de ${props.item.title}`, value: props.item.price })}
 *     <InputDialog {...change} onClose={() => setChange(false)} />
 */

function InputDialog(props) {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [input, setInput] = React.useState(props.value);

    React.useEffect(() => {
        if (props)
            setInput(props.value);

    }, [props]);

    /**for input only */
    const handleInput = React.useCallback(e => {
        const key = Object.keys(props.model)[0];
        const val = props.model[key](e.target.value);

        setInput(val);

    }, [props])

    /**for select only */
    const handleSelect = React.useCallback(value => {
        const key = Object.keys(props.model)[0];
        const val = props.model[key](value);

        setInput(val);

    }, [props])

    const onOk = () => {
        const key = Object.keys(props.model)[0];
        props.callback({ [key]: input });
        return props.onClose();
    }

    const onReset = () => {
        // const key = Object.keys(props.model)[0];
        // props.callback({[key]: props.value});
        return props.onClose();
    }

    return (
        <Dialog TransitionComponent={Transition} fullScreen={isMobile} fullWidth maxWidth="xs" open={Boolean(props.callback)} onClose={onReset}>
            <DialogTitle>
                {props.header}
            </DialogTitle>
            <DialogContent dividers>
                {Boolean(props.select)
                    ? <List>
                        {props.values.map(item =>
                            <ListItem divider button key={item[props.select]} selected={input === item[ Object.keys(props.model)[0] ]}>
                                <ListItemText 
                                    onClick={() => handleSelect(item[ Object.keys(props.model)[0] ])}
                                    primary={item[props.select]}
                                    secondary={item[props.secondary]} />
                            </ListItem>
                        )}
                    </List>

                    : <InputField>
                        <TextField
                            fullWidth
                            type={props.type || 'text'}
                            multiline={props.multiline}
                            rows={5}
                            autoFocus
                            InputProps={{
                                disableUnderline: true
                            }}
                            defaultValue={input}
                            label={props.label}
                            placeholder={props.placeholder}
                            onChange={handleInput}
                        />
                    </InputField>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onReset}>Cancelar</Button>
                <Button disabled={!input} onClick={onOk}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InputDialog;