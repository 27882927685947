import React from 'react';
import { connect } from "react-redux"
import TodosFilter from '../../Reducers/TodosFilter'
import AvatarAddIcon from '../../Components/AvatarAddIcon';
import { UserAvatar } from '../../Components/AvatarUserIcon';
import List from '@material-ui/core/List'
import { ListIcon } from './styles'
import { ToolbarTitle, DrawerSecondary, ToolbarIconButton } from '../../Components/StyledComponents'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Badge from '@material-ui/core/Badge';
import PropTypes from "prop-types";

import ArrowBack from '@material-ui/icons/ArrowBack'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import Folder from '@material-ui/icons/Folder';
import FolderOpen from '@material-ui/icons/FolderOpen';


import Allow from '../../Components/Allow';
import HideOnScroll from '../../Components/HideOnScroll';
import TodosMap from '../../Reducers/TodosMap';

class Todos extends React.Component {

    componentDidMount() {
        this.props.setUsers([this.props.current_user]);
    }

    componentDidUpdate() {
        if (!this.props.filtered_users || this.props.filtered_users.length === 0) {
            this.props.setUsers([this.props.current_user]);
        }
    }

    handleSetUser = (action, id) => {
        const user = this.props.default_users.find(e => e.id === id);
        const result = action.connect ? this.props.filtered_users.concat(user) : this.props.filtered_users.filter(e => e.id !== id);
        this.props.setUsers(result);
    }

    handleUniqueUser = (id) => () => {
        this.props.setUsers(this.props.filtered_users.length === 1 && this.props.filtered_users?.[0].id === id ? [...this.props.default_users] : [this.props.default_users.find(e => e.id === id)]);
    }

    render() {

        return (
            <div>
                <DrawerSecondary anchor="right" variant="permanent">
                    <List>

                        <ListIcon>
                            <IconButton
                                onClick={() => Boolean(this.props.location.pathname === "/todos/alerts")
                                    ? this.props.history.goBack()
                                    : this.props.history.push('/todos/alerts')
                                }>
                                <Badge color="secondary" badgeContent={this.props.counters.play} >
                                    <Badge color="error" badgeContent={this.props.counters.news} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                        {Boolean(this.props.location.pathname === "/todos/alerts") && <AccountBalanceWalletIcon />}
                                        {Boolean(this.props.location.pathname !== "/todos/alerts") && <AccountBalanceWalletOutlinedIcon />}
                                    </Badge>
                                </Badge>
                            </IconButton>
                        </ListIcon>


                        <ListIcon>
                            <IconButton
                                onClick={() => Boolean(this.props.location.pathname === "/todos/files")
                                    ? this.props.history.goBack()
                                    : this.props.history.push('/todos/files', { background: this.props.history.location })
                                }>
                                {Boolean(this.props.location.pathname === "/todos/files") && <Folder />}
                                {Boolean(this.props.location.pathname !== "/todos/files") && <FolderOpen />}
                            </IconButton>
                        </ListIcon>

                        <Allow on="TaskAssociate">
                            <ListIcon>
                                <AvatarAddIcon current={this.props.filtered_users}
                                    onChange={this.handleSetUser}
                                    secondaryActionClick={this.handleUniqueUser}
                                    includeself
                                />
                            </ListIcon>

                            {this.props.filtered_users.map((e, i) =>
                                <ListIcon key={i} onClick={() => this.handleSetUser({ disconnect: true }, e.id)}>
                                    <UserAvatar user={e} />
                                </ListIcon>
                            )}

                        </Allow>
                    </List>
                </DrawerSecondary>
                <Hidden mdUp>
                    <HideOnScroll>
                        <AppBar position="fixed" style={{
                            boxShadow: 'none'
                        }}>
                            <Toolbar>
                                <ToolbarIconButton onClick={() => this.props.history.push('/todos/task')} edge="start" color="inherit" aria-label="menu">
                                    <ArrowBack />
                                </ToolbarIconButton>
                                <ToolbarTitle variant="h6">
                                    Projetos
                                </ToolbarTitle>
                                <ToolbarIconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() => Boolean(this.props.location.pathname === "/todos/files")
                                        ? this.props.history.goBack()
                                        : this.props.history.push('/todos/files', { background: this.props.history.location })
                                    }>
                                    {Boolean(this.props.location.pathname === "/todos/files") && <Folder />}
                                    {Boolean(this.props.location.pathname !== "/todos/files") && <FolderOpen />}
                                </ToolbarIconButton>
                                <ToolbarIconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() => Boolean(this.props.location.pathname === "/todos/alerts")
                                        ? this.props.history.goBack()
                                        : this.props.history.push('/todos/alerts')
                                    }>
                                    {Boolean(this.props.location.pathname === "/todos/alerts") && <AccountBalanceWalletIcon />}
                                    {Boolean(this.props.location.pathname !== "/todos/alerts") && <AccountBalanceWalletOutlinedIcon />}
                                </ToolbarIconButton>
                                <Allow on="TaskAssociate">
                                    <AvatarAddIcon size="small" color="inherit"
                                        current={this.props.filtered_users}
                                        onChange={this.handleSetUser}
                                        secondaryActionClick={this.handleUniqueUser}
                                        includeself />
                                </Allow>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                </Hidden>
                {this.props.children}
            </div>
        )
    }
}

Todos.defaultProps = {
    current_user: PropTypes.shape({
        id: PropTypes.number,
        login: PropTypes.string,
        name: PropTypes.string,
        onLine: PropTypes.bool
    }),
    filtered_users: PropTypes.shape({
        id: PropTypes.number,
        login: PropTypes.string,
        name: PropTypes.string,
        onLine: PropTypes.bool
    }),
    default_users: PropTypes.shape({
        id: PropTypes.number,
        login: PropTypes.string,
        name: PropTypes.string,
        onLine: PropTypes.bool
    })
}


export default connect(
    (state) => {

        const Todos = new TodosMap(state);

        return {
            counters: {
                news: Todos.Count("NEW"),
                play: Todos.Count("PLAY")
            },
            current_user: { id: state.user.id, login: state.user.login, name: state.user.name, image: state.user.image },
            filtered_users: state.todosFilter.users,
            default_users: state.adminUsers.map(e => ({
                id: e.id,
                login: e.login,
                name: e.name,
                onLine: e.onLine,
                image: e.image
            })
            )
        }
    },
    (dispatch) => ({
        setUsers: (users) => dispatch(TodosFilter.setUsers(users))
    })
)(Todos)