import React, { useCallback, useState } from 'react';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AvatarUserIcon, { UploadButton, UploadAvatar, StyledGroupAvatar } from '../../Components/AvatarUserIcon';
import TodoSlider from '../../Components/TodoSlider';
import socket from '../../services/socket'

import CloseIcon from '@material-ui/icons/Close';
import AlarmIcon from '@material-ui/icons/Alarm';

import { DrawerList, SubHeader, FluidBox } from '../../Components/StyledComponents';
import { get, upload } from '../../services/Auth';
import { debounce } from "lodash"

import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ListItemTitle } from './styles';
import Sidenav from '../../Components/Sidenav';
import Input from '../../Components/Input';
import useOpen from '../../services/useOpen';
import StatusIcon from '../../Components/StatusIcon';
import { mapStateToProps } from './flux';

/**
 * @param {import('react-router').RouteChildrenProps & import('react-redux').DefaultRootState } props
 */
const TodosEditDrawer = (props) => {

    /**@type {{ mode: 'task'|'topic'|'toast', uuid: string }} params*/
    const { mode, uuid } = useParams();


    const [anchorMenuEl, setAnchorMenuEl] = useState([null, null]);

    /**@type {Task|Topic|Toast} */
    const task = React.useMemo(() => {
        return props[mode].find(e => e.uuid === uuid);

    }, [props, mode, uuid])

    const [open, close] = useOpen(false, props.location?.state?.background, props.history.goBack);

    const handleUpdate = debounce((objectWillUpdate) => {
        socket.emit(`${mode}:will:update`, { id: task.id, uuid: task.uuid, ...objectWillUpdate });

    }, 3000);


    const handleUpdateNoDebounce = debounce((objectWillUpdate) => {
        socket.emit(`${mode}:will:update`, { id: task.id, uuid: task.uuid, ...objectWillUpdate });

    }, 100);

    const handleFireUpload = useCallback(
        event =>
            upload(`/gallery/upload/todo/${mode}/${task.uuid}`, event.target)
                .then(() => socket.emit(`${mode}:will:change`, { uuid: task.uuid, files: { select: { name: true, file: true } } }))
                .then(() => event = null)
        , [task, mode]);

    const handleDeleteUpload = useCallback(() => {
        get(`/gallery/delete/${anchorMenuEl[1].file}`)
            .then(() => socket.emit(`${mode}:will:change`, { uuid: task.uuid, files: { select: { name: true, file: true } } }))
            .then(() => handleMenuClose());
    }, [task, mode, anchorMenuEl])

    const handleConnectUser = useCallback(data => {
        socket.emit(`${mode}:will:connect`, { id: task.id, uuid: task.uuid, users: data });
    }, [task, mode])


    const handleMenuClick = (event, target) => {
        event.preventDefault();
        setAnchorMenuEl([event.currentTarget, target]);
    };

    const handleMenuClose = () => {
        setAnchorMenuEl([null, null]);
    };

    const availableUsers = React.useMemo(() => {
        if (!task) return [];

        if (mode === 'topic')
            return props.task.find(e => e.id === task.taskId).users.map(e => e.user);

        if (mode === 'toast')
            return props.topic.find(e => e.id === task.topicId).users.map(e => e.user);

        return false;

    }, [mode, task, props])

    if (!task) return (
        <Sidenav open={open} onClose={close} onOpen={() => true}>
            <DrawerList size={650}>
                <ListItem>
                    <ListItemText primary="Não disponível" secondary="Solicite permissão de escrita" />
                </ListItem>
            </DrawerList>
        </Sidenav>);

    return (
        <Sidenav open={open} onClose={close} onOpen={() => true}>
            <Menu anchorEl={anchorMenuEl[0]} open={Boolean(anchorMenuEl[0])} onClose={handleMenuClose}>
                <MenuItem disabled>{anchorMenuEl[1]?.name}</MenuItem>
                <MenuItem disabled>Download</MenuItem>
                <MenuItem disabled>Galeria</MenuItem>
                <MenuItem onClick={handleDeleteUpload}>Excluir</MenuItem>
            </Menu>

            <DrawerList size={650}>
                <SubHeader>
                    <div>Editar Detalhes <small>(modos: {task.modes.join('|')})</small></div>
                    <div>
                        <Tooltip title="Voltar para lista">
                            <IconButton size="small" onClick={close}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </SubHeader>

                {task.modes.includes("OWNER") ? <>

                    <ListItem component="label">
                        <Input fullWidth
                            variant="outlined"
                            size="small"
                            label="Título"
                            value={task.title}
                            onSubmit={input => handleUpdateNoDebounce({ title: input })} />
                    </ListItem>

                    <ListItem divider component="label">
                        <Input fullWidth
                            variant="outlined"
                            size="small"
                            label="Descrição"
                            value={task.description}
                            onSubmit={input => handleUpdateNoDebounce({ description: input })} />
                    </ListItem>

                    <SubHeader> Configurar </SubHeader>
                    <ListItem
                        divider
                        button
                        onClick={() => props.history.push("#dateRangePicker", {
                            task,
                            mode,
                            ...props.location.state
                        })
                        }>
                        <ListItemTitle><AlarmIcon /></ListItemTitle>
                        <ListItemText primary={`${task.begin?.ptBR()} - ${task.finish?.ptBR()}`} secondary="Inicio/Previsao" />
                    </ListItem>

                    {Boolean(task.hasOwnProperty('group')) &&
                        <ListItem divider component="label">
                            <ListItemTitle variant="subtitle2" color="primary" >Grupo</ListItemTitle>
                            <Input disableUnderline placeholder={"Associar a um"} value={task.group} onSubmit={input => handleUpdateNoDebounce({ group: input.Capitalize() || null })} />
                        </ListItem>
                    }


                    {Boolean(task.hasOwnProperty('peso')) &&
                        <ListItem divider component="label">
                            <ListItemTitle variant="subtitle2" color="primary" >Peso</ListItemTitle>
                            <Input disableUnderline placeholder={"Informar valor"} value={task.peso} onSubmit={input => handleUpdateNoDebounce({ peso: Number(input || 0) })} />
                        </ListItem>
                    }

                    <ListItem divider>
                        <ListItemTitle variant="subtitle2" color="primary" >Prioridade</ListItemTitle>
                        <TodoSlider value={task.priority} onChange={(e, value) => handleUpdate({ priority: value.asPriority() })} />
                    </ListItem>

                </> : <>
                    <ListItem>
                        <ListItemText primary={task.title} secondary={task.description || "Sem Descrição"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={`${task.begin?.ptBR()} - ${task.finish?.ptBR()}`} secondary="Inicio/Previsao" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={task.group || "Não agrupado"} secondary={"Grupo"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={task.peso || 0} secondary={"Peso"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={task.priority} secondary={"Prioridade"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={task.status.asStatusText()} secondary={"Status"} />
                    </ListItem>

                </>}

                {Boolean(task.modes.includes("OWNER") || task.modes.includes("EDITOR")) &&
                    <ListItem divider>
                        <ListItemTitle variant="subtitle2" color="primary" >Status</ListItemTitle>
                        {
                            ["STOP", "PLAY", "DONE", "CQ", "DROP"].map(st =>
                                <Tooltip title={st.asStatusText()} disabled={task.status === st} key={`status-buttons-${st}`}>
                                    <IconButton color={task.status !== st ? 'secondary' : 'primary'} component="span" onClick={() => handleUpdate({ status: st })} >
                                        <StatusIcon status={st} />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </ListItem>
                }

                <SubHeader> Participantes do projeto {task.users.length}</SubHeader>
                <ListItem divider>
                    <FluidBox>
                        <AvatarUserIcon
                            includeSelf
                            onChange={Boolean(task.modes.includes("OWNER")) && handleConnectUser}
                            options={availableUsers}
                            users={task.users || []}
                        />
                    </FluidBox>
                </ListItem>

                {task.files && <SubHeader> Arquivos {task.files.length}</SubHeader>}
                {task.files && <ListItem divider>
                    <FluidBox>
                        <UploadButton onFireUpload={e => handleFireUpload(e)} />
                        <StyledGroupAvatar>
                            <>
                                {task.files.map(file =>
                                    <UploadAvatar onContextMenu={e => Boolean(task.modes.includes("OWNER")) && handleMenuClick(e, file)} key={file.file} title={file.name} file={file.file} />
                                )}
                            </>
                        </StyledGroupAvatar>
                    </FluidBox>
                </ListItem>
                }

            </DrawerList >
        </Sidenav>
    )
}

export default connect(mapStateToProps, null)(TodosEditDrawer);