import React from 'react';
import { dispatch as mapDispatch } from './flux.js';
import IconButton from '@material-ui/core/IconButton';
import StatusIcon from '../../Components/StatusIcon/index.js';
// import { CommentRounded, Edit, List } from '@material-ui/icons';
// import { Container } from './styles';

/**@param {Task|Topic|Toast} props */
function InnerOptions(props) {

  const handleStatus = React.useCallback((status) => {
    mapDispatch[props.type].dispatch.willUpdate({ uuid: props.uuid, status: status });

  }, [props]);

  const ContextIsEditable = React.useMemo(() => {
    return (props.modes?.includes('OWNER') || props.modes?.includes('EDITOR'))

  }, [props]);

  // const ContextIsOwner = React.useMemo(() => {
  //   return (props.modes?.includes('OWNER'))

  // }, [props]);


  return Boolean(props.status !== 'CQ') ? (
    <>
      {Boolean(ContextIsEditable && props.status !== 'STOP') &&
        <IconButton onClick={() => handleStatus("STOP")}>
          <StatusIcon style={{ color: "STOP".asStatusColor() }} status="STOP" />
        </IconButton>
      }

      {Boolean(ContextIsEditable && ['STOP', 'DONE', 'DROP'].includes(props.status)) &&
        <IconButton onClick={() => handleStatus("PLAY")}>
          <StatusIcon style={{ color: "PLAY".asStatusColor() }} status="PLAY" />
        </IconButton>
      }

      {Boolean(ContextIsEditable && props.status === 'PLAY') &&
        <IconButton onClick={() => handleStatus("DONE")}>
          <StatusIcon style={{ color: "DONE".asStatusColor() }} status="DONE" />
        </IconButton>
      }
      {Boolean(ContextIsEditable && props.status === 'PLAY') &&
        <IconButton onClick={() => handleStatus("DROP")}>
          <StatusIcon style={{ color: "DROP".asStatusColor() }} status="DROP" />
        </IconButton>
      }
    </>
  ) : <label>CQ</label>
}

export default InnerOptions;