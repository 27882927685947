import { styled } from "@material-ui/core/styles";
import StyledComponents from "../StyledComponents";

export const InputRoot = StyledComponents('div')(props => ({
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
        paddingBottom:props.gutter ? props.theme.spacing(2) : 'inherit',
        marginRight: props.theme.spacing(2),
        marginLeft: props.theme.spacing(2),
        width: props.items ? (100 / props.items) + '%' : '100%',
    }
}))

export const InputFooter = styled('div')({
    display: 'flex',
    padding: 4,
    justifyContent: 'flex-end'
})