import Cache from "../Compress";
import update from 'immutability-helper'
import socket from "../../services/socket";

const initialState = Cache.get('timelines') || {
    tasks: {},
    topics: {},
    toasts: {}
};

export const TASK = 'task:timeline';
export const TOPIC = 'topic:timeline';
export const TOAST = 'toast:timeline';

export const TASK_COMMENTS_WILL_CREATE = 'task:comments:will:create';
export const TOPIC_COMMENTS_WILL_CREATE = 'topic:comments:will:create';
export const TOAST_COMMENTS_WILL_CREATE = 'toast:comments:will:create';


function Timeline() { };

Timeline.actions = {
    taskDidMount: (data) => ({ type: TASK, data }),
    topicDidMount: (data) => ({ type: TOPIC, data }),
    toastDidMount: (data) => ({ type: TOAST, data }),

    taskCommentsWillCreate: (data) => ({ type: TASK_COMMENTS_WILL_CREATE, data }),
    topicCommentsWillCreate: (data) => ({ type: TOPIC_COMMENTS_WILL_CREATE, data }),
    toastCommentsWillCreate: (data) => ({ type: TOAST_COMMENTS_WILL_CREATE, data })

};

Timeline.CreateEventListener = function (store) {
    socket.on(TASK, data => store.dispatch(Timeline.actions.taskDidMount(data)))
    socket.on(TOPIC, data => store.dispatch(Timeline.actions.topicDidMount(data)))
    socket.on(TOAST, data => store.dispatch(Timeline.actions.toastDidMount(data)))

    socket.on(TASK_COMMENTS_WILL_CREATE, data => store.dispatch(Timeline.actions.taskCommentsWillCreate(data)))
    socket.on(TOPIC_COMMENTS_WILL_CREATE, data => store.dispatch(Timeline.actions.topicCommentsWillCreate(data)))
    socket.on(TOAST_COMMENTS_WILL_CREATE, data => store.dispatch(Timeline.actions.toastCommentsWillCreate(data)))

};

Timeline.reducer = (state = initialState, action) => {
    switch (action.type) {
        case TASK:
            return update(state, {
                tasks: { $set: action.data }
            })
        case TASK_COMMENTS_WILL_CREATE:

            if (!state.tasks[action.data.id])
                state = update(state, {
                    tasks: {
                        [action.data.id]: {
                            $set: {
                                comments: [],
                                timeline: []
                            }
                        }
                    }
                })

            return update(state, {
                tasks: {
                    [action.data.id]: {
                        comments: {
                            $push: [action.data]
                        }
                    }
                }
            })

        case TOPIC:
            return update(state, {
                topics: { $set: action.data }
            })

        case TOPIC_COMMENTS_WILL_CREATE:

            if (!state.topics[action.data.id])
                update(state, {
                    topics: {
                        [action.data.id]: {
                            $set: {
                                comments: [],
                                timeline: []
                            }
                        }
                    }
                })

            return update(state, {
                topics: {
                    [action.data.id]: {
                        comments: {
                            $push: [action.data]
                        }
                    }
                }
            })

        case TOAST:
            return update(state, {
                toasts: { $set: action.data }
            })

        case TOAST_COMMENTS_WILL_CREATE:

            if (!state.topics[action.data.id])
                update(state, {
                    topics: {
                        [action.data.id]: {
                            $set: {
                                comments: [],
                                timeline: []
                            }
                        }
                    }
                })

            return update(state, {
                toasts: {
                    [action.data.id]: {
                        comments: {
                            $push: [action.data]
                        }
                    }
                }
            })

        default:
            return state;
    }
}

export default Timeline;