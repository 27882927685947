import React from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import AvatarAddIcon from "../AvatarAddIcon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { styled, withTheme, withStyles } from '@material-ui/core/styles'
import ReactImageGallery from 'react-image-gallery';
import FolderIcon from '@material-ui/icons/Folder';
import "react-image-gallery/styles/css/image-gallery.css";
import { host_gallery } from '../../services/setproxy';

export const StyledGroupAvatar = withStyles(
    (theme) => ({
        root: {
            display: 'flex',
            width: 'auto',
            flexGrow: 1,
            paddingTop: 5,
            paddingBottom: 5,
            '& > *': {
                boxShadow: theme.shadows[3],
                marginRight: theme.spacing(-1),
            },
        }
    })

)(ListItemAvatar)

export const StyledAvatar = styled(withTheme(Avatar))(
    (props) => ({
        width: props.theme.spacing(4.5),
        height: props.theme.spacing(4.5),
        filter: props.active ? 'drop-shadow(0px 0px 3px crimson)' : 'none'
    })
)

/**@param {{onFireUpload: Function, multiple: Boolean} & import('@material-ui/core').IconButtonProps} param */
export const UploadButton = ({ onFireUpload, multiple = true, ...props }) => {

    const ref = React.useRef({});

    const fire = (e) => {
        onFireUpload(e);

        ref.current.value = null;
    }

    return (
        <div>
            <label>
                <input ref={ref} style={{ display: 'none' }} multiple={multiple} type="file" onChange={fire} />
                <IconButton {...props} color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label>
        </div>
    )
};

export const Gallery = ({ items, ...props }) => {
    const array = items.map(file => {
        return ({ original: host_gallery+`${file.file}`, thumbnail: host_gallery+`${file.file}_thumb` })
    });

    return (
        <ReactImageGallery {...props} items={array} />
    )
}

/**@param {{file: String, title: String, & import('@material-ui/core').AvatarProps}} param */
export const UploadAvatar = ({ file, title, ...props }) => {
    return !file ? null : (
        <React.Fragment>
            <Tooltip title={title}>
                <StyledAvatar {...props} src={host_gallery+`${file}_thumb`} >
                    <FolderIcon />
                </StyledAvatar>
            </Tooltip>
        </React.Fragment>
    )
};

/**@param {{user: import('../../typeroots').AdminUser, & import('@material-ui/core').AvatarProps}} param */
export const UserAvatar = ({ user = {}, hide = false, ...props }) => {
    return user && (
        <React.Fragment>
            <Tooltip title={user.name || 'Sem usuario'}>
                <StyledAvatar {...props} active={user.onLine ? 'true' : null} alt={user.name} src={host_gallery+user.image+'_thumb'} />
            </Tooltip>
        </React.Fragment>
    )
};

export default function AvatarUserIcon(props) {

    return (
        <>
            {Boolean(props.onChange) && <AvatarAddIcon includeself={props.includeSelf} options={props.options} current={props.users} onChange={props.onChange} />}
            <StyledGroupAvatar>
                <React.Fragment>
                    {props.users.map((user, i) => <UserAvatar key={'avatar-user-group-' + i} user={user.user} />)}
                </React.Fragment>
            </StyledGroupAvatar>
        </>
    )
}