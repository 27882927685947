import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Dialog from '@material-ui/core/Dialog';

export default class SwipeableModal extends React.PureComponent {
    
    constructor(props) {
        
        super(props);

        this.state = {
            isMobile: false
        }

        this.resizeScreen = this.resizeScreen.bind(this);
    }


    componentDidMount() {

        window.addEventListener("resize", this.resizeScreen);

    }


    resizeScreen() {
        if (window.innerWidth < 933) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }

    }
    render() {

        return this.state.isMobile
            ? <SwipeableDrawer disableSwipeToOpen onOpen={() => true} isMobile={this.state.isMobile} anchor={"bottom"} {...this.props} />
            : <Dialog {...this.props} />
    }
}