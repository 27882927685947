import React from 'react';
import { connect } from 'react-redux';
import { host_gallery } from '../../services/setproxy';
import socket from '../../services/socket';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography'
import { SubHeader } from '../../Components/StyledComponents';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SaveIcon from '@material-ui/icons/SaveAltOutlined';
import DoneIcon from '@material-ui/icons/Done';
import MailIcon from '@material-ui/icons/Mail';

// import Chart from "react-google-charts";

const initialState = {
  name: '',
  image: '',
  login: '',
  roleId: '',
  email: '',
  validity: '',
  loading: true
}
const defaultInputProps = {
  fullWidth: true,
  placeholder: "Não Informado",
  InputProps: { disableUnderline: true },
  onFocus: e => e.target.select()
}

/**@type {import('.').MyTeamsCurrent} */
const MyTeamsCurrent = (props) => {
  const [btnkey, setBtnkey] = React.useState(false);
  const [timelineview, setTimelineview] = React.useState(false);

  const [user, setUser] = React.useState(initialState);
  const set = (prop) => (e) => setUser({ ...user, [prop]: e.target.value });
  const setGroup = (e) => socket.emit('admin:users:will:connect', { user, roleId: e.target.value });

  React.useEffect(() => {

    if (props.user) {
      setUser({ ...props.user, validity: props.user.validity.toDate('date'), loading: false });
    }

  }, [props.user]);

  const applychanges = () => socket.emit('admin:users:will:update', { user: props.user, data: user });

  /**@typedef {Boolean} TestMail*/
  const TestMail = React.useMemo(() => String(user?.email).testEmail(), [user.email]);
  /**@typedef {Boolean} TestValidation*/
  const TestValidation = React.useMemo(() => Boolean(props.user?.emailValidation), [props.user])

  const canapplychanges = React.useMemo(() => {

    return (user?.validity !== props.user?.validity.toDate('date') ||
      user?.name !== props.user?.name ||
      user?.email !== props.user?.email ||
      user?.login !== props.user?.login) ? true : false;

  }, [props.user, user]);

  const passwordrecovery = () => {
    setBtnkey(true);

    if (props.user.emailValidation) {
      socket.emit('admin:users:recoveryPassword', props.user);
    } else {
      socket.emit('admin:users:sendMailValidation', { user: props.user });
    }

  }

  const GetColor = React.useCallback((ent) => {
    return ent === 'task' ? 'cyan' : ent === 'topic' ? 'yellow' : 'orange'

  }, [])

  return (<>
    <ListItem>
      <ListItemIcon>
        <Avatar variant="rounded" style={{ width: 66, height: 66, marginRight: 23 }} alt={props.user?.name} src={host_gallery + user?.image} />
      </ListItemIcon>
      <ListItemText primary={props.user?.name} secondary={props.user?.login} />
      <ListItemSecondaryAction>

        {String(props?.user?.email).testEmail() &&
          <IconButton disabled={btnkey} color="primary" style={{ marginRight: 16 }} onClick={passwordrecovery}>
            <Tooltip title={TestValidation ? "Redefinição de senha" : "Revalidar email"}>
              {TestValidation ? <VpnKeyIcon /> : <MailIcon />}
            </Tooltip>
          </IconButton>
        }

        <Button startIcon={<SaveIcon />} onClick={applychanges} variant="contained" color="secondary" disabled={!canapplychanges}>
          Aplicar
        </Button>
      </ListItemSecondaryAction>

    </ListItem>

    <SubHeader>Permissões</SubHeader>
    <ListItem>
      <ListItemIcon style={{ width: '65%' }}>Grupo</ListItemIcon>
      <TextField fullWidth select onChange={setGroup} value={user.roleId} InputProps={{ disableUnderline: true }}>
        <MenuItem value={null}>Sem grupo</MenuItem>
        {props.roles.map(role => <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
      </TextField>
    </ListItem>

    <SubHeader>Informações gerais</SubHeader>
    <ListItem divider button component="label">
      <ListItemIcon style={{ width: '65%' }}>Email {TestValidation && <DoneIcon style={{ position: 'absolute', right: 12 }} />} </ListItemIcon>
      <TextField type="email" onChange={set('email')} value={user.email} {...defaultInputProps} error={TestMail} />
    </ListItem>
    <ListItem divider button component="label">
      <ListItemIcon style={{ width: '65%' }}>Validade</ListItemIcon>
      <TextField type="date" onChange={set('validity')} value={user.validity} {...defaultInputProps} />
    </ListItem>
    <ListItem divider button component="label">
      <ListItemIcon style={{ width: '65%' }}>Nome</ListItemIcon>
      <TextField type="text" onChange={set('name')} value={user.name} {...defaultInputProps} />
    </ListItem>
    <ListItem divider button component="label">
      <ListItemIcon style={{ width: '65%' }}>Login</ListItemIcon>
      <TextField type="text" onChange={set('login')} value={user.login} {...defaultInputProps} />
    </ListItem>

    <SubHeader>Últimas interações <Button variant="text" onClick={() => setTimelineview(!timelineview)}>ver mais</Button></SubHeader>
    <Collapse in={timelineview}>
      {props.timeline.map((timeline, i) =>
        <ListItem key={'full-timeline-' + i} divider style={{ borderLeft: '3px solid ' + GetColor(timeline.ent) }}>
          <ListItemIcon ><Avatar>{i + 1}</Avatar></ListItemIcon>
          <ListItemText primary={timeline[timeline.ent].title} secondary={<>{timeline.ent} - {timeline.status}</>} />
          <ListItemSecondaryAction>{timeline.createdAt.ptBR(true)}</ListItemSecondaryAction>
        </ListItem>
      )}
    </Collapse>
    <Collapse in={!timelineview}>
      <ListItem>
        {props.timeline.slice(0, 3).map((timeline, i) =>
          <ListItem component="div" key={'timeline-' + i} style={{
            borderLeft: '3px solid ' + GetColor(timeline.ent)
          }}>
            <ListItemIcon ><Avatar>{i + 1}</Avatar></ListItemIcon>
            <ListItemText
              primary={<>
                <Typography variant="caption" display="block" color="textSecondary">{timeline.ent} - {timeline.status}</Typography>
                <Typography noWrap variant="caption">{timeline[timeline.ent].title}</Typography>
              </>}
              secondary={timeline.createdAt.ptBR(true)}
            />
          </ListItem>
        )}
      </ListItem>
    </Collapse>
    {/* <SubHeader>Chart</SubHeader> */}
    {/* <ListItem>
      <Chart
        width={'90%'}
        height={'500'}
        chartType="Line"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { type: 'date', label: 'Day' },
            'Average temperature',
            'Average hours of daylight',
          ],
          [new Date(2020, 0), props.timeline.sum( e => e.status === 'DONE' && e.createdAt.toDate('month') === new Date(2020, 0) ), 0 ],
          [new Date(2020, 1), 0.4, 8.7],
          [new Date(2020, 2), 0.5, 12],
          [new Date(2020, 3), 2.9, 15.3],
          [new Date(2020, 4), 6.3, 18.6],
          [new Date(2020, 5), 9, 20.9],
          [new Date(2020, 6), 10.6, 19.8],
          [new Date(2020, 7), 10.3, 16.6],
          [new Date(2020, 8), 7.4, 13.3],
          [new Date(2020, 9), 4.4, 9.9],
          [new Date(2020, 10), 1.1, 6.6],
          [new Date(2020, 11), -0.2, 4.5],
        ]}
        options={{
          chart: {
            
            title:
              'Average Temperatures and Daylight in Iceland Throughout the Year',
          },
          width: 900,
          height: 500,
          series: {
            // Gives each series an axis name that matches the Y-axis below.
            0: { axis: 'Temps' },
            1: { axis: 'Daylight' },
          },
          axes: {
            // Adds labels to each axis; they don't have to match the axis names.
            y: {
              Temps: { label: 'Temps (Celsius)' },
              Daylight: { label: 'Daylight' },
            },
          },
        }}
        rootProps={{ 'data-testid': '4' }}
      />
    </ListItem> */}
  </>
  );
}

export default connect((state, props) => {

  const user = state.adminUsers.find(e => e.uuid === props.match.params.uuid);

  /**@type {{ createdAt: Date, status: import('../../../../server/typeroots').STATUS }[] } */
  let timeline = [];
  ['tasks', 'topics', 'toasts'].forEach(key => {
    /**@type {"task"|"topic"|"toast"} */
    const ent = key.substring(0, key.length - 1);

    for (const taskId in state.timeline[key]) {
      const Id = Number(taskId);

      if (user)
        timeline = timeline
          .concat(
            state.timeline[key][taskId].timeline
              .filter(e => e.userId === user.id)
              .map(e => ({ status: e.status, createdAt: e.createdAt, ent, [ent]: state[ent].find(e => e.id === Id) }))
          )
    }
  })


  return {
    timeline: timeline.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    user: user,
    roles: state.adminRoles
  }

}, null)(MyTeamsCurrent);