
/** @returns {[{group: string, index: number, tasks: import("../typeroots").Tasks}]} */
function ByGroup() {
    return this.Distinct(['group']).map((e, i) => ({ group: e.group, index: i, tasks: this.filter(t => e.group === t.group) }));
};

/**
 * @typedef {import("../typeroots").Task[]} Tasks
 * 
 * @class
 * @constructor
 * @public
 */
export default class TodosMap {
    /**@type {number[]} */
    #available_users_ids = [];

    /**@type {{tasks: number[], topics: number[] }} */
    #available_ids = [{ tasks: [], topics: [] }];

    /**@returns {boolean} -- just filter it by user*/
    GetMapObjectFilter = (x) => {
        return x.users.some(y => this.#available_users_ids.has(y.user.id))
    }

    /**@param {import("../typeroots").Task} e */
    SetModes = e => {
        const users = e.users.filter(x => this.#available_users_ids.has(x.user.id));

        return {
            ...e,
            modes: users.map(x => x.mode)
        }
    };

    /**
     * @constructor
     * @param {import("react-redux").DefaultRootState} state 
     * @param {"task"|"topic"|"toast"} target
     */
    constructor(state) {

        this.users = state.todosFilter.users;
        this.filters = state.todosFilter;

        this.#available_users_ids = this.users.map(user => user.id);
        this.#available_ids = state.toast.concat(state.topic).concat(state.task).reduce((acc, cur) => {
            if (this.filters.statusMultiple.length === 0 || this.filters.statusMultiple.includes(cur.status)) {
                if (!cur.taskId && !cur.topicId && !acc.tasks.has(cur.id)) acc.tasks.push(cur.id);

                if (cur.taskId && !acc.tasks.has(cur.taskId)) acc.tasks.push(cur.taskId);
                if (cur.topicId && !acc.topics.has(cur.topicId)) acc.topics.push(cur.topicId);
            }

            return acc;

        }, {
            tasks: [],
            topics: []
        });

        /**@returns {boolean} */
        const StatusMapObject = (x) => {

            if (this.filters.statusMultiple.length === 0)
                return true
            //IS A TASK?
            else if (!x.topicId && !x.taskId && this.#available_ids.tasks.has(x.id))
                return true;

            //IS A TOPIC
            else if (x.taskId && this.#available_ids.topics.has(x.id))
                return true;

            //IS A TOAST
            else if (this.filters.statusMultiple.includes(x.status))
                return true;

            else
                return false;

        };

        function ListByState() {
            return this.filter(StatusMapObject)
        };

        const FilterStatus = x => state.todosFilter.status === "ALL" ? true : x.status === state.todosFilter.status;

        function ToList(callback = null) {
            return callback ? this.filter(x => FilterStatus(x) && callback(x)) : this.filter(x => FilterStatus(x))
        };

        /**@type {Tasks}*/
        this.tasks = state.task.filter(this.GetMapObjectFilter).map(this.SetModes);
        Object.defineProperties(this.tasks, {
            ToList: { value: ToList },
            ListByState: { value: ListByState },
            ByGroup: { value: ByGroup }
        })



        this.topics = state.topic.filter(this.GetMapObjectFilter).map(this.SetModes);
        Object.defineProperties(this.topics, {
            ToList: { value: ToList },
            ListByState: { value: ListByState },
            ByGroup: { value: ByGroup },
            Download: { value: this.Download },
            Task: {
                value: function Task() {
                    return state.task.find(e => e.id === this.taskId)
                }
            }
        })


        this.toasts = state.toast.filter(this.GetMapObjectFilter).map(this.SetModes);
        Object.defineProperties(this.toasts, {
            ToList: { value: ToList },
            ListByState: { value: ListByState },
            ByGroup: { value: ByGroup },
            Task: {
                value: function Task() {
                    return state.task.find(e => e.id === this.taskId)
                }
            },
            Topic: {
                value: function Topic() {
                    return state.topic.find(e => e.id === this.topicId)
                }
            }
        })


        this.tasks.forEach(task => {
            const topicFilter = this.topics.filter(topic => topic.taskId === task.id);
            const toastFilter = this.toasts.filter(toast => toast.taskId === task.id);

            const anotherRange = topicFilter.concat(toastFilter);

            const count = anotherRange.length - topicFilter.count(t => toastFilter.map(e => e.topicId).includes(t.id));

            task.comments = state.timeline.tasks[task.id]?.comments||0;
            task.timeline = state.timeline.tasks[task.id]?.timeline||0;

            task.counters = {
                donePercentage: anotherRange.toPercent("peso", (e) => ["DROP", "DONE", "CQ"].includes(e.status)) || 0,
                cqPercentage: anotherRange.toPercent("peso", (e) => ["CQ"].includes(e.status)) || 0,
                count: count,
                peso: anotherRange.sum('peso')
            }
        });

        this.topics.forEach(topic => {
            const toastFilter = this.toasts.filter(toast => toast.topicId === topic.id);

            topic.comments = state.timeline.topics[topic.id]?.comments||0;
            topic.timeline = state.timeline.topics[topic.id]?.timeline||0;

            topic.counters = {
                donePercentage: toastFilter.toPercent("peso", (e) => ["DROP", "DONE", "CQ"].includes(e.status)) || 0,
                cqPercentage: toastFilter.toPercent("peso", (e) => ["CQ"].includes(e.status)) || 0,
                count: toastFilter.length,
                peso: toastFilter.sum('peso'),
            }
        });

        this.toasts.forEach(toast => {
            toast.comments = state.timeline.toasts[toast.id]?.comments||0;
            toast.timeline = state.timeline.toasts[toast.id]?.timeline||0;
        });

        // const anotherRange = this.topics.concat(this.toasts);
        // const count = anotherRange.length - this.topics.count( t => this.toasts.map(e=>e.topicId).includes(t.id));

        // this.counters = {
        //     donePercentage: anotherRange.toPercent("peso", (e) => ["DROP", "DONE", "CQ"].includes(e.status)) || 0,
        //     cqPercentage: anotherRange.toPercent("peso", (e) => ["CQ"].includes(e.status)) || 0,
        //     count: count,
        //     sum: anotherRange.sum('peso') 
        //  }

    }
    /**@param {{type: "csv", title: string, uuids: string[]}} data*/
    Download = (data) => {
        var csvContent = "data:text/csv;charset=utf8,";

        this.topics.filter(e => data.uuids.includes(e.uuid)).forEach(topic => {
            csvContent += `${topic.title};;${topic.description || ''};${topic.status};${topic.group || ''};${topic.peso || ''};${topic.begin.ptBR()};${topic.finish.ptBR()}\r\n`;

            this.toasts.filter(e => e.topicId === topic.id).forEach(toast => {
                csvContent += `;${toast.title};${toast.description || ''};${toast.status};${toast.group || ''};${toast.peso || ''};${toast.begin.ptBR()};${toast.finish.ptBR()}\r\n`;
            })

            csvContent += "\r\n";
        })

        console.log(data)

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", data.title + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
        link.remove();
    }

    Length = () => {
        return (this.tasks.map(e => e.counters).sum("count"));
    }
    /**
     * 
     * @param {"peso"|"price"} prop 
     */
    Sum = (prop) => {
        return (this.tasks.map(e => e.counters).sum(prop))
    }

    /**
     * 
     * @param {import("../typeroots").STATUS} status 
     */
    Count = (status) => {
        // console.log({
        //     task: this.tasks.filter(e => e.status === status && !this.topics.filter(x => x.status === status).map(i => i.taskId).includes(e.id)),
        //     topics: this.topics.filter(e => e.status === status && !this.toasts.filter(x => x.status === status).map(i => i.topicId).includes(e.id) ),
        //     toasts: this.toasts.filter(e => e.status === status)
        // })

        return this.tasks.count(e => e.status === status && !this.topics.filter(x => x.status === status).map(i => i.taskId).includes(e.id))
            + this.topics.count(e => e.status === status && !this.toasts.filter(x => x.status === status).map(i => i.topicId).includes(e.id))
            + this.toasts.count(e => e.status === status)
    }

}


