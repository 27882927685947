import React from 'react';
import {Emit} from '../../services/useSocket'
import InputBase from '@material-ui/core/InputBase'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export default function CodenamesFeaturesAndAttributesGlobal(props) {

    const [input, setInput] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        Emit(`codenames:${props.event}:will:create`, {input})
        setInput('');
    }

    const handleDelete = (item) => {
        Emit(`codenames:${props.event}:will:delete`, item);
    }


    return (<>
        <List dense={true}>
            <ListSubheader component="div">{props.name} globais ({props.list.length})</ListSubheader>
            {props.list.map(e =>
                <ListItem key={`${props.event}-${e.label}`}>
                    <ListItemText primary={e.label} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => handleDelete(e)}>
                            <CloseIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
            <ListItem onSubmit={handleSubmit} component="form">
                <InputBase 
                    required fullWidth autoComplete="off"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={`Novo item ...`}
                />
            </ListItem>
        </List>
        <Divider variant="middle"/>
    </>)
}