import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import useStyles from './styles'
import { Map, GroupBy } from 'react-lodash'
import { ACTIVES } from '../../typeroots';
import { useSelector } from 'react-redux';
import { host_gallery } from '../../services/setproxy';
import Skeleton from '@material-ui/lab/Skeleton';



export default function CommerceCashItemsGroup({ sections, onContext, onOpen }) {
    const classes = useStyles();

    /**@type {Array<Topic>}*/
    const snacks = useSelector(state => state.topic.filter(ACTIVES));

    return (
        <GroupBy collection={snacks} iteratee={snack => snack.group}>
            {groups => Object.keys(groups).map(group =>
                <List container component={Grid}
                    style={{height: 'auto'}}
                    ref={sections[group]}
                    justify="space-around"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    key={group}
                    subheader={
                        <ListSubheader
                            component="div"
                            className={classes.subheader}>
                            <Typography variant="subtitle1">{group === 'null' ? "Sem Categoria" : group}</Typography>
                        </ListSubheader>}
                >
                    <Map collection={groups[group]} iteratee={snack => snack.price &&
                        <Card key={snack.uuid} className={classes.cardroot} onContextMenu={e => onContext(e, snack)}>
                            <CardActionArea onClick={() => onOpen(snack.uuid)}>

                                <CardHeader
                                    avatar={<Avatar aria-label="recipe" src={`${host_gallery + (snack.files?.[1]?.file || snack.files?.[0]?.file)}`}></Avatar>}
                                    titleTypographyProps={{ variant: 'subtitle1' }}
                                    title={snack.title?.rmvParentheses() || ''}
                                    subheaderTypographyProps={{ variant: 'subtitle1' }}
                                    subheader={snack.price?.monetizeme() || ''}
                                />

                                <div className={classes.cardArea}>

                                    <CardMedia
                                        className={classes.media}
                                        image={`${host_gallery + (snack.files[0]?.file)}`}
                                        title={snack.files[0]?.file}
                                    />

                                    <CardContent>
                                        <Typography variant="caption" color="textSecondary" component="p">
                                            {snack.description}
                                        </Typography>
                                    </CardContent>

                                </div>
                            </CardActionArea>
                        </Card>}
                    />
                </List>
            )}
        </GroupBy>
    );
}

export const CommerceCashItemsGroupSkeleton = () => {
    const classes = useStyles();

    return (
        new Array(3).fill(3).map((e, i) =>
            <List
                key={`skeleton-list-root-${i}`}
                className={classes.listRoot}
                component={Grid}
                container
                justify="space-around"
                subheader={<div className={classes.subheader}><Skeleton animation="wave" height={22} width={90} /></div>}
            >
                {new Array(3).fill(3).map((e, i) =>
                    <Card className={classes.cardroot} key={`skeleton-card-root-${i}`}>
                        <CardHeader
                            avatar={<Skeleton animation="wave" variant="rect" width={90} height={90} />}
                            title={<Skeleton animation="wave" height={22} width="80%" style={{ marginBottom: 6 }} />}
                            subheader={<Skeleton animation="wave" height={22} width="40%" />}
                        />
                        <div className={classes.cardArea}>
                            <Skeleton className={classes.media} animation="wave" variant="rect" />
                            <CardContent>
                                <React.Fragment>
                                    <Skeleton animation="wave" height={22} style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" height={22} width="80%" />
                                </React.Fragment>
                            </CardContent>
                        </div>
                    </Card>
                )}
            </List>
        ))
};