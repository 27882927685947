import React from 'react'
import FeaturesAndAttributes from './FeaturesAndAttributes'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CodenamesList from './CodenamesList'
import CodenamesFeaturesAndAttributesGlobal from './CodenamesFeaturesAndAttributesGlobal'
import FileInputSelect from '../Comparisons/FileInputSelect'
import FileContentTable from '../Comparisons/FileContentTable'
import { Socket, Emit } from '../../services/useSocket'
import { withRouter } from "react-router"
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import SubNavigation from './SubNavigation'

const useStyles = theme => ({
  gridContainer: {
    height: '100%',
    '& .MuiPaper-root': {
      height: '100%',
      overflow: 'hidden'
    }
  },
  AutoSizer: {
    height: '100%'
  }
})

class ComparisonEditor extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      // codenames: [],
      // attributes: [],
      // features: [],
      tab: 0,
    };

    this.events = {
      // "codenames:did:mount": this.setCodenames,
      // "codenames:willUpsert:success": this.codenamesWillUpsert,
      // "codenames:will:delete:success": this.codenamesWillDelete,
      "codenames:willUpsert:error": this.setTab,

      // "codenames:features": this.setFeatures,
      // "codenames:features:will:create": this.codenamesFeaturesWillCreate,
      // "codenames:features:will:delete": this.codenamesFeaturesWillDelete,   

      // "codenames:attributes": this.setAttributes,
      // "codenames:attributes:will:create": this.codenamesAttributesWillCreate,
      // "codenames:attributes:will:delete": this.codenamesAttributesWillDelete
    }
  }

  componentDidMount() {

    if (this.props.file)
      Emit('codenames:did:mount', this.props.file)
  }

  // setAttributes = (attributes) => this.setState({ attributes })
  // setCodenames = (codenames) => this.setState({ codenames })
  // setFeatures = (features) => this.setState({ features })
  setFile = (file) => this.setState({ file })
  setList = (list) => this.setState({ list })
  setTab = (tab) => this.setState({ tab })

  componentDidUpdate() {
    if (!this.props.file)
      this.props.history.replace('/comparison/selector')
  }

  // codenamesWillUpsert = (item) => {
  //   let { codenames } = this.state;
  //   if (!codenames.set(e => e.id === item.id, item))
  //     codenames = [item, ...codenames];

  //   this.setState({ codenames });
  // }

  // codenamesWillDelete = (item) => {
  //   this.setState(({ codenames }) => codenames.rmv(e => e.id === item.id));
  // }

  // codenamesFeaturesWillCreate = (data = null) => {
  //   if (!data) return alert("Não é possivel excluír este item, ja existem valores atrelados a ele!")
  //   const { features } = this.state;
  //   features.push(data);
  //   this.setFeatures(features);
  // }

  // codenamesFeaturesWillDelete = (data = null) => {
  //   if (!data) return alert("Não é possivel excluír este item, ja existem valores atrelados a ele!")
  //   const { features } = this.state;
  //   features.rmv(x => x.id === data.id);
  //   this.setFeatures(features);
  // }

  // codenamesAttributesWillCreate = (data = null) => {
  //   if (!data) return alert("Não é possivel excluír este item, ja existem valores atrelados a ele!")
  //   const { attributes } = this.state;
  //   attributes.push(data);
  //   this.setAttributes(attributes);
  // }

  // codenamesAttributesWillDelete = (data = null) => {
  //   if (!data) return alert("Não é possivel excluír este item, ja existem valores atrelados a ele!")
  //   const { attributes } = this.state;
  //   attributes.rmv(x => x.id === data.id);
  //   this.setAttributes(attributes);
  // }



  HandleGet = (item) => this.props.history.push(`/comparison/codenames/${item.id}`);


  AddCodename = (value) => {
    if (value.length > 2 && this.props.codenames.findIndex(e => e.label === value) === -1) {
      Emit('codenames:willUpsert', { label: value, caption: 'Novo', refer: this.props.fileKeyCode });
      return true;
    }

    return false
  }



  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.gridContainer} spacing={1} >
        <Socket events={this.events} />
        <SubNavigation/>
        
        {Boolean(!this.props.match.params.id) && <>
          <Grid item md={4} xs={12}>
            <Paper spacing={2}>
              <FileInputSelect />

              <CodenamesList
                visibility={!this.props.match.params.id}
                codenames={this.props.fileKeyCode ? this.props.codenames.filter(e => e.refer === this.props.fileKeyCode) : this.props.codenames}
                selected={this.props.fileKeyCode}
                OnAddInput={this.AddCodename}
                OnSelect={this.HandleGet}
                OnDelete={(item) => Emit('codenames:will:delete', item)}

              />
            </Paper>
          </Grid>

          <Grid item md={8} xs={12}>
            <Paper spacing={2}>

              <Tabs value={this.state.tab} onChange={(e, value) => this.setTab(value)} aria-label="global table" indicatorColor="primary" textColor="primary" variant="fullWidth">
                <Tab label="Caracteristicas & Atributos" />
                <Tab label="Arquivo" />
              </Tabs>

              {this.state.tab === 0 && <>
                < CodenamesFeaturesAndAttributesGlobal name="Características" event="features" list={this.props.features.filter(e => e.codenameId === null) || []} />
                < CodenamesFeaturesAndAttributesGlobal name="Atributos" event="attributes" list={this.props.attributes.filter(e => e.codenameId === null) || []} />
              </>}

              {this.state.tab === 1 &&
                <FileContentTable CountAssociated={(id) => this.props.codenames.count(e => e.refer === id)} />
              }
            </Paper>
          </Grid>
        </>}

        {Boolean(this.props.match.params.id) &&
          <FeaturesAndAttributes
            id={Number(this.props.match.params.id)}
            Attributes={this.props.attributes}
            setAttributes={this.setAttributes}
            Features={this.props.features}
            setFeatures={this.setFeatures}
          />
        }
      </Grid>

    )
  }
}

export default withStyles(useStyles)(withRouter(connect(
  (state) => ({
    ...state.codenames
  })
)(ComparisonEditor)));