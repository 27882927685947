import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    cardroot: {
      // height: 450,
      '& .MuiCardHeader-title': {
        lineHeight: 1.25
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 355,
        marginBottom: theme.spacing(2),
        '& .MuiAvatar-root': {
          width: 60,
          height: 60
        }
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
        width: '100%',
        '& .MuiCardHeader-root':{
          padding: 0,
        },
        '& .MuiAvatar-root': {
          width: 90,
          height: 90,
          borderRadius: 0
        }
      }
    },
    cardArea: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      }
    },
    subheader:{
      width: '100%',
      background: theme.palette.background.default,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    media: {
      paddingTop: '56.25%', 
      [theme.breakpoints.up('md')]: {
        height: 0,
        minHeight: 150,
        minWidth: 355
      },
      [theme.breakpoints.down('md')]: {
        width: 250,
        height: 175
      }
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
  }));
  