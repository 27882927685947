import React, { useState } from 'react';
import { login, isAuthenticated, post } from '../../services/Auth';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemText from '@material-ui/core/ListItemText';
import Visibility from '@material-ui/icons/Visibility';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import { Container, Form, Item,  AvatarLarge, ParticlesView } from './styles';
import ParticlesParams from './Particles';
import './styles.css';


const Header = ({ mode }) => {
    return (
        <List style={{ padding: 35 }}>
            <ListItem>
                <ListItemAvatar>
                    <AvatarLarge alt="Projav" src={mode ? '/projav400.png' : '/projavdark400.png'} />
                </ListItemAvatar>
                <ListItemText primary="Projav" secondary="Realtime app by ciclo insight" />
            </ListItem>
        </List>

    )
}

export default function SignIn(props) {

    const [values, setValues] = useState({
        login: '',
        password: '',
        visibility: true,
        errorMessage: false,
        lock: false
    })

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const setToken = ({ token }) => {
        setValues({ ...values, errorMessage: true, lock: true });
        login(token, () => {

            window.location.reload();
        });
    }

    const handleChange = (e) => setValues({ ...values, errorMessage: false, [e.target.getAttribute('model')]: e.target.value });

    const changeVisibility = bool => {
        setValues({ ...values, visibility: bool });
    }

    const handleTryLogin = async (e) => {
        e.preventDefault();

        let { login, password } = values
            , errorMessage;
        setValues({ ...values, errorMessage: 'Connectando...', lock: true });

        let response = await post('/authentication/trylogin', { login, password });

        if (response.ok) {
            setToken(await response.json())
        }
        else {
            switch (response.status) {
                case 400:
                    errorMessage = 'Login sem credenciais';
                    break;

                case 401:
                    errorMessage = 'Login inválido';
                    break;

                case 402:
                    errorMessage = 'Sem validade';
                    break;
                case 403:
                    errorMessage = 'Bloqueado';
                    break;


                default:
                    errorMessage = 'Login inválido';
                    break;
            }

            setValues({ ...values, errorMessage, lock: false })
        };

    }


    const handleLostMyPassword = async () => {
        if (values.login) {
            let response = await post('/authentication/tryreset', { login: values.login });

            return (response.ok)
                ? alert("Um email foi encaminhado para redefiniçao de senha")
                : alert("Login não encontrado/Email cadastrado não é valido");
        }

        else
            alert("Informe o usuário");
    }


    if (isAuthenticated()) props.history.replace('/');

    return (
            <Container
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                style={{height: '100vh'}}
            >
                <ParticlesView params={ParticlesParams} />
                <Item component={Paper} item xs={11} sm={6} md={4}>
                    <div className="wave"></div>
                    <Header mode={prefersDarkMode} />
                    <Form onSubmit={handleTryLogin}>
                        <TextField
                            type="text"
                            margin="dense"
                            variant="outlined"
                            id="user-entrace-app-login"
                            label="Login"
                            required
                            helperText="Nome/Usuário do sistema"
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                                model: 'login'
                            }}
                            InputProps={{
                                endAdornment:
                                    <>
                                        < InputAdornment position="end" >
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                type="reset"
                                                aria-label="erase user visibility"
                                                onClick={() => {
                                                    document.getElementById('user-entrace-app-login').focus();
                                                }}
                                                edge="end"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    </>

                            }}
                        />

                        <TextField
                            type={values.visibility ? 'password' : 'text'}
                            margin="dense"
                            variant="outlined"
                            id="password-entrace-app-login"
                            label="Password"
                            required
                            helperText="Senha de usuário"
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                endAdornment:
                                    <>
                                        < InputAdornment position="end" >
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                aria-label="toggle password visibility"
                                                onMouseUp={e => changeVisibility(true)}
                                                onMouseDown={e => changeVisibility(false)}
                                                edge="end"
                                            >
                                                {values.visibility ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    </>

                            }}
                            inputProps={{
                                model: 'password'
                            }}
                        />

                        <Box marginTop={5} justifyContent="center" display="flex">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={values.lock === true}
                                type="submit">
                                {values.errorMessage ? values.errorMessage === true ? 'Redirecionando' : values.errorMessage : 'Sign In'}
                            </Button>
                        </Box>

                    </Form>
                    <Button disabled={!values.login} color="secondary" onClick={handleLostMyPassword} > Esqueci minha senha</Button>
                </Item>
            </Container>
    )
}

