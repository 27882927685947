
import TodosMap from '../../Reducers/TodosMap';

export const mapStateToProps = (state) => {
    const Todos = new TodosMap(state);
    return {
        task: Todos.tasks,
        topic: Todos.topics,
        toast: Todos.toasts,
        filter: state.todosFilter
    };
}