import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../styles';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Allow from '../../../Components/Allow';
import CommerceRoutes from '../routes';
import socket from '../../../services/socket';

const CommerceOptions = () => {
    const classes = useStyles();
    
    const faction = useSelector(state => state.user.faction);

    const setFactionProp = () => {

        socket.emit("admin:faction:set:props", { isAvailable: !faction.props.isAvailable})
    }

    return (
        <Grid className={classes.container} container spacing={2} justify="center" alignContent="center">
            {CommerceRoutes.map(route =>
                <Allow on={route.on} key={'options-' + route.to}>
                    <Grid className={classes.grid} item xs={6} md={2}>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }} to={route.to}>
                            <div>
                                <Avatar className={classes.largeAvatar}>
                                    <route.icon style={{ fill: 'white' }} fontSize="large" />
                                </Avatar>
                                <Typography color="inherit">
                                    {route.label}
                                </Typography>
                            </div>
                        </Link>
                    </Grid>
                </Allow>
            )}
            <Allow on={"CommerceDashboard"} >
                <Grid className={classes.grid} item xs={6} md={2}>
                        <IconButton size="small" onClick={setFactionProp}>
                            <Avatar className={classes.largeAvatar} style={{background: !faction.props.isAvailable ? 'red' : 'blue' }}>
                                <PowerSettingsNewIcon style={{ fill: 'white' }} fontSize="large" />
                            </Avatar>
                            <Typography color="inherit" style={{marginLeft: 8}}>
                                {faction.props.isAvailable ? "online" : "offline"}
                            </Typography>
                        </IconButton>
                </Grid>
            </Allow>
        </Grid>
    )
}

export default CommerceOptions;