import React from 'react';
import { DrawerList, SubHeader } from '../../Components/StyledComponents';
import { Socket, Emit } from '../../services/useSocket';
import { host_gallery } from '../../services/setproxy';
import SwipeableNavigation from '../../Components/SwipeableNavigation';
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'

import Send from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
/**
* @typedef {{ mode: string, uuid: string }} Params
* @typedef { import('react-router').RouteChildrenProps<Params> & import('react-redux').DefaultRootState  } props
* @extends {React.Component<props>}
*/

class TodosCommentDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            comment: '',
            comments: [{
                user: {
                    login: '',
                    image: '',
                },
                description: '',
                createdAt: '',
                isSending: false
            }]
        }

        this.mountComments = this.mountComments.bind(this);
        this.setComment = this.setComment.bind(this);
    }

    setComment(comment) {
        this.setState({ comment });
    }
    mountComments(comments) {
        this.setState({ comments, loading: false });
    }
    pushComments = (comment) => {
        this.setState({ comments: this.state.comments.concat(comment) })
    }

    /**@param {import('react').FormEvent} e */
    submitComment = (e) => {
        e.preventDefault();

        Emit('comments:will:create', { description: this.state.comment, ...this.props.match.params });
        this.setComment('');
    }


    render() {
        console.log(this.state.comments);

        return (
            <SwipeableNavigation drawerProps={{ anchor: 'right', disableSwipeToOpen: true }}>
                <Socket
                    onMount={{ 'comments:did:mount': this.props.match.params }}
                    onUnMount={{ 'comments:did:unmount': this.props.match.params }}
                    events={{
                        'comments:did:mount': this.mountComments,
                        'comments:will:create': this.pushComments
                    }} />
                <DrawerList size={400}>
                    <SubHeader>
                        <Typography variant="subtitle1">Comentários</Typography>
                        <IconButton onClick={this.props.history.goBack}>
                            <Close />
                        </IconButton>
                    </SubHeader>
                    {Boolean(this.state.loading === true)
                        ? Array.from({length:10}, ( (_ ,e) =>
                            <ListItem key={`comment-preloader-${e}`}>
                                <ListItemAvatar>
                                    <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Skeleton animation="wave" height={20} width="40%" />}
                                    secondary={<Skeleton animation="wave" height={40} width="100%" />}
                                />
                            </ListItem>
                        )) : this.state.comments.length === 0 ?
                            <ListItem>
                                <ListItemText primary="Ainda não há comentários" />
                            </ListItem>
                            : this.state.comments.map((comment, indexComment) =>
                                <ListItem key={`comment-${indexComment}`}>
                                    <ListItemAvatar>
                                        <Avatar src={host_gallery + (comment.user?.image || comment.client?.image) + '_thumb'} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primaryTypographyProps={{ variant: 'caption' }}
                                        primary={`${comment.user?.login || comment.client?.name} - ${comment.createdAt.ptBR(true)}`}
                                        secondary={comment.description}
                                    />
                                </ListItem>
                            )}
                </DrawerList>
                <AppBar component="form" position="relative" color="secondary" className={'MuiAppBarBottom'} onSubmit={this.submitComment}>
                    <Toolbar>
                        <TextField
                            label="Escrever comentário"
                            fullWidth
                            onChange={e => this.setComment(e.target.value)}
                            value={this.state.comment}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: <InputAdornment position="end"> <IconButton disabled={!this.state.comment} type="submit" size="small"> <Send /> </IconButton></InputAdornment>
                            }} />
                    </Toolbar>
                </AppBar>
            </SwipeableNavigation>
        )
    }
}

export default TodosCommentDrawer;