import socket from "../../services/socket"
import Cache from '../Compress'

const initialState = Cache.get('orders') || [];

const ADD_ORDER = 'add:order';
const RMV_ORDER = 'rmv:order';
const CLR_ORDER = 'clr:order';

const CREATE_ORDER = 'order:will:create';

export const addOrder = ({ id, taskId, uuid, title, price, restaurant, description, toasts, peso }) => ({ type: ADD_ORDER, data: { id, taskId, uuid, title, price, restaurant, description, toasts, peso } });
export const rmvOrder = (payload) => ({ type: RMV_ORDER, data: payload });
export const clrOrder = () => ({ type: CLR_ORDER });

/**
 * 
 * @param {{
 *  selectAddress: {
 *      street: String,
 *      neighborhood: String,
 *      city: String,
 *      state: String,
 *      complement: String,
 *      cep: String,
 *      number: Number,
 *      clientWebId: Number 
 *  },
 *  order: Array<{
 *      title: String,
 *      price: String,
 *      description: String,
 *      peso: Number,
 *      toasts: Array<{ 
 *          description: String,
 *          title: String,
 *          status: String,
 *      }>
 * }>
 * }} payload 
 */

export const createOrder = (payload) => {
    socket.emit(CREATE_ORDER, payload)
};

const RestaurantOrders = (state = initialState, action) => {
    switch (action.type) {
        case CLR_ORDER:
            return state = [];

        case ADD_ORDER:
            return [
                ...state,
                action.data
            ];

        case RMV_ORDER:
            state.rmv(e => e === action.data)
            return [
                ...state
            ]

        default:

            return state;
    }
}

export default RestaurantOrders;