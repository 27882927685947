import React from 'react';
import MuiMenu from "@material-ui/core/Menu";
import MuiMenuItem from "@material-ui/core/MenuItem";

export class MenuItem extends React.Component {

    render() {
        const { when, ...props } = this.props;

        return Boolean(when) === true ? (
            <MuiMenuItem {...props}>
                {props.children}
            </MuiMenuItem>
        ) : null
    }
};

const initialState = {
    mouseX: null,
    mouseY: null,
};

class Menu extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mouseX: null,
            mouseY: null,
        }
    }

    Open = (event) => {
        event.preventDefault();
        this.setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    }

    Close = () => {
        this.setState(initialState);
        setTimeout(() => this.props.onClose(), 300);
    };

    render() {
        return (
            <MuiMenu {...this.props.MenuProps}
                keepMounted={this.props.keepMounted}
                open={this.state.mouseY !== null}
                onClose={this.Close}
                onContextMenu={this.Close}
                PaperProps={{
                    style: {
                        minWidth: 285
                    },
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    this.state.mouseY !== null && this.state.mouseX !== null
                        ? { top: this.state.mouseY, left: this.state.mouseX }
                        : undefined
                }
            >
                {this.props.children}
            </MuiMenu>
        )
    }
}

export default Menu;