import update from "immutability-helper";
import { isAuthenticated } from "../../services/Auth";
import socket from "../../services/socket"
import Cache from '../Compress'

/**
 * @typedef {import("../TasksReducers").ClientWeb} ClientWeb  
 * @typedef {import("../TasksReducers").ClientAddress} ClientAddress  
 * @typedef {import("../TasksReducers").ClientContact} ClientContact  
 * 
 * */
const initalState = Cache.get('clients') || [];

export const DID_MOUNT   = 'client:did:mount';
export const WILL_MOUNT  = 'client:will:mount';
export const WILL_CHANGE = 'client:will:change';
export const WILL_CREATE = 'client:will:create';
export const WILL_UPDATE = 'client:will:update';
export const WILL_DELETE = 'client:will:delete';
export const WILL_CONNECT = 'client:will:connect';

export const TRY_LOGIN = 'client-web:login';
export const SET_SELECTED = 'client:set:active';
export const STATE_CHANGE = 'client:state:change';

export const actions = {
    didMount: (payload) => ({ type: DID_MOUNT, data: payload }),
    willlChange: (payload) => ({ type: WILL_CHANGE, data: payload }),
    willCreate: (payload) => ({ type: WILL_CREATE, data: payload }),
    willDelete: (payload) => ({ type: WILL_DELETE, data: payload }),
    willUpdate: (payload) => ({ type: WILL_UPDATE, data: payload }),

    stateChange: (payload) => ({ type: STATE_CHANGE, data: payload }),
    SetSelected: (payload) => ({ type: SET_SELECTED, data: payload }),
}

/**
 * 
 * @param {import("redux").Store} store 
 */
export const listen = (store) => {

    function Login(){
        const {clients} = store.getState();
        const client = clients?.find(e => e.selected === true);

        socket.emit(DID_MOUNT, client);
    }

    socket.on(DID_MOUNT, (data) => store.dispatch(actions.didMount(data)))
    socket.on(WILL_CREATE, (data) => store.dispatch(actions.willCreate(data)))
    socket.on(WILL_DELETE, (data) => store.dispatch(actions.willDelete(data)))
    socket.on(WILL_UPDATE, (data) => store.dispatch(actions.willUpdate(data)))
    socket.on(STATE_CHANGE, (data) => store.dispatch(actions.stateChange(data)))
    socket.on(WILL_MOUNT, Login);

    if(!isAuthenticated())
    socket.on('connect', Login);
}

export const dispatch = {
    /**
     * @param {{ name: String, contact: ClientContact, address: ClientAddress  }} data 
     * */
    Create: (data) => {
        return socket.emit(WILL_CREATE, data);
    },
    Delete: ({ uuid }) => {
        return socket.emit(WILL_DELETE, { uuid })
    },
    Update: ({ id, uuid, ...datatoupdate }) => {
        return socket.emit(WILL_UPDATE, { id, uuid, ...datatoupdate })
    },
    /**@param {import("../../../../server/app/socket/projav/client").ClienteCreateRequest} data*/
    Connect: (data) => {
        return socket.emit(WILL_CONNECT, data)
    },
    /**
     * @param {{ name: String, email: string, password: string, type: 'reset'|'register'|'login'}} payload
     */
    Login: (payload) => socket.emit(TRY_LOGIN, payload)
}

/**
 * @param {ClientWeb[]} state 
 * @param {{data: string, data: any}} action 
 */
function reducer(state = initalState, action) {
    switch (action.type) {
        case DID_MOUNT:
            return state = action.data;

        case WILL_CREATE:
            return update(state, {
                $push: [action.data]
            });

        case WILL_UPDATE:
            return update(state, {
                [state.findIndex(e => e.uuid === action.data.uuid)]: { $set: action.data }
            })

        case WILL_DELETE:
            return [...state.filter(e => e.uuid !== action.data.uuid)];

        case SET_SELECTED:
            state.map(e => e.uuid === action.data.uuid ? e.selected = true : e.selected = false);
            return [...state];

        case STATE_CHANGE:
            return update(state, {
                [state.findIndex(x => x.selected === true)]: {
                    orders: {
                        [state.find(x => x.selected === true).orders.findIndex( x => x.uuid === action.data.uuid)]: {
                            $merge: action.data
                        }
                    }
                }
            })

        default:
            return [...state];
    }
}


export default reducer