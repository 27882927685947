import { actions } from '../../Reducers/TodosFilter'
import Reducer from '../../Reducers/TasksReducers'
import TodosMap from '../../Reducers/TodosMap';

const { dispatch: ToastDispatch, actions: ToastActions } = new Reducer('toast');

export const dispatch = {
    task: new Reducer('task').dispatch,
    topic: new Reducer('topic').dispatch,
    toast: ToastDispatch,
}

export const mapStateToProps = (state) => {

    const Todos = new TodosMap(state);

    return {
        tasks: Todos.tasks,
        topics: Todos.topics,
        toasts: Todos.toasts,
        filters: state.todosFilter
    };
};


export const mapDispatchToProps = (dispatch) => ({

    /**@param {import('../TodosTask/Rows').Task & { selected: boolean }} data*/
    setSelect: (data) => dispatch(ToastActions.Select(data)),
    /**@param {import('../../typeroots').STATUS} status */
    setStatus: (status) => dispatch(actions.setStatus(status))
})