import FastfoodIcon from '@material-ui/icons/Fastfood';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import KitchenIcon from '@material-ui/icons/Kitchen';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import RoomIcon from '@material-ui/icons/Room';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import EqualizerIcon from '@material-ui/icons/Equalizer';


const CommerceRoutes = [
    { on: 'CommerceCarte', icon: RestaurantIcon, to: "/commerce/carte", label: "Editar Cardápios" },
    { on: 'CommerceCash', icon: AttachMoneyIcon, to: '#clients', label: "Novo Pedido" },
    { on: 'CommerceCash', icon: FastfoodIcon, to: "/commerce/cash", label: "Cardápio" },
    { on: 'CommercePanel', icon: EqualizerIcon, to: "/commerce/panel", label: "Painel de pedidos" },
    { on: 'CommerceKitchen', icon: OutdoorGrillIcon, to: "/commerce/kitchen", label: "Cozinha" },
    { on: 'CommerceIngredients', icon: KitchenIcon, to: "#ingredients", label: "Geladeira" },
    { on: 'CommerceDelivery', icon: LocalShippingIcon, to: "/commerce/delivery", label: "Entregas" },
    { on: 'CommerceMotoboy', icon: RoomIcon, to: "/commerce/deliveryman", label: "Entregador" },
    { on: 'CommerceDashboard', icon: MenuBookIcon, to: "/commerce/dashboard", label: "Relatórios" },
]

export default CommerceRoutes