import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '75%',
    },
    mediumIcon: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),

    }
}));
