import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import LinearProgressWithLabel from "../ToastUtils/LinearProgress"
import AvatarUserIcon from "../AvatarUserIcon"
import StatusIcon from "../StatusIcon"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Badge from "@material-ui/core/Badge"

import { FlexBox } from '../StyledComponents'
import { TodoRow, Vertical, Column } from './styles'
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox'

const disable = { opacity: '.5', pointerEvents: 'none' };

export default class TodoRows extends React.Component {

  render() {

    const {
      onNavigate,
      task,
      peso,
      cq,
      onContext,
      counter,
      style,
      onSelect
    } = this.props;

    const isSelected = task.selected || false;

    return (
      <div style={style}>
        <TodoRow button divider
          style={task.disabled && disable}
          className={task.priority || ''}
          onContextMenu={onContext(task)}>
          {Boolean(onSelect) &&
            <ListItemIcon>
              <Checkbox
                disabled={!task.modes.includes('OWNER')}
                color="primary"
                name="checked_control_all_rows"
                onChange={onSelect}
                value={isSelected}
                checked={isSelected}
              />
            </ListItemIcon>
          }

          <Column
            width='25%'
            onClick={onNavigate(task)}
            primary={task.title}
            primaryTypographyProps={{
              variant: "subtitle2",
            }}
            secondary={task.description || "Sem descrição"}
            secondaryTypographyProps={{
              variant: "caption",
            }}
          />

          {Boolean(task.group) && <>
            <Vertical orientation="vertical" flexItem />
            <Column
              width='5%'
              display="desktop"
              onClick={onNavigate(task)}
              primary={task.group}
              primaryTypographyProps={{
                variant: "subtitle2",
              }}
            />
          </>}
          <Vertical orientation="vertical" flexItem />

          <Column
            display="desktop"
            max={134}
            primaryTypographyProps={{ component: 'div', variant: 'caption' }}
            secondaryTypographyProps={{ component: 'div', variant: 'caption' }}
            primary={
              <>
                <div style={{ fontSize: 9, display: "block" }}>Número de itens: {counter || 0}</div>
                <div style={{ fontSize: 10, display: "block" }}>Peso: {(task.peso || 0) + (task.counters.peso || 0)}</div>
              </>
            }
            secondary={
              <FlexBox>
                <Typography style={{ fontSize: 11 }} display="block" variant="caption">{Boolean(task.begin && task.finish) && `${task.begin?.ptBR()} - ${task.finish?.ptBR()}`}</Typography>
              </FlexBox>}
          />

          <Vertical orientation="vertical" flexItem />

          <Column display="desktop" max={120}
            primary={
              <>
                <LinearProgressWithLabel value={peso || 0} color="secondary" />
                <LinearProgressWithLabel value={cq || 0} />
              </>
            }
          />
          <Vertical orientation="vertical" flexItem />

          <Column display="desktop" max={100} overflow="auto" primary={
            <FlexBox>
              <AvatarUserIcon users={task.users} />
            </FlexBox>}
          />

          <Vertical orientation="vertical" flexItem />

          <ListItemSecondaryAction>
            <Tooltip title={`${task.status} - mais opções`}>
              <IconButton onClick={onContext(task)}>
                <Badge color="secondary" badgeContent={task.comments?.length}>
                  <StatusIcon status={task.status} />
                </Badge>
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </TodoRow>
      </div>
    );
  }
}
