import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    GridListRoot: {
        padding: '0px!important',
        overflowX: 'hidden',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('md')]: {
            overflowY: 'auto',
            height: '100vh',
        }
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    timelineRoot: {
        width: '100%',
        alignItems: 'flex-start',
        padding: '24px 0px 0px 0px',
        // margin: 0
    },
    timelineItem: {
        width: '100%',
        "&.MuiTimelineItem-missingOppositeContent:before": {
            flex: 0
        },
        '& .MuiTimelineContent-root': {
            width: '100%',
            marginTop: -30,
            // marginBottom: 30,
            '& .MuiListItem-container:hover .MuiListItemSecondaryAction-root': {
                display: 'block'
            },
            '& .MuiListItem-container .MuiListItemSecondaryAction-root':{
                display: 'none'
            }
        }
    }
}));