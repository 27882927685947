import React from 'react';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FileInputSelect from './FileInputSelect'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

class ComparisonFileList extends React.Component {

    render() {
        
        if(this.props.file){
            this.props.history.push('/comparison/user');
        }
        return (
            <Box height="100vh" display="flex">
                <Grid container direction="row" justify="center" alignItems="center" alignContent="center" wrap="nowrap">
                    <Grid item xs={11} sm={6} md={4} >
                        <FileInputSelect />
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const mapStateToProps = state => ({
    file: state.codenames.file
});

export default connect(mapStateToProps)(withRouter(ComparisonFileList))