import React from 'react';
import { Collapse, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Checkbox, Grid, List } from '@material-ui/core';
import Sidenav from '../../Components/Sidenav';
import useOpen from '../../services/useOpen';
import { GridOverflowed, SubHeader } from '../../Components/StyledComponents';
import { useSelector } from 'react-redux';
import { Close, DeleteSweepTwoTone, KitchenTwoTone, AddCircleTwoTone } from '@material-ui/icons';

import dispatch, { UniqIngredientsList, AddIngredients, ingredientType, IncrementFields } from './AddIngredients'
import Task from '../../Reducers/TasksReducers';

const { dispatch: ToastDispatch } = new Task('toast');


export function UniqIngredientsMap(options, keys = e => `${e.title} (${e.peso || 0}g)`) {
    const data = [...new Set(options.map(keys).map(e => e.UcFirst()))];
    const array = data.map(e => ({ title: e }));

    return array.sort((a, b) => a.title.localeCompare(b.title));
}

function CommerceIngredients(props) {
    const [open, close] = useOpen(false, props.location.hash === '#ingredients', props.history.goBack);
    const [collapse, setCollapse] = React.useState(false);
    const [selected, setSelected] = React.useState(false);


    /**@type {Ingredients} */
    const ingredients = useSelector(state => state.ingredients);

    /**@type {Toasts} */
    const toasts = useSelector(state => state.toast);


    // const handlePeso = React.useCallback(ingredient => value => {
    //     dispatch.Update({ uuid: ingredient.uuid, peso: Number(value || 0) })

    // }, []);

    const handleDelete = React.useCallback(async ingredient => {
        const toasteds = toasts.filter(toast => toast.group === ingredient.title);

        if (toasteds.length > 0 && window.confirm("Limpar os items dessa prateleira?")) {
            await toasteds.map(e => ToastDispatch.willUpdate({ uuid: e.uuid, group: null }))
        }

        dispatch.Delete({ uuid: ingredient.uuid });

    }, [toasts]);

    const handleConnect = React.useCallback(ingredient => {

        toasts
            .filter(toast => toast.title.toUpperCase() === ingredient.title.toUpperCase())
            .map(e => ToastDispatch.willUpdate({ uuid: e.uuid, group: e.group === collapse ? null : collapse }))

    }, [toasts, collapse]);

    const havagrouped = toasts.Distinct(['title', 'group'], e => Boolean(e.group));
    const allagrouped = toasts.Distinct(['title', 'group']);

    return (
        <React.Fragment>
            <Sidenav open={open} onClose={close} onOpen={() => true}>
                <Grid container style={{ overflowY: 'auto', minWidth: 350 }}>
                    <GridOverflowed item xs={12}>
                        <Collapse in={Boolean(!collapse)} >
                            <List>
                                <SubHeader>Prateleiras organizadoras e totais ({ingredients.length})</SubHeader>

                                <ListItem divider>
                                    <ListItemIcon>
                                        <KitchenTwoTone color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={<AddIngredients />} />
                                </ListItem>

                                {ingredients.map(ingredient => {
                                    const groupeds = havagrouped.filter(e => e.group === ingredient.title);
                                    const isOp = ingredient.type !== 'QTD';
                                    const isSelected = selected === ingredient.uuid;

                                    return (
                                        <div key={ingredient.uuid}>
                                            <ListItem button divider
                                                onClick={() => setSelected(selected === ingredient.uuid ? false : ingredient.uuid)}
                                                selected={isSelected}
                                            >
                                                <ListItemIcon>
                                                    <KitchenTwoTone color="secondary" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        variant: 'caption',

                                                    }}
                                                    primary={ingredient.title + (Boolean(isOp) ? ` (${groupeds.length} items)` : '')}
                                                    secondary={`valor corrente no estoque ${ingredientType(ingredient.type) + ' ' + ingredient.current}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    {isOp &&
                                                        <IconButton onClick={() => setCollapse(ingredient.title)}>
                                                            <AddCircleTwoTone color="secondary" />
                                                        </IconButton>
                                                    }
                                                    <IconButton onClick={() => handleDelete(ingredient)}>
                                                        <DeleteSweepTwoTone color="secondary" />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Collapse in={isSelected}>
                                                <IncrementFields uuid={selected} close={() => setSelected(false)} />
                                            </Collapse>
                                        </div>
                                    )
                                }
                                )}
                            </List>
                        </Collapse>

                        <Collapse in={Boolean(collapse)} >
                            <List dense>
                                <SubHeader>
                                    Ingredientes {collapse} ({allagrouped.length})
                                    <IconButton onClick={e => setCollapse(false)}>
                                        <Close />
                                    </IconButton>
                                </SubHeader>
                                <UniqIngredientsList
                                    list={allagrouped}
                                    onClick={handleConnect}
                                    icon={props => <Checkbox checked={props.group === collapse} />}
                                />
                            </List>
                        </Collapse>
                    </GridOverflowed>
                </Grid>
            </Sidenav>
        </React.Fragment>
    );
}

export default CommerceIngredients;