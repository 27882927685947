import { get, login, logout, upload } from "../../services/Auth";
import socket from "../../services/socket";
import Cache from '../Compress'

/**@type {User} */
export const defaultState = Cache.get('user') || {
    id: 0,
    isActive: false,
    login: null,
    name: null,
    token: null,
    validity: null,
    emailValidation: false,
    faction: null,
    links: [],
    role: {
        id: 0,
        name: null,
        rules: [
            { label: "ComparisonCreate" },
            { label: "ComparisonNavigate" },
            { label: "TaskAssociate" },
            { label: "AdminUsers" }
        ]
    },
}

export const SET_USER = 'set_user';
export const SET_ROLE = 'set_role';
export const SET_RULE = 'set_rule';
export const SET_FACT = 'set_fact';
export const SET_LINK = 'set_link';


export const actions = {
    setUser: (payload) => ({ type: SET_USER, data: payload }),
    setRole: (payload) => ({ type: SET_ROLE, data: payload }),
    setRule: (payload) => ({ type: SET_RULE, data: payload }),
    setFact: (payload) => ({ type: SET_FACT, data: payload }),
    setLink: (payload) => ({ type: SET_LINK, data: payload })
}

export const dispatch = {
    setFactionAddress: (payload) => socket.emit('admin:faction:will:update:address', payload),
    /**@param {{image: Event, title: string, src: string, description: string}} formData*/
     createLink: (formData) => upload(`/gallery/upload/one`, { files: [formData.image] }).then( e => e.json() ).then( e => socket.emit('user:links:set', { ...formData, image: e.filename }) ),
     removeLink: (link) => get(`/gallery/upload/remove/${link}`).then( () => socket.emit('user:links:rmv', {link}) )
}

/**
 * 
 * @param {User} state 
 * @param {*} action 
 */
export const reducer = (state = defaultState, action) => {
    switch (action.type) {

        case SET_USER:
            return state = action.data;

        case SET_LINK:
            return {
                ...state,
                links: [
                    ...action.data
                ]
            };

        case SET_ROLE:
            return {
                ...state,
                role: {
                    ...action.data
                }
            };

        case SET_FACT:
            return {
                ...state,
                faction: {
                    ...action.data
                }
            };

        case SET_RULE:
            return {
                ...state,
                role: {
                    ...state.role,
                    rules: {
                        ...action.data
                    }
                }
            };

        default:
            return state;
    }

}


export const listen = (store) => {
    socket.on('authorized', (data) => {
        login(data.token, () => {
            store.dispatch(actions.setUser(data));

        });
    });

    socket.on('unauthorized', () => {
        logout( () => {
            store.dispatch(actions.setUser(defaultState));


        })
    });

    socket.on('role:change', (data) => {
        store.dispatch(actions.setRole(data));
    });

    socket.on('rule:change', (data) => {
        store.dispatch(actions.setRule(data));
    });

    socket.on('user:links:set', (data) => {
        store.dispatch(actions.setLink(data));
    });

    socket.on('faction:change', (data) => {
        store.dispatch(actions.setFact(data));
    });

}