import { makeStyles, styled } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import ListItemIcon from "@material-ui/core/ListItemIcon";
export default makeStyles(theme => ({
    root: {
        width: 450
    }
})
)

export const Icon = styled(ListItemIcon)({
    paddinRight: 0,
    // fontSize: 11
})

export const Input = styled(InputBase)({
    '-moz-appearance': 'textfield',
    '-webkit-appearance': 'none',
      margin: 0
})