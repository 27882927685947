import React from 'react';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Container } from './styles';
import { connect } from 'react-redux';
import socket from '../../../services/socket'
import CommerceCards from '../PanelCards';
import { SubHeader } from '../../../Components/StyledComponents';

const CommercePanel = connect(
    (state) => {
        return {
            kitchen: state.task.filter(e => e.status === "STOP").sort((a, b) => new Date(a.begin) - new Date(b.begin)),
            awaiting: state.task.filter(e => e.status === "PLAY").sort((a, b) => new Date(a.begin) - new Date(b.begin)),
            inDelivery: state.task.filter(e => e.status === "DONE").sort((a, b) => new Date(a.begin) - new Date(b.begin)),
            released: state.task.filter(e => e.status === "CQ").sort((a, b) => new Date(a.begin) - new Date(b.begin)),
            comments: state.timeline.tasks,
            topics: state.topic,
            toasts: state.toast
        }
    }, null)(props => {

        const handleStatus = (newState, uuid) => {
            socket.emit('order:will:update', { uuid: uuid, status: newState });
        }

        return (
            <Grid container justify="center" spacing={1} style={{ height: 'calc(100% - 10px)' }}>
                <Container item xs={12} md={3} >
                    <SubHeader>
                        <Typography variant="h6">Cozinha</Typography>
                        <IconButton disabled>{props.kitchen.length}</IconButton>
                    </SubHeader>
                    {props.kitchen && props.kitchen.map(task =>
                        <CommerceCards
                            key={'commerce-cards-' + task.uuid}
                            onChangeStatus={(uuid) => handleStatus("PLAY", uuid)}
                            task={task}
                            topics={props.topics.filter(topic => topic.taskId === task.id)}
                            toasts={props.toasts.filter(topic => topic.taskId === task.id)}
                            messages={props.comments[task.id]?.comments || []}
                        />
                    )}
                </Container>

                <Container item xs={12} md={3} >
                    <SubHeader>
                        <Typography variant="h6">Aguardando entrega</Typography>
                        <IconButton disabled>{props.awaiting.length || 0}</IconButton>
                    </SubHeader>
                    {props.awaiting && props.awaiting.map(task =>
                        <CommerceCards
                            key={'commerce-cards-' + task.uuid}
                            onChangeStatus={(uuid) => handleStatus("DONE", uuid)}
                            onBackStatus={(uuid) => handleStatus("STOP", uuid)}
                            task={task}
                            topics={props.topics.filter(topic => topic.taskId === task.id)}
                            toasts={props.toasts.filter(topic => topic.taskId === task.id)}
                            messages={props.comments[task.id]?.comments || []}
                        />
                    )}
                </Container>

                <Container item xs={12} md={3} >
                    <SubHeader>
                        <Typography variant="h6">Saiu para entrega</Typography>
                        <IconButton disabled>{props.inDelivery.length || 0}</IconButton>
                    </SubHeader>
                    {props.inDelivery && props.inDelivery.map(task =>
                        <CommerceCards
                            key={'commerce-cards-' + task.uuid}
                            onChangeStatus={(uuid) => handleStatus("CQ", uuid)}
                            onBackStatus={(uuid) => handleStatus("PLAY", uuid)}
                            task={task}
                            topics={props.topics.filter(topic => topic.taskId === task.id)}
                            toasts={props.toasts.filter(topic => topic.taskId === task.id)}
                            messages={props.comments[task.id]?.comments || []}
                        />
                    )}
                </Container>

                <Container item xs={12} md={3} >
                    <SubHeader>
                        <Typography variant="h6">Entregue</Typography>
                        <IconButton disabled>{props.released.length || 0}</IconButton>
                    </SubHeader>

                    {props.released && props.released.map(task =>
                        <CommerceCards
                            key={'commerce-cards-' + task.uuid}
                            onBackStatus={(uuid) => handleStatus("DONE", uuid)}
                            task={task}
                            topics={props.topics.filter(topic => topic.taskId === task.id)}
                            toasts={props.toasts.filter(topic => topic.taskId === task.id)}
                            messages={props.comments[task.id]?.comments || []}
                            doneOnly
                        />
                    )}
                </Container>
            </Grid>
        )
    })

export default CommercePanel;