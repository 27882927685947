import {host_api} from './setproxy';


export const TOKEN_KEY = "projav-authorization-key";
export const TOKEN_FILE_REFERENCE = "file-reference";
export const TODO_USERS_FILTERS = "todo-users-filter";

export const isAuthenticated = () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if(token && token.length > 33)
        return true;

    return false;

};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token) => token ? localStorage.setItem(TOKEN_KEY, token) : localStorage.removeItem(TOKEN_KEY) ;
export const setRoom  = (room) => room ? window.sessionStorage.setItem('room', room) : window.sessionStorage.removeItem('room') ;

export const login = (token, callback) => {
    localStorage.setItem(TOKEN_KEY, token);
    return ( Boolean(callback) ) ? callback() : null;
};

export const logout = (callback) => {
    localStorage.clear();
    return ( Boolean(callback) ) ? callback() : null;
};

export const post = (endpoint, body, headers = {}) => {

    var proxyurl = `${host_api}/${endpoint.charAt(0) === "/" ? endpoint.substr(1) : endpoint}`;

    if (!headers['Content-Type'])
        headers['Content-Type'] = 'application/json';

    if (!headers['Accept-Econding'])
        headers['Accept-Econding'] = 'gzip';

    if(isAuthenticated()){
        headers['Authorization'] = `Bearer ${getToken()}`;
    }

    console.log('post', {proxyurl} );

    return fetch(proxyurl, {
        method: 'post',
        body: JSON.stringify(body),
        headers
    })
}

export const upload = (endpoint, input, headers = {}) => {

    let data = new FormData()

    for (const file of input.files) {
      data.append('files', file, file.name)
    }

    var proxyurl = `${host_api}/${endpoint.charAt(0) === "/" ? endpoint.substr(1) : endpoint}`;

    if (!headers['Accept-Econding'])
        headers['Accept-Econding'] = 'gzip';

    if(isAuthenticated()){
            
        headers['Authorization'] = `Bearer ${getToken()}`;
        return fetch(proxyurl, {
            method: 'post',
            body: data,
            headers
        })

    }

    return false;
}



export const get = (endpoint, headers = {}) => {

    var proxyurl = `${host_api}/${endpoint.charAt(0) === "/" ? endpoint.substr(1) : endpoint}`;

    if (!headers['Content-Type'])
        headers['Content-Type'] = 'application/json';

    if (!headers['Accept-Econding'])
        headers['Accept-Econding'] = 'gzip';

    if(isAuthenticated()){
        headers['Authorization'] = `Bearer ${getToken()}`;
    }

    return fetch(proxyurl, {
        method: 'get',
        headers
    })
}