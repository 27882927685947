export const CARTE    = e => e.status === "CARTE";
export const REQUIRED = e => e.status === "REQUIRED";
export const REMOVED  = e => e.status === "REMOVED";
export const EDITABLE = e => e.status === "EDITABLE";
export const OPTIONAL = e => e.status === "OPTIONAL";

export const ACTIVES  = e => e.useAsModel === true;
export const ORDERTOTAL = (a,b) => a+parseFloat(b.price || 0) + b.toasts.sum('price', OPTIONAL);

export const INGREDIENTS = (e) => ({
    price: e.price||0,
    title: e.title,
    status: e.status,
    peso: e.peso,
})