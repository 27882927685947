import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

/**
 * @typedef {{
 *  task: import('../../../../typeroots').Task
 *  topics: import('../../../../typeroots').Topic[]
 *  toasts: import('../../../../typeroots').Toast[]
 * }} Props 
 */

const /**@type {React.FC<Props>} */ Folder = ({ task, topics, toasts }) => {

    const memo = React.useMemo(() => {

        return {
            username: task.title,
            address: task.description.Before('|').rmvParentheses(),
            payment: task.description.After('|').rmvParentheses(),
            status: task.status.asStatusText(),
            total: parseFloat(
                topics.sum('price') + toasts.sum('price', e => e.status === 'OPTIONAL')
            ).monetizeme()
        }

    }, [task, topics, toasts])

    return (
        <List dense maxHeight={450} width="100%">
            <ListItem divider>
                <ListItemText>
                    <Typography display="block" gutterBottom variant="caption" >Endereço: {String(task.description).Before('|').rmvParentheses()} </Typography>
                </ListItemText>
            </ListItem>
            <ListItem divider>
                <ListItemText primary="Pagamento" />
                <ListItemText secondary={memo.payment} secondaryTypographyProps={{ style: { float: 'right' } }} />
            </ListItem>
            <ListItem divider>
                <ListItemText primary="Total" />
                <ListItemText secondary={memo.total} secondaryTypographyProps={{ style: { float: 'right' } }} />
            </ListItem>
        </List>
    )
}

export default Folder;