import React from 'react';
import { NavLink } from 'react-router-dom'
import Routes from '../../routes';
import clsx from 'clsx';
import useLocalStorage from '../../services/useLocalStorage';
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Allow, { Faction } from '../Allow'
import useStyles, { BottomBar } from './styles'
import useWindowDimensions from '../../services/useWindowDimensions';

import BuildIcon from '@material-ui/icons/Build';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import StoreIcon from '@material-ui/icons/Store';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';

import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { host_gallery } from '../../services/setproxy';

/**@param {import('react-router-dom').RouteComponentProps & {user: User}} props*/
const DeskNavigation = (props) => {
  const ToggleDrawerOpen = () => open ? setOpen(false) : setOpen(true);
  const classes = useStyles();


  const [open, setOpen] = useLocalStorage('drawer', true);

  React.useEffect(() => {

    if (props.location.pathname === '/')
      setOpen(true);

    else
      setOpen(false);

    localStorage.setItem('pathname', JSON.stringify(props.location.pathname));

  }, [props.location, setOpen])

  return (
    <Drawer
      variant="permanent"
      onClose={() => setOpen(false)}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <List >
        <ListItem button>
          <ListItemAvatar onClick={ToggleDrawerOpen}>
            <Avatar style={{ marginLeft: -8 }} alt={props.user.name} src={`${host_gallery}${props.user.image}_thumb`} />
          </ListItemAvatar>
          <ListItemText
            primary={props.user.login}
            primaryTypographyProps={{
              variant: "caption"
            }}
            secondary={props.user.name}
          />
        </ListItem>
      </List>

      <Divider />

      <List className={classes.navList} >

        <ListItem component={NavLink} button to={{ pathname: '/settings', state: { background: props.location } }}>
          <ListItemIcon><MenuIcon /></ListItemIcon>
          <ListItemText
            primary={"Menu"}
            secondary={'Outras Opções'}
            primaryTypographyProps={{ variant: "subtitle2" }}
            secondaryTypographyProps={{ variant: "caption", style: { fontSize: 9 } }}
          />
        </ListItem>
        <ListItem component={NavLink} button to={{ pathname: '/shortcuts', state: { background: props.location } }}>
          <ListItemIcon><AppsIcon /></ListItemIcon>
          <ListItemText
            primary={"Atalhos"}
            secondary={'Meus aplicativos personalizado'}
            primaryTypographyProps={{ variant: "subtitle2" }}
            secondaryTypographyProps={{ variant: "caption", style: { fontSize: 9 } }}
          />
        </ListItem>

        {Routes.map((route, i) =>
          <Faction on={route.allow} key={route.path + i}>
            <ListItem component={NavLink} button to={{ pathname: route.path }}>
              <ListItemIcon>
                {props.location.pathname.match(route.path) ? <route.active /> : <route.default />}
              </ListItemIcon>
              <ListItemText
                primary={route.label}
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondary={route.caption}
                secondaryTypographyProps={{ variant: "caption", style: { fontSize: 9 } }}
              />
            </ListItem>
          </Faction>
        )}

        <Allow on={'AdminUsers'} >
          <ListItem component={NavLink} button to={{ pathname: '/my/teams' }}>
            <ListItemIcon>
              {props.location.pathname.match('/my/teams')
                ? <SupervisedUserCircleIcon />
                : <SupervisedUserCircleOutlinedIcon />
              }
            </ListItemIcon>
            <ListItemText
              primary={"Meu time"}
              secondary={'Criação/Edição de usuários e perfis'}
              primaryTypographyProps={{ variant: "subtitle2" }}
              secondaryTypographyProps={{ variant: "caption", style: { fontSize: 9 } }}
            />
          </ListItem>

          <ListItem component={NavLink} button to={{ pathname: '/signout' }}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText
              primary={"SignOut"}
              secondary={'Sair do sistema'}
              primaryTypographyProps={{ variant: "subtitle2" }}
              secondaryTypographyProps={{ variant: "caption", style: { fontSize: 9 } }}
            />
          </ListItem>

        </Allow>
      </List>





      <Divider />
      <List>
        <ListItem button>
          <ListItemAvatar onClick={ToggleDrawerOpen}>
            <Avatar style={{ marginLeft: -8 }} alt={props.user.faction?.name} src={`${host_gallery}${props.user.faction?.image}_thumb`} />
          </ListItemAvatar>
          <ListItemText
            primary={props.user.faction?.name}
            primaryTypographyProps={{
              variant: "caption"
            }}
            secondary={props.user.role.name}
          />
        </ListItem>
      </List>

    </Drawer >
  )
}


const MobileNavigation = (props) => {
  const [tab, setTab] = useLocalStorage('pathname');

  const dimensions = useWindowDimensions();

  const handleChangeTab = (event, path) => {
    let background;

    if (path === '/settings')
      background = true;

    else
      setTab(path);


    setTimeout(() => props.history.push(path, { background: background ? props.location : false }), 255);
  };

  if (dimensions.height < 450) return null;

  const { isComparative, isManagement, isRestaurant } = props.user.faction;
  return (
    <BottomBar position="fixed" color="secondary">
      <BottomNavigation
        style={{ justifyContent: 'initial', overflow: 'auto', placeContent: 'center' }}
        value={tab}
        onChange={handleChangeTab}>

        <BottomNavigationAction value={'/settings'} icon={<MenuIcon />} />

        {isComparative &&
          <BottomNavigationAction value={'/comparison/selector'}
            icon={props.location.pathname.match('/comparison/selector') ? <BuildIcon /> : <BuildOutlinedIcon />}
          />}

        {isManagement &&
          <BottomNavigationAction value={'/todos/task'}
            icon={props.location.pathname.match('/todos/task') ? <DashboardIcon /> : <DashboardOutlinedIcon />}
          />}

        {isRestaurant &&
          <BottomNavigationAction value={'/commerce'}
            icon={props.location.pathname.match('/commerce') ? <StoreIcon /> : <StoreOutlinedIcon />}
          />}

        <BottomNavigationAction value={'/my/teams'}
          icon={props.location.pathname.match('/my/teams') ? <SupervisedUserCircleIcon /> : <SupervisedUserCircleOutlinedIcon />}
        />


      </BottomNavigation>
    </BottomBar>
  )
}


export default function MainDrawer(props) {
  const matches = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (matches ? <MobileNavigation {...props} /> : <DeskNavigation {...props} />);
}
