import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box'
import { AutoSizer, List as VList } from 'react-virtualized'

import SaveIcon from '@material-ui/icons/Save'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import BurstModeIcon from '@material-ui/icons/BurstMode';

import { upload } from '../../services/Auth';

import {Emit} from '../../services/useSocket'

import ImagesDIalog from './FeaturesAndAttributesDilalog';


export default function CodenamesListEdit(props) {

  const [input, setInput] = React.useState('');
  const [value, setValue] = React.useState('');
  const [state, setState] = React.useState({
    element: null,
    select: null,
    expand: false
  });

  const [dialogImages, setDialogImages] = React.useState(false);

  const handleFeatureDelete = (item) => window.confirm(`confirma exclusão de ${item.label}?`) && Emit(`comparison:${props.event}:will:delete`, item);

  const handleOpenMenu = (event, item) => {
    setState({
      ...state,
      element: event.currentTarget,
      select: item
    })
  };

  const handleFileUpload = (input, id) =>
    upload(`/upload/${props.event}/${id}`, input)
      .then(res => res.json())
      .then(files => Emit(`comparison:${props.event}:willreceiveupload`, { id, files }))
      .catch(() => alert('erro'));


  const handleFeatureUpsert = (form) => {
    form.preventDefault();
    Emit(`comparison:${props.event}:willUpsert`, { input, value })
    setValue('');
    setInput('');
  };

  const handleMenuItemClick = (option) => {
    const { select } = state;
    Emit(`comparison:${props.event}:will:update`, { option, item: select });
    handleCloseMenu();
  }

  const handleCloseMenu = () => {
    setState({
      ...state,
      element: null,
      select: null
    });
  };


  const RowRenderer = ({ key, index, style }) => (
    <div key={`codespaces-codename${props.name}-${props.event}-${key}`} style={style}>
      <ListItem
        divider
        aria-haspopup="true"
        aria-controls="lock-menu"
        aria-label={props.list[index].label}
        ContainerComponent="div" >

        <ListItemText
          primary={props.list[index].label}
          secondary={props.list[index]?.[props.event]?.label}
        />

        <ListItemSecondaryAction>
          {Boolean(props.list[index].files?.length > 0) &&

            <IconButton onClick={() => setDialogImages(props.list[index])} aria-label="view uploads">
              <BurstModeIcon />
            </IconButton>
          }
          <IconButton onClick={(event) => handleOpenMenu(event, props.list[index])} aria-label="edit">
            <EditIcon />
          </IconButton>
          <input multiple type="file"
            id={`raised-button-file-${props.event}-${index}`}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
            onChange={({ target }) => handleFileUpload(target, props.list[index].id)} />
          <label htmlFor={`raised-button-file-${props.event}-${index}`}>
            <IconButton component="span" aria-label="upload">
              <PublishIcon />
            </IconButton>
          </label>
          <IconButton onClick={() => handleFeatureDelete(props.list[index])} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )

  return (
    <Box height="78vh" width="100%">
      <AutoSizer>
        {({ height, width }) => (
          <Box width={width} height={height}>
            <List dense={true} >
              <ListSubheader component="div">
                Outras {props.name} ({props.options.length})
                </ListSubheader>
              <ListItem>
                <form onSubmit={handleFeatureUpsert} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <Box width="45%" >
                    <Autocomplete
                      fullWidth
                      value={input}
                      onInputChange={(e, newvalue) => setInput(newvalue)}
                      options={props.options.map(option => option.label)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label={props.name}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                  <Box width="50%" >
                    <TextField
                      required
                      fullWidth
                      onChange={(e) => setValue(e.target.value)}
                      autoComplete="off"
                      size="small"
                      name="caption"
                      label="Descrição"
                      value={value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton type="submit" size="small"><SaveIcon /></IconButton>
                          </InputAdornment>
                        )
                      }} />
                  </Box>
                </form>
              </ListItem>
            </List>
            <List dense={true} >
              <ListSubheader component="div">{props.name} da entidade</ListSubheader>

              <VList
                width={width}
                height={height - 98}
                rowCount={props.list.length}
                rowHeight={60}
                rowRenderer={RowRenderer}
              />
            </List>
          </Box>
        )}
      </AutoSizer >
      <Menu
        id="lock-menu"
        anchorEl={state.element}
        open={Boolean(state.element)}
        onClose={handleCloseMenu}
      >
        <MenuList>
          {props.options.map((option, index) => (
            <MenuItem
              key={`${props.event}-${option.label}-${index}`}
              selected={option.label === state.select?.[props.event]?.label}
              onClick={() => handleMenuItemClick(option)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <ImagesDIalog Event={props.event} Item={dialogImages} OnClose={() => setDialogImages(false)} />
    </Box>
  )
}
