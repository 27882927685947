import { styled, withTheme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"

export const Vertical = styled(withTheme(Divider))(
    props => ({
        marginRight: props.theme.spacing(2.5),
        marginLeft: props.theme.spacing(2.5),
        [props.theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }))

export const TodoRow = styled(ListItem)({
    cursor: "pointer",
    '&.MEDIUM': {
        backgroundColor: 'rgba(90,200,200, .05)'
    },
    '&.HIGH': {
        backgroundColor: 'rgba(200,200,90, .05)'
    },
    '&.URGENT': {
        backgroundColor: 'rgba(200,90,90, .05)'
    }
})

export const Column = withTheme(
    styled(ListItemText)(
        props => ({
            whiteSpace: 'nowrap',
            userSelect: 'none',
            MsUserSelect: 'none',
            MozUserSelect: 'none',
            KhtmlUserSelect: 'none',
            WebkitUserSelect: 'none',
            WebkitTouchCallout: 'none',
            maxWidth: props.max,
            width: props.width || '100%',
            overflow: props.overflow || 'auto',
            [props.theme.breakpoints.down('md')]: {
                display: props.display === "desktop" && 'none'
            }
        })
    ))