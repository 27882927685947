import React from "react";
import { List, AutoSizer } from "react-virtualized";
import { VariableSizeList, FixedSizeList } from "react-window";

const ScrollView = ({ boxHeight, rowHeight, rowCount, children, ...props }) =>
    <div style={{ height: boxHeight }}>
        <AutoSizer>
            {({ height, width }) => (
                <List {...props}
                    rowCount={rowCount}
                    rowHeight={rowHeight}
                    height={height}
                    width={width}
                    rowRenderer={children}
                />
            )}
        </AutoSizer >
    </div>

export const VariableScrollView = ({ boxHeight, items, children, ...props }) =>
    <div style={{ height: boxHeight }}>
        <AutoSizer>
            {({ height, width }) => (
                <VariableSizeList
                    height={height}
                    width={width}
                    itemCount={items.length}
                    itemSize={index => items[index].height}
                    {...props} >
                    {children}
                </VariableSizeList>
            )}
        </AutoSizer >
    </div>

export const FixedScrollView = ({ itemSize, boxHeight, itemCount, children, ...props }) =>
    <div style={{ height: boxHeight }}>
        <AutoSizer>
            {({ height, width }) => (
                <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={itemCount}
                    itemSize={itemSize}
                    {...props} >
                    {children}
                </FixedSizeList >
            )}
        </AutoSizer >
    </div>

export default ScrollView;