import React from 'react';
import update from 'immutability-helper'
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import { host_gallery } from '../../services/setproxy';
import useStyles from './styles';
import { addOrder } from '../../Reducers/RestaurantOrders';
import { EDITABLE, OPTIONAL, REQUIRED, INGREDIENTS } from '../../typeroots';
import { SubHeader } from '../../Components/StyledComponents';
import { Box, DialogActions, TextField } from '@material-ui/core';
import AmountSelector from '../../Components/AmountSelector';
import SwipeableModal from '../../Components/SwipeableModal';
import useOpen from '../../services/useOpen';

/**
 * @typedef {import('../../typeroots').Topic} Snack
 * @typedef {import('../../typeroots').Restaurant} Restaurant
 * @typedef {import('../../typeroots').Toast[]} Toasts
 * @typedef {{snack: Snack, restaurant: Restaurant, ListRequireds: Toasts,ListEditables:Toasts,ListOptionals:Toasts} & import('react-router-dom').RouteComponentProps} CommerceCashDialogProps
 */

const mapStateToProps = (state, props) => {

    const snack = state.topic.find(e => e.uuid === props.match?.params?.uuid);

    if(!snack) return { snack: false };

    const restaurant = state.deliver.restaurants.find(e => e.name === props.match?.params?.restaurantuuid);
    const toasts = snack && state.toast.filter(e => e.topicId === snack.id);

    return {
        snack,
        restaurant,
        ListRequireds: toasts?.filter(REQUIRED) || [],
        ListEditables: toasts?.filter(EDITABLE) || [],
        ListOptionals: toasts?.filter(OPTIONAL) || []
    }
}

const mapDispatchToProps = dispatch => ({
    addOrder: (data) => dispatch(addOrder(data))

});

/**@param {CommerceCashDialogProps} props */
function Content({classes, isMobile, ...props}) {
        
    /**@typedef {Array<String>} optionals */
    const [optionals, setOptionals] = React.useState(props.ListEditables);
    
    /**@typedef {number} amount */
    const [amount, setAmount] = React.useState(1);

    /**@typedef {string} description */
    const [description, setDescription] = React.useState('');

    const handleToastAdd = (toast) => () => {
        const indexof = optionals.findIndex(e => e.id === toast.id);
        if (indexof === -1)
            return setOptionals(update(optionals, { $push: [toast] }))

        return setOptionals(update(optionals, { $splice: [[indexof, 1]] }))

    }

    const handleToastToggle = (status, i) => () => {
        setOptionals(update(optionals, {
            [i]: { $merge: { status: status === "DROP" ? "OPTIONAL" : "DROP" } }
        }))
    }

    const setOrders = () => {

        for (let index = 0; index < amount; index++) {
            props.addOrder({
                ...props.snack,
                restaurant: props.restaurant,
                description,
                toasts: [
                    ...props.ListRequireds.map(INGREDIENTS),
                    ...optionals.map(INGREDIENTS)
                ],
            })
        }

        return props.setClose();
    }


    /**@typedef {File?} tile*/
    const tile = props.snack.files[0] || {};

    return (
        <>
            <DialogContent dividers className={classes.content}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5} >
                        <GridList cellHeight={window.innerWidth < 933 ? 300 : 500} cols={1}>
                            <GridListTile cols={1} >
                                <img style={{position:'absolute'}} src={host_gallery + tile.file} alt={tile.name} />
                            </GridListTile>
                        </GridList>
                    </Grid>
                    <Grid item xs={12} md={7} className={classes.gridContent} style={{ height: isMobile ? '100%' : 500 }}>

                        <List>
                            <ListItem>
                                <ListItemText primary={props.snack.title} secondary={props.snack.description} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Typography gutterBottom variant="h5" color="secondary">{(props.snack.price || 0).monetizeme()}</Typography>} />
                                {props.snack.peso ? `${props.snack.peso} - ${props.snack.peso + 10} mins` : null}
                            </ListItem>
                            {/* <ConfigureleToasts props.snack={props.snack} toasts={toasts} Close={props.setClose} {...props} /> */}
                            {props.ListEditables.length > 0 && <>
                                <SubHeader>Editar</SubHeader>
                                {props.ListEditables.map((toast, i) => {
                                    const { status } = optionals?.find(e => e.id === toast.id) || { status: false };

                                    return status && (
                                        <ListItem key={toast.uuid} button onClick={handleToastToggle(status, i)}>
                                            <ListItemText
                                                primaryTypographyProps={{ style: { textDecoration: status === "DROP" ? 'line-through' : '' } }}
                                                primary={toast.title}
                                                secondary={status === "DROP" && 'Item Removido'}
                                            />
                                            <CloseIcon className={status === "DROP" ? 'rotate' : ''} />
                                        </ListItem>
                                    )
                                }
                                )}
                            </>}

                            {props.ListOptionals.length > 0 && <>
                                <SubHeader>Opcionais</SubHeader>
                                {props.ListOptionals.map(toast => {
                                    const hasOptional = optionals?.find(e => e.id === toast.id);

                                    return (
                                        <ListItem component="label" button key={toast.uuid}>
                                            <ListItemText primary={toast.title} secondary={`+ ${toast.price?.monetizeme() || 'Grátis'}`} />
                                            <Switch checked={Boolean(hasOptional)} onChange={handleToastAdd(toast)} />
                                        </ListItem>
                                    )
                                }
                                )}
                            </>}

                            <Box padding={2}>
                                <TextField fullWidth multiline
                                    label={`Observações? ${description.length}/140`}
                                    error={description.length > 130}
                                    onChange={e => setDescription(e.target.value)}
                                    value={description}
                                    rows={2}
                                    max={10}
                                    variant="outlined"
                                    placeholder="Ex: maionese à parte, Carne ao ponto etc..."
                                />
                            </Box>
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {!isMobile && 
                <Button style={{ marginRight: 'auto' }} onClick={props.setClose} variant="contained" color="secondary" startIcon={<ArrowBackIcon />}>
                    Voltar
                </Button>}
                <AmountSelector number={amount} onChange={setAmount} />
                <Button variant="contained" color="secondary" onClick={setOrders}>
                    Adicionar {((optionals.sum('price', e => e.status === 'OPTIONAL') * amount) + (props.snack.price * amount)).monetizeme()}
                </Button>
            </DialogActions>
        </>
    );
}

/**@param {CommerceCashDialogProps} props*/
function CommerceCashDialog(props){

    const [open, close] = useOpen(false, props.match.params.uuid, props.history.goBack );
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const classes = useStyles();

    return (
        <SwipeableModal fullWidth maxWidth={'md'} open={open} onClose={close} >
            {
                Boolean(props.snack)
                ? <Content {...props} setClose={close} isMobile={isMobile} classes={classes}/>
                : <DialogContent className={classes.content}>

                  </DialogContent>
            }
        </SwipeableModal>
    )
    
}

export default connect(mapStateToProps, mapDispatchToProps)(CommerceCashDialog);