import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'

import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from '@material-ui/icons/Delete'
import UpdateIcon from '@material-ui/icons/Update'
import CloseIcon from '@material-ui/icons/Close'

import { upload, get } from '../../services/Auth'
import useLocalstorage from '../../services/useLocalStorage'
import {useHistory} from 'react-router-dom'
import socket from '../../services/socket'
import { connect } from 'react-redux'

import CodenamesReducer from '../../Reducers/CodenamesReducers'
import PreviewTable from './FileUploaPreviewTable'

function SelectInput({ fileWillUpdateInternalKeyCode, fileWillUpdate, fileWillChange, ...props }) {

    const history = useHistory();
    const [file, setFile] = useLocalstorage('file-reference');    
    const [input, setInput] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [preview, setPreview] = React.useState({});
    const [checked, setChecked] = React.useState([]);
    const [update, setUpdate] = React.useState(false);

    const handleCheckbox = (e) => {
        var result = checked

        if (checked.findIndex(x => x === e.target.value) > -1) {
            result.rmv(x => x === e.target.value);
        } else {
            result = [...checked, e.target.value];
        }

        setChecked(result);
    }

    React.useEffect(() => {

        fileWillUpdateInternalKeyCode(null)
        fileWillChange(file)

        if(!file){
            history.replace('/comparison/selector')
        }

        // eslint-disable-next-line
    }, [file])

    const handleClose = () => {
        setPreview({});
        setChecked([]);
        setInput(null);
        setLoading(false);
        document.querySelector('#filerefupload').value = '';
    }


    const savePreview = async () => {
        if (checked.length < 1) return alert("Selecione uma das chaves possíveis, elas serão a correalação para seus codenomes.");

        setLoading(true);
        const response = await upload(`/upload?keys=${checked}&${update && `file=${file}`}`, input)

        if (response.ok) {
            const data = await response.json();

            socket.emit('comparison:reference:did:mount');
            socket.emit('codenames:did:mount', data.filename);

            setFile(data.filename);
            fileWillChange(data.filename);
        }

        else
            alert("erro");

        handleClose();

    }

    const handleChange = (e) => {
        setFile(e.target.value);
        socket.emit('codenames:did:mount', e.target.value);
    }

    const handleDeleteFile = async () => {
        if (!window.confirm("Certeza de excluir esse arquivo?")) return false;

        await get(`/upload/file/delete/${file}`);
        fileWillChange(null);
        setFile(null);

        socket.emit('comparison:reference:did:mount');        
    }

    const showFile = (e) => {
        e.preventDefault()


        const reader = new FileReader();

        var headers = [];
        var body = [];
        var file = null;
        file = e.target.files[0];


        if (file !== '') {

            //input will send on finish job
            setInput(e.target);

            reader.onload = async (e) => {
                e.target.result.split("\n").map((line, i) =>
                    (i === 0)
                        ? headers = line.split(/;|\t/g)
                        : body.push(line.split(/;|\t/g)
                        ))
            };

            reader.onloadend = () => {
                setPreview({ headers, body });
            }


            reader.readAsText(file);
        }


    }

    return (
        <>
            <List dense={true} subheader={
                <ListSubheader component="div">
                    Arquivo de referência
                    <ListItemSecondaryAction>

                        {Boolean(file) &&
                            <Tooltip title="Delete">
                                <IconButton size="small" onClick={handleDeleteFile} component="span">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }

                        <input id="filerefupload" type="file" style={{ display: 'none' }} onChange={showFile} />
                        <label htmlFor="filerefupload">
                            {Boolean(file) &&
                                <Tooltip title="Update">
                                    <IconButton size="small" onClick={() => setUpdate(true)} component="span">
                                        <UpdateIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Upload">
                                <IconButton size="small" onClick={() => setUpdate(false)} component="span">
                                    <PublishIcon />
                                </IconButton>
                            </Tooltip>
                        </label>

                    </ListItemSecondaryAction>
                </ListSubheader>}>
                <ListItem>

                    <Box width="100%" display="flex" alignItems="center" >
                        <FormControl fullWidth >
                            <Select
                                labelId="file-simple-select-label"
                                id="file-simple-select"
                                value={file}
                                onChange={handleChange}>
                                {props.codenames.fileCurrentList.map(list => <MenuItem key={list.file} value={list.file}>{list.name}</MenuItem>)}
                            </Select>
                            <FormHelperText>Selecione o arquivo</FormHelperText>
                        </FormControl>
                        <InputAdornment position="end">
                            <IconButton onClick={() => setFile(false)} component="span">
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    </Box>

                </ListItem>
            </List>
            <Dialog maxWidth={"xl"} open={Boolean(preview.body)} onClose={handleClose}>
                <DialogTitle>Selecionar colunas de correlação {update && "para atualização"} </DialogTitle>

                <DialogContent>
                    <PreviewTable
                        handleCheckbox={handleCheckbox}
                        headers={preview.headers || []}
                        body={preview.body || []}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Sair
                    </Button>
                    {loading && <CircularProgress disableShrink />}
                    {!loading && <Button onClick={savePreview} disabled={loading} variant="outlined" color="secondary"> Salvar </Button>}
                </DialogActions>
            </Dialog>
        </>

    )
}


const mapStateToProps = state => {
    return {
        codenames: state.codenames
    }
}

const { fileWillUpdateInternalKeyCode, fileWillUpdate, fileWillChange } = new CodenamesReducer();

const mapDispatchToProps = dispatch => {
    return {
        fileWillUpdateInternalKeyCode: HashId => {
            dispatch(fileWillUpdateInternalKeyCode(HashId))
        },
        fileWillUpdate: content => {
            dispatch(fileWillUpdate(content))
        },
        fileWillChange: filekeycode => {
            dispatch(fileWillChange(filekeycode))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);