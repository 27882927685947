import TodosMap from "../../Reducers/TodosMap"


/**
 * @param {import("react-redux").DefaultRootState} state 
 * @return {state}
 */
export const mapStateToProps = state => {

    const todos = new TodosMap(state);

    return {
        task: todos.tasks,
        topic: todos.topics,
        toast: todos.toasts
    }

}