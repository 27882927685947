import socket from '../services/socket';
import { get } from '../services/Auth'

const initialState = {
    file: JSON.parse(localStorage.getItem('file-reference')),
    fileKeyCode: null, // the id internal file
    fileContentList: [],
    fileCurrentList: [],
    fileCurrentSelected: {
        file: '',
        filekeys: [],
        name: ''
    },
    codenames: [],
    features: [],
    attributes: [],
    comparisons: []
};

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const initFile = filename => {
    if (filename) {
        socket.emit('codenames:did:mount', filename);
    }
};

function remove(array, data) {
    array.rmv(e => e.id === data.id);
    return array;
}

function upsert(array, data) {

    if (!array.set(e => e.id === data.id, data))
        array = [data, ...array];

    return array;
}

const DIDMOUNT = `codenames:did:mount`;
const WILLUPSERT = `codenames:willUpsert`;
const WILLDELETE = `codenames:will:delete`;

const FEATURESDIDMOUNT = "codenames:features:did:mount";
const FEATURESWILLCREATE = "codenames:features:will:create";
const FEATURESWILLDELETE = "codenames:features:will:delete";

const ATTRIBUTESDIDMOUNT = "codenames:attributes:did:mount";
const ATTRIBUTESWILLCREATE = "codenames:attributes:will:create";
const ATTRIBUTESWILLDELETE = "codenames:attributes:will:delete";

const FILEDIDMOUNT = `comparison:reference:did:mount`;
const FILEWILLRECEIVEPROPS = `comparison:reference:will:update`; // receive content of file from api
const FILEWILLCHANGEHEADERS = `comparison:reference:willChangeHeaders`;

const FILEWILLUPDATEINTERNALKEYCODE = `codenames:file:will:update`;  // when change content key code not server side
const FILEWILLCHANGE = `codenames:file:will:change`; // when change keycode load new file not server side

export default class CodenamesReducer {

    didMount = (payload) => ({ type: DIDMOUNT, data: payload });
    willUpsert = (payload) => ({ type: WILLUPSERT, data: payload });
    willDelete = (payload) => ({ type: WILLDELETE, data: payload });

    featuresDidMount = (payload) => ({ type: FEATURESDIDMOUNT, data: payload });
    featuresWillCreate = (payload) => ({ type: FEATURESWILLCREATE, data: payload });
    featuresWillDelete = (payload) => ({ type: FEATURESWILLDELETE, data: payload });

    attributesDidMount = (payload) => ({ type: ATTRIBUTESDIDMOUNT, data: payload });
    attributesWillCreate = (payload) => ({ type: ATTRIBUTESWILLCREATE, data: payload });
    attributesWillDelete = (payload) => ({ type: ATTRIBUTESWILLDELETE, data: payload });

    fileWillUpdateInternalKeyCode = (payload) => ({ type: FILEWILLUPDATEINTERNALKEYCODE, data: payload });
    fileWillChange = (payload) => ({ type: FILEWILLCHANGE, data: payload });
    fileWillChangeHeaders = (payload) => ({ type: FILEWILLCHANGEHEADERS, data: payload });
    fileDidMount = (payload) => ({ type: FILEDIDMOUNT, data: payload });
    fileWillUpdate = (payload) => ({ type: FILEWILLRECEIVEPROPS, data: payload });

    listen = (store) => {

        socket.on(DIDMOUNT, (data) => {
            store.dispatch(this.didMount(data))
        });
        socket.on(WILLUPSERT, (data) => {
            store.dispatch(this.willUpsert(data))
        });
        socket.on(WILLDELETE, (data) => {
            store.dispatch(this.willDelete(data))
        });

        socket.on(FEATURESDIDMOUNT, (features) => {
            store.dispatch(this.featuresDidMount(features));
        });

        socket.on(FEATURESWILLCREATE, (data) => {
            const { features } = store.getState().codenames;
            features.push(data);
            store.dispatch(this.featuresWillCreate(features));
        });

        socket.on(FEATURESWILLDELETE, (data) => {
            const { features } = store.getState().codenames;
            features.rmv(x => x.id === data.id);
            store.dispatch(this.featuresWillDelete(features));
        });

        socket.on(ATTRIBUTESDIDMOUNT, (attributes) => {
            store.dispatch(this.attributesDidMount(attributes));
        });
        socket.on(ATTRIBUTESWILLCREATE, (data) => {
            const { attributes } = store.getState().codenames;
            attributes.push(data);
            store.dispatch(this.attributesWillCreate(attributes));
        });
        socket.on(ATTRIBUTESWILLDELETE, (data) => {
            const { attributes } = store.getState().codenames;
            attributes.rmv(x => x.id === data.id);
            store.dispatch(this.attributesWillDelete(attributes));
        });



        socket.on(FILEDIDMOUNT, (data) => {
            store.dispatch(this.fileDidMount(data));
        });
        socket.on(FILEWILLCHANGEHEADERS, (data) => {
            store.dispatch(this.fileWillChangeHeaders(data));
        });

        socket.on(FILEWILLRECEIVEPROPS, (data) => {
            store.dispatch(this.fileWillUpdate([]));

            socket.emit('codenames:attributes:did:mount', data);
            socket.emit('codenames:features:did:mount', data);

            return get(`/upload/get/${data}`)
                .then(res => res.json())
                .then(content => {
                    store.dispatch(this.fileWillUpdate(content));
                })
                .catch(() => alert('Erro ao pegar arquivo'));

        })

        socket.emit('comparison:reference:did:mount');
        initFile(initialState.file);
    }

    reducer = (state = initialState, action) => {
        switch (action.type) {

            case DIDMOUNT:

                return {
                    ...state,
                    codenames: action.data
                };

            case WILLUPSERT:
                const upsertdata = upsert(state.codenames, action.data);
                return {
                    ...state,
                    codenames: [
                        ...upsertdata
                    ]
                };

            case WILLDELETE:
                const deletedata = remove(state.codenames, action.data);
                return {
                    ...state,
                    codenames: [
                        ...deletedata
                    ]
                };

            case FILEWILLCHANGE:

                if (state.file !== action.data) {
                    debounce(() => initFile(action.data), 666);
                }

                return {
                    ...state,
                    file: action.data,
                };

            case FEATURESDIDMOUNT:
                return {
                    ...state,
                    features: [...action.data]
                }

            case FEATURESWILLCREATE:
                return {
                    ...state,
                    features: [...action.data]
                }

            case FEATURESWILLDELETE:
                return {
                    ...state,
                    features: [...action.data]
                }

            case ATTRIBUTESDIDMOUNT:
                return {
                    ...state,
                    attributes: [...action.data]
                }
            case ATTRIBUTESWILLCREATE:
                return {
                    ...state,
                    attributes: [...action.data]
                }
            case ATTRIBUTESWILLDELETE:
                return {
                    ...state,
                    attributes: [...action.data]
                }

            case FILEWILLUPDATEINTERNALKEYCODE:
                return {
                    ...state,
                    fileKeyCode: state.fileKeyCode === action.data ? null : action.data
                };

            case FILEWILLRECEIVEPROPS:
                return {
                    ...state,
                    fileContentList: action.data,
                    fileCurrentSelected: {
                        ...state.fileCurrentList.search(e => e.file === state.file) || initialState.fileCurrentSelected
                    }
                };

            case FILEWILLCHANGEHEADERS:
                const fileCurrentListHeaders = state.fileCurrentList;
                fileCurrentListHeaders.set(e => e.file === action.data.file, { filekeys: action.data.filekeys })

                return {
                    ...state,
                    fileCurrentList: [
                        ...fileCurrentListHeaders
                    ],
                    fileCurrentSelected: {
                        ...state.fileCurrentList.search(e => e.file === state.file) || initialState.fileCurrentSelected
                    }
                };

            case FILEDIDMOUNT:
                return {
                    ...state,
                    fileCurrentList: action.data
                };

            default:
                return state;
        }
    }



}