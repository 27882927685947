import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDistance } from 'geolib';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'

import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Sidenav from '../../Components/Sidenav';

import MessageIcon from '@material-ui/icons/Message';
import FaceIcon from '@material-ui/icons/Face';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { createOrder, rmvOrder, clrOrder } from '../../Reducers/RestaurantOrders';

import CommerceOrdersList from '../../Components/CommerceOrdersList';
import { DrawerList } from '../../Components/StyledComponents';
import Tooltip from '@material-ui/core/Tooltip'
import { Close, Done } from '@material-ui/icons';

import useStyles from './styles';

/**
 * @typedef {import('../../typeroots').ClientWeb} Client
 * @param {*} props 
 */

const margin = { marginRight: 26 };

function CommerceCashOrders(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const orders = useSelector(({ orders }) => orders.filter(e => e.id));
    const faction = useSelector(({ user }) => user.faction);
    const [observations, setObservations] = useState('');
    const [payment, setPayment] = React.useState('Dinheiro');

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(true);

    const setClose = () => {
        setOpen(false);
        setTimeout(props.history.goBack, 555);
    }

    /**@typedef {Client} client */
    const client = useSelector(state => state.clients.find(e => e.selected === true));


    const [selectAddress, setSelectAddress] = useState(false);

    const handleSelectAddress = useCallback(address => (e) => {
        setSelectAddress(address);

    }, [])

    useEffect(() => {
        if (client)
            setSelectAddress(client.address?.[0]);

    }, [client]);

            
    if(!faction?.address) 
    return (
        <Sidenav open={open} onClose={setClose} onOpen={() => setOpen(true)}>
            <DrawerList>
                <ListItem>
                    <ListItemText primary="Cadastre o endereço do restaurante primeiro"/>
                </ListItem>
            </DrawerList>            
        </Sidenav>        
    )

    let [ld1, ll1] = (selectAddress?.coords) ? selectAddress.coords.split(',') : [];
    let [ld2, ll2] = (faction?.address.coords) ? faction.address.coords.split(',') : [];
    
    const distance = ld1 && ld2
    ? ((getDistance({ latitude: ld1, longitude: ll1 }, { latitude: ld2, longitude: ll2 }) / 1000) * 1.6).toFixed(0)
        : null;

    return (
        <Sidenav open={open} onClose={setClose} onOpen={() => setOpen(true)}>

            <DrawerList size={550}>

                <CommerceOrdersList
                    orders={orders}
                    onRemove={(order) => dispatch(rmvOrder(order))}
                    onClear={() => { dispatch(clrOrder()); setClose() }}
                />

                {!client &&
                    <Fragment>
                        <ListItem divider>
                            <Button onClick={() => props.history.push("#clients")} fullWidth variant="contained" color="secondary">
                                Selecionar cliente
                            </Button>
                        </ListItem>
                    </Fragment>
                }

                {client && orders.length > 0 &&
                    <Fragment>
                        <ListItem divider>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText
                                primary={distance ? `${distance}KM` : 'Endereço não cadastrado'}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemIcon><MessageIcon /></ListItemIcon>
                            <ListItemText style={margin} fullWidth>
                                <TextField select
                                    style={{ marginRight: 6 }}
                                    value={payment}
                                    label="Opções"
                                    helperText="Método de pagamento"
                                    onChange={e => { setObservations(''); setPayment(e.target.value) }}>
                                    <MenuItem value="Débito">Débito</MenuItem>
                                    <MenuItem value="Crédito">Crédito</MenuItem>
                                    <MenuItem value="Dinheiro">Dinheiro</MenuItem>
                                </TextField>

                                {payment === "Dinheiro" &&
                                    <TextField
                                        style={{ marginTop: 3, minWidth: 275 }}
                                        label="Precisa de troco?"
                                        helperText="Observações"
                                        value={observations}
                                        onChange={e => setObservations(e.target.value)}
                                    />
                                }

                            </ListItemText>
                        </ListItem>
                        <ListItem divider button onClick={() => props.history.push("#clients")}>
                            <ListItemIcon><FaceIcon /></ListItemIcon>
                            <ListItemText
                                style={margin}
                                primary={client.name}
                                secondary={client.contacts.map(e => e.phone).join(' - ')}
                            />
                        </ListItem>

                        <ListItem divider button onClick={() => props.history.push(`/commerce/client/${client.uuid}`)}>
                            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                            <ListItemText
                                style={margin}
                                secondary={"Editar/Cadastrar Novo Endereço"}
                            />
                        </ListItem>

                        <RadioGroup aria-label="address" name="address" value={selectAddress}>
                            {client.address.map(address => {
                                let [ld3, ll3] = (address?.coords) ? address.coords.split(',') : [];
                                let distance = (Boolean(ld3))
                                    ? ((getDistance({ latitude: ld3, longitude: ll3 }, { latitude: ld2, longitude: ll2 }) / 1000) * 1.6).toFixed(0)
                                    : null;

                                const distanceIsValid = Boolean(distance <= 8);

                                return (
                                    <ListItem divider button 
                                        key={address.uuid}
                                        component="label">
                                        <ListItemIcon>
                                            <FormControlLabel  
                                                disabled={!distanceIsValid}
                                                value={address} 
                                                control={<Radio />} 
                                                onChange={handleSelectAddress(address)} 
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            style={margin}
                                            primary={Boolean(distance) ? `${address.title} ${distance} KM` : `${address.title} Fora da área de cobertura`}
                                            secondary={`${address.street}, ${address.number} - ${address.city}`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Tooltip title={distanceIsValid ? 'Dentro da área de cobertura' : "fora da área de cobertura"}>
                                                <Avatar className={distanceIsValid ? classes.green : classes.red }>
                                                    {distanceIsValid ? <Done/> : <Close />}
                                                </Avatar>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                            )}
                        </RadioGroup>
                        {client && client.address.length > 0 &&
                            <ListItem>
                                <Button
                                    fullWidth
                                    endIcon={loading && <CircularProgress disableShrink size={20} />}
                                    disabled={loading}
                                    onClick={() => {
                                        setLoading(true);
                                        createOrder({ orders, selectAddress, payment, observations });
                                    }}
                                    variant="contained"
                                    color="secondary">
                                    Concluir Pedido
                                    </Button>
                            </ListItem>
                        }
                    </Fragment>
                }
            </DrawerList>


        </Sidenav>
    );
}

export default CommerceCashOrders;