import update from "immutability-helper";
import socket from "../../services/socket"
import Cache from '../Compress'

/**@type { Ingredients }*/
const initalState = Cache.get('ingredients') || [];

export const DID_MOUNT      = 'ingredients:did:mount';
export const WILL_CHANGE    = 'ingredients:will:change';
export const WILL_CREATE    = 'ingredients:will:create';
export const WILL_UPDATE    = 'ingredients:will:update';
export const WILL_DELETE    = 'ingredients:will:delete';
export const WILL_CONNECT   = 'ingredients:will:connect';
export const WILL_INCREMENT = 'ingredients:will:increment';

export const SET_SELECTED = 'ingredients:set:active';

export const actions = {
    didMount: (payload) => ({ type: DID_MOUNT, data: payload }),
    willlChange: (payload) => ({ type: WILL_CHANGE, data: payload }),
    willCreate: (payload) => ({ type: WILL_CREATE, data: payload }),
    willDelete: (payload) => ({ type: WILL_DELETE, data: payload }),
    willUpdate: (payload) => ({ type: WILL_UPDATE, data: payload }),

    SetSelected:(payload) => ({ type: SET_SELECTED, data: payload }),
}

export const listen = (store) => {
    socket.on(DID_MOUNT,   (data) => store.dispatch(actions.didMount(data)))
    socket.on(WILL_CREATE, (data) => store.dispatch(actions.willCreate(data)))
    socket.on(WILL_DELETE, (data) => store.dispatch(actions.willDelete(data)))
    socket.on(WILL_UPDATE, (data) => store.dispatch(actions.willUpdate(data)))

    setTimeout(() => {
        socket.emit(DID_MOUNT);
    }, 3777)

}

export const dispatch = {
    /**@param {{title: string, type: string, item: number?}} props*/
    Create: (props) => {
        return socket.emit(WILL_CREATE, props );
    },
    /**@param {{uuid: string}} props*/
    Delete: ({uuid}) => {
        return window.confirm("Deseja Excluir Este Ítem?") && socket.emit(WILL_DELETE, {uuid} )
    },
    /**@param {{uuid: string, title: string, type: string}} props*/
    Update: ({uuid, ...datatoupdate}) => {
        return socket.emit(WILL_UPDATE, {uuid, ...datatoupdate} )
    },
    /**@param {{uuid: string, price: string, amount: number, amountAlert, validity: date, validityAlert: date }} props*/
    Increment: (props) => {
        return socket.emit(WILL_INCREMENT, {
            uuid: props.uuid,
            price: props.price.CurrencyToFloat(),
            amount: Number(props.amount), 
            amountAlert: Number(props.amount), 
            validity: props.validity.toDate('date'), 
            validityAlert: props.validityAlert.toDate('date')
        })
    },
    Connect: (props) => {
        return socket.emit(WILL_CONNECT, props )
    },
}


const reducer = (state = initalState, action) => {
    switch (action.type) {
        case DID_MOUNT:
            return state = action.data;

        case WILL_CREATE:
            return update(state, {
                $push: [action.data]
            });

        case WILL_UPDATE:

            return update(state, { 
                [state.findIndex(e => e.id === action.data.id)]: { $set: action.data }
            })

        case SET_SELECTED:

            state.map( e => e.id === action.data.id ? e.selected = true : e.selected = false );
            return [...state];

        case WILL_DELETE:
            return [...state.filter(e => e.id !== action.data.id)];

        default:
            return state;
    }
}


export default reducer