import React from "react";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {
    DateRangePicker,
    LocalizationProvider
} from "@material-ui/pickers";
import ptBR  from "date-fns/locale/pt-BR";

/**
 * 
 * @type {React.FC<{
 *  startText: String,
 *  endText: String,
 *  allowSameDateSelection: Boolean|false,
 *  allowKeyboardControl: Boolean,
 *  value: Array<Number,Number>,
 *  orientation: ("portrait" | "landscape"),
 *  calendars: (1|2|3)|2,
 *  cancelText: String|"CANCEL",
 *  clearText: String|"CLEAR",
 *  displayStaticWrapperAs: ("desktop" | "mobile" | "static"),
 *  loading: Boolean,
 *  DialogProps,
 *  PopperProps,
 *  onOpen: <> => void,
 *  onClose: <> => Date,
 *  onAccept: <> => Date|null,
 *  onChange: <> => Date,
 *  component: JSX.ElementClass
 * }>} 
 */
const InputDateRange = ({ component, ...props }, ref) => {
    return (
        <LocalizationProvider locale={ptBR} dateAdapter={DateFnsUtils} >
            <DateRangePicker ref={ref} 
                renderInput={component}
                {...props}
            />
        </LocalizationProvider>
    )
}

export default React.forwardRef(InputDateRange)