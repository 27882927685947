import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';

import { Actions } from '../Panel/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import PrintIcon from '@material-ui/icons/Print';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MessageIcon from '@material-ui/icons/Message'
import Folder from './Folder';
import Content from './Content';

import { host_api, fromGallery } from '../../../services/setproxy';
import { getToken } from '../../../services/Auth';
// import { Container } from './styles';

/**
 * @typedef {{
 *  task: import('../../../typeroots').Task,
 *  topics: import('../../../typeroots').Topic[],
 *  toasts: import('../../../typeroots').Toast[],
 *  messages: import('../../../typeroots').Comment[],
 *  onBackStatus(uuid: string): void,
 *  onChangeStatus(uuid: string): void,
 *  doneOnly: Boolean
 * }} Props 
 */

const/**@type {React.FC<Props>}*/CommercePanelCards = ({ task, topics, toasts, messages, ...props }) => {

    const history = useHistory();
    const faction = useSelector(state => state.user.faction);

    const [color, setColor] = React.useState(null);
    const [visibility, setVisibility] = React.useState(false);
    const [lenMessages, setLenMessages] = React.useState(messages.length)

    const [minutes, setMinutes] = React.useState(task.begin.CompareMinutes(props.doneOnly ? task.done : null))

    const toggleVisibility = (id) => {
        if (visibility === id) {
            setVisibility(false);
        } else {
            setVisibility(id);
        }
    }

    React.useEffect(() => {
        let interval, minutes = 0;

        if (!props.doneOnly && !interval) {
            interval = setInterval(() => {

                if (props.doneOnly) {
                    minutes = task.begin.CompareMinutes(task.done);
                }
                else {
                    minutes = task.begin.CompareMinutes();
                }

                setMinutes(minutes)
            }, 30000);
        }

        return () => clearInterval(interval);

    }, [props.doneOnly, task.begin, task.done]);

    React.useEffect(() => {

        if (task.status === "CQ") return setColor(null);
        if (task.status === "DONE" && minutes >= 30) return setColor("rgba(166, 63, 63, .5)");
        if (task.status === "PLAY" && minutes >= 20) return setColor("rgba(166, 63, 63, .5)");
        if ((task.status === "NEW" || task.status === "STOP") && minutes >= 10) return setColor("rgba(166, 63, 63, .5)");

        setColor("rgba(63, 137, 166, .5)");


    }, [minutes, task.status])


    return (
        <Card key={'commerce-cards-' + task.uuid} style={{ marginBottom: 5 }} >
            <CardActionArea onClick={() => toggleVisibility(task.id)}>
                <CardHeader
                    style={{ backgroundColor: color }}
                    title={<Typography variant="caption" display="block">#{task.id} - {task.title.toUpperCase()}</Typography>}
                    titleTypographyProps={{ variant: 'caption' }}
                    action={`${props.doneOnly ? 'Em' : 'Há'} ${minutes.displayAsTime()}`}
                />
                <CardContent style={{ padding: 0 }}>
                    <Collapse in={Boolean(visibility)}>
                        <Content topics={topics} toasts={toasts} />
                    </Collapse>
                    <Collapse in={!Boolean(visibility)}>
                        <Folder task={task} topics={topics} toasts={toasts}/>
                    </Collapse>

                </CardContent>
            </CardActionArea>
            <Actions>
                <Avatar src={fromGallery(faction.image, true)} />

                <IconButton onClick={() => {
                    history.push(`/todos/comment/task/${task.uuid}`, { background: history.location });
                    setLenMessages(messages.length)
                }}>
                    <Badge badgeContent={messages.length} color={lenMessages === messages.length ? "secondary" : "error"}>
                        <MessageIcon />
                    </Badge>
                </IconButton>

                <IconButton onClick={() => {
                    window.open(`${host_api}/documents/deliver/timeline/${task.uuid}?key=${getToken()}`, "_blank");
                }}>
                    <PrintIcon />
                </IconButton>

                {Boolean(props.onBackStatus) &&
                    <IconButton onClick={() => props.onBackStatus(task.uuid)}>
                        <ArrowBackIcon />
                    </IconButton>
                }
                {Boolean(props.onChangeStatus) &&
                    <IconButton onClick={() => props.onChangeStatus(task.uuid)}>
                        <DoneAllIcon />
                    </IconButton>
                }
            </Actions>
        </Card>
    );
}

export default CommercePanelCards;