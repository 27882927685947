import React from 'react'
import useLocalstorage from '../../services/useLocalStorage'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Fade from '@material-ui/core/Fade'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ListItemText from '@material-ui/core/ListItemText'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import FileInputSelect from './FileInputSelect'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Socket, Emit } from '../../services/useSocket'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from "lodash";

import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import SubNavigation from '../Codenames/SubNavigation'

// const filterOptions = debounce( (options, { inputValue }) => options.$filter(inputValue).slice(0, 25), 2000);

// const filterOptions = (options, { inputValue }) => options.$filter(inputValue).slice(0, 25);

const getOptionsLabel = (data) => (option) => (
    <Typography noWrap={true} variant="caption">
        {data?.filekeys.map(e => option[e]).join(' ')}
    </Typography>

)

const ComparisonIndex = (props) => {
    const [file] = useLocalstorage('file-reference');
    const history = useHistory();
    const [autocomplete, setAutcomplete] = React.useState(false);
    const [autocompleteResults, setAutocompleteResults] = React.useState([]);
    const [focus, setFocus] = React.useState(false);
    const [comparisons, setComparisons] = React.useState({
        items: []
    });

    React.useEffect(() => {
        if (!file) {
            history.push('/comparison/selector');
        }

        // eslint-disable-next-line
    }, [file]);

    const handleSearch = (e, value) => {
        var result = props.codenames.fileContentList.$filter(value).slice(0, 50);
        setAutocompleteResults(result);
    }

    const handleAutocompleteSelect = (event = null, comparativeObjects) => {
        if (typeof comparativeObjects === 'object')
            setAutcomplete(comparativeObjects);
    }

    const handleThisCodenameToComparison = (data) => {
        if (!comparisons.items.some(e => e.id === data.id)) {
            const { items } = comparisons;
            items.push(data);

            setComparisons({
                items: items
            });
        }
        else {
            const { items } = comparisons;
            items.rmv(d => d.id === data.id);

            setComparisons({
                items: items
            });
        }
    }

    return file ? (
        <Grid container >
            <Socket events={{
                'codenames:will:update': handleThisCodenameToComparison
            }} />

            <SubNavigation />

            {!focus &&
                <Fade in={!focus} direction={`right`}>
                    <Grid item xs={12} md={4}>
                        <Paper spacing={2} style={{ height: '98vh', marginRight: 4, overflow: 'auto' }}>
                            <FileInputSelect />
                            <List subheader={<ListSubheader >Pesquisa de objeto</ListSubheader >}>
                                <ListItem>
                                    {Boolean(props.codenames.fileContentList?.length) ?
                                        !Boolean(autocomplete)
                                            ? <Autocomplete fullWidth freeSolo
                                                onChange={handleAutocompleteSelect}
                                                onInputChange={debounce(handleSearch, 600)}
                                                filterOptions={x => x}
                                                options={autocompleteResults}
                                                getOptionLabel={getOptionsLabel(props.codenames.fileCurrentSelected)}
                                                renderInput={(params) =>
                                                    <TextField {...params} autoFocus
                                                        helperText="Selecione um item para incluir na comparação"
                                                        label={`Explorar ${props.codenames.fileContentList.length} itens`}
                                                        size="small"
                                                    />}
                                            />
                                            : <ListItemText button
                                                onClick={() => setAutcomplete(false)}
                                                primary={props.codenames.fileCurrentSelected.filekeys.map(e => autocomplete[e]).slice(0, 2).join(' ')}
                                                secondary={props.codenames.fileCurrentSelected.filekeys.map(e => autocomplete[e]).slice(2).join(' ')} />

                                        : <ListItemText primary="Carregando dados ..." />
                                    }
                                </ListItem>
                            </List>
                            <List subheader={<ListSubheader >Resultado da pesquisa {autocomplete._id}</ListSubheader >}>
                                {props.codenames.codenames.filter(e => autocomplete._id === e.refer).map(e =>
                                    <ListItem button divider
                                        selected={Boolean(comparisons.items.some(x => x.id === e.id))}
                                        onClick={() => Emit('codenames:get', e.id)}>
                                        <ListItemText primary={e.label} secondary={`${e.refer} - ${e.caption}`} />
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Grid>
                </Fade>
            }

            <Grid item xs={12} md={!focus && 8} >
                <TableContainer component={Paper} spacing={2} style={{ height: '98vh', overflow: 'auto', display: 'flex' }}>
                    <div onClick={() => setFocus(!focus)}
                        style={{
                            alignSelf: 'center'
                        }}>
                        <IconButton size="small">
                            <ChevronLeftOutlinedIcon className={focus ? 'rotate-180g' : ''} />
                        </IconButton>
                    </div>

                    {Boolean(comparisons.items.first()) ?
                        <Table stickyHeader
                            size="medium"
                            style={{
                                minWidth: 600,
                                width: comparisons.items.length * 250 + 250
                            }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Atributos</b></TableCell>
                                    {comparisons.items.map(comparison => <TableCell key={comparison.label}>{comparison.label}</TableCell>)}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.codenames.attributes.map(attribute =>
                                    <TableRow key={attribute.label}>
                                        <TableCell>{attribute.label}</TableCell>
                                        {comparisons.items.map(comparison => <TableCell>{comparison.attributes[attribute.id] || '-'}</TableCell>)}
                                    </TableRow>
                                )}
                            </TableBody>

                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Características</b></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.codenames.features.map(feature =>
                                    <TableRow key={feature.label}>
                                        <TableCell>{feature.label}</TableCell>
                                        {comparisons.items.map(comparison => <TableCell>{comparison.features[feature.id] || '-'}</TableCell>)}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        : <Box alignSelf="center">
                            <Typography variant="body1">
                                Tabela de comparação de items
                            </Typography>
                            <Typography variant="body2">
                                Explore e escolha seus codenomes para começar
                            </Typography>
                        </Box>
                    }
                </TableContainer>
            </Grid>
        </Grid>
    )
        : null
}


export default connect((state) => ({
    codenames: state.codenames
})
)(ComparisonIndex);