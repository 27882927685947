import React from 'react';
import socket from '../../../services/socket';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const defaultInputProps = {
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    required: true,
    style: {
        marginTop: 18
    }
}

const initialState = {
    login: '',
    name: '',
    password: '',
    validity: '',
}
function AddUserDialog(props) {
    const [user, setUser] = React.useState(initialState)

    const handleCreateUser = () => {
        socket.emit('admin:users:will:create', {...user, validity: new Date(user.validity) });
        setUser(initialState);
        props.onClose();
    };

    const check = React.useMemo(() => {
        return (user.name.testName() && user.login.length > 5 && user.password.testPassword() && user.validity) ? true : false;

    }, [user])

    return (
        <Dialog {...props} keepMounted={false}>
            <DialogTitle>
                Cadastro de novo usuário de acesso
            </DialogTitle>
            <DialogContent>

                <TextField {...defaultInputProps}
                    label="Nome"
                    error={!user.name.testName()}
                    value={user.name}
                    onChange={e => setUser({ ...user, name: e.target.value.Capitalize() })}
                    helperText="Um nome completo é importante para identificação"
                />

                <TextField {...defaultInputProps}
                    label="Login"
                    error={user.login.length < 5}
                    value={user.login}
                    onChange={e => setUser({ ...user, login: e.target.value })}
                    helperText="Acesso do usuário"
                />

                <TextField {...defaultInputProps}
                    label="Senha"
                    value={user.password}
                    helperText="A senha deve conter char especiais, uma letra maíscula e pelo menos um numero"
                    onChange={e => setUser({ ...user, password: e.target.value })}
                    error={!user.password.testPassword()}
                />

                <TextField {...defaultInputProps} type="date"
                    label="Validade"
                    value={user.validity}
                    InputLabelProps={{ shrink: true }}
                    helperText="Se expirar não haverá acesso"
                    onChange={e => setUser({ ...user, validity: e.target.value })}
                />

            </DialogContent>
            <DialogActions>
                <Button disabled={!check} onClick={() => setUser(initialState)} style={{marginRight: 'auto'}}>Limpar campos</Button>
                <Button onClick={props.onClose}>Sair</Button>
                <Button onClick={handleCreateUser} disabled={!check}>Salvar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddUserDialog;