import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './styles.css';
import { connect } from 'react-redux';
import socket from '../../services/socket';

import { isAuthenticated, logout } from '../../services/Auth';

const emitGeolocation = ({ latitude, longitude }) => {
  socket.emit('user:current:position', { lat: latitude, lon: longitude });
}

/**@param {{message: string, click: function, clickMessage: string}} props */
const LoadingTemplate = (props) => (
  <div className="Splash">
    <div className="Splash-header">

      <svg className="Splash-logo" width="256" height="256" />

      <div className="Splash-actions">
        <Typography display="block" variant="h5" paragraph>
          @Projav Project
       </Typography>

        <Typography display="block" variant="body2" paragraph>
          {props.message || 'Ciclo Insight'}
        </Typography>

        {Boolean(props.click) &&
          <Button variant="contained" onClick={props.click}>{props.clickMessage}</Button>
        }
      </div>
    </div>
  </div>
)


class RenderApp extends React.PureComponent {

  constructor(props) {
    super(props)

    /**@type {{coords: string, locationState: ('granted'|'prompt'|'denied'|'loading')}} App.state */
    this.state = {
      coords: '',
      locationState: 'loading'
    }

    this.getGeoLocation = this.getGeoLocation.bind(this);
    this.checkGeoLocation = this.checkGeoLocation.bind(this);
  }

  componentDidMount() {

    this.checkGeoLocation();

    setInterval(() => {
      this.getGeoLocation();

    }, 25000);
  }

  checkGeoLocation() {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if(result?.state){
          this.setState({ locationState: result.state });         

          if (result.state === 'granted') {
            this.getGeoLocation();
          }

        }

        result.onchange = () => {
          this.setState({ locationState: result.state });

          if (result.state === 'granted')
            this.getGeoLocation();
        };
      })
  }

  getGeoLocation() {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        emitGeolocation(position.coords);
        this.setState({ coords: position.coords.latitude + ',' + position.coords.longitude })

      }, () => {
        this.setState({ coords: 'err' })
      })
    }

  }

  render() {
    const { locationState } = this.state;

    if (locationState === 'loading')
      return <LoadingTemplate message={`Connectando ...`} />
      // || locationState === 'denied'
    if (locationState === 'prompt')
      return <LoadingTemplate message={`Esse app precisa saber sua localização`} clickMessage="Ativar Geolocalização" click={this.getGeoLocation} />

    if (!this.props.user.id && isAuthenticated()) {
      return <LoadingTemplate
        message="Connectando ..."
        click={() => logout(() => window.location.href = '/realtime')} clickMessage="Sair" />
    }

    return this.props.children;
  }
}

export default connect(state => ({ user: state.user }))(RenderApp);