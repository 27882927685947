/*eslint no-extend-native: ["error", { "exceptions": ["Object","ArrayBuffer","Array","String","Number","Function","Date"] }]*/

String.prototype.UcFirst = function () {
    return this.slice(0, 1).toUpperCase() + this.slice(1).toLowerCase();
}

// mask('00000', 11  );   // '00011'
// mask('00011','00' );   // '00000'
// mask( 2     , 3   );   // '3'
// mask('0'    ,'111');   // '1'
// mask('fork' ,'***');   // 'f***'
// mask('_____','dog');   // '__dog'

String.prototype.Format = function (mask, placeholder = '0') {
    var s = '' + this || '', r = '';
    while (s.length < mask.match(/#/g).length) s = placeholder + s;

    for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
        r += mask.charAt(im) === '#' ? s.charAt(is++) : mask.charAt(im);
    }
    return r;
}

String.prototype.getParentheses = function () {
    var regExp = /\(([^)]+)\)/;
    var matches = regExp.exec(this);
    return matches && matches[1] ? matches[1] : null
}

String.prototype.rmvParentheses = function () {
    return this.replace(/\s*\(.*?\)\s*/g, '');
}

String.prototype.ptBR = function (addHour) {
    var self = this;

    if (!self) return null;

    const date = new Date(self);
    let options = {};

    // const date = new Date(self.replace(/T|Z/g, ' '));
    // let options = { timeZone: 'America/Sao_Paulo' };

    if (addHour === true)
        options = { ...options, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    return (!isNaN(date.getTime())) ? new Intl.DateTimeFormat('pt-BR', options).format(date) : null;
}


Date.prototype.ptBR = function (addHour) {
    var data = this,
    dia  = data.getDate().toString(),
    diaF = (dia.length === 1) ? '0'+dia : dia,
    mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length === 1) ? '0'+mes : mes,
    anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF + (Boolean(addHour) ? ' ' + this.toLocaleTimeString() : '' );
  }

Date.prototype.toDate = function (substr) {
    if (substr === 'month')
    return new Date(this.getFullYear(), this.getMonth());

    if (substr === 'datetime-local')
        return this.toISOString().substring(0, 16);

    if (substr === 'date')
        return this.toISOString().substring(0, 10);

    return this.toISOString();

};

String.prototype.toDate = function (substr) {
    const date = new Date(this);

    if (substr === 'date')
        date.setHours(date.getHours() + 3);

    return date;
    // return new Date(this.replace(/T|Z/g, ' '));
};

String.prototype.CompareMinutes = function (current) {
    const date = new Date(this);
    current = current ? new Date(current) : new Date();

    var diff = Math.abs(date - current);

    return Math.floor((diff / 1000) / 60);
}

Date.prototype.CompareMinutes = function (current) {
    const date = this;
    current = current ? new Date(current) : new Date();

    var diff = Math.abs(date - current);
    
    return Math.floor((diff / 1000) / 60);
}

String.prototype.difference = function (today, what = 'mins') {
    var result = {};

    var Comporator = new Date(this);

    var diffMs = (Comporator - today); // milliseconds between now & Comporator

    result.Days = Math.floor(diffMs / 86400000); // days
    result.Hours = Math.floor((diffMs % 86400000) / 3600000); // hours
    result.Minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    return result;
}


// a extension from Number
String.prototype.monetizeme = function () {
    return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(this);
};

/**
 * six digits
 * 1 number
 * 1 special char
 * 1 uc letter
 */
String.prototype.testPassword = function () {
    return /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g.test(this);
};
String.prototype.testEmail = function () {
    return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(this);
};
String.prototype.testName = function () {
    // return /^(([A-Za-z]+[-']?)*([A-Za-z]+)?\s)+([A-Za-z]+[-']?)*([A-Za-z]+)?$/.test(this);
    return /^[a-z\u00C0-\u017F]{3,}([-']?[a-z\u00C0-\u017F]+)*( [a-z\u00C0-\u017F]{2,}([-']?[a-z\u00C0-\u017F]+)*)+$/gi.test(this);
};
String.prototype.Capitalize = function () {
    return this.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};



Object.defineProperty(String.prototype, 'CurrencyToFloat', {
    value: function StringToFloat() {
        let currency = this;

        let regex = /([+-]?[0-9|^.|^,]+)[.|,]([0-9]+)$/igm
        let result = regex.exec(currency);
        let floatResult = result ? result[1].replace(/[.,]/g, "") + "." + result[2] : currency.replace(/[^0-9-+]/g, "");

        return floatResult;
    }
})

Object.defineProperty(String.prototype, 'in', {
    value: function(array){
        return array.indexOf(this) !== -1;
    }
})

Object.defineProperty(String.prototype, 'Before', {
    value: function Before(match) {
        return this.indexOf(match) !== -1 ? this.substring(0, this.indexOf(match)).trim() : this
    }
})

Object.defineProperty(String.prototype, 'After', {
    value: function After(match) {
        return this.indexOf(match) !== -1 ? this.substring(this.indexOf(match) + 1).trim() : ''
    }
})


//---------------------EXCLUSIVE FOR THIS PROJECT
Object.defineProperty(String.prototype, 'asPriority', {
    value: function asPriority() {
        // console.log('String', this.valueOf());
        switch (this.valueOf()) {
            case 'MEDIUM': return 1;
            case 'HIGH': return 2;
            case 'URGENT': return 3;
            default: return 0;
        }
    }
});

Object.defineProperty(String.prototype, 'asStatusQuest', {
    value: function asStatusQuest() {
        switch (this.valueOf()) {
            case "NEW":  return 0;
            case "STOP": return 1;
            case "PLAY": return 2;
            case "DONE": return 3;
            case "CQ":   return 4;
            case "DROP": return 5;
            default:     return 5;
        }
    }
});

Object.defineProperty(String.prototype, 'asStatusColor', {
    value: function asStatusColor() {
        switch (this.valueOf()) {
            case "NEW":  return 'yellow';
            case "STOP": return 'black';
            case "PLAY": return 'orange';
            case "DONE": return 'cyan';
            case "CQ":   return 'blue';
            case "DROP": return 'red';
            default:     return false;
        }
    }
});

Object.defineProperty(String.prototype, 'asStatusText', {
    value: function asStatusText() {
        switch (this.valueOf()) {
            case "NEW":  return 'Novo';
            case "STOP": return 'Parado';
            case "PLAY": return 'Andando';
            case "DONE": return 'Concluído';
            case "CQ":   return 'Revisado';
            case "DROP": return 'Cancelado';
            default:     return null;
        }
    }
});

