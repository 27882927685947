import React from 'react'
import ReactDOM from 'react-dom'
import * as ServiceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import RenderApp from './Components/RenderApp'
import ThemeProvider from './services/useTheme'
import { Provider } from 'react-redux'
import store from './Reducers'

import 'react-virtualized/styles.css'
import 'fontsource-roboto'

import './index.css'
import './Js/number-prototypes';
import './Js/string-prototypes';
import './Js/object-prototypes';
import RenderPages from './Components/RenderPages';
import { BrowserRouter } from 'react-router-dom';
import { isLocalhost } from './services/setproxy';

ReactDOM.render(
  <ThemeProvider >
    <Provider store={store}>
      <RenderApp>
        <BrowserRouter>
          <RenderPages />
        </BrowserRouter>
      </RenderApp>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

if (isLocalhost)
  ServiceWorker.unregister();

else
  ServiceWorker.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();