import React from "react"
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps, dispatch } from './flux';
import { host_api } from "../../services/setproxy"
import { getToken } from "../../services/Auth"
import TopBar from "../../Components/ToastUtils/TopBar"
import TodoFilters from '../../Components/TodoFilters'
import TodoContextMenu from "../../Components/TodoContextMenu"
import TodoRows from "../../Components/TodoRows"
import socket from "../../services/socket";
import ContextMenu from '../../Components/ContextMenu'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

import { FlexBox, ScrollViewArea, Vertical } from '../../Components/StyledComponents';
import ScrollView from '../../Components/ScrollView';
import TodosUserAssociate from '../../Components/TodosUserAssociate';
import InputDialog from '../../Components/InputDialog';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


function TodosTask({ history, location, ...props }) {
  const [input, setInput] = React.useState("");
  const [change, setChange] = React.useState(false);
  const [element, setElement] = React.useState(false);
  const MenuRef = React.useRef({});

  const HandleSubmit = (e) => {
    e.preventDefault();
    socket.emit("task:will:create", { input });
    setInput("");
    e.target.reset();
    props.setStatus("NEW");
  };


  /**@type {Array<task>} */
  const tasks = React.useMemo(() => {
    return props.tasks.ToList().$filter(input);

  }, [props, input]);


  /**@param {Task} task */
  /**@param {Connect} data */
  const handleUserInTask = React.useCallback((task) => (data) => {
    socket.emit("task:will:connect", { uuid: task.uuid, users: data });

    if (data.disconnect && props.topics && props.toasts) {
      props.topics.filter(topic => topic.taskId === task.id && topic.users.map(x => x.user.id).indexOf(data.disconnect.id) !== -1)
        .map(topic => socket.emit("topic:will:connect", { uuid: topic.uuid, users: data }))


      props.toasts.filter(toast => toast.taskId === task.id && toast.users.map(x => x.user.id).indexOf(data.disconnect.id) !== -1)
        .map(toast => socket.emit("toast:will:connect", { uuid: toast.uuid, users: data }))
    }

  }, [props]);

  /** @param {task} task */
  const handleOpenMenu = React.useCallback((task) => (event) => {
    if(event) event.preventDefault();

    if(task.modes.includes("OWNER")){
      setElement(task);
      return MenuRef.current.Open(event)(); 
    }
    else {

      history.push(`/todos/edit/task/${task.uuid}`, { background: location });
    }

  }, [history, location]);


  React.useEffect(() => {
    if (element) {

    } else {
      MenuRef.current.Close();
    }

  }, [element]);

  const setCloseMenu = React.useCallback(() => {
    setElement(false);

  }, []);

  const handleRedirect = React.useCallback((target) => () => history.push(`/todos/task/${target.id}`), [history]);

  const handleEditTask = React.useCallback(() => {
    MenuRef.current.Close();
    history.push(`/todos/edit/task/${element.uuid}`, { background: location });
    setElement(false);

  }, [element, history, location]);


  const handleDeleteTask = React.useCallback(() => {

    if (window.confirm("Deseja excluir essa atividade")) {
      socket.emit("task:will:delete", { uuid: element.uuid });
      setElement(false);
    }

  }, [element]);

  /** @param {task} newState */
  const handleStatusChange = React.useCallback((newState) => () => {
    socket.emit('task:will:update', { uuid: element.uuid, status: newState });

  }, [element]);

  const openGallery = React.useCallback(() => {
    history.push(`/todos/gallery/task/${element.uuid}`, { background: location });

  }, [element, history, location]);

  const openComments = React.useCallback(() => {
    history.push(`/todos/comment/task/${element.uuid}`, { background: location });

  }, [element, history, location]);

  const openHistory = React.useCallback(() => {
    window.open(
      `${host_api}/documents/task/timeline/${element.uuid}?key=${getToken()}`,
      `${element.title}`,
      `width=${window.outerWidth}, height=${window.outerHeight}, top=0, left=0, scrollbars=no`
    );

    setElement(false);

  }, [element]);

  //#region [select checkbox]
  const checkbox = React.useMemo(() => {
    return tasks.filter(task => task.modes.includes('OWNER') && task.selected === true);

  }, [tasks]);

  const useMemoizedChecked = React.useMemo(() => {
    const Maps = tasks.filter(task => task.modes.includes('OWNER') ).map(e => e.uuid);
    const checkboxuuids = checkbox.map(e => e.uuid);

    return {
      every: Maps.every(uuid => checkboxuuids.includes(uuid)),
      some: Maps.some(uuid => checkboxuuids.includes(uuid))
    }

  }, [tasks, checkbox]);

  const handleChangeMultiple = React.useCallback((prop) => {
    tasks.forEach(task => {
      if(task.modes.includes('OWNER') && task.selected === true)
        dispatch.task.willUpdate({ uuid: task.uuid, ...prop })
    })

  }, [tasks]);

  const handleCheckSome = React.useCallback(() => {
    if (!useMemoizedChecked.every)
      tasks.forEach(e => e.modes.includes('OWNER') && props.setSelect({ uuid: e.uuid, selected: true }))
    else
      tasks.forEach(e => props.setSelect({ uuid: e.uuid, selected: false }))

  }, [tasks, useMemoizedChecked, props]);
  //#endregion [select checkbox]

  return (
    <Grid container>
      <Grid item xs={12} >
        <ScrollViewArea heightmobile="calc(100vh - 116px)" disablePadding={true} dense={true}>

          <ContextMenu ref={MenuRef}>
            <TodoContextMenu
              onClose={setCloseMenu}
              menuItem={tasks.find(e => e.id === element.id)}
              onOpenHistory={openHistory}
              onGallery={openGallery}
              onComments={openComments}
              onSelect={handleEditTask}
              onDelete={handleDeleteTask}
              onChangeStatus={handleStatusChange}

            />
          </ContextMenu>

          <TodoFilters task={props.tasks} />

          <TopBar
            input={input}
            setInput={setInput}
            onSubmit={HandleSubmit}
            placeholder="Pesquisar/Adicionar nova atividade em branco"
            startActions={
              <Checkbox
                color="primary"
                name="checked_control_all"
                onChange={handleCheckSome}
                checked={useMemoizedChecked.every}
                indeterminate={useMemoizedChecked.some && !useMemoizedChecked.every}
              />
            }
            actions={tasks.length > 0 &&
              <FlexBox>
                <Vertical orientation="vertical" flexItem />
                <TodosUserAssociate
                  disabled={checkbox.length === 0}
                  event="task"
                  toasts={checkbox}
                />
                <IconButton disabled={checkbox.length === 0} size="small"
                  onClick={() => setChange({
                    model: { begin: x => x.toDate('date') },
                    type: 'date',
                    callback: handleChangeMultiple,
                    header: `Datas iniciais de ${checkbox.length} tópicos`,
                    value: new Date().toDate('date')
                  })}>
                  <AccessTimeIcon />
                </IconButton>
                <IconButton disabled={checkbox.length === 0} size="small"
                  onClick={() => setChange({
                    model: { finish: x => x.toDate('date') },
                    type: 'date',
                    callback: handleChangeMultiple,
                    header: `Datas finais de ${checkbox.length} tópicos`,
                    value: new Date().toDate('date')
                  })}>
                  <AccessAlarmIcon />
                </IconButton>
              </FlexBox>
            }
          />

          <InputDialog {...change} onClose={() => setChange(false)} />

          <ScrollView
            boxHeight="calc(100% - 98px)"
            rowCount={tasks.length}
            rowHeight={66}>
            {({ index, style, key }) => {
              return (
                <TodoRows
                  key={key}
                  style={style}
                  task={tasks[index]}
                  counter={tasks[index].counters.count}
                  peso={tasks[index].counters.donePercentage}
                  cq={tasks[index].counters.cqPercentage}
                  onNavigate={handleRedirect}
                  onContext={handleOpenMenu}
                  onConnectUser={handleUserInTask}
                  onSelect={() => props.setSelect({ uuid: tasks[index].uuid, selected: !tasks[index].selected })}
                />
              )
            }}
          </ScrollView>
        </ScrollViewArea>
      </Grid>
    </Grid >
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(TodosTask);
