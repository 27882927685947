import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { UploadButton, UserAvatar } from '../../Components/AvatarUserIcon';

import CameraIcon from '@material-ui/icons/Camera';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MailIcon from '@material-ui/icons/Mail';

import 'react-html5-camera-photo/build/css/index.css';
import { upload } from '../../services/Auth';
import socket from '../../services/socket';
import { useHistory } from 'react-router-dom';

import CameraPreview from './CameraPreview';

function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return (true)
    }
    return (false)
}

function MyAccount() {
    const [disabled, setDisabled] = useState([]);
    const user = useSelector(state => state.user);
    const history = useHistory()

    const [data, setData] = useState(user);

    const handleDisable = useCallback((key, remove = false) =>
        (remove) ? setDisabled(disabled.filter(e => e !== key)) : setDisabled([...disabled, key])
        , [disabled])

    const setName = useCallback((e) => {
        const value = e.target.value;
        handleDisable('name', value.length > 4)
        setData({ ...data, name: value.Capitalize() });

    }, [data, handleDisable])

    const setLogin = useCallback((e) => {
        const value = e.target.value;
        handleDisable('login', value.length > 4)
        setData({ ...data, login: value });

    }, [data, handleDisable])

    const setMail = useCallback((e) => {
        const value = e.target.value;
        handleDisable('email', value.includes('@') && value.includes('.') && ValidateEmail(value))
        setData({ ...data, email: value });

    }, [data, handleDisable])


    const handleTakeImage = useCallback((e) => {

        upload(`/gallery/upload/image/user/${user.uuid}`, e.target)
            .then(() => socket.emit('admin:users:will:change', { uuid: user.uuid }))
            .then(() => e = null)

    }, [user]);


    const handlePasswordReset = useCallback(() => {
        socket.emit("admin:users:recoveryPassword", user);
        handleDisable('password');

    }, [user, handleDisable])

    const handleRevalidateMail = useCallback(() => {
        socket.emit("admin:users:sendMailValidation", { user });
        socket.emit("snackbar", { message: 'Revalidando seu email' });
        handleDisable('emailsend');
    }, [user, handleDisable])


    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        handleDisable('login');
        socket.emit("admin:users:will:update", { user, data });

    }, [user, data, handleDisable])

    return (
        <Grid container style={{ height: '100%' }} alignItems="center" justify="center">
            <Grid item xs={12} md={4}>
                <Card component="form" onSubmit={handleSubmit}>
                    <CardHeader
                        avatar={<UserAvatar user={user} />}
                        title={user.name}
                        subheader={[user.role.name, ' ', user.faction.name]}
                        action={
                            <Fragment>
                                <UploadButton multiple={false} onFireUpload={handleTakeImage} />
                                <Tooltip title="Tirar uma foto">
                                    <IconButton onClick={() => history.push('#camera')}>
                                        <CameraIcon />
                                    </IconButton>
                                </Tooltip>
                            </Fragment>
                        }

                    />
                    <CardContent>

                        <Typography variant="subtitle2">
                            Editar
                        </Typography>
                        <Typography variant="caption" color="secondary" gutterBottom>
                            Valido até {user.validity.ptBR()}
                        </Typography>

                        <TextField margin="dense" onChange={setName} fullWidth value={data.name} label="Nome" />
                        <TextField margin="dense" onChange={setLogin} fullWidth value={data.login} label="Login" />

                        <TextField
                            onChange={setMail}
                            fullWidth
                            error={!user.emailValidation || disabled.has("email")}
                            helperText={user.emailValidation ? `${user.email} é um email valido` : "Valide esse email"}
                            margin="dense"
                            value={data.email}
                            label="E-mail"

                        />

                    </CardContent>
                    <CardActions>
                        <IconButton variant="outlined" disabled={!user.emailValidation || user.email !== data.email || disabled.has('password')} onClick={handlePasswordReset}>
                            <Tooltip title="Redefinir senha">
                                <LockOpenIcon />
                            </Tooltip>
                        </IconButton>

                        <IconButton variant="outlined"
                            disabled={!user.email || !user.emailValidation || disabled.has('emailsend')}
                            onClick={handleRevalidateMail}>
                            <Tooltip title="Reenviar validar email">
                                <MailIcon />
                            </Tooltip>
                        </IconButton>

                        <Button type="submit"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            disabled={(user.name === data.name && user.email === data.email && user.login === data.login) || disabled.has('email') || disabled.has('name') || disabled.has('login')}>
                            Salvar alterações
                        </Button>

                    </CardActions>
                </Card>
            </Grid>
            <CameraPreview user={user} />
        </Grid>
    )
}

export default MyAccount;