import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Gallery } from '../../Components/AvatarUserIcon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function GalleryDrawer(props) {
  const { mode, uuid } = useParams();
  const todo = useSelector(state => state[mode] && state[mode].find(e => e.uuid === uuid));

  const [open, setOpen] = React.useState(false);
  const setClose = () => {
    setOpen(false);
    setTimeout(props.history.goBack, 555);
  }
  React.useEffect(() => {
    if (Boolean(props.location.state?.background))
      setOpen(true);

  }, [props.location]);

  if (!todo || !todo?.files) return null;

  const files = todo.files.filter(e => (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(e.name))

  return (
    <SwipeableDrawer anchor="bottom" disableDiscovery disableSwipeToOpen open={open} onClose={setClose} onOpen={() => true}>
        <DialogTitle>Galeria {todo.title}</DialogTitle>
        <DialogContent dividers>
          {
            files.length === 0
              ? "Galeria de imagens vazia"
              : <Gallery items={files} style={{height: 400}} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={setClose}>Sair</Button>
        </DialogActions>
    </SwipeableDrawer>
  );
}

export default GalleryDrawer;