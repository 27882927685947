import React from 'react';
import Image from '../Image';
import ImageGrid from '../ImageGrid';

export default class InputFile extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    /**@param {Event} e */
    bindChange = (e) => {
        return this.props.multiple ? this.props.onChange(e.target.files) : this.props.onChange(e.target.files[0]);
        // return this.props.onChange(e.target);
    }

    render() {
        const { component: Component, multiple, preview, onChange, accept, ...props } = this.props;
        const node = this.inputRef.current;

        return (
            <label style={{width: '100%'}}>
                <input
                    accept={accept}
                    ref={this.inputRef}
                    type="file"
                    multiple={multiple}
                    style={{ display: 'none' }}
                    onChange={this.bindChange}
                />

                <Component type="text" {...props} onClick={() => node.focus()} />
                {multiple
                    ? Boolean(preview?.length > 0) && <ImageGrid tiles={preview} />
                    : Boolean(preview?.name) && <Image src={URL.createObjectURL(preview)} title={preview.name} />
                }

            </label>

        )
    }
}
