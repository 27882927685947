import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabInfo from './TabInfo';
import TabContacts from './TabContacts';
import TabAddress from './TabAddress';
import { GridOverflowed } from '../../Components/StyledComponents';

/**
 * 
 * @typedef {import('../../typeroots').ClientWeb} Client
 * 
 */

function CommerceClientEdit() {
  const { uuid } = useParams();

  /**
   * 
   * @type {Client}
   * 
   */

  const [currentTab, setCurrentTab] = useState(0);

  const client = useSelector(state => state.clients.find(e => e.uuid === uuid));

  return (
    <Fragment>
      <Grid container justify="center">
        <GridOverflowed item xs={12} md={6}>
          <Card>
            <CardHeader
              title={client.name}
              subtitle={client.birthday}
              avatar={
                <Avatar alt={client.name} src={`/static/uploads/${client.image}`} />
              }
            />
            <CardContent>
                <Tabs value={currentTab} centered onChange={(e,v)=>setCurrentTab(v)}>
                  <Tab label="Endereços"></Tab>
                  <Tab label="informaçoes"></Tab>
                  <Tab label="Contatos"></Tab>
                </Tabs>
                <TabAddress  tab={currentTab === 0} client={client} />
                <TabInfo     tab={currentTab === 1} client={client} />
                <TabContacts tab={currentTab === 2} client={client} />
            </CardContent>
            <CardActions>
                            
            </CardActions>
          </Card>
        </GridOverflowed>
      </Grid>
    </Fragment>
  );
}

export default CommerceClientEdit;