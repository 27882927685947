import React from 'react';
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {
    StaticDateRangePicker,
    LocalizationProvider
} from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import TaskReducer from '../../Reducers/TasksReducers'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery';
/**
 * 
 * @param { import('react-router').RouterProps & {location: { state: { task: Task|Topic|Toast, background: LocationRouterDom } } }} props
 * 
 */

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


//onClick={() => props.history.push( "#dateRangePicker", {task, ...props.location.state} ) }
function DateRangePicker(props) {

    const [values, setValues] = React.useState([]);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);

    const close = () => {
        setOpen(false);
        props.history.goBack();
    };

    React.useEffect(() => {
        if (props.location.state?.task)
            setValues([props.location.state.task.begin, props.location.state.task.finish]);

        if(Boolean(props.location.hash === "#dateRangePicker"))
            setOpen(true);

        return () => {
            setOpen(false);
        }

    }, [props.location])

    if (!props.location.state?.task) return null;

    const verify = () => {
        if (!props.location.state.task.uuid) return close();

        return true;

    }

    const handleValues = (values) => {
        setValues(values)
    }

    const resetValues = () => {
        setValues([props.location.state.task.begin, props.location.state.task.finish])
    }

    const handleSave = () => {
        const [begin, finish] = values;

        const { dispatch } = new TaskReducer(props.location.state.mode);
        dispatch.willUpdate({ uuid: props.location.state.task.uuid, begin, finish });

        return close();
    }

    return (
        <Dialog
            maxWidth="lg"
            fullScreen={isMobile}
            TransitionComponent={Transition}
            open={open}
            onEnter={verify}
            onClose={close}
        >

            <DialogTitle id="dateTimePicker">
                <Typography>Calendário De Range</Typography>
                <Typography variant="caption">Editar {props.location.state.task?.title || ''}</Typography>
                <IconButton onClick={close} style={{ right: 5, top: 5, position: "absolute" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <LocalizationProvider locale={ptBR} dateAdapter={DateFnsUtils} >
                    <StaticDateRangePicker
                        displayStaticWrapperAs={isMobile ? "mobile" : "desktop"}
                        onChange={handleValues}
                        onAccept={handleValues}
                        renderInput={() => null}
                        value={values}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetValues}>Reset</Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSave}
                    disabled={!values[0] || !values[1] || (values[0] === props.location.state.task.begin && values[1] === props.location.state.task.finish)}>
                    Salvar e sair
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DateRangePicker;