import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function StatusSelectorDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;

/**@typedef {STATUS} value*/
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Estado de atividade</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value="NEW" control={<Radio />} label="Novo" />
                    <FormControlLabel value="STOP" control={<Radio />} label="Parado" />
                    <FormControlLabel value="PLAY" control={<Radio />} label="Produzindo" />
                    <FormControlLabel value="DONE" control={<Radio />} label="Feito" />
                    <FormControlLabel value="CARTE" control={<Radio />} label="Cardápio" />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleOk} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

StatusSelectorDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

export default function StatusSelector(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            props.onChange({ target: { value: newValue } })
        }
    };

    return (
        <>
            <ListItemText
                onClick={handleClickListItem}
                primary="Status"
                secondary={props.value} />

            <StatusSelectorDialog
                open={open}
                onClose={handleClose}
                value={props.value}
                classes={{
                    paper: classes.paper,
                }}
            />

        </>
    );
}