// const isLocalhost = Boolean(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    // IP
    window.location.hostname.match(
      /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/
    )
  );

export const embeded = Boolean(window!==window.top);

export const web = "https://heaconsultoria.com.br/"
export const wss = isLocalhost === true ? '//localhost:3001/projav' : '//projav.herokuapp.com/projav';
export const host_api = isLocalhost === true ? '//localhost:3001' : '//projav.herokuapp.com';
export const host_gallery = isLocalhost === true ? 'https://heaconsultoria.com.br/static/upload/' : '/static/upload/';

console.log({ isLocalhost, embeded });

/**
 * @param {string} p 
 * @param {boolean} thumb 
 */
 export const fromGallery = (p, thumb = false) => {
  if(!p) return null;

  if(typeof p === 'string')
  return host_gallery + p + (thumb ? '_thumb' : '');

  else {
      for (let i = 0; i < p.length; i++) {
          if(p[i]) 
          return host_gallery + p[i] + (thumb ? '_thumb' : ''); 
      }
  }
};
