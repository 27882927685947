import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
    const { children, container } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: container || undefined,
        threshold: 500,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.object,
};

export default React.forwardRef(({ groups, ...props }, ref) => {

    const [tab, setTab] = React.useState(0);

    ref.current.setTab = setTab;

    return (
        <AppBar color="inherit" position="sticky" component={Tabs} value={tab}>

            <Tab
                label="Cardápio"
                onClick={() => props.container.current.scrollTop = 0}
            />

            {groups.map((group, i) => {
                return group && (
                    <Tab
                        id={group + '-tab-' + i}
                        key={group + '-tab-' + i}
                        onClick={() => props.container.current.scrollTop = props.sections[group]?.current.offsetTop - 56}
                        label={group}
                    />
                )
            })}

        </AppBar>
    );
})
