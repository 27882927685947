
//transform base64 into blob
export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

      var blob = new Blob(byteArrays, {type: contentType});

  return blob;
}

// get base64 from camera and convert into uploadable file list
export const ConvertIntoImage = (dataUri, fileName) => {
    var block = dataUri.split(";");
    // Get the content type
    var contentType = block[0].split(":")[1];// In this case "image/jpg"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
    // Convert to blob
    var blob = b64toBlob(realData, contentType);
    //send as filelist input type    
    return new File([blob], fileName, { type: contentType });
}
