import React from 'react';
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { StyledBadge } from '../../Components/StyledComponents';

/**@param { import('../../typeroots').Task & {click: Function, dispatch: Function, actions: import('react').ReactElement }} props */
export default function InnerTimelineText(props) {

    if (props.modes.includes("VIEWER"))
        return (
            <ListItem component="span" button onContextMenu={props.click} onClick={props.defaultClick}>
                <ListItemText primary={`${props.title}`} secondary={props.description || "Sem comentários"} />
            </ListItem>
        )

    if (props.status === "NEW")
        return (
            <ListItem component="span" button onContextMenu={props.click} onClick={() => props.dispatch.willUpdate({ uuid: props.uuid, status: 'PLAY' })}>
                <ListItemText primary={`${props.title}`} secondary={<>Iniciar <br /> {props.description} </>} />
            </ListItem>
        )

    return (
        <ListItem component="span" button onContextMenu={props.click} onClick={props.click}>
            <StyledBadge color={"secondary"} badgeContent={props.comments?.length}>
                <ListItemText
                    primary={`${props.title}`}
                    secondary={<>
                        <Typography variant="caption">
                            {(["STOP", "DONE", "DROP"].includes(props.status)) && <>
                                {props.status.asStatusText()} em: {props.done.ptBR(true)}
                                <br />
                            </>}
                            {(props.status === "PLAY") && <>
                                {props.begin.ptBR()} - {props.finish.ptBR()}
                                <br />
                            </>}
                            {props.description || "Sem Comentários"}
                        </Typography>
                    </>}
                />
            </StyledBadge>
            <ListItemSecondaryAction>
                <Hidden mdDown>
                    {props.actions}
                </Hidden>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
