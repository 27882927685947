import { makeStyles } from '@material-ui/core/styles'
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { host_gallery } from '../../services/setproxy';

const useStyles = makeStyles(props => ({
    root: {
        width: '100%'
    },
    tile: {
        height: 400,
        width: '100%',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'auto',
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat'
    }
}))


export default function CommerceCarousel(props) {

    const classes = useStyles(props.height);

    return !props.files ? null : (
        <Carousel className={classes.root} animation={props.animation} interval={7500}>
            {props.files.map(file =>
                <div className={classes.tile} key={file.file} style={{ backgroundImage: `url(${ host_gallery + file.file})` }} />
            )}
        </Carousel>
    )
}