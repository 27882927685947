import React from 'react';
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import socket from '../../services/socket'
import { mapStateToProps, mapDispatchToProps, dispatch } from './flux';

import { useParams } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

import { FlexBox, ListView, ScrollViewArea, SubHeader, Vertical } from '../../Components/StyledComponents';
import TodoEdit from '../../Components/TodoEdit'
import TopBar from '../../Components/ToastUtils/TopBar'
import TodoFilters from '../../Components/TodoFilters';
import ContextMenu from '../../Components/ContextMenu';
import TodoContextMenu from '../../Components/TodoContextMenu';
import ScrollView from '../../Components/ScrollView';
import TodoRows from '../../Components/TodoRows';
import TodosUserAssociate from '../../Components/TodosUserAssociate';
import InputDialog from '../../Components/InputDialog';

import ClearAllIcon from '@material-ui/icons/ClearAll'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

function TodosTopic(props) {
    const MenuRef = React.useRef({});

    const [ListWillShow, setListWillShow] = React.useState(false);
    const [element, setElement] = React.useState(false);
    const [change, setChange] = React.useState(false);
    const [input, setInput] = React.useState('');

    const { taskId, topicId } = useParams();

    const toggleListWillShow = () => ListWillShow === true ? setListWillShow(false) : setListWillShow(true)

    const task = React.useMemo(() => {
        return props.tasks.find(e => Number(taskId) === e.id) || {};

    }, [props.tasks, taskId])

    const topics = React.useMemo(() => {
        return props.topics.ToList(x => x.taskId === Number(taskId)).$filter(input);

    }, [props.topics, taskId, input])


    const handleUserInTask = (task) => (data) => {
        socket.emit("task:will:connect", { uuid: task.uuid, users: data });

        if (data.disconnect && topics && props.toasts) {
            topics
                .filter(topic => topic.taskId === task.id && topic.users.map(x => x.user.id).indexOf(data.disconnect.id) !== -1)
                .map(topic => socket.emit("topic:will:connect", { uuid: topic.uuid, users: data }))

            props.toasts
                .filter(toast => toast.taskId === task.id && toast.users.map(x => x.user.id).indexOf(data.disconnect.id) !== -1)
                .map(toast => socket.emit("toast:will:connect", { uuid: toast.uuid, users: data }))
        }
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        socket.emit("topic:will:create", { input: input, taskId: task.id, finish: task.finish, begin: task.begin });
        setInput('');
        e.target.reset();
        props.setStatus("NEW");
    }

    const handleOpenMenu = React.useCallback((task) => (event) => {
        if (event) event.preventDefault();

        if (task.modes.includes("OWNER")) {
            setElement(task);
            MenuRef.current.Open(event)()
        } else {

            props.history.push(`/todos/edit/topic/${task.uuid}`, { background: props.history.location })
        }

    }, [props.history]);

    const handleCloseMenu = () => {
        setElement(false);
        MenuRef.current.Close();
    };

    const handleRedirect = (target) => () => Boolean(Number(topicId) === target.id)
        ? props.history.goBack()
        : props.history.push(`/todos/task/${target.taskId}/${target.id}`);


    const handleUserInTopic = React.useCallback(topic => (data) => {
        socket.emit('topic:will:connect', { uuid: topic.uuid, users: data });

        if (data.disconnect && props.toasts) {
            props.toasts
                .filter(toast => toast.topicId === topic.id && toast.users.map(x => x.user.id).indexOf(data.disconnect.id) !== -1)
                .map(toast => socket.emit("toast:will:connect", { uuid: toast.uuid, users: data }))

        }
    }, [props.toasts])

    const handleEditTopic = React.useCallback(() => {
        props.history.push(`/todos/edit/topic/${element.uuid}`, { background: props.history.location })
        handleCloseMenu();

    }, [element, props]);

    const handleDeleteTopic = React.useCallback(() => {
        if (window.confirm("Deseja excluir esse tópico")) dispatch.topic.willDelete(element);
        handleCloseMenu();

    }, [element]);

    const openComments = React.useCallback(() => {
        props.history.push(`/todos/comment/topic/${element.uuid}`, { background: props.location });

    }, [element, props.history, props.location]);

    //#region [select checkbox]
    const checkbox = React.useMemo(() => {
        return topics.filter(task => task.modes.includes("OWNER") && task.selected === true);

    }, [topics]);

    const useMemoizedChecked = React.useMemo(() => {
        const Maps = topics.filter(task => task.modes.includes("OWNER")).map(e => e.uuid);
        const checkboxuuids = checkbox.map(e => e.uuid);

        return {
            every: Maps.every(uuid => checkboxuuids.includes(uuid)),
            some: Maps.some(uuid => checkboxuuids.includes(uuid))
        }

    }, [topics, checkbox]);

    const handleChangeMultiple = React.useCallback((prop) => {
        topics.forEach(topic => {
            if (topic.modes.includes("OWNER") && topic.selected === true)
                dispatch.topic.willUpdate({ uuid: topic.uuid, ...prop })
        })

    }, [topics]);


    const handleCheckSome = React.useCallback(() => {
        if (!useMemoizedChecked.every)
            topics.forEach(e => e.modes.includes("OWNER") && props.setSelect({ uuid: e.uuid, selected: true }))
        else
            topics.forEach(e => props.setSelect({ uuid: e.uuid, selected: false }))

    }, [topics, useMemoizedChecked, props]);
    //#endregion [select checkbox]

    if (!task.users) return null;

    return (
        <>
            <Grid container spacing={1} onContextMenu={e => e.preventDefault()} >
                <Hidden mdDown>
                    <Grid item xs={3}>
                        {ListWillShow ?
                            <ListView dense>
                                <SubHeader>
                                    Lista de Atividades
                                    <div>
                                        <IconButton size="small" onClick={toggleListWillShow}>
                                            <ClearAllIcon />
                                        </IconButton>
                                    </div>
                                </SubHeader>
                                {props.tasks.map(task =>
                                    <ListItem divider button key={task.id} selected={task.id === Number(taskId)} onClick={() => props.history.push(`/todos/task/${task.id}`)}>
                                        <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItem>)}
                            </ListView>
                            : <TodoEdit event="task" onExit={toggleListWillShow} task={task} onConnectUser={handleUserInTask} />
                        }
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={9}>
                    <ScrollViewArea heightmobile="calc(100vh - 116px)" disablePadding={true} dense={true} >

                        <ContextMenu ref={MenuRef}>
                            <TodoContextMenu
                                menuItem={topics.find(e => e.id === element.id)}
                                onClose={handleCloseMenu}
                                onComments={openComments}
                                onSelect={handleEditTopic}
                                onDelete={handleDeleteTopic}
                                onChangeStatus={(status) => () => dispatch.topic.willUpdate({ uuid: element.uuid, status: status })}
                                onChangeType={(el, value) => dispatch.topic.willUpdate({ uuid: element.uuid, useAsModel: value })}
                                onChangePeso={(el, value) => dispatch.topic.willUpdate({ uuid: element.uuid, peso: value, cq: value })}
                                onChangePrice={(el, value) => dispatch.topic.willUpdate({ uuid: element.uuid, price: String(value) })}
                                onChangeGroup={(el, value) => dispatch.topic.willUpdate({ uuid: element.uuid, group: value })}
                                onChangePriority={(el, value) => dispatch.topic.willUpdate({ uuid: element.uuid, group: value.asPriority() })}
                            />
                        </ContextMenu>

                        <TodoFilters task={props.topics.filter(e => e.taskId === task.id)} />

                        <TopBar
                            setInput={setInput}
                            onSubmit={HandleSubmit}
                            placeholder="Pesquisar/Adicionar Novo tópico em branco"
                            startActions={
                                <Checkbox
                                    color="primary"
                                    name="checked_control_all"
                                    onChange={handleCheckSome}
                                    checked={useMemoizedChecked.every}
                                    indeterminate={useMemoizedChecked.some && !useMemoizedChecked.every}
                                />

                            }
                            actions={topics.length > 0 &&
                                <FlexBox>
                                    <Vertical orientation="vertical" flexItem />
                                    <TodosUserAssociate
                                        disabled={checkbox.length === 0}
                                        event="topic"
                                        toasts={checkbox}
                                    />
                                    <IconButton disabled={checkbox.length === 0} size="small"
                                        onClick={() => setChange({
                                            model: { begin: x => x.toDate('date') },
                                            type: 'date',
                                            callback: handleChangeMultiple,
                                            header: `Datas iniciais de ${checkbox.length} tópicos`,
                                            value: new Date().toDate('date')
                                        })}>
                                        <AccessTimeIcon />
                                    </IconButton>
                                    <IconButton disabled={checkbox.length === 0} size="small"
                                        onClick={() => setChange({
                                            model: { finish: x => x.toDate('date') },
                                            type: 'date',
                                            callback: handleChangeMultiple,
                                            header: `Datas finais de ${checkbox.length} tópicos`,
                                            value: new Date().toDate('date')
                                        })}>
                                        <AccessAlarmIcon />
                                    </IconButton>
                                    <IconButton disabled={checkbox.length === 0} size="small" onClick={() => props.topics.Download({ type: 'csv', title: task.title, uuids: checkbox.map(e => e.uuid) })}>
                                        <SystemUpdateAltIcon />
                                    </IconButton>
                                </FlexBox>
                            }
                        />

                        <InputDialog {...change} onClose={() => setChange(false)} />

                        <ScrollView boxHeight="calc(100% - 98px)" rowCount={topics.length} rowHeight={66}>
                            {({ index, style, key }) =>
                                <TodoRows
                                    key={key}
                                    task={topics[index]}
                                    style={style}
                                    counter={topics[index].counters.count}
                                    peso={topics[index].counters.donePercentage}
                                    cq={topics[index].counters.cqPercentage}
                                    onContext={handleOpenMenu}
                                    onNavigate={handleRedirect}
                                    onConnectUser={handleUserInTopic}
                                    onSelect={() => props.setSelect({ uuid: topics[index].uuid, selected: !topics[index].selected })}
                                />
                            }
                        </ScrollView>

                    </ScrollViewArea>
                </Grid>

            </Grid>
        </>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TodosTopic))