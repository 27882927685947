import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import GroupAdd from '@material-ui/icons/GroupAdd'
import { connect } from 'react-redux';

import TimelineIcon from '@material-ui/icons/Timeline';
import useStyles from './styles';
// import PersonAddIcon from '@material-ui/icons/PersonAdd'
// options={props.administrativeUsers}
// task={props.task}
// value={props.current}
// onChange={props.onChange}

const SecondaryActionButton = ({ secondaryActionClick }) =>
(
    <ListItemSecondaryAction onClick={secondaryActionClick}>
        <IconButton size="small">
            <TimelineIcon />
        </IconButton>
    </ListItemSecondaryAction>
)

/**@param {{administrativeUsers: import('../../typeroots').AdminUser, current: import('../../typeroots').AdminUser}} props*/ 

function AvatarAddIcon({
    administrativeUsers,
    modalHeaderComponent,
    options,
    onClose,
    color,
    current: currentUsers,
    onChange,
    dispatch,
    secondaryActionClick,
    includeself = false, ...props
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState([]);
    const FormGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (currentUsers && open) {
            setValue(currentUsers?.map(e => e.user?.id || e.id) || []);
        }

    }, [currentUsers, open]);


    const handleChange = (event) => {
        const eventId = Number(event.target.value);

        var newValue = value
            , eventFinal = {};

        if (newValue.rmv(e => e === eventId)) {
            eventFinal.disconnect = { id: eventId };

        } else {
            newValue.concat(eventId);
            eventFinal.connect = { id: eventId };
        }

        setValue(newValue);

        return onChange(eventFinal, eventId);

    };

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

        if (onClose) onClose();
    };

    return (
        <>
            {props.children
                ? <div onClick={handleClickListItem}>{props.children}</div>
                : <IconButton color={color || "primary"} onClick={handleClickListItem} size={props.size || 'medium'}><GroupAdd /></IconButton>}


            <Dialog
                classes={{
                    paper: classes.paper,
                }}
                id="administrativeUsers-selector-menu"
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                {...props}
            >
                <DialogTitle id="confirmation-dialog-title">{modalHeaderComponent || "Usuários do projeto"}</DialogTitle>
                <DialogContent dividers>
                    <List dense={true}>
                        <FormGroup ref={FormGroupRef} value={value} onChange={handleChange}>

                            {includeself &&
                                <ListItem divider>
                                    <FormControlLabel
                                        key={props.user.login}
                                        control={<Checkbox checked={value.indexOf(props.user.id) !== -1} />}
                                        value={props.user.id}
                                        label={props.user.name}
                                    />

                                    {Boolean(secondaryActionClick) &&
                                        <SecondaryActionButton secondaryActionClick={secondaryActionClick(props.user.id)} />
                                    }

                                </ListItem>
                            }

                            {(options || administrativeUsers).map((user) => (user.id !== props.user.id &&
                                <ListItem key={user.login} divider>
                                    <FormControlLabel
                                        style={{opacity: user.onLine ? 1 : .65 }}
                                        control={<Checkbox checked={value.indexOf(user.id) !== -1} />}
                                        value={user.id}
                                        label={user.name} />

                                    {Boolean(secondaryActionClick) &&
                                        <SecondaryActionButton secondaryActionClick={secondaryActionClick(user.id)} />
                                    }
                                </ListItem>
                            ))}
                        </FormGroup>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const stateToProps = state => ({
    user: state.user,
    administrativeUsers: state.adminUsers
})

export default connect(stateToProps)(AvatarAddIcon)