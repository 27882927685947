import React from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import Fade from '@material-ui/core/Fade'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { AutoSizer, List as VirtualList } from 'react-virtualized'

import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'

export default function CodenamesList(props) {

  const [input, setInput] = React.useState('');
  const [error, setError] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [filteredList, setFilteredList] = React.useState([]);

  const HandleChange = (value) => {
    setInput(value);
    setError(false);
    setFilteredList(props.codenames.filter(e => e.label.includes(value)))
  }

  const SaveChanges = () => {
    if (props.OnAddInput(input)) {
      setTimeout(() => {
        setAdd(false);
        setError(false);
      }, 600);
    }
    else {
      setError(true);
    }

    return setFilteredList(props.codenames.filter(e => e.label.includes(input)))
  }

  // because it need to reload list when props.codenames changes 
  React.useEffect(() => {
    HandleChange(input);

    // eslint-disable-next-line 
  }, [props.codenames.length, input]);

  React.useEffect(() => {
    if (input.length > 2 && filteredList.length !== 1) setAdd(true); else setAdd(false);

  }, [filteredList, input.length]);

  return (
    <Fade in={props.visibility} mountOnEnter unmountOnExit>
      <div className="max-height no-overflow">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{
              width,
              height
            }}>
              <List dense={true} subheader={<ListSubheader>Cadastro & pesquisa</ListSubheader>} >
                <ListItem >
                  <TextField fullWidth autoComplete="off"
                    autoFocus
                    value={input}
                    onChange={(e) => HandleChange(e.target.value)}
                    variant="outlined"
                    size="small"
                    id="add-new-codespace"
                    label={error ? "Forma incorreta de uso" : "Pesquisar codenome ..."}
                    error={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment   >
                          <IconButton size="small" aria-label="limpar campo" onClick={() => HandleChange('')}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment  >
                      )
                    }}
                  />
                </ListItem>
              </List>
              <List dense={true} subheader={<ListSubheader>Codenomes Cadastrados</ListSubheader>} >
                {add &&
                  <ListItem button onClick={() => SaveChanges()} >
                    <ListItemText primary={`Criar ${input}?`} secondary={Boolean(props.selected) ? `Adicionar a ${props.selected}` : "Clique para adicionar a lista"}  />
                  </ListItem>
                }

                <VirtualList
                  width={width}
                  height={height - 268}
                  rowCount={filteredList.length}
                  rowHeight={60}
                  rowRenderer={({ key, index, style }) => (
                      <ListItem divider button key={`codespaces-${key}`} ContainerProps={{ style: style }} ContainerComponent="div" onClick={() => props.OnSelect(filteredList[index])}>
                        <ListItemText 
                          primary={filteredList[index].label} 
                          secondary={`${filteredList[index].refer} ${filteredList[index].caption}`} 
                          />
                        {Boolean(props.OnDelete) &&
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => props.OnDelete(filteredList[index])} size="small" edge="end" aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        }
                      </ListItem>
                  )}
                />
              </List>
            </div>
          )}
        </AutoSizer>
      </div>
    </Fade>
  )
}