import { styled } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";

export const Container = styled(Grid)({
    width: '100%',
    height: '100%',
    borderRight: 'thin solid grey', 
    overflow: 'auto'
})

export const Actions = styled(CardActions)({
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '& .MuiAvatar-root': {
        marginRight: 'auto'
    }
})